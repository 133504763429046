import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import './CurrentUser.scss'
import { useAuthentication } from '../useAuthentication'
import { Roles } from '../Roles'
import ChangeAreaTrigger from '../../features/area/area-resolver/change-area.trigger'
import { AreasStore } from '../../store/areas.store'
import { getPagePath } from '../../router/utils'
import { UserStore } from '../../store/user.store'

const RolesContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  justify-content: flex-end;

  div {
    margin-left: 5px;

    a {
      color: #fff;
      border-bottom: solid 1px white;

      :hover {
        border-bottom: transparent;
      }
    }
  }
`

const Section = styled.div`
  width: 100%;
  margin-bottom: 20px;
  text-align: right;
  &:last-child {
    margin-bottom: 0;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:${Section} {
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const SectionClub = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const Header1 = styled.div`
  text-transform: uppercase;
  font-weight: 300;
  font-size: 14px;
  color: white;
`

const Header2 = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  text-align: right;
  color: white;
`

const getRoleLink = (role: Roles) => {
  const links = {
    [Roles.MANAGER]: getPagePath('manager'),
    [Roles.DISPENSARY]: getPagePath('dispensary'),
    [Roles.OWNER]: getPagePath('owner'),
  }
  return links[role]
}

interface CurrentUserProps {}

export const CurrentUser = observer((_: CurrentUserProps) => {
  const { logOut, getFullName } = useAuthentication()
  const handleSignOut = () => {
    logOut()
  }

  return (
    <Container>
      <Section>
        <Header1>Hello, {getFullName()}</Header1>
      </Section>
      <Section>
        <Header2>Available roles:</Header2>
        <RolesContainer>
          {UserStore.roles.map((role) => (
            <div key={role}>
              <a href={getRoleLink(role)}>{role}</a>
            </div>
          ))}
        </RolesContainer>
      </Section>

      {AreasStore.commonAreas && AreasStore.commonAreas!.length > 1 && (
        <Section>
          <SectionClub>
            <Header2>Club: {AreasStore.settings?.area?.title}</Header2>
          </SectionClub>
          <SectionClub>
            <ChangeAreaTrigger title="Change club" />
          </SectionClub>
        </Section>
      )}
      <Section>
        <span
          tabIndex={-1}
          role="button"
          onKeyDown={handleSignOut}
          className="current-user__anchor"
          onClick={handleSignOut}
        >
          sign out
        </span>
      </Section>
    </Container>
  )
})
