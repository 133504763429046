import React, { ReactNode, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'shared/components/modal'

interface EntityAddModalProps {
  onClose: () => void
  onInit?: () => void
  entityForm: ReactNode
  title: string
}

export function EntityAddModal({ onClose, onInit, entityForm, title }: EntityAddModalProps) {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onInit && onInit()
  }, [onInit])
  return (
    <Modal>
      <ModalHeader onClose={onClose}>{title}</ModalHeader>
      <ModalBody>{entityForm}</ModalBody>
    </Modal>
  )
}
