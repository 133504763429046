import React from 'react'
import './club-qr.page.scss'
import { QrFeature } from 'features/qr'
import { CommonPage } from 'shared/layout/CommonPage'
import { observer } from 'mobx-react-lite'

const AreaQrSignaturePage = observer(({ areaId }: { areaId: string }) => {
  const url = `${window.location.origin}/signature/${areaId}/`
  return (
    <CommonPage title="Signature QR Code">
      <QrFeature value={url} />
      <div>{url}</div>
    </CommonPage>
  )
})

export default AreaQrSignaturePage
