import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx'
import { CheckoutProductType, ProductWithQuantityType } from 'shared/models/product.types'
import { ReadMemberDto, WarehouseItemReadShortDto } from 'api-client'
import * as R from 'ramda'
import { Effect } from './utils/Effect'
import { RequestHelper } from './utils/RequestHelper'
import ApiClient from '../api'
import { AreasStore } from './areas.store'
import { add, divide, multiply, subs } from '../shared/utils/math'

type Sorting = 'ASC' | 'DESC' | 'NONE'

export class CashierStoreClass {
  constructor() {
    makeAutoObservable(this)
  }

  @observable checkoutList: CheckoutProductType = {}

  @observable member: ReadMemberDto = {} as ReadMemberDto

  @observable total: number = 0

  @observable error?: any

  @observable loading: boolean = false

  @observable discount: number = 0

  @observable currentCategoryId: string | null = null

  @observable currentSalePointId: string | null = null

  @observable sortPrice: Sorting = 'ASC'

  setTotal = (total: number) => {
    this.total = total
  }

  @action setPriceSort = (sort: Sorting) => {
    this.sortPrice = sort
  }

  isActiveSort = (sort: Sorting) => this.sortPrice === sort

  @computed get subTotals() {
    const t = Object.values(this.checkoutList).reduce((acc, item) => {
      // eslint-disable-next-line eqeqeq
      if (item.quantity == 0) return 0
      if (!item.price)
        throw new Error('Error, product without Price found, can not proceed subtotal counting.')
      const sum = add(acc, multiply(item.quantity, item.price))
      // eslint-disable-next-line no-param-reassign
      acc = sum
      return acc
    }, 0)
    return t
  }

  @computed get totals() {
    const totalDiscount = add(multiply(this.subTotals, divide(this.discount, 100)), 0)
    return subs(this.subTotals, totalDiscount)
  }

  getProductsEffect = new Effect(
    () =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().warehouse.getApiV1WarehouseCashierByArea(AreasStore.selectedAreaId)
      ),
    '',
    true
  )

  @action
  async loadProducts() {
    await this.getProductsEffect.run()
  }

  get products() {
    return this.getProductsEffect.data?.slice() || []
  }

  @computed get productsBySalePoint() {
    return this.products.filter((p) => p.salePointId === this.currentSalePointId) ?? []
  }

  get productsFiltered() {
    return R.pipe(
      R.filter((p: WarehouseItemReadShortDto) => p.categoryId === this.currentCategoryId),
      R.sort(
        this.sortPrice === 'ASC' ? R.ascend(R.propOr(0, 'price')) : R.descend(R.propOr(0, 'price'))
      )
    )(this.productsBySalePoint)
  }

  @action setCategoryId = (id: string | null) => {
    runInAction(() => {
      this.currentCategoryId = id
    })
  }

  @computed get categories() {
    const result = R.pipe(
      R.map((p: WarehouseItemReadShortDto) => p.categories),
      R.uniq,
      R.flatten,
      R.sortBy(R.prop('sortOrder'))
    )(this.productsBySalePoint)
    const defaultCategory = R.head(result)
    if (defaultCategory) this.setCategoryId(defaultCategory.id!)
    return result
  }

  @action setSalePointId = (id: string | null) => {
    this.currentSalePointId = id
  }

  @action
  addToCheckout(item: ProductWithQuantityType) {
    const product = Object.keys(this.checkoutList).find((id: string) => id === item?.id)
    if (!product) {
      const checkoutProduct: CheckoutProductType = {
        [item!.id as string]: {
          ...item,
          quantity: 1,
        },
      }
      this.checkoutList = { ...this.checkoutList, ...checkoutProduct }
    } else {
      this.checkoutList = {
        ...this.checkoutList,
        [product]: {
          ...item,
          quantity: add(this.checkoutList[product].quantity, 1),
        },
      }
    }
  }

  @action increaseQuantity(productId: string) {
    const product = this.checkoutList[productId]
    product.quantity += 0.5
    this.checkoutList = {
      ...this.checkoutList,
      [productId]: product,
    }
  }

  @action decreaseQuantity(productId: string) {
    const product = this.checkoutList?.[productId as any]
    if (product.quantity > 0) {
      product.quantity -= 0.5
      this.checkoutList = {
        ...this.checkoutList,
        [productId]: product,
      }
    }
  }

  @action setQuantity(productId: string, quantity: number) {
    const product = this.checkoutList[productId]
    product.quantity = quantity
    this.checkoutList = {
      ...this.checkoutList,
      [productId]: product,
    }
  }

  @action setGiftQuantity(productId: string, quantity: number) {
    const product = this.checkoutList[productId]
    product.giftQuantity = quantity
    this.checkoutList = {
      ...this.checkoutList,
      [productId]: product,
    }
  }

  getGiftQuantity = (productId: string) => {
    const product = this.checkoutList[productId]
    return product.giftQuantity ?? 0
  }

  @action getQuantity(productId: string) {
    return this.checkoutList[productId].quantity
  }

  @action removeFromCheckout(productId: string) {
    const products: CheckoutProductType = {}
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(this.checkoutList).forEach(([key, value]) => {
      if (key !== productId) {
        products[key as string] = value
      }
    })
    this.checkoutList = products
  }

  @action setDiscount = (discount: number) => {
    this.discount = discount
  }

  @action setProducts = (products: Array<WarehouseItemReadShortDto> | null) => {
    this.getProductsEffect.data = products
  }

  @action resetCheckoutList = () => {
    this.checkoutList = {}
  }

  @action setCheckoutList = (list: CheckoutProductType) => {
    this.checkoutList = list
  }

  get hasProducts() {
    return Object.keys(this.checkoutList).length > 0
  }
}

export const CashierStore = new CashierStoreClass()
