/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProductDto } from '../models/CreateProductDto';
import type { FilterProductsDto } from '../models/FilterProductsDto';
import type { ReadProductDto } from '../models/ReadProductDto';
import type { ReadProductDtoApiResponse } from '../models/ReadProductDtoApiResponse';
import type { ReadProductDtoIEnumerableApiResponse } from '../models/ReadProductDtoIEnumerableApiResponse';
import type { UpdateProductDto } from '../models/UpdateProductDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductsService {

    /**
     * @param requestBody
     * @returns ReadProductDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1Products(
        requestBody?: CreateProductDto,
    ): CancelablePromise<ReadProductDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/products',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ReadProductDtoApiResponse Success
     * @throws ApiError
     */
    public static putApiV1Products(
        id: string,
        requestBody?: UpdateProductDto,
    ): CancelablePromise<ReadProductDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/products/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiV1Products(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/products/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns ReadProductDtoApiResponse Success
     * @throws ApiError
     */
    public static getProductById(
        id: string,
    ): CancelablePromise<ReadProductDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/product/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param areaId
     * @returns ReadProductDtoIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static getApiV1ProductsByArea(
        areaId: string,
    ): CancelablePromise<ReadProductDtoIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/products/by-area/{areaId}',
            path: {
                'areaId': areaId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns ReadProductDto Success
     * @throws ApiError
     */
    public static postApiV1ProductsCashier(
        requestBody?: FilterProductsDto,
    ): CancelablePromise<Array<ReadProductDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/products/cashier',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
