import React from 'react'
import { Form, Input } from 'antd'

interface MemberQuoteAddFormProps {
  formData?: any
  setFormData: (data: any) => void
}

export function MemberQuoteAddForm({ formData, setFormData }: MemberQuoteAddFormProps) {
  return (
    <Form
      layout="vertical"
      initialValues={formData}
      onValuesChange={(changedValues: any, values: any) =>
        setFormData({
          ...values,
          ...changedValues,
        })
      }
    >
      <Form.Item
        label="Title"
        name="title"
        rules={[
          {
            required: true,
            message: 'Enter your Title please',
          },
        ]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item
        label="Period"
        name="period"
        rules={[
          {
            required: true,
            message: 'Enter your Period please',
          },
        ]}
      >
        <Input type="number" />
      </Form.Item>

      <Form.Item
        label="Price"
        name="price"
        rules={[
          {
            required: true,
            message: 'Enter Quote Price',
          },
        ]}
      >
        <Input type="number" />
      </Form.Item>
    </Form>
  )
}
