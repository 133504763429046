import React from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { WarehouseStore } from 'store/warehouse.store'
import Table from 'shared/components/table/Table'
import { getUiDateShort } from 'shared/dates/formatters'
import { ReadWarehouseItemDto } from 'api-client'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

type WarehouseItemTable = Omit<ReadWarehouseItemDto, 'id'>

export const WarehouseOwnerList = observer(() => (
  <div>
    <h2>Opened Stock</h2>
    <Container>
      <Table<WarehouseItemTable>
        columns={[
          {
            title: 'Product',
            dataIndex: ['product', 'title'],
            sortable: true,
          },
          {
            title: 'Quantity',
            dataIndex: ['quantity'],
            sortable: true,
          },
          {
            title: 'Original Quantity',
            dataIndex: ['originalQuantity'],
            sortable: true,
          },
          {
            title: 'Price',
            dataIndex: ['price'],
            sortable: true,
          },
          {
            title: 'Date Added',
            dataIndex: ['dateCreated'],
            render: (date: string) => <span>{getUiDateShort(date)}</span>,
          },
        ]}
        data={WarehouseStore.filteredProducts as Array<WarehouseItemTable>}
        sortColumnDefault="quantity"
      />
    </Container>
  </div>
))
