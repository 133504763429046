import React from 'react'
import { Table } from 'antd'
import { MemberQuoteStore } from 'store/member-quote.store'
import { observer } from 'mobx-react-lite'

interface MemberQuoteListFeatureProps {}

export const MemberQuoteListFeature: React.FC<MemberQuoteListFeatureProps> = observer(() => {


  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Period (days)',
      dataIndex: 'period',
    },
    {
      title: 'Price',
      dataIndex: 'price',
    },
  ]
  return (
    <div>
      <Table columns={columns} dataSource={MemberQuoteStore?.data?.slice()} />
    </div>
  )
})
