import { ApiResponse } from 'api/types'
import { ApiStore } from 'store/api.store'

const unwrapFromFetchResponse = <T = unknown>(
  promise: Promise<ApiResponse<T>>,
  onSuccessMessage?: string
) =>
  promise
    .then((response) => {
      if (onSuccessMessage) {
        ApiStore.setNotification({
          title: onSuccessMessage,
          message: response.message,
        })
      }
      return response.data
    })
    .catch((error) => {
      ApiStore.setMessage(error.body.message)
      return Promise.reject(error)
    })

const unwrapListFromFetchResponse = <T = unknown>(
  promise: Promise<ApiResponse<T>>,
) =>
  promise
    .then((response) => ({
        data: response.data,
        // @ts-ignore
        total: response.totalCount,
      }))
    .catch((error) => {
      ApiStore.setMessage(error.body.message)
      return Promise.reject(error)
    })

export const RequestHelper = {
  unwrapFromFetchResponse,
  unwrapListFromFetchResponse,
}
