import React, { ReactNode } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

interface DashboardContainerProps {
  children?: ReactNode
}

// eslint-disable-next-line react/function-component-definition
export const DashboardContainer: React.FC<DashboardContainerProps> = ({ children }) => (
  <Container>{children}</Container>
)
