import * as React from 'react'
import Webcam from 'react-webcam'
import styled from 'styled-components'
import { Button } from 'shared/components'

type GetPhotoFeaturePropsStyled = {
  forTabs?: boolean
}
type GetPhotoFeatureProps = {
  setPhoto: (photo: string) => void
} & GetPhotoFeaturePropsStyled

const Container = styled.div<GetPhotoFeaturePropsStyled>`
  position: relative;
  justify-content: center;
  border: solid 2px #05bd82;
  border-radius: ${(props) => props.forTabs ? '0' : '4px'} 4px 4px 4px;
  width: 100%;
`

export default function GetPhotoFeature({ setPhoto, forTabs }: GetPhotoFeatureProps) {
  const webcamRef = React.useRef<any>(null)
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()
    setPhoto(imageSrc)
  }, [webcamRef, setPhoto])

  return (
    <Container forTabs={forTabs}>
      <Webcam
        style={{ width: '100%' }}
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
      />
      <Button primary square block onClick={capture}>
        Capture photo
      </Button>
    </Container>
  )
}
