/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppUserApiResponse } from '../models/AppUserApiResponse';
import type { ReadMemberDtoApiResponse } from '../models/ReadMemberDtoApiResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @param id
     * @returns AppUserApiResponse Success
     * @throws ApiError
     */
    public static getApiV1UserPersonalData(
        id: string,
    ): CancelablePromise<AppUserApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/personal-data/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns ReadMemberDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1UserPromote(
        id: string,
    ): CancelablePromise<ReadMemberDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/promote/{id}',
            path: {
                'id': id,
            },
        });
    }

}
