import React, { ReactNode } from 'react'
import { Button } from 'shared/components'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/components/modal'

interface SuccessModalProps {
  successText?: string
  actions?: ReactNode
  header?: ReactNode
  onClose: () => void
}

export function SuccessModal({ successText, actions, header, onClose }: SuccessModalProps) {
  return (
    <Modal>
      <ModalHeader>{header ?? 'Success!'}</ModalHeader>
      <ModalBody>
        <p>{successText ?? 'Success'}</p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Ok</Button>
        {actions && actions}
      </ModalFooter>
    </Modal>
  )
}
