import React from 'react'
import styled from "styled-components";

const Container = styled.div`
  margin-top: 20px;
  padding: 20px;
  text-align: center;
  background-color: #fcdddd;
`
export function ErrorMessage({ message }: { message: string }) {
  return <Container>
    {message}
  </Container>
}
