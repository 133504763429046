import React, { useState } from 'react'
import styled from 'styled-components'
import { MergeBatchModal } from './merge-batch.modal'
import { MergeBatchTriggerProps } from './types'

const Trigger = styled.span``

export function MergeBatchTrigger({
  onSubmit,
  children,
  parentBatchId,
  productId,
  productTitle,
}: MergeBatchTriggerProps) {
  const [show, setShow] = useState<boolean>(false)
  return (
    <div>
      {show && (
        <MergeBatchModal
          parentBatchId={parentBatchId}
          productId={productId}
          productTitle={productTitle}
          onSubmit={onSubmit}
          onClose={() => {
            setShow(false)
          }}
        />
      )}
      <Trigger onClick={() => setShow(true)}>{children}</Trigger>
    </div>
  )
}
