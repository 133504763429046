import React from 'react'
import MemberPersonalDataForm from '../member-personal-data.form'
import { CommonStepRegistrationProps } from './types'

interface StepPersonalDataProps extends CommonStepRegistrationProps {
  personalData: any
  setPersonalData: (data: any) => void
  setIsFormValid: (isValid: boolean) => void
}

export function StepPersonalData({
  personalData,
  setPersonalData,
  setIsFormValid,
}: StepPersonalDataProps) {
  return (
    <MemberPersonalDataForm
      setFormData={setPersonalData}
      formData={personalData}
      legalAge={21}
      isValid={setIsFormValid}
    />
  )
}
