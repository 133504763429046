import { useCallback } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { Roles } from './Roles'
import { authenticationService } from './authenticationService'

export const useAuthentication = (): UseAuthenticationReturnType => {
  const { initialized, keycloak } = useKeycloak()
  const keycloakId = authenticationService.getUserId()
  const clientName = process.env?.REACT_APP_KEYCLOAK_CLIENT
  const isInitialized = () => initialized
  const getToken = () => keycloak.token ?? null
  const getRoles = (): Array<Roles> => {
    if (clientName) {
      const resourceRoles = keycloak?.resourceAccess?.[clientName]?.roles as Array<Roles>
      const realmRoles = keycloak?.realmAccess?.roles as Array<Roles>
      const roles = [...(resourceRoles || []), ...(realmRoles || [])]
      return roles ?? null
    }
    return []
  }
  const getUserName = () => keycloak?.profile?.username ?? ''
  const getFullName = () => keycloak?.idTokenParsed?.name ?? ''

  const logIn = useCallback(() => {
    keycloak.login().then((data: any) => {
      authenticationService.setToken(data)
    })
  }, [keycloak])

  const logOut = (redirectUri?: string): any => {
    authenticationService.setToken(null)
    return keycloak.logout({ redirectUri })
  }

  const authenticateWithSavedCreds = () => {
    authenticationService.setToken(getToken())
    console.info('AUTHENTICATION RESTORED')
  }

  return {
    getToken,
    getRoles,
    logIn,
    logOut,
    getUserName,
    getFullName,
    isInitialized,
    isAuthenticated: () => keycloak.authenticated ?? false,
    keycloak,
    authenticateWithSavedCreds,
    keycloakId,
  }
}

interface UseAuthenticationReturnType {
  getToken: () => string | null
  getRoles: () => Array<Roles>
  logIn: () => void
  logOut: (redirectUri?: string) => any
  getUserName: () => string
  getFullName: () => string
  isInitialized: () => boolean
  isAuthenticated: () => boolean
  keycloak: any
  authenticateWithSavedCreds: () => void
  keycloakId: string | null
}
