import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/components/modal'
import React, { useCallback, useState } from 'react'
import { Button } from 'shared/components'
import {
  CloseBatchFormData,
  CloseBatchModalProps,
} from '../store-batch/types'
import { CloseBatchForm } from './close-batch.form'

export function CloseBatchModal({ onSubmit, onClose, batch, productTitle }: CloseBatchModalProps) {
  const [formData, setFormData] = useState<CloseBatchFormData>(batch ?? { quantity: '0' })
  const getTitle = useCallback(() => `Close Batch of ${productTitle}`, [productTitle])
    const isSubmitDisabled = () => !formData.comment
  return (
    <Modal onClose={onClose}>
      <ModalHeader onClose={onClose}>{getTitle()}</ModalHeader>
      <ModalBody>
        <CloseBatchForm setFormData={setFormData} />
      </ModalBody>
      <ModalFooter>
        <Button
          primary
          disabled={isSubmitDisabled()}
          onClick={() => {
            if (formData) onSubmit(formData)
            onClose()
          }}
        >
          Submit
        </Button>
        <Button danger bordered onClick={() => onClose()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}
