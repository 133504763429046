import moment from 'moment'
import { Rule } from 'rc-field-form/es/interface'
import { getAge } from 'shared/utils/getAge'

export const legalAgeValidator = (legalAge: number): Rule => ({
  validator: (rule, value) => {
    const age = moment().diff(value, 'milliseconds')
    const legalAgeDuration = moment.duration(legalAge, 'years')
    if (age < legalAgeDuration.asMilliseconds()) {
      return Promise.reject(new Error(`Illegal age: ${getAge(age)}. Legal age is: ${legalAge}`))
    }
    return Promise.resolve(value)
  },
})
