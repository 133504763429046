import React from 'react'
import { observer } from 'mobx-react-lite'
import { Col, Row } from 'antd'
import { PaymentStore } from 'store/payment.store'
import { ReportsStore } from 'store/reports.store'
import {
  DashboardHeader,
  DashboardHeaderAnnotation,
  DashboardHeaderPrimary,
  DashboardHeaderTitle,
  DashboardHeaderValue,
} from './styled'


export const TodayReportOwner = observer(() => (
      <div>
        <Row gutter={24}>
        <Col xs={24} md={12} lg={6}>
          <DashboardHeaderPrimary>
            <DashboardHeaderTitle>Total Donations</DashboardHeaderTitle>
            <DashboardHeaderValue>{PaymentStore.cashForToday}</DashboardHeaderValue>
            <DashboardHeaderAnnotation>Points</DashboardHeaderAnnotation>
          </DashboardHeaderPrimary>
        </Col>
        <Col xs={24} md={12} lg={6}>
          <DashboardHeader>
            <DashboardHeaderTitle>Total checked in</DashboardHeaderTitle>
            <DashboardHeaderValue>{ReportsStore.activeVisits}</DashboardHeaderValue>
            <DashboardHeaderAnnotation>People</DashboardHeaderAnnotation>
          </DashboardHeader>
        </Col>

        <Col xs={24} md={12} lg={6}>
          <DashboardHeader>
            <DashboardHeaderTitle>New members</DashboardHeaderTitle>
            <DashboardHeaderValue>{ReportsStore.todayRegistrationsCount}</DashboardHeaderValue>
            <DashboardHeaderAnnotation>for today</DashboardHeaderAnnotation>
          </DashboardHeader>
        </Col>

        <Col xs={24} md={12} lg={6}>
          <DashboardHeader>
            <DashboardHeaderTitle>Today Quotes</DashboardHeaderTitle>
            {Object.entries(ReportsStore.todayMemberQuotesByGroup).map((i) => (
              <DashboardHeaderAnnotation key={i[0]}>{`${i[0]} - ${i[1].length} x ${i[1][0]
                ?.price!}`}</DashboardHeaderAnnotation>
            ))}
            <DashboardHeaderTitle>
              <hr />
              Total: {ReportsStore.todayMemberQuoteSum}
            </DashboardHeaderTitle>
          </DashboardHeader>
        </Col>
      </Row>

      </div>
  ))
