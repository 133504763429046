/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DecimalApiResponse } from '../models/DecimalApiResponse';
import type { GetCashForPeriodDto } from '../models/GetCashForPeriodDto';
import type { OrderApiResponse } from '../models/OrderApiResponse';
import type { PayMemberQuoteDto } from '../models/PayMemberQuoteDto';
import type { PayMemberQuoteRevertDto } from '../models/PayMemberQuoteRevertDto';
import type { RenewMemberQuoteDto } from '../models/RenewMemberQuoteDto';
import type { ReturnFundsDto } from '../models/ReturnFundsDto';
import type { TransferMemberQuoteDto } from '../models/TransferMemberQuoteDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentService {

    /**
     * @param requestBody
     * @returns OrderApiResponse Success
     * @throws ApiError
     */
    public static postApiV1PaymentMemberQuote(
        requestBody?: PayMemberQuoteDto,
    ): CancelablePromise<OrderApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payment/member-quote',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns OrderApiResponse Success
     * @throws ApiError
     */
    public static postApiV1PaymentMemberQuoteRevert(
        requestBody?: PayMemberQuoteRevertDto,
    ): CancelablePromise<OrderApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payment/member-quote/revert',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns OrderApiResponse Success
     * @throws ApiError
     */
    public static postApiV1PaymentMemberQuoteTransfer(
        requestBody?: TransferMemberQuoteDto,
    ): CancelablePromise<OrderApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payment/member-quote-transfer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns OrderApiResponse Success
     * @throws ApiError
     */
    public static postApiV1PaymentMemberQuoteRenew(
        requestBody?: RenewMemberQuoteDto,
    ): CancelablePromise<OrderApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payment/member-quote-renew',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns OrderApiResponse Success
     * @throws ApiError
     */
    public static postApiV1PaymentReturnMemberFunds(
        requestBody?: ReturnFundsDto,
    ): CancelablePromise<OrderApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payment/return-member-funds',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns DecimalApiResponse Success
     * @throws ApiError
     */
    public static postApiV1PaymentGetAllCash(
        requestBody?: GetCashForPeriodDto,
    ): CancelablePromise<DecimalApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payment/get-all-cash',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
