export const enUS = {
    translation: {
        fullName: 'Full name',
        checkInTime: 'Check In Time',
        dashboard: 'Dashboard',
        warehouseJournal: {
            pageTitle: 'Orders by Date',
            orderDetailsTitle: 'Order Details',
        },
    },
}