import React from 'react'
import { IconName } from 'shared/components/icon/IconName'
import iconMap from 'shared/components/icon/icon-map'

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: IconName
  size?: string | number
  color?: string
  colorStroke?: string
  title?: string
}

function Icon({ name, size = 15, color = '#ffffff', colorStroke, ...rest }: IconProps) {
  // @ts-ignore
  const SvgIcon = iconMap[name]

  let style
  if (size !== undefined) {
    style = { width: size, height: size }
  }

  return (
    <SvgIcon fill={color} color={color} stroke={colorStroke} style={style} height={size} width={size} {...rest} />
  )
}

export default Icon
