import React from 'react'
import styled from 'styled-components'
import { ColumnsType } from 'antd/es/table'
import { Listing } from '../listing/Listing'

const Container = styled.div`
  width: 100%;
`

interface DashboardListProps {
  columns: ColumnsType<any>
  data: any
  summary?: any
  rowKey: any
  loading: boolean
}

export function DashboardList({ data, columns, summary, rowKey, loading }: DashboardListProps) {
  return (
    <Container>
      <Listing loading={loading} key={rowKey} columns={columns} data={data} summary={summary} />
    </Container>
  )
}
