import React from 'react'
import { Form, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'

interface SalePointAddFormProps {
  setFormData: (formData: any) => void
}

export function SalePointAddForm({ setFormData }: SalePointAddFormProps) {
  return (
    <Form
      labelCol={{ xs: 24, sm: 6 }}
      onValuesChange={(changed: any, values: any) => {
        setFormData({ ...values, ...changed })
      }}
    >
      <Form.Item
        label="Sale Point name"
        name="title"
        rules={[{ required: true, message: 'Enter sale point title' }]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item label="Description" name="description">
        <TextArea />
      </Form.Item>
    </Form>
  )
}
