import React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { ContentLayout } from 'shared/layout/ContentLayout/ContentLayout'
import { LayoutSidebar } from 'shared/layout/sidebar/LayoutSidebar'
import { LayoutSidebarSection } from 'shared/layout/sidebar/LayoutSidebarSection'
import { LogoSidebar } from 'shared/components/logo/LogoSidebar'
import { CurrentUser } from 'auth/components/CurrentUser'
import { useAuthentication } from 'auth/useAuthentication'
import { Roles } from '../auth/Roles'

interface RoleProtectedRouteProps extends RouteProps {
  role: Roles[]
  redirectUrl?: string
}

export function RoleProtectedRoute(props: RoleProtectedRouteProps) {
  const { children, role, ...routeProps } = props
  const { getRoles } = useAuthentication()
  const roles = getRoles()
  const renderChildren = roles?.some((r) => role.includes(r))
  return (
    <Route {...routeProps}>
      <>
        {!renderChildren && (
          <ContentLayout
            sidebar={
              <LayoutSidebar>
                <LayoutSidebarSection>
                  <LogoSidebar />
                </LayoutSidebarSection>
                <LayoutSidebarSection fullHeight />
                <LayoutSidebarSection profile>
                  <CurrentUser />
                </LayoutSidebarSection>
              </LayoutSidebar>
            }
          >
            <h1>Forbidden. Insuffucient Access Rights.</h1>
          </ContentLayout>
        )}

        {renderChildren && children}
      </>
    </Route>
  )
}
