import React from 'react'
import { observer } from 'mobx-react-lite'
import { AppRouter } from './router/AppRouter'
import 'antd/dist/antd.min.css'

const App = observer(() => (
  <div className="app-wrapper">
    <AppRouter
      redirectUrl={window.location.pathname.length > 1 ? window.location.pathname : undefined}
    />
  </div>
))

export default App
