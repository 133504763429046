import React from 'react'
import styled from 'styled-components'

const Title = styled.div`
  font-size: 8px;
`
const Text = styled.span``
const AdditionalText = styled.div`
  font-size: 11px;
  color: #646464;
`

const Container = styled.div<CommonMessageModifiers>`
  ${({ success, block, warning }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px #000;
  border-radius: 4px;
  padding: 16px;
  ${
    block
      ? `
    width: 100%;
  `
      : ''
  }

  ${
    success
      ? `
      border-color: #03bd82;
    ${Title} {
      font-size: 20px;
      color: #07ab2e;
    }
    ${Text} {
      font-size: 16px;
      color: #0a2a12;
    }
  `
      : ''
  }

  ${
    warning
      ? `
      border-color: #e2131e;
    ${Title} {
      font-size: 20px;
      color: #e2131e;
    }
    ${Text} {
      font-size: 16px;
      color: #000000;
    }
    ${AdditionalText} {
      font-size: 14px;
      color: #9b0000;
    }
  `
      : ''
  }

`}
`

export type CommonMessageModifiers = {
  success?: boolean
  warning?: boolean
  block?: boolean
}

export type CommonMessageProps = {
  title?: string
  text?: string
  additionalText?: string
} & CommonMessageModifiers

export function CommonMessage({
  title,
  text,
  additionalText,
  success,
  block,
  warning,
}: CommonMessageProps) {
  return (
    <Container success={success} block={block} warning={warning}>
      <Title>{title}</Title>
      <Text>{text}</Text>
      {additionalText && <AdditionalText>{additionalText}</AdditionalText>}
    </Container>
  )
}
