/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateManagerDto } from '../models/CreateManagerDto';
import type { ReadManagerDtoApiResponse } from '../models/ReadManagerDtoApiResponse';
import type { ReadManagerDtoIEnumerableApiResponse } from '../models/ReadManagerDtoIEnumerableApiResponse';
import type { ReadManagerDtoListApiResponse } from '../models/ReadManagerDtoListApiResponse';
import type { UpdateManagerDto } from '../models/UpdateManagerDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ManagersService {

    /**
     * @param requestBody
     * @returns ReadManagerDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1ManagersCreateManager(
        requestBody?: CreateManagerDto,
    ): CancelablePromise<ReadManagerDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/managers/create-manager',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param ownerId
     * @returns ReadManagerDtoListApiResponse Success
     * @throws ApiError
     */
    public static getApiV1ManagersByOwner(
        ownerId?: string,
    ): CancelablePromise<ReadManagerDtoListApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/managers/by-owner',
            query: {
                'ownerId': ownerId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns ReadManagerDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1Managers(
        requestBody?: CreateManagerDto,
    ): CancelablePromise<ReadManagerDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/managers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ReadManagerDtoIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static getApiV1Managers(): CancelablePromise<ReadManagerDtoIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/managers',
        });
    }

    /**
     * @param id
     * @returns ReadManagerDtoApiResponse Success
     * @throws ApiError
     */
    public static deleteApiV1Managers(
        id?: string,
    ): CancelablePromise<ReadManagerDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/managers',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns ReadManagerDtoApiResponse Success
     * @throws ApiError
     */
    public static getApiV1Managers1(
        id: string,
    ): CancelablePromise<ReadManagerDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/managers/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ReadManagerDtoApiResponse Success
     * @throws ApiError
     */
    public static putApiV1Managers(
        id: string,
        requestBody?: UpdateManagerDto,
    ): CancelablePromise<ReadManagerDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/managers/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
