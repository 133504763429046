import * as React from 'react'
import { Popconfirm, Table } from 'antd'
import { useHistory } from 'react-router'
import { observer } from 'mobx-react-lite'
import { Preloader } from 'shared/components/preloader'
import { ProductCategoriesStore } from 'store/product-categories.store'

const ProductCategoriesListFeature = observer(() => {
  const history = useHistory()
  const deleteItem = (item: any) => ProductCategoriesStore.deleteCategory(item.id)
  const edit = (id: string) => history.push(`edit/${id}`)

  const columns: any = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Delete',
      dataIndex: '',
      key: 'x',
      render: (item: any) => (
        <>
          <Popconfirm
            title="Delete this category?"
            onConfirm={() => deleteItem(item)}
            okText="Yes"
            cancelText="No"
          >
            <a href="#/">Delete</a>
          </Popconfirm>

          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            onClick={() => {
              edit(item.id)
            }}
          >
            Edit
          </div>
        </>
      ),
    },
  ]
  return (
    <>
      {ProductCategoriesStore.loadStatus.isLoading && <Preloader />}
      <Table columns={columns} dataSource={ProductCategoriesStore?.data?.slice()} rowKey="id" />
    </>
  )
})

export default ProductCategoriesListFeature
