import React from 'react'
import { useHistory } from 'react-router'
import { observer } from 'mobx-react-lite'
import { Table } from 'antd'
import { WarehouseRevisionsStore } from 'store/warehouse-revisions.store'
import { WarehouseRevisionReadDto } from 'api-client'

export const RevisionsListFeature = observer(() => {
  const { revisions } = WarehouseRevisionsStore

  const history = useHistory()

  return (
    <div>
      <Table
        onRow={(record, index) => ({
          onClick: (event) => {
            history.push(`warehouse-revisions/${record.id}`)
          },
        })}
        dataSource={revisions}
        columns={[
          {
            title: 'Revision Date',
            dataIndex: 'title',
          },
          {
            title: 'User Started',
            dataIndex: 'user',
            render: (user) => <div>{user?.firstName} {user?.lastName}</div>,
          },
          {
            title: 'User Approved',
            dataIndex: 'approvedUser',
            render: (user) => <div>{user?.firstName} {user?.lastName}</div>,
          },
          {
            title: 'Comment',
            dataIndex: 'comment',
            render: (comment: string) => <div>{comment}</div>,
            width: '30%'
          },
          {
            title: 'Status',
            render: (item: WarehouseRevisionReadDto) => (
              <div>
                {item.isCancelled && <span>Cancelled</span>}
                {item.isApplied && <span>Applied</span>}
                {!item.isApplied && !item.isCancelled && <span>In Progress</span>}
              </div>
            ),
          },
        ]}
      />
    </div>
  )
})
