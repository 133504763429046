import { action, computed, observable } from 'mobx'
import * as R from 'ramda'
import { ReadSalePointDto, ReadSalePointDtoIEnumerableApiResponse } from 'api-client'
import { Roles } from 'auth/Roles'
import { BasicStore, BasicStoreApi } from './BasicStore'
import { ApiStore } from './api.store'
import { AreasStore } from './areas.store'
import { Effect } from './utils/Effect'
import { RequestHelper } from './utils/RequestHelper'
import ApiClient from '../api'
import { UserStore } from './user.store'

export class SalePointsStoreClass extends BasicStore<Array<ReadSalePointDto>, any> {
  @observable selectedId: string | null = this.defaultId

  @action setSelectedId = (id: string | null) => {
    this.selectedId = id
  }

  get selected() {
    return this.selectedId
  }

  @observable selectedIds: Array<string> = []

  @action setSelectedIds = (ids: Array<string>) => {
    this.selectedIds = ids
  }

  @computed get selectedSalePointIds() {
    return this.selectedIds
  }

  @computed get salePoints() {
    return this.data?.slice() || []
  }

  get defaultId(): string {
    const salePointDefaultId =
      UserStore.currentRole === Roles.MANAGER
        ? AreasStore.settings.managerDefaultSalePointId
        : AreasStore.settings.dispensaryDefaultSalePointId
    return salePointDefaultId ?? R.or(R.head(this.salePoints)?.id, '')
  }

  // eslint-disable-next-line class-methods-use-this
  getAll = (): Promise<ReadSalePointDtoIEnumerableApiResponse> =>
    ApiClient().salePoints.getApiV1SalePoints(AreasStore.selectedAreaId)

  api: BasicStoreApi<Array<ReadSalePointDto>> = {
    load: this.getAll,
    delete: (id: string) => ApiClient().salePoints.deleteApiV1SalePoints(id),
  }

  createEffect = new Effect((request) =>
    RequestHelper.unwrapFromFetchResponse(ApiClient().salePoints.postApiV1SalePoints(request))
  )

  @action create = async (data: ReadSalePointDto) => {
    const request = {
      ...data,
      areaId: AreasStore.selectedAreaId,
    }
    const response = await this.createEffect.run(request)
    if (response) {
      this.data = [
        // @ts-ignore
        ...this.data,
        response,
      ]
    }
  }

  @action deleteSalePoint = (id: string) => {
    this.delete(id).then(() => {
      if (this.data) {
        this.data = this.data.filter((item) => item.id !== id)
        ApiStore.setResult({
          title: 'Deleted',
          message: `Your Sale Point (${id}) was Deleted Successfully.`,
        })
      }
    })
  }
}

export const SalePointsStore = new SalePointsStoreClass()
