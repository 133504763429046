import { computed, makeAutoObservable } from 'mobx'
import { Effect } from 'store/utils/Effect'
import { RequestHelper } from 'store/utils/RequestHelper'
import ApiClient from 'api'
import { CreateGeneralAccountDto, ReadGeneralAccountDto } from 'api-client'
import { GeneralAccountFormModel } from './models'
import { AreasStore } from '../../../store/areas.store'

export class GeneralAccountStoreClass {
  constructor() {
    makeAutoObservable(this)
  }

  /*
   * Effects
   */

  private loadGeneralAccountsEffect = new Effect(() =>
    RequestHelper.unwrapFromFetchResponse(ApiClient().accounting.getApiV1AccountingAccounts())
  )

  private loadByIdEffect = new Effect((id) =>
    RequestHelper.unwrapFromFetchResponse(
      new Promise((resolve) => {
        resolve({})
      })
    )
  )

  private createGeneralAccountEffect = new Effect((request: CreateGeneralAccountDto) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().accounting.postApiV1AccountingAccounts(request)
    )
  )

  private updateGeneralAccountEffect = new Effect((id, request) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().accounting.putApiV1AccountingAccounts(id, request)
    )
  )

  private deleteGeneralAccountEffect = new Effect((id) =>
    RequestHelper.unwrapFromFetchResponse(ApiClient().accounting.deleteApiV1AccountingAccounts(id))
  )

  /*
   * Actions
   */

  public loadGeneralAccounts = () => this.loadGeneralAccountsEffect.run()

  public loadById = (id: string) => this.loadByIdEffect.run(id)

  public createGeneralAccount = async (data: GeneralAccountFormModel) => {
    const request = {
      ...data,
      areaId: AreasStore.selectedAreaId,
    }
    const response = await this.createGeneralAccountEffect.run(request)
    if (response) {
      this.addGeneralAccounts(response)
    }
    return response
  }

  public updateGeneralAccount = async (id: string, data: GeneralAccountFormModel) => {
    const response = await this.updateGeneralAccountEffect.run(id, data)
    if (response) {
      this.setGeneralAccount(response)
      this.updateGeneralAccounts(id, response)
    }
  }

  public deleteGeneralAccount = async (id: string) => {
    const response = await this.deleteGeneralAccountEffect.run(id)
    if (response) {
      this.setDeletedAccount(id)
    }
  }

  /*
   * Getters
   */

  @computed get generalAccounts() {
    return this.loadGeneralAccountsEffect.data ?? []
  }

  @computed get generalAccountsSliced() {
    return this.loadGeneralAccountsEffect.data?.slice()
  }

  get generalAccount() {
    return this.loadByIdEffect.data ?? {}
  }

  /*
   * Setters
   */

  addGeneralAccounts = (data: any) => {
    this.loadGeneralAccountsEffect.data = [...this.generalAccounts, data]
  }

  updateGeneralAccounts = (id: string, data: ReadGeneralAccountDto) => {
    // debugger
    const index = this.loadGeneralAccountsEffect?.data?.findIndex((i) => i.id === id)
    if (index && this.loadGeneralAccountsEffect.data) {
      this.loadGeneralAccountsEffect.data[index] = data
    }
  }

  setDeletedAccount = (id: string) => {
    this.loadGeneralAccountsEffect.data = this.loadGeneralAccountsEffect.data?.filter(
      (i) => i.id !== id
    )
  }

  setGeneralAccount = (data: any) => {
    this.loadByIdEffect.data = data
  }
}

export const GeneralAccountStore = new GeneralAccountStoreClass()
