import React, { useCallback, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/components/modal'
import { Button } from 'shared/components'
import { StoreBatchForm } from './store-batch.form'
import { StoreBatchFormData, StoreBatchModalProps } from './types'

export function StoreBatchModal({
  onSubmit,
  onClose,
  batch,
  productTitle,
  transfer,
}: StoreBatchModalProps) {
  const [formData, setFormData] = useState<StoreBatchFormData>(
    batch ?? {
      price: '0',
      quantity: '0',
    }
  )
  const getTitle = useCallback(
    () =>
        // eslint-disable-next-line no-nested-ternary
      transfer
        ? `Transfer Batch ${productTitle} from old Application`
        : batch
        ? `Update ${productTitle} Batch`
        : `Store New Batch of ${productTitle}`,
    [batch, productTitle, transfer]
  )
  return (
    <Modal>
      <ModalHeader onClose={onClose}>{getTitle()}</ModalHeader>
      <ModalBody>
        <StoreBatchForm setFormData={setFormData} formData={formData} />
      </ModalBody>
      <ModalFooter>
        <Button
          primary
          onClick={() => {
            if (formData) onSubmit(formData)
            onClose()
          }}
        >
          Submit
        </Button>
        <Button danger bordered onClick={() => onClose()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}
