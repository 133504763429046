import React, { useState } from 'react'
import Icon from 'shared/components/icon/Icon'
import './ListSearch.scss'
import Input from '../form/input/Input'

interface ListSearchProps {
  value?: string
  setValue?: (value: string) => void
  placeholder?: string
  onSubmit?: (event: React.KeyboardEvent) => void
  autoFocus?: boolean
}

export function ListSearch({
  value = '',
  setValue,
  placeholder,
  onSubmit,
  autoFocus,
}: ListSearchProps) {
  const [searchValue, setSearchValue] = useState(value)
  const onValueChange = (e: any) => {
    const value: string = e.target.value || ''
    setSearchValue(value)
    setValue?.(value)
  }

  return (
    <div className="list-search">
      <div className="list-search__icon">
        <Icon size={13} name="search" fill="#3C3C3C" />
      </div>
      <Input
        autoFocus={autoFocus}
        className="list-search__input"
        value={searchValue}
        onChange={onValueChange}
        onKeyDown={onSubmit}
        placeholder={placeholder}
      />
    </div>
  )
}
