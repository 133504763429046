import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { CommonPage } from 'shared/layout/CommonPage'
import { WarehouseListFeature } from 'features/warehouse/warehouse-list.feature'
import { SalePointCheckerFeature } from 'features/sale-point/sale-point-checker/sale-point-checker.feature'
import { Preloader } from 'shared/components/preloader'
import { WarehouseViewModel } from './warehouse.view-model'

interface WarehousePageProps {}

const WarehousePage: React.FC<WarehousePageProps> = observer(() => {
  useEffect(() => {
    WarehouseViewModel.init()
  }, [])

  return WarehouseViewModel.isLoading ? (
    <Preloader message="Loading Warehouse Data" lower />
  ) : (
    <CommonPage
      isMounted={!WarehouseViewModel.isLoading}
      title="Opened Stock"
      actions={<SalePointCheckerFeature />}
    >
      <WarehouseListFeature />
    </CommonPage>
  )
})
export default WarehousePage
