import * as React from 'react'
import { useEffect, useRef } from 'react'
import moment from 'moment'
import 'moment-duration-format'
import { Col, DatePicker, Form, Input, Row } from 'antd'
import styled from 'styled-components'
import { legalAgeValidator } from 'shared/utils/validators'
import {
  memberPersonalDataFormTitles,
  MemberPersonalDataFormValues,
} from './member-personal-data.form-fields'

const Container = styled.div`
  padding: 12px;
`

interface MemberPersonalDataFormProps {
  formData?: MemberPersonalDataFormValues
  setFormData: (data: MemberPersonalDataFormValues) => void
  legalAge: number
  isValid?: (isValid: boolean) => void
}

function MemberPersonalDataForm({
  formData,
  setFormData,
  legalAge,
  isValid,
}: MemberPersonalDataFormProps) {
  const onFinish = (formData: MemberPersonalDataFormValues) => {
    setFormData(formData)
  }
  const [form] = Form.useForm()
  const formRef = useRef(null)

  useEffect(() => {
    const requiredFields = ['firstName', 'lastName', 'documentNumber', 'birthDate', 'nationality']
    if (form.isFieldsTouched(requiredFields, true)) {
      form
        .validateFields(requiredFields)
        .then(() => isValid && isValid(true))
        .catch(() => isValid && isValid(false))
    }
  }, [form, formData, isValid])

  return (
    <Container>
      <Form
        layout="vertical"
        form={form}
        ref={formRef}
        onFinish={onFinish}
        initialValues={{
          ...formData,
        }}
        onValuesChange={(changedValues: any, values: any) =>
          setFormData({
            ...values,
            ...changedValues,
          })
        }
      >
        <Row gutter={50}>
          <Col xs={24}>
            <Form.Item
              label={memberPersonalDataFormTitles.firstName}
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'Please input your first Name!',
                },
              ]}
            >
              <Input size="large" autoFocus />
            </Form.Item>

            <Form.Item
              label={memberPersonalDataFormTitles.lastName}
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Please input your last name!',
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>

            <Form.Item label={memberPersonalDataFormTitles.address} name="address">
              <Input size="large" />
            </Form.Item>

            <Form.Item
              label={memberPersonalDataFormTitles.birthDate}
              name="birthDate"
              rules={[
                {
                  required: true,
                  message: 'Enter your birth date please.',
                },
                legalAgeValidator(legalAge),
              ]}
            >
              <DatePicker
                size="large"
                format="DD.MM.YYYY"
                value={formData?.birthDate ? moment.utc(formData?.birthDate) : null}
              />
            </Form.Item>

            <Form.Item
              label={memberPersonalDataFormTitles.documentNumber}
              name="documentNumber"
              rules={[
                {
                  required: true,
                  message: 'Enter your DNI please',
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>

            <Form.Item
              label={memberPersonalDataFormTitles.email}
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid Email!',
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              label={memberPersonalDataFormTitles.nationality}
              name="nationality"
              rules={[
                {
                  required: true,
                  message: 'Enter your Nationality please',
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default MemberPersonalDataForm
