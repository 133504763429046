import React, { useState } from 'react'
import { Button } from 'shared/components'
import { SalePointsStore } from 'store/sale-points.store'
import { SalePointAddForm } from './sale-point-add.form'

class SalePointAddFeatureProps {
  onSuccess?: () => void
}

export function SalePointAddFeature({ onSuccess }: SalePointAddFeatureProps) {
  const [formData, setFormData] = useState<any>()
  const create = () =>
    formData && SalePointsStore.create(formData).then(() => onSuccess && onSuccess())

  return (
    <>
      <SalePointAddForm setFormData={setFormData} />
      <Button centered primary onClick={() => create()}>
        Submit
      </Button>
    </>
  )
}
