import styled from 'styled-components'
import { responsive } from 'styles/mixins'

export const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;

  .app-button {
    margin-left: 12px;
  }

  ${responsive.desktop.mobile`
   flex-direction: column;
   
    .app-button {
      display: block;
      width: 93%;
      margin-bottom: 12px;
    }
  `}
`
export const Card = styled.div`
  padding: 24px;
  border-radius: 6px;
  background: #f5f5f5;
`
export const Price = styled.div`
  font-size: 21px;
`
export const Title = styled.div`
  font-size: 32px;
  line-height: 1.2;
`
export const Quantity = styled.div`
  font-size: 24px;
`
export const UpTitle = styled.div`
  font-size: 11px;
`
export const DownTitle = styled.div`
  font-size: 16px;
`
export const Box = styled.div`
  display: flex;
  flex-direction: column;
  background: #f1f1f1;
  margin: 12px;
  padding: 20px;
`