import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { CommonPage } from 'shared/layout/CommonPage'
import { MonthConsumptionFeature } from 'features/report/month/month-consumption.feature'
import { MonthMoneyIncomeFeature } from 'features/report/month/month-money-income.feature'
import { Divider } from 'shared/components/divider/Divider'
import { DatePicker } from 'antd'
import { Preloader } from 'shared/components/preloader'
import { StatsMonthViewModel } from './stats-month.view-model'

const StatsMonthPage = observer(() => {
  const [date, setDate] = useState(moment())

  const from = date.startOf('month').toISOString()
  const to = date.endOf('month').toISOString()

useEffect(() => {
  StatsMonthViewModel.init(from, to)
}, [from, to])

  return StatsMonthViewModel.loading ? (
    <Preloader message="Loading Warehouse Deviations" lower />
  ) : (
    <CommonPage
      title={
        <DatePicker
          picker="month"
          defaultValue={date}
          onChange={(value) => {
            if (value) setDate(value)
          }}
        />
      }
      isMounted={!StatsMonthViewModel.loading}
    >
      <MonthConsumptionFeature />
    <Divider />
    <MonthMoneyIncomeFeature />
    </CommonPage>
  )
})

export default StatsMonthPage
