import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/components'
import { CreateManagerDto } from 'api-client'
import { ManagersStore } from '../../store/managers.store'
import { ManagerAddForm } from './manager-add.form'

interface ManagerAddFeatureProps {
  onSuccess?: () => void
}

export const ManagerAddFeature = observer(({ onSuccess }: ManagerAddFeatureProps) => {
  const [formData, setFormData] = useState<CreateManagerDto>({
    firstName: '',
    lastName: '',
    email: '',
    username: '',
  })

  const submit = () => {
    ManagersStore.addManager(formData).then(() => onSuccess && onSuccess())
  }

  return (
    <>
      <ManagerAddForm setFormData={setFormData} formData={formData} />
      <Button
        primary
        onClick={() => {
          submit()
        }}
      >
        Add
      </Button>
    </>
  )
})
