import styled from 'styled-components'

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-shrink: 0;
`
export const ImageBody = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  padding: 40px;
`
export const ImageWrapper = styled.div`
  align-self: center;
`
export const ImageButton = styled.div`
  display: flex;
  flex-direction: column;
`
export const Image = styled.img``
