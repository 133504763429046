import React, { ReactNode } from 'react'
import { Button } from 'shared/components'
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../modal'
import './ErrorModal.scss'

interface ResultModalProps {
  onClose: () => void
  title?: string
  body: ReactNode
  actions?: ReactNode
}

export function ResultModal({ onClose, title, body, actions }: ResultModalProps) {
  return (
    <Modal onClose={onClose}>
      <ModalHeader onClose={onClose}>{title ?? 'Result Title'}</ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        {actions && actions}
        <Button onClick={onClose}>Ok</Button>
      </ModalFooter>
    </Modal>
  )
}
