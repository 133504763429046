import React, { ReactNode, useState } from 'react'
import { Popover } from 'antd'
import styled from 'styled-components'
import { ReadWarehouseJournalThreadDto } from 'api-client'
import TextArea from 'antd/es/input/TextArea'
import { Button } from 'shared/components'
import { getUiDateTimeShort } from 'shared/dates/formatters'

const Container = styled.div`
  width: 268px;
`
const CommentItem = styled.div`
  padding: 2px 8px 2px 0;
  border-bottom: solid 1px #a9a9a9;
`
const Text = styled.div`
  font-size: 14px;
`
const UserDate = styled.div`
  display: flex;
`
const User = styled.div`
  font-size: 11px;
`
const Date = styled.div`
  font-size: 11px;
  margin-left: auto;
`

interface WarehouseJournalCommentPopoverProps {
  children: ReactNode
  submitComment: (comment: string) => void
  comments: Array<ReadWarehouseJournalThreadDto>
}

export function WarehouseJournalCommentPopover({
  children,
  submitComment,
  comments,
}: WarehouseJournalCommentPopoverProps) {
  const [comment, setComment] = useState<string>('')
  return (
    <Popover
      overlayStyle={{
        width: '300px',
      }}
      title="Comments"
      trigger="click"
      content={
        <Container>
          {comments.map((item) => (
            <CommentItem key={item.id}>
              <UserDate>
                <User>
                  {item.lastName} {item.firstname}
                </User>
                <Date>{getUiDateTimeShort(item.dateCreated)}</Date>
              </UserDate>
              <Text>{item.comment}</Text>
            </CommentItem>
          ))}

          <TextArea
              autoFocus
            style={{ width: '100%', resize: 'none' }}
            value={comment}
            onChange={(e) => {
              setComment(e.target.value)
            }}
          />

          <Button
            block
            disabled={!comment}
            primary
            onClick={() => {
              submitComment(comment)
              setComment('')
            }}
          >
            Add Comment
          </Button>

        </Container>
      }
    >
      {children}
    </Popover>
  )
}
