/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateRegistrationDto } from '../models/CreateRegistrationDto';
import type { ReadRegistrationDtoApiResponse } from '../models/ReadRegistrationDtoApiResponse';
import type { ReadRegistrationDtoIEnumerableApiResponse } from '../models/ReadRegistrationDtoIEnumerableApiResponse';
import type { ReadRegistrationDtoListApiResponse } from '../models/ReadRegistrationDtoListApiResponse';
import type { UpdateRegistrationDto } from '../models/UpdateRegistrationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RegistrationService {

    /**
     * @param requestBody
     * @returns ReadRegistrationDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1Appointments(
        requestBody?: CreateRegistrationDto,
    ): CancelablePromise<ReadRegistrationDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/appointments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param areaId
     * @returns ReadRegistrationDtoListApiResponse Success
     * @throws ApiError
     */
    public static getApiV1Appointments(
        areaId: string,
    ): CancelablePromise<ReadRegistrationDtoListApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/appointments/{areaId}',
            path: {
                'areaId': areaId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns ReadRegistrationDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1Registration(
        requestBody?: CreateRegistrationDto,
    ): CancelablePromise<ReadRegistrationDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/registration',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ReadRegistrationDtoIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static getApiV1Registration(): CancelablePromise<ReadRegistrationDtoIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/registration',
        });
    }

    /**
     * @param id
     * @returns ReadRegistrationDtoApiResponse Success
     * @throws ApiError
     */
    public static deleteApiV1Registration(
        id?: string,
    ): CancelablePromise<ReadRegistrationDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/registration',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns ReadRegistrationDtoApiResponse Success
     * @throws ApiError
     */
    public static getApiV1Registration1(
        id: string,
    ): CancelablePromise<ReadRegistrationDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/registration/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ReadRegistrationDtoApiResponse Success
     * @throws ApiError
     */
    public static putApiV1Registration(
        id: string,
        requestBody?: UpdateRegistrationDto,
    ): CancelablePromise<ReadRegistrationDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/registration/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
