import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx'
import * as R from 'ramda'
import {
  WarehouseRevisionBatchReadDto,
  WarehouseRevisionBatchUpdateDto,
  WarehouseRevisionReadDto,
} from '../api-client'
import ApiClient from '../api'
import { AreasStore } from './areas.store'
import { Effect } from './utils/Effect'
import { RequestHelper } from './utils/RequestHelper'

class WarehouseRevisionsStoreClass {
  @observable filter: string = ''

  @action setFilterValue = (value: string) => {
    this.filter = value
  }

  getRevisionByIdEffect = new Effect(
    (id) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().warehouseRevisions.getApiV1WarehouseRevisionBatchesGetRevision(
          id,
          AreasStore.selectedAreaId
        )
      ),
    '',
    true
  )

  getRevisionById = async (id: string) => {
    await this.getRevisionByIdEffect.run(id)
  }

  get revision() {
    return this.getRevisionByIdEffect.data || {}
  }

  @computed get batchesFiltered() {
    const filterProducts = (item: WarehouseRevisionBatchReadDto) => {
      if (this.filter) {
        const query = this.filter.replace(/\s+/g, '').toLowerCase()
        return item?.productTitle!.toLowerCase().includes(query)
      }
      return true
    }
    return R.pipe(R.filter(filterProducts))(this.batches)
  }

  @computed get batchesNotApproved() {
    const filterNotApproved = (item: WarehouseRevisionBatchReadDto) => !item?.isApproved
    return R.pipe(R.filter(filterNotApproved))(this.batches)
  }

  approveRevisionBatchEffect = new Effect((id) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().warehouseRevisions.getApiV1WarehouseRevisionBatchesToggleApproveBatch(id)
    )
  )

  approveRevisionBatch = async (id: string) => {
    const response = await this.approveRevisionBatchEffect.run(id)
    if (response) {
      this.updateBatch(response)
    }
  }

  get batches(): Array<WarehouseRevisionBatchReadDto> {
    return this.getRevisionByIdEffect.data?.batches || []
  }

  updateBatch = (batch: WarehouseRevisionBatchReadDto) => {
    const index = this.batches.findIndex((b) => b.id === batch.id)
    const newBatches = [...this.batches]
    newBatches[index] = batch
    const data = {
      ...this.revision,
      batches: newBatches,
    }
    this.getRevisionByIdEffect.data = data
  }

  updateRevisionBatchEffect = new Effect((request) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().warehouseRevisions.putApiV1WarehouseRevisionBatches(
        request.revisionBatchId,
        request
      )
    )
  )

  updateRevisionBatch = async (request: WarehouseRevisionBatchUpdateDto) => {
    const response = await this.updateRevisionBatchEffect.run(request)
    if (response) {
      const batchIndex = this.revision.batches?.findIndex((b) => b.id === request.revisionBatchId)
      if (batchIndex !== undefined) {
        const newRevision = { ...this.revision }
        newRevision.batches![batchIndex] = response
        this.getRevisionByIdEffect.data = newRevision
      }
    }
  }

  getAllRevisionsEffect = new Effect(
    () =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().warehouseRevisions.getApiV1WarehouseRevisionBatchesGetAll(
          AreasStore.selectedAreaId
        )
      ),
    '',
    true
  )

  getAllRevisions = async () => {
    await this.getAllRevisionsEffect.run()
  }

  get revisions() {
    return this.getAllRevisionsEffect.data || []
  }

  startRevisionEffect = new Effect(() =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().warehouseRevisions.postApiV1WarehouseRevisionBatchesStartRevision({
        areaId: AreasStore.selectedAreaId,
      })
    )
  )

  startRevision = async () => {
    const response = await this.startRevisionEffect.run()
    if (response) {
      this.getAllRevisionsEffect.data = [response, ...this.revisions]
    }
  }

  applyRevisionEffect = new Effect((id) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().warehouseRevisions.getApiV1WarehouseRevisionBatchesApplyRevision(id)
    )
  )

  applyRevision = async (id: string) => {
    const response = await this.applyRevisionEffect.run(id)
    if (response) {
      this.updateRevisions(id, response)
      this.updateRevision(response)
    }
  }

  cancelRevisionEffect = new Effect((id) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().warehouseRevisions.getApiV1WarehouseRevisionBatchesCancelRevision(id)
    )
  )

  cancelRevision = async (id: string) => {
    const response = await this.cancelRevisionEffect.run(id)
    if (response) {
      this.updateRevisions(id, response)
      this.updateRevision(response)
    }
  }

  approveAllPositionsEffect = new Effect((id) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().warehouseRevisions.getApiV1WarehouseRevisionBatchesApproveAllBatches(id)
    )
  )

  approveAllPositions = async (id: string) => {
    const response = await this.approveAllPositionsEffect.run(id)
    if (response) await this.getRevisionById(id)
  }

  reopenRevisionEffect = new Effect((id) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().warehouseRevisions.getApiV1WarehouseRevisionBatchesReopenRevision(id)
    )
  )

  reopenRevision = async (id: string) => {
    const response = await this.reopenRevisionEffect.run(id)
    if (response) {
      this.updateRevision(response)
    }
  }

  addCommentEffect = new Effect((id, comment) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().warehouseRevisions.putApiV1WarehouseRevisionBatchesUpdateRevision(id, { comment })
    )
  )

  addComment = async (id: string, comment: string) => {
    await this.addCommentEffect.run(id, comment)
  }

  @action updateRevisions = (id: string, data: WarehouseRevisionReadDto) => {
    const index = this.revisions.findIndex((r) => r.id === id)
    const newList = [...this.revisions]
    newList[index] = data
    runInAction(() => {
      this.getAllRevisionsEffect.data = newList
    })
  }

  @action updateRevision = (data: WarehouseRevisionReadDto) => {
    runInAction(() => {
      this.getRevisionByIdEffect.data = data
    })
  }

  hasActiveRevisionEffect = new Effect(
    () =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().warehouseRevisions.getApiV1WarehouseRevisionBatchesHasActiveRevision(
          AreasStore.selectedAreaId
        )
      ),
    '',
    true
  )

  hasActiveRevision = async () => {
    const response = await this.hasActiveRevisionEffect.run()
    return response
  }

  getDeviationsEffect = new Effect(
    (from: string, to: string) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().warehouseRevisions.postApiV1WarehouseRevisionBatchesDeviations({
          from,
          to,
          areaId: AreasStore.selectedAreaId,
        })
      ),
    '',
    true
  )

  getDeviations = async (from: string, to: string) => {
    const response = await this.getDeviationsEffect.run(from, to)
    return response
  }

  get deviations() {
    return this.getDeviationsEffect.data?.deviations || {}
  }

  get deviationsTuple() {
    return Object.entries(this.deviations)
  }

  constructor() {
    makeAutoObservable(this)
  }
}

export const WarehouseRevisionsStore = new WarehouseRevisionsStoreClass()
