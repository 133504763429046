import React from 'react'
import styled from 'styled-components'
import { ReadMemberDto } from 'api-client'

const Container = styled.div`
    font-size: 18px;
    border-bottom: solid 1px #bbbbbb;
    padding: 8px 12px 8px 0;
    cursor: pointer;

    &:hover {
        background: #eeeeee
    }
`


interface CheckedInMemberItemProps {
  member: ReadMemberDto
}

export function CheckedInMemberItem({ member }: CheckedInMemberItemProps) {
  return (
    <Container>
      <span>{member.lastName} </span>
      <span>{member.firstName}</span>
    </Container>
  )
}
