/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSalePointDto } from '../models/CreateSalePointDto';
import type { ReadSalePointDtoApiResponse } from '../models/ReadSalePointDtoApiResponse';
import type { ReadSalePointDtoIEnumerableApiResponse } from '../models/ReadSalePointDtoIEnumerableApiResponse';
import type { UpdateSalePointDto } from '../models/UpdateSalePointDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SalePointsService {

    /**
     * @param requestBody
     * @returns ReadSalePointDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1SalePoints(
        requestBody?: CreateSalePointDto,
    ): CancelablePromise<ReadSalePointDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/sale-points',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ReadSalePointDtoApiResponse Success
     * @throws ApiError
     */
    public static putApiV1SalePoints(
        id: string,
        requestBody?: UpdateSalePointDto,
    ): CancelablePromise<ReadSalePointDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/sale-points/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns ReadSalePointDtoApiResponse Success
     * @throws ApiError
     */
    public static deleteApiV1SalePoints(
        id: string,
    ): CancelablePromise<ReadSalePointDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/sale-points/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns ReadSalePointDtoApiResponse Success
     * @throws ApiError
     */
    public static getSalePointById(
        id: string,
    ): CancelablePromise<ReadSalePointDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/sale-point/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param areaId
     * @returns ReadSalePointDtoIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static getApiV1SalePoints(
        areaId: string,
    ): CancelablePromise<ReadSalePointDtoIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/sale-points/{areaId}',
            path: {
                'areaId': areaId,
            },
        });
    }

}
