import * as React from 'react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { getPagePath } from 'router/utils'
import { Col, Row, Tabs, TabsProps } from 'antd'
import { MemberStore } from 'store/member.store'
import GetPhotoFeature from 'features/camera/get-photo/get-photo.feature'
import './member-add.scss'
import { MemberQuoteStore } from 'store/member-quote.store'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { Divider } from 'shared/components/divider/Divider'
import { Button } from 'shared/components'
import { PhotoContainer } from 'shared/components/photo-container/PhotoContainer'
import { ApiStore } from 'store/api.store'
import { AreasStore } from 'store/areas.store'
import { AddMemberFormValues, addMemberInitialData } from './add-member-form-fields'
import SignatureFeature from '../../signature/signature.feature'
import { PayMemberQuoteFeature } from '../member-quote/pay-member-quote.feature'
import MemberAddForm from './member-add.form'
import MemberAddResult from './member-add-result'

const FormActions = styled.div`
  display: flex;

  .app-button {
    margin-right: 12px;
  }
`

const H3 = styled.h3`
  font-weight: 600;
`

const MemberAddFeature = observer(({ id }: { id?: string }) => {
  const history = useHistory()
  const [formData, setFormData] = useState<AddMemberFormValues>(addMemberInitialData)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [initialized, setInitialized] = useState<boolean>(!id)
  const canSubmit = () => isValid && MemberStore.hasSignature

  const createMember = (formData: AddMemberFormValues) => {
    MemberStore.createMember(formData).then((response) => {
      if (response) {
        MemberStore.resetMember()
      }
    })
  }

  const updateMember = async (formData: AddMemberFormValues) => {
    if (id) {
      await MemberStore.updateMember(id, formData)
    }
  }

  const goToList = () => {
    history.push(getPagePath('manager/search'))
    MemberStore.hideResultSuccess()
  }

  const goToProfile = () => {
    history.push(getPagePath(`manager/members/${id || MemberStore.id}`))
  }

  const goToProfileAndHideResult = () => {
    goToProfile()
    MemberStore.hideResultSuccess()
  }

  const goToEdit = () => {
    history.push(getPagePath(`manager/members/edit/${MemberStore.id}`))
    MemberStore.hideResultSuccess()
  }

  useEffect(() => {
    if (id) {
      MemberStore.loadItem(id).then(() => {
        ApiStore.stopLoading()
        setInitialized(true)
      })
    }
  }, [id])

  useEffect(() => {
    if (id) {
      setFormData(MemberStore.currentItem as AddMemberFormValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MemberStore.currentItem, id])

  useEffect(() => {
    MemberStore.resetMember()
    MemberQuoteStore.load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const items: TabsProps['items'] = [
    {
      style: { color: 'red' },
      key: '1',
      label: 'Front',
      children: (
        <>
          {!MemberStore.hasPhotoDocument && (
            <GetPhotoFeature
              setPhoto={(photo: string) => {
                MemberStore.setPhotoDocument(photo)
              }}
            />
          )}

          {MemberStore.hasPhotoDocument && (
            <PhotoContainer
              onReset={() => {
                MemberStore.setPhotoDocument(null)
              }}
              src={MemberStore.getPhotoDocument}
            />
          )}
        </>
      ),
    },
    {
      key: '2',
      label: 'Back',
      children: (
        <>
          {!MemberStore.hasPhotoDocumentBack && (
            <GetPhotoFeature
              forTabs
              setPhoto={(photo: string) => {
                MemberStore.setPhotoDocumentBack(photo)
              }}
            />
          )}

          {MemberStore.hasPhotoDocument && (
            <PhotoContainer
              onReset={() => {
                MemberStore.setPhotoDocumentBack(null)
              }}
              src={MemberStore.getPhotoDocumentBack}
            />
          )}
        </>
      ),
    },
  ]

  return (
    <div>
      {MemberStore.resultSuccess ? (
        <MemberAddResult
          id={id}
          goToList={goToList}
          goToProfile={goToProfileAndHideResult}
          goToEdit={goToEdit}
        />
      ) : (
        <div>
          {initialized && (
            <Row gutter={50}>
              <Col xs={24} md={8}>
                <div>
                  <H3>Member Signature</H3>
                  {!MemberStore.hasSignature ? (
                    <SignatureFeature
                      areaId={AreasStore.selectedAreaId}
                      wsUrl={`${process.env.REACT_APP_WS_HUB}`}
                      onFinish={(signature: string) => {
                        MemberStore.setSignature(signature)
                      }}
                    />
                  ) : (
                    <PhotoContainer
                      onReset={() => {
                        MemberStore.setSignature('')
                      }}
                      src={MemberStore.getSignature}
                    />
                  )}
                </div>

                <Divider />

                <div>
                  <H3>Member Photo</H3>
                  {!MemberStore.hasPhoto && (
                    <GetPhotoFeature
                      setPhoto={(photo: string) => {
                        MemberStore.setPhoto(photo)
                      }}
                    />
                  )}
                  {MemberStore.hasPhoto && (
                    <PhotoContainer
                      onReset={() => {
                        MemberStore.setPhoto('')
                      }}
                      src={MemberStore.getPhoto}
                    />
                  )}
                </div>

                <Divider />

                <H3>Document Photo</H3>
                <Tabs
                  type="card"
                  tabBarStyle={{
                    marginBottom: '0',
                    color: 'rgba(0, 0, 0, 0.65)',
                    borderColor: '#ff0000',
                  }}
                  defaultActiveKey="1"
                  items={items}
                />
              </Col>
              <Col xs={24} md={16}>
                {initialized && formData && (
                  <MemberAddForm
                    setFormData={setFormData}
                    formData={formData}
                    isValid={setIsValid}
                  />
                )}
                <Divider />

                {id && <PayMemberQuoteFeature id={id} />}

                <Divider big />

                <FormActions>
                  <Button
                    primary
                    disabled={!canSubmit()}
                    onClick={() => {
                      if (!id) {
                        createMember(formData)
                      } else {
                        updateMember(formData)
                      }
                    }}
                  >
                    {`${!id ? 'Add Member' : 'Update Member'}`}
                  </Button>

                  {id && (
                    <Button bordered onClick={goToProfile}>
                      Go To profile
                    </Button>
                  )}
                </FormActions>
              </Col>
            </Row>
          )}
        </div>
      )}
    </div>
  )
})

export default MemberAddFeature
