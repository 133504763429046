import React from 'react'
import './LogoSidebar.scss'

export function LogoSidebar({ title = 'CoreSocial' }: { title?: string }) {
  return (
    <div className="sidebar-logo">
      <span className="sidebar-logo__text">{title}</span>
    </div>
  )
}
