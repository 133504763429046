import { runInAction } from 'mobx'
import { BasicStore, BasicStoreApi } from './BasicStore'
import { Effect } from './utils/Effect'
import { RequestHelper } from './utils/RequestHelper'
import ApiClient from '../api'
import { AreasStore } from './areas.store'
import { ReadMemberQuoteDto } from '../api-client'

class MemberQuoteStoreClass extends BasicStore<Array<ReadMemberQuoteDto>> {
  api: BasicStoreApi<Array<ReadMemberQuoteDto>, any> = {
    load: () => ApiClient().memberQuotes.getApiV1MemberQuoteByArea(AreasStore.selectedAreaId),
  }

  createEffect = new Effect((request) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().quotes.postApiV1MemberQuote(request),
      'Member Quote created successfully'
    )
  )

  create = async (title: string, period: number, price: number) => {
    const response = await this.createEffect.run({
      title,
      period,
      price,
      areaId: AreasStore.selectedAreaId,
    })
    if (response) {
      runInAction(() => {
        this.data = [
          // @ts-ignore
          ...this.data,
          response,
        ]
      })
    }
  }

  loadByIdEffect = new Effect((id: string) =>
    RequestHelper.unwrapFromFetchResponse(ApiClient().quotes.getApiV1MemberQuote1(id))
  )

  loadById = async (id: string): Promise<ReadMemberQuoteDto> => {
    const response = await this.loadByIdEffect.run(id)
    return response as ReadMemberQuoteDto ?? {} as ReadMemberQuoteDto
  }
}

export const MemberQuoteStore = new MemberQuoteStoreClass()
