import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/components/modal'
import React, { useState } from 'react'
import { Button } from 'shared/components'
import { RegisterWasteForm } from './register-waste.form'
import { RegisterWasteFormData, RegisterWasteModalProps } from './types'

export function RegisterWasteModal({ onSubmit, onClose, productTitle }: RegisterWasteModalProps) {
  const [formData, setFormData] = useState<RegisterWasteFormData>({ quantity: '0' })
  return (
    <Modal onClose={onClose}>
      <ModalHeader onClose={onClose}>Register Waste Weight ({productTitle})</ModalHeader>
      <ModalBody>
        <RegisterWasteForm setFormData={setFormData} />
      </ModalBody>
      <ModalFooter>
        <Button
          primary
          onClick={() => {
            if (formData) onSubmit(formData)
            onClose()
          }}
        >
          Submit
        </Button>
        <Button danger bordered onClick={() => onClose()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}
