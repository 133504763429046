import React, { useCallback } from 'react'
import styled from 'styled-components'
import { WarehouseItemReadShortDto } from 'api-client'
import { ProductComponent } from 'shared/components/product/product.component'
import { CashierStore } from 'store/cashier.store'
import { useHistory } from 'react-router'

interface CashierProductItemProps {
  product: WarehouseItemReadShortDto
}

const CashierProductContainer = styled.div`
  margin-bottom: 16px;
  padding: 8px;
`

function CashierProductItem({ product }: CashierProductItemProps) {
  const history = useHistory()

  const isOutOfStock = useCallback(
    (item: WarehouseItemReadShortDto) => !item.quantity || item?.quantity <= 0,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const productWithQuantity = {
    ...product,
    quantity: 1,
  }

  return (
    <CashierProductContainer>
      <ProductComponent
        productWithQuantity={productWithQuantity}
        quantityInWarehouse={product.quantity ?? 0}
        outOfStock={isOutOfStock(product)}
        onClick={() => {
          CashierStore.addToCheckout(productWithQuantity)
        }}
        goToBatch={(id: string) => {
          history.push(`/app/dispensary/warehouse/${id}`)
        }}
      />
    </CashierProductContainer>
  )
}

export default CashierProductItem
