import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/components'
import * as R from 'ramda'
import { WarehouseRevisionsStore } from 'store/warehouse-revisions.store'
import { WarehouseRevisionBatchReadDto } from 'api-client'
import { Popconfirm } from 'antd'
import { HasNotApprovedPositionsModal } from './has-not-approved-positions.modal'

export const ApproveRevisionFeature = observer(({ revisionId }: any) => {
  const [showNotApprovedModal, setShowNotApprovedModal] = useState(false)
  const filterNotApproved = (item: WarehouseRevisionBatchReadDto): boolean => !item?.isApproved

  const applyRevision = () => {
    WarehouseRevisionsStore.applyRevision(revisionId)
  }

  const startApproveFlow = () => {
    const isAllApproved = R.pipe<any[], Array<WarehouseRevisionBatchReadDto>, number, boolean>(
      R.filter(filterNotApproved),
      R.length,
      R.equals(0)
    )(WarehouseRevisionsStore.batches)

    if (!isAllApproved) {
      setShowNotApprovedModal(true)
    } else {
      applyRevision()
    }
  }

  return (
    <>
      {showNotApprovedModal && (
        <HasNotApprovedPositionsModal
          revisionId={revisionId}
          onSubmit={() => {
            applyRevision()
          }}
          onClose={() => {
            setShowNotApprovedModal(false)
          }}
        />
      )}

      <Popconfirm
        title="You just about Apply Revision. This action will provide Warehouse changes. Do you want to proceed?"
        onConfirm={() => {
          startApproveFlow()
        }}
      >
        <Button primary>Apply Revision</Button>
      </Popconfirm>
    </>
  )
})
