import { action, makeAutoObservable, observable } from 'mobx'
import { CreateAreaDto, ReadAreaDto } from 'api-client'
import { makePersistable } from 'mobx-persist-store'
import { AreaSettings } from 'api/models'
import { Effect } from './utils/Effect'
import { RequestHelper } from './utils/RequestHelper'
import { ApiStore } from './api.store'
import ApiClient from '../api'

export class AreasStoreClass {
  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: 'AreasStore',
      properties: ['selectedAreaId', 'settings'],
      storage: window.localStorage,
      debugMode: true,
    })
  }

  @observable selectedAreaId: string = ''

  @observable areaData: ReadAreaDto | null = null

  get hasSelectedArea() {
    return !!this.selectedAreaId
  }

  get needOnboarding() {
    return this.areas.length === 0
  }

  @observable settings: AreaSettings = {}

  setSettings = (settings: AreaSettings) => {
    this.settings = settings
  }

  @action setSelectedAreaId = (id: string) => {
    this.initArea(id)
    this.selectedAreaId = id
  }

  initArea = (id: string) => {
    this.loadAreaById(id).then(() => {
      if (this.loadAreaDataEffect.data) {
        this.setAreaData(this.loadAreaDataEffect.data)
        if (this.loadAreaDataEffect.data.settings) {
          this.setSettings(this.loadAreaDataEffect.data.settings)
        }
      }
    })
  }

  setAreaData = (data: ReadAreaDto) => {
    this.areaData = data
  }

  get getAreaData() {
    return this.loadAreaDataEffect.data
  }

  resetSelectedAreaId = () => {
    this.selectedAreaId = ''
  }

  areasEffect = new Effect(
    (keycloakId: string) =>
      RequestHelper.unwrapFromFetchResponse(ApiClient().areas.getApiV1AreaByOwner(keycloakId)),
    'Loading Areas',
    true
  )

  loadAreas = async (keycloakId: string): Promise<Array<ReadAreaDto>> => {
    const response = await this.areasEffect.run(keycloakId)
    if (response) {
      if (response[0]) {
        if (this.selectedAreaId === '') {
          const id = response[0].id!
          this.setSelectedAreaId(id)
          this.initArea(id)
        }
      }
    }
    return this.areasEffect.data ?? []
  }

  get areas(): Array<ReadAreaDto> {
    return this.areasEffect.data ?? []
  }

  get areasSpliced() {
    return this.areas.slice()
  }

  areaCreator = new Effect(
    (area) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().areas.postApiV1AreaByUser(area),
        'Area created.'
      ),
    'Creating Area'
  )

  currentUserAreasEffect = new Effect(
    (id) => RequestHelper.unwrapFromFetchResponse(ApiClient().areas.getApiV1AreaByManager(id)),
    'Loading Areas',
    true
  )

  attachManagerEffect = new Effect((areaId: string, managerId: string, attach: boolean) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().areas.postApiV1AreaSwitchManager({
        areaId,
        managerId,
        attach,
      })
    )
  )

  detachManagerEffect = new Effect((areaId: string, managerId: string, attach: boolean) =>
    RequestHelper.unwrapFromFetchResponse<ReadAreaDto>(
      ApiClient().areas.postApiV1AreaSwitchManager({
        areaId,
        managerId,
        attach,
      })
    )
  )

  loadAreaDataEffect = new Effect(
    (id) => RequestHelper.unwrapFromFetchResponse(ApiClient().areas.getApiV1Area1(id)),
    'Loading Area Data',
    true
  )

  loadAreaManagersEffect = new Effect((id: string) =>
    RequestHelper.unwrapFromFetchResponse(ApiClient().areas.getApiV1AreaGetAreaManagers(id))
  )

  loadAreaManagers = (id: string) => this.loadAreaManagersEffect.run(id)

  loadAreaById = (id: string) => this.loadAreaDataEffect.run(id)

  get areaManagers() {
    return this.loadAreaManagersEffect?.data ?? []
  }

  createArea = async (area: CreateAreaDto) => {
    const response = await this.areaCreator.run(area)
    if (response) this.appendNewArea(response)
    return response
  }

  getCurrentUserAreas = async (id: string): Promise<Array<ReadAreaDto>> => {
    const response = await this.currentUserAreasEffect.run(id)
    if (response) {
      const id = response[0].id!
      if (response[0]) {
        this.setSelectedAreaId(id)
        this.initArea(id)
      }
    }
    return this.currentUserAreasEffect.data ?? []
  }

  get currentUserAreas() {
    return this.currentUserAreasEffect.data || []
  }

  get commonAreas() {
    return this.currentUserAreas.length ? this.currentUserAreas : this.areas
  }

  attachManager = async (areaId: string, managerId: string) =>
    this.attachManagerEffect.run(areaId, managerId, true)

  detachManager = async (areaId: string, managerId: string) =>
    this.detachManagerEffect.run(areaId, managerId, false)

  assignManagers = (managers: Array<string>, areaId: string) => {
    const result = managers.map((manager) => this.attachManager(areaId, manager))
    Promise.all(result).then(() => {
      ApiStore.setNotification({
        title: 'Success!',
        message: 'Managers were assigned.',
      })
    })
  }

  detachManagers = (managers: Array<string>, areaId: string) => {
    const result = managers.map((manager) => this.detachManager(areaId, manager))
    Promise.all(result).then(() => {
      ApiStore.setNotification({
        title: 'Success!',
        message: 'Managers were detached.',
      })
    })
  }

  appendNewArea = (area: ReadAreaDto) => this.areasEffect.data && this.areasEffect.data.push(area)
}

export const AreasStore = new AreasStoreClass()
