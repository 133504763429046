import { ReportsStore } from 'store/reports.store'
import { ViewModelClass } from 'store/utils/ViewModel'

class StatsMonthViewModelClass extends ViewModelClass {
  init = async (from: string, to: string) => {
    this.startLoading()
    await ReportsStore.loadConsumptionByCategory(from, to)
    await ReportsStore.loadTopConsumptionMember(from, to)
    this.stopLoading()
  }
}

export const StatsMonthViewModel = new StatsMonthViewModelClass()
