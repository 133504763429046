import { SalePoint } from 'api'
import { makeAutoObservable, runInAction } from 'mobx'
import { CashierStore } from 'store/cashier.store'
import { SalePointsStore } from 'store/sale-points.store'
import { WarehouseRevisionsStore } from 'store/warehouse-revisions.store'

class CashierViewModelClass {
  loading: boolean = true

  hasActiveRevision: boolean = false

  // eslint-disable-next-line class-methods-use-this
  setSalePointId = (id: string) => {
    SalePointsStore.setSelectedId(id)
    CashierStore.setSalePointId(id)
  }

  loadCashierData = async () => {
    this.loading = true
    const salePoints: Array<SalePoint> = await SalePointsStore.load()

    if (salePoints) {
      CashierStore.setSalePointId(SalePointsStore.defaultId)
      await CashierStore.loadProducts()
      const hasActiveRevision = await WarehouseRevisionsStore.hasActiveRevision()
      runInAction(() => {
        this.hasActiveRevision = !!hasActiveRevision
        this.loading = false
      })
    }
  }

  initCashier = async () => {
    this.loadCashierData()
  }

  constructor() {
    makeAutoObservable(this)
  }
}

export const CashierViewModel = new CashierViewModelClass()
