import ApiClient from '../api'
import { Effect } from './utils/Effect'
import { RequestHelper } from './utils/RequestHelper'
import { AreasStore } from './areas.store'

export class DashboardStoreClass {
  loading: boolean = false

  getMemberIdByNfcEffect = new Effect((nfcNumber) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().members.getMemberByNfcNumber(nfcNumber, AreasStore.selectedAreaId)
    )
  )

  getMemberIdByNfc = async (nfcNumber: string): Promise<string | undefined | null> => {
    const response = await this.getMemberIdByNfcEffect.run(nfcNumber)
    if (response) {
      return response.id
    }
    return null
  }

}

export const DashboardStore = new DashboardStoreClass()
