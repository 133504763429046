import React from 'react'
import Checkbox, { CheckboxProps } from 'shared/components/form/checkbox/Checkbox'

interface LabeledCheckboxProps extends CheckboxProps {
  label: string
}

export function LabeledCheckbox({ label, id, ...rest }: LabeledCheckboxProps) {
  const labelId = `app-label_${id}`
  return (
    <label className="labeled-checkbox" htmlFor={labelId}>
      <Checkbox id={labelId} {...rest} />
      <span className="labeled-checkbox__text">{label}</span>
    </label>
  )
}
