import React, { ChangeEvent } from 'react'

interface FileUploadFeatureProps {
  setFile: (file: any) => void
}

function FileUploadFeature({ setFile }: FileUploadFeatureProps) {
  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0])
  }
  return <input type="file" onChange={handleFile} />
}

export default FileUploadFeature
