import * as React from 'react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Preloader } from 'shared/components/preloader'
import { ReactNode } from 'react'

export interface ListingColumns<T> extends ColumnsType<T> {}

interface ListingProps<T> {
  columns: ListingColumns<T>
  data: Array<T>
  loading?: boolean
  rowKey?: any
  summary?: (data: any) => ReactNode
}

export function Listing<T>({ columns, data, loading, rowKey, summary }: ListingProps<T>) {
  return (
    <Table
      // @ts-ignore
      dataSource={data?.slice() ?? []}
      loading={{
        spinning: loading ?? false,
        indicator: <Preloader />,
      }}
      // @ts-ignore
      columns={columns}
      rowKey={rowKey}
      summary={summary}
      pagination={false}
    />
  )
}
