import React, { useEffect, useState } from 'react'
import ProductCategoriesListFeature from 'features/product-category/product-categories-list.feature'
import { ProductCategoriesStore } from 'store/product-categories.store'
import { observer } from 'mobx-react-lite'
import { CommonPage } from 'shared/layout/CommonPage'
import { Button } from 'shared/components'
import { EntityAddModal } from 'shared/components/modal/entity-modal/EntityModal'
import ProductCategoryAddFeature from 'features/product-category/product-category-add.feature'
import { AreasStore } from 'store/areas.store'
import { Preloader } from 'shared/components/preloader'

interface ProductCategoryListPageProps {}

const ProductCategoryListPage: React.FC<ProductCategoryListPageProps> = observer(() => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false)

  useEffect(() => {
    ProductCategoriesStore.load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AreasStore.selectedAreaId])

  return (
    <>
      {showAddModal && (
        <EntityAddModal
          title="Add Category"
          entityForm={
            <ProductCategoryAddFeature
              onSuccess={() => {
                setShowAddModal(false)
              }}
            />
          }
          onClose={() => {
            setShowAddModal(false)
          }}
        />
      )}

      {ProductCategoriesStore.loadStatus.isLoading ? (
        <Preloader message="Loading Data." lower />
      ) : (
        <div>
          <CommonPage
            isMounted={!ProductCategoriesStore.loadStatus.isLoading}
            title="Product Categories"
            actions={
              <div>
                <Button
                  primary
                  onClick={() => {
                    setShowAddModal(true)
                  }}
                >
                  Add Category
                </Button>
              </div>
            }
          >
            <ProductCategoriesListFeature />
          </CommonPage>
        </div>
      )}
    </>
  )
})
export default ProductCategoryListPage
