import classnames from 'classnames'
import './LayoutSidebarSection.scss'
import React, { ReactNode } from 'react'

export interface ILayoutSidebarSectionProps {
  fullHeight?: boolean
  profile?: boolean
  children?: ReactNode
}

export function LayoutSidebarSection({
  children,
  fullHeight,
  profile,
}: ILayoutSidebarSectionProps) {
  const classNames = classnames({
    'app-sidebar-section': true,
    'app-sidebar-section_full-height': fullHeight,
    'app-sidebar-section_profile-widget': profile,
  })
  return <div className={classNames}>{children}</div>
}
