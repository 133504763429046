import * as React from 'react'
import { useHistory } from 'react-router'
import { Table } from 'antd'
import { observer } from 'mobx-react-lite'
import { ColumnsType } from 'antd/es/table'
import { ReadWarehouseItemDto } from 'api-client'
import { WarehouseStore } from 'store/warehouse.store'
import { getPagePathRoleBased } from 'router/utils'
import { Button } from 'shared/components'
import { UserStore } from 'store/user.store'
import { ListSearch } from 'shared/components/listing/ListSearch'
import { getUiDate } from 'shared/dates/formatters'
import { sortByDate } from 'shared/utils/dates'
import { sortAlphabetically } from 'shared/utils/sortings'
import { useDebounce } from 'shared/hooks/useDebounce'

export const WarehouseListClosedFeature = observer(() => {
  const history = useHistory()

  const columns: ColumnsType<ReadWarehouseItemDto> = [
    {
      title: 'Title',
      dataIndex: 'product',
      render: (item) => <div>{item?.title}</div>,
    },
    {
      title: 'Price',
      dataIndex: '',
      sorter: (a, b) => a.price! - b.price!,
      render: (item) => <strong>{item?.price}</strong>,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: (a: ReadWarehouseItemDto, b: ReadWarehouseItemDto) => a.quantity! - b.quantity!,
      render: (item) => (
        <div>
          <strong>{item}</strong>
        </div>
      ),
    },
    {
      title: 'Original Quantity',
      dataIndex: 'originalQuantity',
      render: (item) => (
        <div>
          <strong>{item}</strong>
        </div>
      ),
    },
    {
      title: 'Date Added',
      sorter: (a, b) => sortByDate(a.dateCreated!, b.dateCreated!),
      render: (item) => <strong>{getUiDate(item?.dateCreated)}</strong>,
    },
    {
      title: 'Category',
      dataIndex: '',
      sorter: (a, b) =>
        sortAlphabetically(a?.product!.categories![0].title!, b.product?.categories![0].title!),
      render: (item: ReadWarehouseItemDto) => (
        <strong>
          {item.product?.categories?.length &&
            item.product?.categories.map((c) => <div key={c.id}>{c.title}</div>)}
        </strong>
      ),
    },
    {
      title: 'Sale Point',
      dataIndex: '',
      render: (item: ReadWarehouseItemDto) => (
        <strong>{item.product?.salePoint && item.product?.salePoint.title}</strong>
      ),
    },
    {
      title: 'Change',
      render: (item: ReadWarehouseItemDto) => (
        <Button
          primary
          onClick={() =>
            history.push(getPagePathRoleBased(`warehouse/${item.id}`, UserStore.currentRole!))
          }
        >
          Edit
        </Button>
      ),
    },
  ]

  const findProductByTitle = async (query: string) => {
    WarehouseStore.setSearchProductTitle(query)
    WarehouseStore.setClosedStockCurrentPage(1)
    await WarehouseStore.loadClosed(query)
  }

  const debouncedSearch = useDebounce(findProductByTitle, 500)

  const handlePageChange = (page: number) => {
    WarehouseStore.loadClosed(
      WarehouseStore.searchProductTitle ?? undefined,
      page,
      WarehouseStore.closedStockPageSize
    ).then(() => {
      WarehouseStore.setClosedStockCurrentPage(page ?? 1)
    })
  }

  return (
    <div>
      <ListSearch
        setValue={debouncedSearch}
        value={WarehouseStore.searchProductTitle}
        placeholder="Search by Product title"
      />
      <Table
        columns={columns}
        dataSource={WarehouseStore.filteredProductsClosed.slice()}
        loading={WarehouseStore.loadClosedEffect.isLoading}
        pagination={{
          hideOnSinglePage: true,
          total: WarehouseStore.totalCountClosed,
          pageSize: WarehouseStore.closedStockPageSize,
          current: WarehouseStore.closedStockCurrentPage,
          onChange: handlePageChange,
          onShowSizeChange: (_, size) => {
            WarehouseStore.setClosedStockPageSize(size)
          }
        }}
      />
    </div>
  )
})
