import { ReportsStore } from "store/reports.store"
import { ViewModelClass } from "store/utils/ViewModel"

class BatchesArrivalsViewModelClass extends ViewModelClass {
    init = async (from: string, to: string) => {
      this.startLoading()
      await ReportsStore.getBatchesTotalArrival(from, to)
      this.stopLoading()
    }
  }
  
  export const BatchesArrivalsViewModel = new BatchesArrivalsViewModelClass()
  