import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import GetPhotoFeature from 'features/camera/get-photo/get-photo.feature'
import { observer } from 'mobx-react-lite'
import { Col, Row } from 'antd'
import { MemberStore } from 'store/member.store'
import Icon from 'shared/components/icon'
import './member-profile.scss'
import { BalanceStore } from 'store/balance.store'
import { ErrorModal } from 'shared/components/results/error-modal/error-modal'
import { SuccessModal } from 'shared/components/results/success-modal/success-modal'
import { MemberPhoto } from 'shared/components/member'
import { Divider } from 'shared/components/divider/Divider'
import { isNegative } from 'shared/utils/isNegative'
import { Collapse } from 'shared/components/collapse'
import { PaymentStore } from 'store/payment.store'
import { ChangeBalanceModal } from '../increase-balance/increase-balance-modal'
import { MemberInfo } from '../member-info/member-info'
import { PayMemberQuoteFeature } from '../member-quote/pay-member-quote.feature'
import { MemberBalanceMovementsFeature } from '../member-balance-movements/member-balance-movements.feature'
import { MemberOrdersFeature } from '../member-orders/member-orders.feature'
import { MemberCheckIn } from './member-check-in'
import { MemberQuoteJournal } from '../member-quote-journal/member-quote-journal.feature'

interface MemberProfileFeatureProps {
  id: string
}

export const MemberProfileFeature = observer(({ id }: MemberProfileFeatureProps) => {
  const [showPhoto, setShowPhoto] = useState<boolean>(false)
  const [showIncreaseBalanceModal, setShowIncreaseBalanceModal] = useState<boolean>(false)
  const [showTransferBalanceModal, setShowTransferBalanceModal] = useState<boolean>(false)
  const [showReturnFundsModal, setShowReturnFundsModal] = useState<boolean>(false)
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
  const history = useHistory()

  useEffect(() => {
    setShowPhoto(MemberStore.hasPhoto)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MemberStore.hasPhoto, MemberStore.currentItem])

  const updateMemberPhoto = (photo: string) => {
    MemberStore.updateMemberPhoto(id, photo)
  }

  const onIncreaseBalance = () => {
    setShowIncreaseBalanceModal(true)
  }
  const onTransferBalance = () => {
    setShowTransferBalanceModal(true)
  }

  const onIncrease = (amount: number) => {
    if (isNegative(amount)) {
      return
    }
    PaymentStore.increaseBalance(id, amount).then(() => setShowIncreaseBalanceModal(false))
  }
  const onReturnFunds = (amount: number) => {
    PaymentStore.returnFunds(id, amount).then(() => setShowReturnFundsModal(false))
  }
  const onTransfer = (amount: number) => {
    PaymentStore.transferBalance(id, amount).then(() => setShowTransferBalanceModal(false))
  }

  if (!MemberStore.currentItem) {
    return null
  }

  return (
    <>
      {showSuccessModal && (
        <SuccessModal
          successText="Donation has been accepted!"
          onClose={() => {
            setShowSuccessModal(false)
          }}
        />
      )}

      {showErrorModal && (
        <ErrorModal
          onClose={() => {
            setShowErrorModal(false)
          }}
        />
      )}

      {showTransferBalanceModal && (
        <ChangeBalanceModal
          title="Transfer Balance"
          buttonText="Transfer"
          onClose={() => {
            setShowTransferBalanceModal(false)
          }}
          onSubmit={(amount) => {
            onTransfer(amount)
          }}
        />
      )}

      {showIncreaseBalanceModal && (
        <ChangeBalanceModal
          title="Make a Donation"
          buttonText="Make a donation"
          onClose={() => {
            setShowIncreaseBalanceModal(false)
          }}
          onSubmit={(amount) => {
            onIncrease(amount)
          }}
        />
      )}

      {showReturnFundsModal && (
        <ChangeBalanceModal
          title="Return Credits"
          buttonText="Return"
          defaultAmount={BalanceStore.currentBalance}
          onClose={() => {
            setShowReturnFundsModal(false)
          }}
          onSubmit={(amount) => {
            onReturnFunds(amount)
          }}
        />
      )}

      <div className="app-profile">
        <Row gutter={30}>
          <Col xs={24} md={8}>
            {!showPhoto && (
              <>
                <GetPhotoFeature
                  setPhoto={(photo: string) => {
                    updateMemberPhoto(photo)
                  }}
                />
                <Divider big />
              </>
            )}
            {showPhoto && (
              <div className="app-profile__photo">
                <MemberPhoto src={MemberStore.getPhoto} />

                <div className="app-profile__photo-action">
                  <Icon
                    name="refresh"
                    size={20}
                    className="app-profile__photo-action-icon"
                    color="#000000"
                    onClick={() => {
                      setShowPhoto(false)
                    }}
                  />
                </div>
                <Divider big />
              </div>
            )}
            {id && (
              <>
                <PayMemberQuoteFeature id={id} />
                <Divider big />
              </>
            )}
            <MemberCheckIn id={id} />
          </Col>
          <Col xs={24} md={16}>
            <MemberInfo
              balance={BalanceStore.currentBalance}
              member={MemberStore.currentItem}
              onIncreaseBalance={onIncreaseBalance}
              returnFunds={() => setShowReturnFundsModal(true)}
              transferBalance={onTransferBalance}
              onEditMember={() => {
                history.push(`/app/manager/members/edit/${id}`)
              }}
            />
          </Col>
        </Row>

        <Divider gutter={40} />

        <Collapse showText="Show Stats" hideText="Hide Stats">
        <Divider gutter={16} />
          <Row gutter={30}>
            <Col xs={24} md={12}>
              <div>
                <h2>Orders</h2>
                <MemberOrdersFeature />
              </div>
            </Col>
            <Col xs={24} md={12}>
              <MemberBalanceMovementsFeature />
            </Col>
          </Row>

          <Row gutter={30}>
            <Col xs={24} md={12}>
              <MemberQuoteJournal />
            </Col>
            <Col xs={24} md={12} />
          </Row>
        </Collapse>
        <Divider gutter={40} />
      </div>
    </>
  )
})
