import React, { ReactNode, useEffect } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { isMobile } from 'react-device-detect'
import { AppStore } from 'store/app.store'
import Icon from '../../components/icon'
import './content-layout.scss'

export enum LayoutVariantsEnum {
  MANAGER,
  ADMIN,
  CUSTOMER,
}

export interface ContentLayoutProps {
  sidebar: ReactNode
  admin?: boolean
  manager?: boolean
  customer?: boolean
  children?: ReactNode
}

export const ContentLayout: React.FC<ContentLayoutProps> = observer(
  ({ children, sidebar, admin, manager, customer }) => {
    const { setSidebarHidden, toggleSidebarVisibility } = AppStore

    const classNames = classnames({
      'app-layout': true,
      'app-layout_admin': admin,
      'app-layout_manager': manager,
      'app-layout_customer': customer,
    })

    useEffect(() => {
      if (isMobile) {
        setSidebarHidden()
      }
    }, [setSidebarHidden])

    // const changeLanguageHandler = () => {
    //   i18n.changeLanguage('ru-RU')
    // }

    return (
      <div className={classNames}>
        <div className="app-layout__sidebar-burger">
          <Icon
            name="menu"
            size={48}
            color="#000"
            onClick={() => {
              toggleSidebarVisibility()
            }}
          />
        </div>
        <div className="app-layout-wrapper">
          <div
            className="app-layout__sidebar"
            style={{ display: AppStore.sidebarHidden ? 'none' : 'block' }}
          >
            {sidebar}
          </div>
          <div className="app-layout__content">{children}</div>
        </div>
      </div>
    )
  }
)
