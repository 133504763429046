import { makeAutoObservable } from 'mobx'
import { Effect } from 'store/utils/Effect'
import { RequestHelper } from 'store/utils/RequestHelper'
import ApiClient from 'api'
import { CreateManagerDto } from 'api-client'
import { UserStore } from 'store/user.store'

export class ManagersStoreClass {
  get managers() {
    return this.managersEffect.data ?? []
  }

  get managerForTransfer() {
    return this.managers.map((manager, index) => ({
      ...manager,
      key: index,
    }))
  }

  get managersList() {
    return this.managers?.slice()
  }

  constructor() {
    makeAutoObservable(this)
  }

  managerAddEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().managers.postApiV1ManagersCreateManager(request)
      ),
    'Creating Manager'
  )

  managersEffect = new Effect(
    (ownerId: string) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().managers.getApiV1ManagersByOwner(UserStore.userKeycloakId)
      ),
    'Loading Managers',
    true
  )

  loadManagers = (ownerId: string) => this.managersEffect.run(ownerId)

  addManager = async (data: CreateManagerDto) => {
    await this.managerAddEffect.run(data)
  }
}

export const ManagersStore = new ManagersStoreClass()
