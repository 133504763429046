import React from 'react'
import { observer } from 'mobx-react-lite'
import { WarehouseRevisionsStore } from 'store/warehouse-revisions.store'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader  } from 'shared/components'
import { ApproveRevisionModalProps } from './types'

export const HasNotApprovedPositionsModal = observer(
  ({ onClose, onSubmit, revisionId }: ApproveRevisionModalProps) => (
      <Modal>
        <ModalHeader onClose={onClose}>Merge Batch Weight ({revisionId})</ModalHeader>
        <ModalBody>
          You have a few positions that have not been approved. Make sure you really dont want to
          approve them before submitting the revision.
          <div>
            <strong>Unapproved positions:</strong>
            <ul>
              {WarehouseRevisionsStore.batchesNotApproved.map((batch) => (
                <li key={batch.id}>{batch.productTitle}</li>
              ))}
            </ul>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            primary
            onClick={() => {
              onSubmit()
              onClose()
            }}
          >
            Approve
          </Button>
          <Button danger bordered onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
)
