import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { CommonPage } from 'shared/layout/CommonPage'
import { Button } from 'shared/components'
import ProductListFeature from 'features/product/products-list/products-list.feature'
import { EntityAddModal } from 'shared/components/modal/entity-modal/EntityModal'
import ProductAddFeature from 'features/product/product-add/product-add.feature'
import { SalePointsStore } from 'store/sale-points.store'
import { ProductCategoriesStore } from 'store/product-categories.store'
import { Preloader } from 'shared/components/preloader'
import { ProductsListViewModel } from './products.view-model'

interface ProductsPageProps {}

const ProductsPage: React.FC<ProductsPageProps> = observer(() => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false)

  useEffect(() => {
    ProductsListViewModel.init()
  }, [])
  return ProductsListViewModel.loading ? (
    <Preloader message="Loading Products." lower />
  ) : (
    <div>
      {showAddModal && (
        <EntityAddModal
          title="Add Product"
          onInit={() => {
            ProductCategoriesStore.load()
            SalePointsStore.load()
          }}
          entityForm={
            <ProductAddFeature
              onSuccess={() => {
                setShowAddModal(false)
              }}
            />
          }
          onClose={() => {
            setShowAddModal(false)
          }}
        />
      )}
      <CommonPage
        isMounted={!ProductsListViewModel.loading}
        title="Products"
        actions={
          <div>
            <Button
              primary
              onClick={() => {
                setShowAddModal(true)
              }}
            >
              Add Product
            </Button>
          </div>
        }
      >
        <ProductListFeature />
      </CommonPage>
    </div>
  )
})
export default ProductsPage
