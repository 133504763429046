/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReadMemberQuoteDtoApiResponse } from '../models/ReadMemberQuoteDtoApiResponse';
import type { ReadMemberQuoteDtoIEnumerableApiResponse } from '../models/ReadMemberQuoteDtoIEnumerableApiResponse';
import type { UpdateMemberQuoteDto } from '../models/UpdateMemberQuoteDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MemberQuoteService {

    /**
     * @param areaId
     * @returns ReadMemberQuoteDtoIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static getApiV1MemberQuoteByArea(
        areaId?: string,
    ): CancelablePromise<ReadMemberQuoteDtoIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/member-quote/by-area',
            query: {
                'areaId': areaId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns ReadMemberQuoteDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1MemberQuote(
        requestBody?: UpdateMemberQuoteDto,
    ): CancelablePromise<ReadMemberQuoteDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/member-quote',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ReadMemberQuoteDtoIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static getApiV1MemberQuote(): CancelablePromise<ReadMemberQuoteDtoIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/member-quote',
        });
    }

    /**
     * @param id
     * @returns ReadMemberQuoteDtoApiResponse Success
     * @throws ApiError
     */
    public static deleteApiV1MemberQuote(
        id?: string,
    ): CancelablePromise<ReadMemberQuoteDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/member-quote',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns ReadMemberQuoteDtoApiResponse Success
     * @throws ApiError
     */
    public static getApiV1MemberQuote1(
        id: string,
    ): CancelablePromise<ReadMemberQuoteDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/member-quote/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ReadMemberQuoteDtoApiResponse Success
     * @throws ApiError
     */
    public static putApiV1MemberQuote(
        id: string,
        requestBody?: UpdateMemberQuoteDto,
    ): CancelablePromise<ReadMemberQuoteDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/member-quote/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
