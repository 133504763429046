import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import { Col, DatePicker, Form, Row } from 'antd'
import { MemberQuoteStore } from 'store/member-quote.store'
import styled from 'styled-components'
import { PhotoContainer } from 'shared/components/photo-container/PhotoContainer'
import { observer } from 'mobx-react-lite'
import { DatePickerProps } from 'antd/es'
import { RegistrationsStore } from 'store/registrations.store'
import { Divider } from 'shared/components/divider/Divider'
import { Button } from 'shared/components'
import MemberAddForm from '../../member/member-add/member-add.form'
import GetPhotoFeature from '../../camera/get-photo/get-photo.feature'
import { UserStore } from '../../../store/user.store'
import { AreasStore } from '../../../store/areas.store'
import SignatureFeature from '../../signature/signature.feature'
import { getPagePath } from '../../../router/utils'

const SignatureContainer = styled.div`
  background: #bababa;
  min-height: 120px;
`
const PhotoSelfContainer = styled.div`
  background: #bababa;
`
const PhotoDocumentContainer = styled.div`
  background: #bababa;
`
const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  & > * {
    margin-right: 10px;
  }
`

interface Props {
  id?: string
}

export const RegistrationEditFeature = observer(({ id }: Props) => {
  const [mounted, setMounted] = useState<boolean>(false)

  const [quoteId, setQuoteId] = useState<string>('')
  const isDisabled = useCallback(() => !RegistrationsStore.registration?.quoteId, [])
  const history = useHistory()

  useEffect(() => {
    setQuoteId(RegistrationsStore.registration?.quoteId ?? '')
  }, [])

  const getMemberQuote = useMemo(
    () => MemberQuoteStore.data?.find((item) => item.id === quoteId),
    [quoteId]
  )

  useEffect(() => {
    MemberQuoteStore.load()
  }, [])

  useEffect(() => {
    if (id) {
      RegistrationsStore.loadById(id).then(() => {
        setMounted(true)
      })
    }
  }, [id])

  return (
    <>
      <Row gutter={30}>
        <Col xs={24} md={8}>
          <Form.Item label="Appointment Time">
            <DatePicker
              showTime
              value={RegistrationsStore.appointmentTime}
              onChange={(_: DatePickerProps['value'], dateString: string) => {
                RegistrationsStore.setAppointmentTime(dateString)
              }}
            />
          </Form.Item>

          <SignatureContainer>
            {!RegistrationsStore.sign ? (
              <div>
                <div>Please Sign our Terms and Conditions.</div>
                <SignatureFeature
                  areaId={AreasStore.selectedAreaId}
                  wsUrl={`${process.env.REACT_APP_WS_HUB}`}
                  onFinish={(signature: string) => {
                    RegistrationsStore.setSignature(signature)
                  }}
                />
              </div>
            ) : (
              <PhotoContainer
                src={RegistrationsStore.sign}
                onReset={() => {
                  RegistrationsStore.setSignature('')
                }}
              />
            )}
          </SignatureContainer>
          <Divider />
          <PhotoSelfContainer>
            {!RegistrationsStore.viewData.photo ? (
              <GetPhotoFeature setPhoto={RegistrationsStore.setPhoto} />
            ) : (
              <PhotoContainer
                src={RegistrationsStore.viewData.photo}
                onReset={() => {
                  RegistrationsStore.setPhoto('')
                }}
              />
            )}
          </PhotoSelfContainer>
          <Divider />
          <PhotoDocumentContainer>
            {!RegistrationsStore.viewData.photoDocument ? (
              <GetPhotoFeature setPhoto={RegistrationsStore.setPhotoDocument} />
            ) : (
              <PhotoContainer
                src={RegistrationsStore.viewData.photoDocument}
                onReset={() => {
                  RegistrationsStore.setPhotoDocument('')
                }}
              />
            )}
          </PhotoDocumentContainer>
        </Col>
        <Col xs={24} md={16}>
          {!id && (
            <MemberAddForm
              setFormData={RegistrationsStore.setPersonalData}
            />
          )}

          {mounted && (
            <MemberAddForm
              setFormData={RegistrationsStore.setPersonalData}
              formData={{
                ...RegistrationsStore.viewData.personalData,
                memberQuote: getMemberQuote,
              }}
            />
          )}
        </Col>
      </Row>

      <Divider />
      <ButtonRow>
        {!id && (
          <Button primary onClick={() => RegistrationsStore.addAppointment()}>
            Add Appointment
          </Button>
        )}
        {id && (
          <>
            <Button primary onClick={() => RegistrationsStore.updateRegistration(id)}>
              Save
            </Button>
            <Button
              disabled={isDisabled()}
              primary
              onClick={() => {
                UserStore.promoteToMember(id).then((response) => {
                  if (response) {
                    history.push(getPagePath(`manager/members/${response.id}`))
                  }
                })
              }}
            >
              Promote to Member
            </Button>
          </>
        )}
      </ButtonRow>
    </>
  )
})
