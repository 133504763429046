import React, { useState } from 'react'
import { Button } from 'shared/components'
import { AssignManagerToAreaFeature } from '.'

type AssignManagerTriggerProps = {
  areaId?: string
}
export function AssignManagerTrigger({ areaId }: AssignManagerTriggerProps) {
  const [show, setShow] = useState<boolean>(false)
  return (
    <>
      <Button
        primary
        onClick={() => {
          setShow(true)
        }}
      >
        Manage Staff
      </Button>
      {show && <AssignManagerToAreaFeature onClose={() => setShow(false)} areaId={areaId} />}
    </>
  )
}
