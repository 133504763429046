import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { observer } from 'mobx-react-lite'
import { AreasStore } from 'store/areas.store'
import { UserStore } from 'store/user.store'
import { useAuthentication } from 'auth/useAuthentication'
import { Roles } from 'auth/Roles'
import { Preloader } from 'shared/components/preloader'
import { AreaResolverModal } from './area-resolver.modal'

type AreaDataResolverProps = {
  children: ReactNode
}
export const AreaDataResolver = observer(({ children }: AreaDataResolverProps) => {
  const { keycloakId } = useAuthentication()
  const [initialized, setInitialized] = useState<boolean>(false)
  const { loadAreas, getCurrentUserAreas } = AreasStore

  const history = useHistory()

  const { roles } = UserStore
  const isOwner = useCallback(() => roles.some((role) => role === Roles.OWNER), [roles])

  useEffect(() => {
    if (keycloakId) {
      if (roles.length) {
        if (isOwner()) {
          loadAreas(keycloakId).then(() => {
            setInitialized(true)
            if (AreasStore.needOnboarding) {
              history.push('/app/owner/onboarding')
            }
          })
        } else {
          getCurrentUserAreas(keycloakId).then(() => setInitialized(true))
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, keycloakId])

  return (
    <div>
      {!initialized && <Preloader message="App Initialization" />}
      {initialized && (
        <div>
          {AreasStore.needOnboarding ? (
            children
          ) : (
            <div>{!AreasStore.hasSelectedArea ? <AreaResolverModal /> : children}</div>
          )}
        </div>
      )}
    </div>
  )
})
