import React, { ReactNode } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/components/modal'

interface ModalCommonProps {
  onClose: () => void
  body: ReactNode
  actions: ReactNode
  title: string
}

function ModalCommon({ onClose, body, title, actions }: ModalCommonProps) {
  return (
    <Modal>
      <ModalHeader onClose={onClose}>{title}</ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>{actions}</ModalFooter>
    </Modal>
  )
}

export default ModalCommon
