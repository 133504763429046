import * as R from 'ramda'
import { divide, multiply, subs } from 'shared/utils/math'
import { CheckoutProductType, ProductWithQuantityType } from 'shared/models/product.types'
import { FinancesStore } from 'store/finances.store'
import { CashierProduct } from 'api-client'

export const getDiscountedPrice = (price: number, discount: number) =>
  subs(price, divide(multiply(price, discount), 100))

export const calculateQuantitiesFromTotal = async (
  total: number,
  checkoutList: CheckoutProductType,
  discount: number
) => {
  if (total < 0) return false

  const productsQuantitiesArray = await FinancesStore.calculateQuantities({
    total,
    discount,
    products: Object.values(checkoutList).map((i) => ({
      id: i.id,
      price: i.price,
    })) as Array<CashierProduct>,
  })

  const productsQuantities = R.mergeAll(
    R.map(
      (i) => ({
        [i.id as string]: i.quantity,
      }),
      productsQuantitiesArray
    )
  )

  const newCheckoutListArray = Object.values(checkoutList).map((i) => {
    const id: string = i.id!
    return {
      [id]: {
        ...i,
        // @ts-ignore
        quantity: productsQuantities[id],
      } as ProductWithQuantityType,
    }
  })

  return R.mergeAll(newCheckoutListArray)
}

export const calculateQuantitiesFromProductQuantity = (
  total: number,
  checkoutList: CheckoutProductType,
  discount: number,
  changedProduct: CheckoutProductType
) => {
  const changedProductData = Object.values(changedProduct)[0]
  const discountedPrice = getDiscountedPrice(changedProductData.price!, discount)

  if (discountedPrice === 0) {
    changedProductData.quantity = 1
  }

  let discountedSum = changedProductData.quantity * discountedPrice

  if (discountedSum > total) {
    changedProductData.quantity = divide(total, discountedPrice)
    discountedSum = total
  }

  const leftMoney = subs(total, discountedSum)
  const leftProducts = Object.values(checkoutList).filter((i) => i.id !== changedProductData.id)

  const productsQuantitiesArray = leftProducts.map((i) => {
    const priceWithDiscount = getDiscountedPrice(i.price!, discount)
    let quantityWithDiscount = 0
    if (priceWithDiscount === 0) {
      quantityWithDiscount = 1
    } else {
      quantityWithDiscount = divide(divide(leftMoney, priceWithDiscount), leftProducts.length)
    }
    return {
      [i.id as string]: quantityWithDiscount,
    }
  })

  const productsQuantities = R.mergeAll(productsQuantitiesArray)

  productsQuantities[changedProductData.id as string] = changedProductData.quantity

  const newCheckoutListArray = Object.values(checkoutList).map((i) => {
    const id: string = i.id!
    return {
      [i.id as string]: {
        ...i,
        // @ts-ignore
        quantity: productsQuantities[id],
      } as ProductWithQuantityType,
    }
  })

  const newCheckoutList = R.mergeAll(newCheckoutListArray)

  return newCheckoutList
}
