import React from 'react'
import './member-info.scss'
import { ReadMemberDto } from 'api-client'
import { subs } from 'shared/utils/math'

interface MemberInfoProps {
  member: ReadMemberDto
  balance?: number
  total?: number
}

export function MemberInfoShort({ member, balance, total }: MemberInfoProps) {
  return (
    <div className="member-info">
      <div className="member-info__item member-info__name">
        <div>{member.firstName}</div>
        <div>{member.lastName}</div>
      </div>

      <div className="member-info__item member-info__nfc">
        <div className="member-info__item-key">NFC Number:</div>
        <div className="member-info__item-value">{member.nfcKey}</div>
      </div>

      <div className="member-info__item member-info__birth-document-number">
        <div className="member-info__item-key">Document Number:</div>
        <div className="member-info__item-value">{member.documentNumber}</div>
      </div>

      <div className="member-info__balance member-info__balance_short">
        <div className="member-info__balance-key">Current balance:</div>
        <div className="member-info__balance-value">{balance ?? 0} Credits</div>
      </div>

      <div className="member-info__balance member-info__balance_short">
        <div className="member-info__balance-key">Total:</div>
        <div className="member-info__balance-value">{total ?? 0} Credits</div>
      </div>

      <div className="member-info__balance member-info__balance_short">
        <div className="member-info__balance-key">Next balance:</div>
        <div className="member-info__balance-value">{subs(balance!, total!)} Credits</div>
      </div>
    </div>
  )
}
