import React, { InputHTMLAttributes, ReactElement, useRef } from 'react'
import './Input.scss'
import classnames from 'classnames'

export interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  transparent?: boolean
  coreTest?: boolean
  postfix?: ReactElement

}

export default function Input(props: IInputProps) {
  const { className, transparent, coreTest, postfix, ...restProps } = props
  const inputRef = useRef<HTMLInputElement>(null)

  const isActive = () => !!inputRef?.current?.value

  const classNames = classnames(className, {
    'app-input': true,
    'app-input_active': isActive(),
    'app-input_transparent': transparent,
    'app-input_core-test': coreTest,
  })

  return (
    <>
      <input ref={inputRef} className={classNames} {...restProps} />
      {postfix && <div className="app-input__postfix">{postfix}</div>}
    </>
  )
}
