import React from 'react'
import { observer } from 'mobx-react-lite'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { ContentLayout } from 'shared/layout'
import { LayoutSidebar } from 'shared/layout/sidebar/LayoutSidebar'
import { AreasStore } from 'store/areas.store'
import { CurrentUser } from 'auth/components/CurrentUser'
import { AreaDataResolver } from 'features/area/area-resolver'
import { LayoutSidebarSection } from 'shared/layout/sidebar/LayoutSidebarSection'
import ProductsPage from 'modules/shared/products/products.page'
import { MemberQuoteListPage } from 'modules/owner/pages/member-quote-list.page'
import MemberQuoteAddPage from 'modules/owner/pages/member-quote-add.page'
import WarehousePage from 'modules/shared/warehouse/warehouse.page'
import SalePointListPage from 'modules/shared/sale-point-list.page'
import ProductCategoryListPage from 'modules/shared/product-category-list.page'
import AddMemberPage from 'modules/shared/add-member-page/add-member.page'
import EditMemberPage from 'modules/shared/add-member-page/edit-member.page'
import MembersListPage from 'modules/shared/members-list/members-list.page'
import EditBatchPage from 'modules/shared/batch-edit/edit-batch.page'
import MemberProfilePage from 'modules/shared/member-profile/member-profile.page'
import { WarehouseRevisionPage } from 'modules/shared/warehouse-revisions/warehouse-revision.page'
import { WarehouseRevisionEditPage } from 'modules/shared/warehouse-revisions/warehouse-revision-edit.page'

import { OwnerNavigationMenu } from './OwnerNavigationMenu'
import { AreaListPage } from '../pages/area-list'
import { AreaAddPage } from '../pages/area-add'
import { ManagerListPage } from '../pages/manager-list'
import { ManagerAddPage } from '../pages/manager-add'
import { GeneralAccountListPage } from '../pages/general-account-list'
import { AccountingListPage } from '../pages/accounting-list'
import AreaViewPage from '../pages/area-view'
import SalePointAddPage from '../../admin/components/sale-point-add-page/sale-point-add.page'
import ProductCategoryEditPage from '../pages/product-category-edit.page'
import { WarehouseJournalPage } from '../../admin/pages/warehouse-journal.page'
import DashboardPage from '../pages/dashboard/dashboard.page'
import { AreaRelatedInit } from '../../../AreaRelatedInit'
import StatsMonthPage from '../pages/start-month/stats-month.page'
import { OnbordingPage } from '../pages/onboarding/onboarding.page'
import { WarehouseDeviationsPage } from '../pages/warehouse-deviations/warehouse-deviations.page'
import { BatchesArrivalsPage } from '../pages/batches-arrivals/batches-arrivals.page'
import WarehouseClosedStockPage from "../../shared/warehouse/warehouse-closed-stock.page";

export const AreaOwnerRoutes: React.FC = observer(() => {
  const { path } = useRouteMatch()
  return (
    <AreaDataResolver>
      <AreaRelatedInit>
        <ContentLayout
          sidebar={
            <LayoutSidebar>
              <LayoutSidebarSection profile>
                <CurrentUser />
              </LayoutSidebarSection>
              <LayoutSidebarSection fullHeight>
                {AreasStore.needOnboarding ? null : <OwnerNavigationMenu />}
              </LayoutSidebarSection>
            </LayoutSidebar>
          }
        >
          <Switch>
            <Route path={`${path}`} exact>
              <Redirect to={`${path}/dashboard`} />
            </Route>

            <Route path={`${path}/onboarding`}>
              <OnbordingPage />
            </Route>

            <Route path={`${path}/dashboard`} exact>
              <DashboardPage />
            </Route>

            <Route path={`${path}/dashboard/stats/month`} exact>
              <StatsMonthPage />
            </Route>

            <Route path={`${path}/managers`} exact>
              <ManagerListPage />
            </Route>

            <Route path={`${path}/managers/add`} exact>
              <ManagerAddPage />
            </Route>

            <Route path={`${path}/areas/add`} exact>
              <AreaAddPage />
            </Route>

            <Route path={`${path}/areas/:id`} exact>
              <AreaViewPage />
            </Route>

            <Route path={`${path}/areas`}>
              <AreaListPage />
            </Route>
            <Route path={`${path}/accounting`} exact>
              <AccountingListPage />
            </Route>
            <Route path={`${path}/accounting/general-accounts`} exact>
              <GeneralAccountListPage />
            </Route>

            <Route path={`${path}/products/list`}>
              <ProductsPage />
            </Route>

            <Route path={`${path}/warehouse`} exact>
              <WarehousePage />
            </Route>

            <Route path={`${path}/warehouse/closed`} exact>
              <WarehouseClosedStockPage />
            </Route>

            <Route path={`${path}/warehouse/journal`}>
              <WarehouseJournalPage />
            </Route>

            <Route path={`${path}/member-quote/list`}>
              <MemberQuoteListPage />
            </Route>
            <Route path={`${path}/member-quote/edit`}>
              <MemberQuoteAddPage />
            </Route>

            <Route path={`${path}/product-categories/edit/:id`}>
              <ProductCategoryEditPage />
            </Route>
            <Route path={`${path}/product-categories/list`}>
              <ProductCategoryListPage />
            </Route>

            <Route path={`${path}/sale-points/edit`}>
              <SalePointAddPage />
            </Route>

            <Route path={`${path}/sale-points/list`}>
              <SalePointListPage />
            </Route>

            <Route path={`${path}/members/edit/:id`}>
              <EditMemberPage />
            </Route>
            <Route path={`${path}/members/edit`} exact>
              <AddMemberPage />
            </Route>

            <Route path={`${path}/search`}>
              <MembersListPage />
            </Route>

            <Route path={`${path}/members/:id`}>
              <MemberProfilePage />
            </Route>


            <Route path={`${path}/dashboard/deviations`} exact>
              <WarehouseDeviationsPage />
            </Route>

            <Route path={`${path}/warehouse/:id`} exact>
              <EditBatchPage />
            </Route>
          
            <Route path={`${path}/warehouse-revisions`} exact>
              <WarehouseRevisionPage />
            </Route>

            <Route path={`${path}/warehouse-revisions/:id`} exact>
              <WarehouseRevisionEditPage />
            </Route>

            <Route path={`${path}/reports/batches-arrivals`} exact>
              <BatchesArrivalsPage />
            </Route>

            <Route path="*">Page not found</Route>
          </Switch>
        </ContentLayout>
      </AreaRelatedInit>
    </AreaDataResolver>
  )
})
