import { MemberQuoteListFeature } from 'features/member/member-quote/member-quote-list.feature'
import { CommonPage } from 'shared/layout/CommonPage'
import { Button } from 'shared/components'
import React, { useState } from 'react'
import { EntityAddModal } from 'shared/components/modal/entity-modal/EntityModal'
import { MemberQuoteAddFeature } from 'features/member/member-quote/member-quote-add/member-quote-add.feature'

export default function MemberQuoteListPage() {
  const [showAddModal, setShowAddModal] = useState<boolean>(false)
  return (
    <>
      {showAddModal && (
        <EntityAddModal
          title="Add Quote"
          entityForm={
            <MemberQuoteAddFeature
              onSuccess={() => {
                setShowAddModal(false)
              }}
            />
          }
          onClose={() => {
            setShowAddModal(false)
          }}
        />
      )}

      <CommonPage
        title="Member Quotes"
        actions={
          <div>
            <Button
              primary
              onClick={() => {
                setShowAddModal(true)
              }}
            >
              Add Quote
            </Button>
          </div>
        }
      >
        <MemberQuoteListFeature />
      </CommonPage>
    </>
  )
}
