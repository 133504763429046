import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { DatePicker } from 'antd'
import moment from 'moment/moment'
import { CommonPage } from 'shared/layout/CommonPage'
import { Preloader } from 'shared/components/preloader'
import { TodayReportOwner } from 'features/report/today/today-report-owner'
import { WarehouseOwnerList } from 'features/warehouse/owner-list/warehouse-owner-list'
import { Divider } from 'shared/components/divider/Divider'
import { OwnerDashboardViewModel } from './dashboard.view-model'

const DashboardPage = observer(() => {
  const [date, setDate] = useState(moment())

  const from = date.startOf('day').toISOString()
  const to = date.endOf('day').toISOString()

  useEffect(() => {
    OwnerDashboardViewModel.init(from, to)
  }, [from, to])

  return OwnerDashboardViewModel.loading ? (
    <Preloader message="Loading Statistics" lower />
  ) : (
    <CommonPage
      isMounted={!OwnerDashboardViewModel.loading}
      title={
        <DatePicker
          format="DD.MM.YYYY"
          defaultValue={date}
          onChange={(value) => {
            if (value) setDate(value)
          }}
        />
      }
    >
      <TodayReportOwner />
      <Divider big />
      <WarehouseOwnerList />
    </CommonPage>
  )
})

export default DashboardPage
