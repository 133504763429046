/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppUserListApiResponse } from '../models/AppUserListApiResponse';
import type { AreaRelatedDataApiResponse } from '../models/AreaRelatedDataApiResponse';
import type { CreateAreaDto } from '../models/CreateAreaDto';
import type { ReadAreaDtoApiResponse } from '../models/ReadAreaDtoApiResponse';
import type { ReadAreaDtoIEnumerableApiResponse } from '../models/ReadAreaDtoIEnumerableApiResponse';
import type { SwitchManagerDto } from '../models/SwitchManagerDto';
import type { UpdateAreaDto } from '../models/UpdateAreaDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AreaService {

    /**
     * @param requestBody
     * @returns ReadAreaDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1AreaByUser(
        requestBody?: CreateAreaDto,
    ): CancelablePromise<ReadAreaDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/area/by-user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns AreaRelatedDataApiResponse Success
     * @throws ApiError
     */
    public static postApiV1AreaSwitchManager(
        requestBody?: SwitchManagerDto,
    ): CancelablePromise<AreaRelatedDataApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/area/switch-manager',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns AppUserListApiResponse Success
     * @throws ApiError
     */
    public static getApiV1AreaGetAreaManagers(
        id: string,
    ): CancelablePromise<AppUserListApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/area/get-area-managers/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns ReadAreaDtoIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static getApiV1AreaByOwner(
        id: string,
    ): CancelablePromise<ReadAreaDtoIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/area/by-owner/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns ReadAreaDtoIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static getApiV1AreaByManager(
        id: string,
    ): CancelablePromise<ReadAreaDtoIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/area/by-manager/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody
     * @returns ReadAreaDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1Area(
        requestBody?: CreateAreaDto,
    ): CancelablePromise<ReadAreaDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/area',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ReadAreaDtoIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static getApiV1Area(): CancelablePromise<ReadAreaDtoIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/area',
        });
    }

    /**
     * @param id
     * @returns ReadAreaDtoApiResponse Success
     * @throws ApiError
     */
    public static deleteApiV1Area(
        id?: string,
    ): CancelablePromise<ReadAreaDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/area',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns ReadAreaDtoApiResponse Success
     * @throws ApiError
     */
    public static getApiV1Area1(
        id: string,
    ): CancelablePromise<ReadAreaDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/area/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ReadAreaDtoApiResponse Success
     * @throws ApiError
     */
    public static putApiV1Area(
        id: string,
        requestBody?: UpdateAreaDto,
    ): CancelablePromise<ReadAreaDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/area/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
