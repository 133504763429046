import React from 'react'
import styled from 'styled-components'

const Container = styled.div<DividerModifiers>`
  ${({ big, small, vertical }: DividerModifiers) => `
     margin-bottom: 12px;
    ${
      small
        ? `
          margin-bottom: 6px;
        `
        : ''
    } 
    ${
      big
        ? `
      margin-bottom: 24px;
    `
        : ''
    }
    ${
      vertical
        ? `
        margin-bottom: 0;
        margin-right: 6px;
    `
        : ''
    }
`}
`
type DividerModifiers = {
  big?: boolean
  small?: boolean
  vertical?: boolean
}
type DividerProps = {
  centered?: boolean
  gutter?: number
} & DividerModifiers

export function Divider({ centered, big, small, gutter, vertical }: DividerProps) {
  return (
    <Container
      big={big}
      small={small}
      vertical={vertical}
      style={{ marginBottom: gutter && gutter }}
    />
  )
}
