import React, { ReactNode, SyntheticEvent } from 'react'
import './Button.scss'
import classnames from 'classnames'

export interface IButtonProps {
  children?: string | ReactNode
  icon?: ReactNode
  onClick?: (e: SyntheticEvent) => void
  primary?: boolean
  danger?: boolean
  secondary?: boolean
  small?: boolean
  large?: boolean
  bordered?: boolean
  oval?: boolean
  login?: boolean
  block?: boolean
  black?: boolean
  success?: boolean
  disabled?: boolean
  lowercase?: boolean
  normalcase?: boolean
  text?: boolean
  square?: boolean
  className?: string
  centered?: boolean
}

export default function Button(props: IButtonProps): JSX.Element {
  const {
    children,
    className,
    icon,
    onClick,
    primary,
    danger,
    secondary,
    small,
    large,
    bordered,
    oval,
    login,
    block,
    black,
    disabled,
    lowercase,
    normalcase,
    text,
    square,
    centered,
    ...restProps
  } = props
  const classNames = classnames(
    {
      'app-button': true,
      'app-button_primary': primary,
      'app-button_danger': danger,
      'app-button_secondary': secondary,
      'app-button_small': small,
      'app-button_large': large,
      'app-button_bordered': bordered,
      'app-button_oval': oval,
      'app-button_login': login,
      'app-button_icon-center': !children,
      'app-button_block': block,
      'app-button_disabled': disabled,
      'app-button_black': black,
      'app-button_lowercase': lowercase,
      'app-button_text': text,
      'app-button_square': square,
      'app-button_centered': centered,
      'app-button_normalcase': normalcase,
    },
    className
  )

  return (
    <button
      type="button"
      disabled={disabled}
      className={classNames}
      onClick={onClick}
      {...restProps}
    >
      {icon && <span className="app-button__icon">{icon}</span>}
      {children && <span className="app-button__text">{children}</span>}
    </button>
  )
}

export { Button }
