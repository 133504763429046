import React from 'react'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Roles } from '../auth/Roles'
import { useAuthentication } from '../auth/useAuthentication'
import { getPagePath } from './utils'

interface DefaultPageRedirectProps {
  redirectUrl?: string
}

export const DefaultPageRedirect: React.FC<DefaultPageRedirectProps> = observer(
  ({ redirectUrl }) => {
    const { getRoles } = useAuthentication()
    const redirect = redirectUrl === '/app' || redirectUrl === '/app/' ? undefined : redirectUrl
    if (!getRoles()) return <Redirect to="/" />

    let to: string
    if (getRoles().includes(Roles.OWNER)) {
      to = `${redirect ?? getPagePath('owner')}`
    } else if (getRoles().includes(Roles.MANAGER)) {
      to = `${redirect ?? getPagePath('manager')}`
    } else if (getRoles().includes(Roles.DISPENSARY)) {
      to = `${redirect ?? getPagePath('dispensary')}`
    } else {
      to = '/forbidden'
    }

    return <Redirect to={to} />
  }
)
