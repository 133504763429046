import React, { HTMLAttributes } from 'react'
import './Checkbox.scss'
import Icon from 'shared/components/icon'

export interface CheckboxProps extends HTMLAttributes<HTMLInputElement> {
  checked?: boolean
  iconColor?: string
  iconSize?: number
  className?: string
  size?: number
}

export default function Checkbox({
  checked,
  iconSize,
  iconColor = '#fff',
  className,
  size = 6,
    id,
  ...rest
}: CheckboxProps) {
  return (
    <div className={`app-checkbox ${className ?? ''}`}>
      <input id={id} type="checkbox" className="app-checkbox-hidden" checked={checked} {...rest} />
      <div className={`app-checkbox-styled ${checked && 'app-checkbox-styled_checked'}`}>
        <div className="app-checkbox-styled__checkmark">
          <Icon size={size} name="checkmark" color={iconColor} />
        </div>
      </div>
    </div>
  )
}
