import { ViewModelClass } from 'store/utils/ViewModel'
import { WarehouseStore } from 'store/warehouse.store'

class BatchEditViewModeClass extends ViewModelClass {
  init = async (batchId: string, from: string, to: string) => {
    this.startLoading()
    await WarehouseStore.loadWarehouseBatchJournal({
      batchId,
      from,
      to,
    })
    await WarehouseStore.loadWarehouseBatch(batchId)
    this.stopLoading()
  }
}

export const BatchEditViewModel = new BatchEditViewModeClass()
