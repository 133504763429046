export class LocalstorageService {
  constructor(private name: string) {}

  get(): string | null {
    try {
      const item = localStorage.getItem(this.name)
      if (item === null) {
        return item
      }
      return JSON.parse(item)
    } catch {
      localStorage.removeItem(this.name)
      return null
    }
  }

  set(value: string | null) {
    try {
      localStorage.setItem(this.name, JSON.stringify(value))
    } catch (error) {
      console.warn("Couldn't set token: ", error)
      throw error
    }
  }
}
