import React, { useEffect } from 'react'
import { Preloader } from 'shared/components/preloader/Preloader'
import { useStores } from 'store/useStores'
import { observer } from 'mobx-react-lite'
import { MountAnimation } from '../mount-animation/MountAnimation'

export const ApiLoading = observer(() => {
  const { ApiStore } = useStores()
  useEffect(() => {}, [ApiStore.loading])
  return (
    <MountAnimation isVisible={ApiStore.loading} classNames="transition">
      <Preloader message={ApiStore.loadingMessage} over />
    </MountAnimation>
  )
})
