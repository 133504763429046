import React, { useEffect } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { EditBatchFeature } from 'features/warehouse/edit-batch'
import { Preloader } from 'shared/components/preloader'
import { CommonPage } from 'shared/layout/CommonPage'
import { BatchEditViewModel } from './batch-edit.view-mode'

 const EditBatchPage = observer(() => {
  const { id } = useParams<{
    id: string
  }>()

  const from = moment.utc().subtract(12, 'month').startOf('day').toISOString()
  const to = moment.utc().endOf('day').toISOString()

  useEffect(() => {
    BatchEditViewModel.init(id, from, to)
  }, [id, from, to])

  return BatchEditViewModel.loading ? (
    <Preloader message="Loading Batch Journal." lower />
  ) : (
    <CommonPage isMounted={!BatchEditViewModel.loading}>
      <EditBatchFeature id={id} />
    </CommonPage>
  )
})

export default EditBatchPage