import { ProductsStore } from "store/products.store";
import { ViewModelClass } from "store/utils/ViewModel";

class ProductsListViewModelClass extends ViewModelClass {

    init = async () => {
        this.startLoading()
        await ProductsStore.load()
        this.stopLoading()
    }

}

export const ProductsListViewModel = new ProductsListViewModelClass()