import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import './NavigationItemChild.scss'

interface NavigationItemChildProps {
  to: string
  title: string
}

export function NavigationItemChild({ to, title }: NavigationItemChildProps) {
  const { pathname } = useLocation()
  const isActive = pathname.includes(to.replace(/\/$/, ''))

  return (
    <div
      className={`
      navigation-item-child 
      ${isActive ? 'navigation-item-child_active' : ''}
      `}
    >
      <Link to={to}>{title}</Link>
    </div>
  )
}
