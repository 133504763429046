import React from 'react'
import { Button } from 'shared/components'
import QRCode from 'react-qr-code'

interface QrFeatureProps {
  src?: string | null
  value: string
}

export function QrFeature({ src, value }: QrFeatureProps) {
  return (
    <>
      <Button className="btn btn-success btn-lg" onClick={() => window.print()}>
        PRINT
      </Button>
      <div id="print">
        <QRCode
          size={256}
          style={{ height: 'auto', maxWidth: '100%', width: '100%', padding: '190px' }}
          value={value}
          viewBox="0 0 256 256"
        />
      </div>
    </>
  )
}
