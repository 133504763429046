import { useCallback, useState } from 'react'
import moment from 'moment'
import { CreateRegistrationDto } from 'api-client'
import { MemberPersonalDataFormValues } from './member-personal-data.form-fields'
import ApiClient from '../../api'

export interface RegistrationData {
  termsApproved: boolean
  signature: string
  photoDocument: string
  photoSelf: string
  personalData: MemberPersonalDataFormValues
}

export const useRegistration = (areaId: string) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)

  const mapData = useCallback(
    (data: RegistrationData): CreateRegistrationDto => ({
      ...data.personalData,
      birthDate: data.personalData.birthDate
        ? moment(data.personalData.birthDate).format('DD.MM.YYYY')
        : '',
      sign: data.signature,
      photo: data.photoSelf,
      photoDocument: data.photoDocument,
      areaId,
    }),
    [areaId]
  )
  const request = useCallback(
    (data: RegistrationData) => {
      setLoading(true)
      ApiClient()
        .appointments.postApiV1Registration(mapData(data))
        .then(() => {
          setLoading(false)
          setSubmitted(true)
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [mapData]
  )

  return {
    loading,
    request,
    submitted,
  }
}
