import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/components/modal'
import React, { useState } from 'react'
import { Button } from 'shared/components'
import { RechargeBatchForm } from './recharge-batch.form'
import { RechargeBatchFormData, RechargeBatchModalProps } from './types'

export function RechargeBatchModal({ onSubmit, onClose, productTitle }: RechargeBatchModalProps) {
  const [formData, setFormData] = useState<RechargeBatchFormData>({ quantity: '0' })
  return (
    <Modal onClose={onClose}>
      <ModalHeader onClose={onClose}>Recharge Batch Weight ({productTitle})</ModalHeader>
      <ModalBody>
        <RechargeBatchForm setFormData={setFormData} />
      </ModalBody>
      <ModalFooter>
        <Button
          primary
          onClick={() => {
            if (formData) onSubmit(formData)
            onClose()
          }}
        >
          Submit
        </Button>
        <Button danger bordered onClick={() => onClose()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}
