import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { MemberStore } from 'store/member.store'
import { ReportsStore } from 'store/reports.store'
import { Table, TableColumnsType } from 'antd'
import { Order } from 'api-client'
import { toTodayEnd, lastYear } from 'shared/utils/today'
import { getUiDateTime } from 'shared/dates/formatters'
import styled from 'styled-components'

const LabelSmall = styled.div`
  font-size: 11px;
  color: #bababa;
`

export const MemberBalanceMovementsFeature = observer(() => {
  const { member } = MemberStore

  useEffect(() => {
    ReportsStore.loadMemberBalanceMovements(lastYear, toTodayEnd, member?.id!)
  }, [member?.id])

  const columns: TableColumnsType<Order> = [
    {
      title: 'Date',
      dataIndex: 'dateCreated',
      render: (item: string) => <div>{getUiDateTime(item)}</div>,
    },
    {
      title: 'Count',
      render: (item: Order) => {
        const count = item.journalEntry?.count!
        return <div>{count}</div>
      },
    },
    {
      title: 'Assigner',
      render: (item: Order) => <div>{item.assigner?.personalData?.firstName!} {item.assigner?.personalData?.lastName!}</div>,
    },
    {
      title: 'Action',
      render: (item: Order) => {
        const transfer = item.journalEntry?.debit?.code === 92
        const increasing = item.journalEntry?.credit?.code === 60
        return (
          <div>
            {increasing ? 'Increased' : 'Returned'}
            {transfer ? <LabelSmall>(Transferred)</LabelSmall> : ''}
          </div>
        )
      },
    },
  ]

  return (
    <div>
      <h2>Balance Movements</h2>
      <Table
        rowKey="id"
        loading={ReportsStore.memberBalanceMovementsLoading}
        dataSource={ReportsStore.memberBalanceMovements}
        columns={columns}
        pagination={{
          pageSize: 5,
        }}
      />
    </div>
  )
})
