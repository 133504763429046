import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { CommonPage } from 'shared/layout/CommonPage'
import { RevisionEditFeature } from 'features/warehouse/revision/revision-edit.feature'
import { Preloader } from 'shared/components/preloader'
import { WarehouseRevisionsViewModel } from './warehouse-revisions.view-model'

export const WarehouseRevisionEditPage = observer(() => {
  const { id } = useParams<{
    id: string
  }>()

  useEffect(() => {
    WarehouseRevisionsViewModel.initRevision(id)
  }, [id])

  return WarehouseRevisionsViewModel.loading ? (
    <Preloader message="Loading Revision Data." lower />
  ) : (
    <CommonPage title="Revision" isMounted={!WarehouseRevisionsViewModel.loading}>
      <RevisionEditFeature id={id} />
    </CommonPage>
  )
})
