import React, { ReactNode } from 'react'
import { Button } from 'shared/components'
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../modal'
import './ErrorModal.scss'

interface ErrorModalProps {
  onClose: () => void
  title: string
  body: ReactNode
  actions?: ReactNode
}

export function ErrorModal({ onClose, title, body, actions }: ErrorModalProps) {
  return (
    <Modal onClose={onClose}>
      <ModalHeader onClose={onClose}>{title}</ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        {actions && actions}
        <Button onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}
