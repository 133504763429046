import { configure } from 'mobx'

export const configureMobX = () => {
  setTimeout(() => {
    configure({
      enforceActions: 'observed',
      reactionScheduler(f) {
        setTimeout(f, 1)
      },
    })
  }, 1)
}
