import React, { ReactNode, useEffect, useState } from 'react'
import { ApiStore } from 'store/api.store'
import { observer } from 'mobx-react-lite'
import { Result, notification } from 'antd'
import { ResultModal } from './error-modal/ResultModal'
import { ErrorModal } from './error-modal/ErrorModal'

interface CustomMessageHandlerProps {
  children?: ReactNode
}

export const CustomMessageHandler: React.FC<CustomMessageHandlerProps> = observer(
  ({ children }) => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [showResult, setShowResult] = useState<boolean>(false)

    useEffect(() => {
      if (ApiStore.hasErrors) setShowModal(true)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ApiStore.hasErrors])

    useEffect(() => {
      if (ApiStore.hasResult) setShowResult(true)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ApiStore.hasResult])

    const isModalVisible = () => showModal && ApiStore.hasErrors

    const notify = () => {
      notification.success({
        message: `${ApiStore?.notification?.title}`,
        description: `${ApiStore?.notification?.message}`,
        duration: 2,
      })
      ApiStore.setNotification(null)
    }

    useEffect(() => {
      if (ApiStore.hasNotification) {
        notify()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ApiStore.hasNotification])

    return (
      <>
        {showModal && (
          <ErrorModal
            onClose={() => {
              setShowModal(false)
              ApiStore.setError(null)
            }}
            title={`${ApiStore?.error?.title ?? 'Custom Error'}`}
            body={
              <div>
                {isModalVisible() && (
                  <div className="ui-error">
                    <div>{ApiStore?.error?.message}</div>
                    {ApiStore?.statusCode && (
                      <div style={{ marginTop: '10px' }}>Status Code: {ApiStore?.statusCode}</div>
                    )}
                  </div>
                )}
              </div>
            }
          />
        )}
        {showResult && (
          <ResultModal
            onClose={() => {
              setShowResult(false)
              ApiStore.setResult(null)
            }}
            title={ApiStore?.result?.title}
            body={
              <div>
                <Result status="success" title={ApiStore?.result?.message} />
              </div>
            }
          />
        )}
      </>
    )
  }
)
