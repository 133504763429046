import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { WarehouseStore } from 'store/warehouse.store'
import { WarehouseJournalStore } from 'store/warehouse-journal.store'
import { OrderDetailsModal } from './order-details.modal'
import { OrderDetailsTriggerProps } from './types'

const Trigger = styled.span``

export const OrderDetailsTrigger = observer(({ id, children }: OrderDetailsTriggerProps) => {
  const [show, setShow] = useState<boolean>(false)
  const { revertReleaseProducts } = WarehouseStore
  const { loadJournalRecord, journalRecord } = WarehouseJournalStore
  return (
    <div>
      {show && (
        <OrderDetailsModal
          onRevert={revertReleaseProducts}
          onClose={() => {
            setShow(false)
          }}
          order={journalRecord}
        />
      )}
      <Trigger
        onClick={() => {
          loadJournalRecord(id).then(() => {
            setShow(true)
          })
        }}
      >
        {children}
      </Trigger>
    </div>
  )
})
