import styled from "styled-components";

export const DashboardActions = styled.div`
  align-self: flex-end;
  display: flex;
  height: auto;
`

export const DashboardStats = styled.div`
  margin-bottom: 40px;
  display: flex;
  height: auto;
`
export const DashboardHeader = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1;
  background: #e7e7e7;
  border-radius: 6px;
  padding: 12px;
  min-width: 125px;
    margin-bottom: 16px;
`
export const DashboardHeaderPrimary = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1;
  background-color: #03bd82;
  color: #fff;
  border-radius: 6px;
  padding: 12px;
  min-width: 125px;
    margin-bottom: 16px;
`


export const DashboardHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
`
export const DashboardHeaderValue = styled.div`
  font-size: 58px;
  font-weight: 900;
  text-align: center;
`

export const DashboardHeaderAnnotation = styled.div`
  font-size: 11px;
  font-weight: 700;
`