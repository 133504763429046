import React from 'react'
import { observer } from 'mobx-react-lite'
import { Col, Row } from 'antd'
import { ReportsStore } from 'store/reports.store'
import { Divider } from 'shared/components/divider/Divider'

export const MonthConsumptionFeature = observer(() => (
  <div>
    <h2>Consumption by Category</h2>
    <Row>
      <Col xs={8}>
        <div style={{ display: 'flex' }}>
          {ReportsStore.consumptionByCategory.map((i) => (
            <div key={i.category?.id} style={{ marginRight: '20px' }}>
              <div>{i.category?.title}</div>
              <div>Quantity: {i.quantity}</div>
              <div>Sum: {i.sum}</div>
            </div>
          ))}
        </div>
      </Col>
    </Row>
    <Divider />
    <h2>Top Consumption Member</h2>
    <div>
      <div>
        <span>{ReportsStore.topConsumptionMember.member?.firstName} </span>
        <span>{ReportsStore.topConsumptionMember.member?.lastName}</span>
      </div>
      <h1>{ReportsStore.topConsumptionMember.consumption}</h1>
    </div>
  </div>
))
