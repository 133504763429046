import React from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { CheckoutStore } from 'store/checkout.store'
import { ApiStore } from 'store/api.store'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/components/modal'
import { Divider } from 'shared/components/divider/Divider'
import { Button } from 'shared/components'

const OrderList = styled.div`
  display: flex;
  flex-direction: column;
`
const Table = styled.table`
  border-bottom: solid 1px black;
`

const Th = styled.th`
  font-size: 11px;
  text-align: left;
  color: #bababa;
`
const Td = styled.td`
  text-align: left;
  border-bottom: solid 1px #a1a1a1;
  font-size: 18px;
  min-width: 50px;
  padding: 5px 0;
`
const Title = styled.div`
  font-size: 14px;
  padding-right: 5px;
`

const OrderDiscount = styled.div`
  font-size: 16px;
  color: #de6438;
  text-align: right;
  margin-bottom: 12px;
`

const OrderTotal = styled.div`
  font-size: 21px;
  color: #00be82;
  text-align: right;
`

interface CheckoutCurrentOrderModalProps {
  onClose: () => void
}
export const CheckoutCurrentOrderModal = observer(({ onClose }: CheckoutCurrentOrderModalProps) => {
  const { products, sum, discount } = CheckoutStore.lastOrder || {}

  return (
    <div>
      <Modal width={320}>
        <ModalHeader onClose={onClose}>Success Order</ModalHeader>
        <ModalBody>
          {products?.length && (
            <div>
              <OrderList>
                <Table>
                  <thead>
                    <tr>
                      <Th>Title</Th>
                      <Th>Price</Th>
                      <Th>Quantity</Th>
                      <Th>Gift</Th>
                    </tr>
                  </thead>
                  <tbody>
                    {products?.map((item) => (
                      <tr key={item.id}>
                        <Td>
                          <Title>{item.product?.title}</Title>
                        </Td>
                        <Td>{item.price}</Td>
                        <Td>{item.orderQuantity}</Td>
                        <Td>{item.giftQuantity}</Td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </OrderList>

              <Divider />
              {!!discount && <OrderDiscount>Discount: {discount}%</OrderDiscount>}
              <OrderTotal>Total: {sum}</OrderTotal>
            </div>
          )}
          {!products?.length && <div>Error: {ApiStore.message}</div>}
        </ModalBody>
        <ModalFooter>
          <Button primary onClick={onClose}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
})
