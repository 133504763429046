import React, { useEffect, useState } from 'react'
import { ReadWarehouseItemDto } from 'api-client'
import { Col, Row, Select } from 'antd'
import styled from 'styled-components'
import { MergeBatchFormData } from './types'

interface MergeBatchFormProps {
  setFormData: (formData: MergeBatchFormData) => void
  options: Array<ReadWarehouseItemDto>
}

const FormRow = styled.div`
  width: 100%;
  margin-bottom: 15px;
`

export function MergeBatchForm({ setFormData, options }: MergeBatchFormProps) {
  const [batchId, setBatchId] = useState<string>('0')

  useEffect(() => {
    setFormData({
      childBatchId: batchId,
    })
  }, [batchId, setFormData])

  return (
    <form>
      <FormRow>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="quantity">
          <Row>
            <Col xs={8}>Recharge amount, grams</Col>
            <Col xs={16}>
              <Select onSelect={setBatchId} style={{ width: '100%' }}>
                {options.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    <span>{item.product?.title}: </span>
                    <span>price: {item.price} </span>
                    <span>quantity: {item.quantity} </span>
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        </label>
      </FormRow>
    </form>
  )
}
