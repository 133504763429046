import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { Col, Input, Row } from 'antd'
import { WarehouseRevisionsStore } from 'store/warehouse-revisions.store'
import { Button } from 'shared/components'
import { subs } from 'shared/utils/math'
import { Divider } from 'shared/components/divider/Divider'
import { Preloader } from 'shared/components/preloader'
import { ListSearch } from 'shared/components/listing/ListSearch'
import { RevisionComment } from './revision-comment'
import { ReopenRevisionFeature } from './reopen-revision.feature'
import { ApproveRevisionFeature } from './approve-revision/approve-revision.feature'

const BatchItem = styled.div`
  display: flex;
  width: 100%;
  justify-items: stretch;
  margin-bottom: 12px;
  border-bottom: solid 1px #bababa;
`

const Title = styled.div`
  width: 30%;
  font-weight: bold;
`

const ExpectedQuantity = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  width: 20%;
`

const Quantity = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 26px;
  width: 20%;
`

const QuantityInput = styled.div`
  width: 120px;
`

const Actions = styled.div`
  display: flex;
  gap: 12px;
`

const Approved = styled.div`
  color: #49901f;
  font-weight: bold;
`

const Declined = styled.div`
  color: #f90000;
  font-weight: bold;
`

interface RevisionEditFeatureProps {
  id: string
}

export const RevisionEditFeature = observer(({ id }: RevisionEditFeatureProps) => {
  const {
    updateRevisionBatch,
    approveRevisionBatch,
    revision,
    approveAllPositions,
    cancelRevision,
    addComment,
    setFilterValue,
    filter,
    batchesFiltered,
  } = WarehouseRevisionsStore

  const [comment, setComment] = useState<string>(revision.comment || '')

  const updateQuantity = (id: string, quantity: string) => {
    updateRevisionBatch({
      revisionBatchId: id,
      quantity: parseFloat(quantity),
    })
  }

  const getDifference = (a: number, b: number) => subs(b, a)

  const isFinished = useCallback(() => revision.isCancelled || revision.isApplied, [revision])

  return (
    <div>
      {WarehouseRevisionsStore.getRevisionByIdEffect.isLoading ? (
        <Preloader message="Loading Revision Data" />
      ) : (
        <>
          <Row>
            <Col xs={24} sm={6}>
              <h2>Revision: {revision.title}</h2>
            </Col>
            <Col xs={24} sm={6}>
              <h2>
                User Started: {revision.user?.firstName}{' '}
                {revision.user?.lastName}
              </h2>
            </Col>
            <Col xs={24} sm={6}>
              <h2>
                User Approved: {revision.approvedUser?.firstName}{' '}
                {revision.approvedUser?.lastName}
              </h2>
            </Col>
            <Col xs={24} sm={6}>
              <div>
                <RevisionComment comment={comment} setComment={setComment} />
                <Button
                  block
                  primary
                  onClick={() => {
                    addComment(revision.id!, comment)
                  }}
                >
                  Add Comment
                </Button>
              </div>
            </Col>
          </Row>

          <Divider big />

          <ListSearch
            setValue={setFilterValue}
            value={filter}
            placeholder="Search by Product title"
          />

          <Divider big />
          <div>
            {batchesFiltered.map((b) => (
              <BatchItem key={b.id + b.productTitle!}>
                <Title>{b.productTitle}</Title>
                <ExpectedQuantity>
                  Expected Quantity: <strong>{b.expectedQuantity}</strong>
                </ExpectedQuantity>

                {isFinished() ? (
                  <Quantity>
                    Real Quantity:
                    <strong>{b.quantity}</strong>
                  </Quantity>
                ) : (
                  <Quantity>
                    Real Quantity:
                    {b.isApproved ? (
                      <strong>{b.quantity}</strong>
                    ) : (
                      <QuantityInput>
                        <Input
                          defaultValue={b.quantity}
                          onBlur={(e) => {
                            updateQuantity(b.id!, e.target.value)
                          }}
                        />
                      </QuantityInput>
                    )}
                  </Quantity>
                )}

                {b.expectedQuantity !== undefined && b.quantity !== undefined && (
                  <ExpectedQuantity>
                    Difference:{' '}
                    <strong
                      style={{
                        color: getDifference(b.expectedQuantity, b.quantity) >= 0 ? 'green' : 'red',
                      }}
                    >
                      {getDifference(b.expectedQuantity, b.quantity)}
                    </strong>
                  </ExpectedQuantity>
                )}

                {!isFinished() && (
                  <div>
                    {b.isApproved ? (
                      <Button small danger onClick={() => approveRevisionBatch(b.id!)}>
                        Revoke
                      </Button>
                    ) : (
                      <Button small primary onClick={() => approveRevisionBatch(b.id!)}>
                        Approve
                      </Button>
                    )}
                  </div>
                )}

                {isFinished() && (
                  <div>
                    {b.isApproved ? <Approved>Approved</Approved> : <Declined>Declined</Declined>}
                  </div>
                )}
              </BatchItem>
            ))}
          </div>

          <Divider big />

          <Actions>
            {!isFinished() && (
              <>
                <ApproveRevisionFeature revisionId={revision.id} />

                <Button
                  primary
                  onClick={() => {
                    approveAllPositions(id)
                  }}
                >
                  Approve All Positions
                </Button>

                <Button
                  danger
                  onClick={() => {
                    cancelRevision(id)
                  }}
                >
                  Cancel Revision
                </Button>
              </>
            )}
            {revision.isCancelled && <ReopenRevisionFeature id={id} />}
          </Actions>
        </>
      )}
    </div>
  )
})
