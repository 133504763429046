import React from 'react'
import { AreaAddFeature } from 'features/area/area-edit'
import { CommonPage } from 'shared/layout/CommonPage'
import { useHistory } from 'react-router'
import { privateWebAppPath } from '../../../../router/constants'

export function AreaAddPage() {
  const location = useHistory()
  return (
    <CommonPage title="Add a new Social Area">
      <AreaAddFeature
        onSuccess={() => {
          location.push(`${privateWebAppPath}/owner/areas`)
        }}
      />
    </CommonPage>
  )
}
