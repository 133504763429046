import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Preloader } from 'shared/components/preloader'
import { CommonPage } from 'shared/layout/CommonPage'
import { DatePicker } from 'antd'
import { BatchesArrivalReportFeature } from 'features/report/batches-arrival/batches-arrival'
import { BatchesArrivalsViewModel } from './batches-arrivals.view-model'

const DateRangeContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const BatchesArrivalsPage = observer(() => {
  const [date, setDate] = useState(moment())
  const [dateTo, setDateTo] = useState(moment())

  const from = date.startOf('month').toISOString()
  const to = dateTo.endOf('month').toISOString()

  useEffect(() => {
    BatchesArrivalsViewModel.init(from, to)
  }, [from, to])

  return BatchesArrivalsViewModel.loading ? (
    <Preloader message="Loading Warehouse Deviations" lower />
  ) : (
    <CommonPage
      title={
        <DateRangeContainer>
          <span>From</span>
          <DatePicker
            picker="month"
            defaultValue={date}
            onChange={(value) => {
              if (value) setDate(value)
            }}
          />
          <span>To</span>
          <DatePicker
            picker="month"
            defaultValue={dateTo}
            onChange={(value) => {
              if (value) setDateTo(value)
            }}
          />
        </DateRangeContainer>
      }
      isMounted={!BatchesArrivalsViewModel.loading}
    >
      <BatchesArrivalReportFeature />
    </CommonPage>
  )
})
