import { makeAutoObservable, observable } from 'mobx'
import { UIError } from 'api/types'

const DEFAULT_LOADER_MESSAGE = 'Loading.'
export class ApiStoreClass {
  public loading: boolean = false

  public loadingMessage?: string | null = DEFAULT_LOADER_MESSAGE

  statusCode: number | null = null

  @observable message: string | null = null

  error: UIError | null = null

  notification?: UIError | null = null

  result: UIError | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setStatusCode = (statusCode: number | null) => {
    this.statusCode = statusCode
  }

  setMessage = (message: string | null) => {
    this.message = message
  }

  setError = (error: UIError | null) => {
    this.error = error
  }

  setResult = (result: UIError | null) => {
    this.result = result
  }

  setNotification = (notification: UIError | null) => {
    this.notification = notification
  }

  public setLoadingMessage = (message: string | null) => {
    this.loadingMessage = message
  }

  public setDefaultLoadingMessage = () => {
    this.loadingMessage = DEFAULT_LOADER_MESSAGE
  }

  public startLoading = () => {
    this.loading = true
  }

  public stopLoading = () => {
    this.loading = false
  }

  get getStatusCode() {
    return this.statusCode ?? null
  }

  get hasResult(): boolean {
    return !!this.result
  }

  get hasNotification(): boolean {
    return !!this.notification
  }

  get hasErrors(): boolean {
    return !!this.error
  }
}

export const ApiStore = new ApiStoreClass()
