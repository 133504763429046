import { ColumnsType } from 'antd/es/table'
import { ReadManagerDto } from 'api-client'

export const ManagerListColumns: ColumnsType<ReadManagerDto> = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'First Name',
    dataIndex: ['personalData', 'firstName'],
    key: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: ['personalData', 'lastName'],
    key: 'lastName',
  },
  {
    title: 'Email',
    dataIndex: ['personalData', 'email'],
    key: 'email',
  },
]
