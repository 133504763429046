import React, { useEffect } from 'react'
import { CommonPage } from 'shared/layout/CommonPage'
import { ManagerListFeature, ManagersStore } from 'features/manager'
import { useAuthentication } from 'auth/useAuthentication'
import { Preloader } from 'shared/components/preloader'
import { observer } from 'mobx-react-lite'

export const ManagerListPage = observer(() => {
  const { keycloakId } = useAuthentication()
  useEffect(() => {
    if (keycloakId) ManagersStore.loadManagers(keycloakId)
  }, [keycloakId])

  return ManagersStore.managersEffect.isLoading ? (
    <Preloader message="Loading Quotes." lower />
  ) : (
    <CommonPage title="Managers">
      <ManagerListFeature />
    </CommonPage>
  )
})
