import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ImportMembersTrigger from 'features/owner/import-members/import-members.trigger'
import { AreasStore } from 'store/areas.store'
import { CommonPage, PageSection } from 'shared/layout/CommonPage'
import { Button } from 'shared/components'
import { Preloader } from 'shared/components/preloader'
import { SalePointSelectFeature } from 'features/sale-point/sale-point-select/sale-point.select.feature'
import { Divider } from 'shared/components/divider/Divider'

function AreaViewPage() {
  const [loaded, setLoaded] = useState<boolean>(false)
  const { id } = useParams<{
    id: string
  }>()

  useEffect(() => {
    AreasStore.setSelectedAreaId(id)

    Promise.all([AreasStore.loadAreaById(id)]).finally(() => {
      setLoaded(true)
    })
  }, [id])

  return (
    <div>
      {!loaded && <Preloader message="Loading" over />}
      {loaded && (
        <CommonPage
          title="Area View"
          actions={
            <>
              <Button primary onClick={() => {}}>
                Edit Area
              </Button>
              <ImportMembersTrigger areaId={id} />
            </>
          }
        >
          <PageSection title="Area Settings">
            {}
            Manager Default Sale Point:
            <SalePointSelectFeature setSalePointId={() => {}} />
            <Divider big />
            Dispensary Default Sale Point:
            <SalePointSelectFeature
              setSalePointId={(id) => {
              }}
            />
          </PageSection>

          <PageSection title={`Area: ${AreasStore.getAreaData?.title}`}>
            <div>{AreasStore.areaData?.address}</div>
          </PageSection>
        </CommonPage>
      )}
    </div>
  )
}

export default AreaViewPage
