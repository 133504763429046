import React from 'react'
import { useParams } from 'react-router-dom'
import './signature.page.scss'
import { SignTermsAndConditionsFeature } from 'features/registration/sign-terms-and-conditions'
import ApiClient from 'api'

export function SignaturePage() {
  const { areaId } = useParams<{
    areaId: string
    memberId?: string
  }>()

  const setSignature = async (signature: string) => {
    await ApiClient().webSocket.postApiV1Signature({
      signature,
      areaId,
    })
  }
  return <SignTermsAndConditionsFeature setSignature={setSignature} />
}
