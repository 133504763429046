import React from 'react'

import { Navigation } from 'shared/components/navigation/Navigation'
import { NavigationItem, NavigationItemTheme } from 'shared/components/navigation/NavigationItem'
import { IconName } from 'shared/components/icon/IconName'
import { useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

export interface RoutePageElement {
  path: string
  title: string
  icon?: IconName
  lockedInactive?: boolean
  isCollapsed?: boolean
  items?: Array<Omit<RoutePageElement, 'lockedInactive'>>
}

interface NavigationMenuProps {
  elements: RoutePageElement[]
  theme?: NavigationItemTheme
}

export const NavigationMenu: React.FC<NavigationMenuProps> = observer(({ elements, theme }) => {
  const { pathname } = useLocation()

  return (
    <Navigation>
      {elements.map(({ path, title, icon, lockedInactive, items, isCollapsed }) => (
        <NavigationItem
          key={path}
          to={path}
          active={pathname.includes(path.replace(/\/$/, ''))}
          icon={icon}
          theme={theme}
          disabled={lockedInactive && !pathname.includes(path.replace(/\/$/, ''))}
          items={items}
          isCollapsed={isCollapsed}
        >
          {title}
        </NavigationItem>
      ))}
    </Navigation>
  )
})
