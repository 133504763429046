/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeBalanceDto } from '../models/ChangeBalanceDto';
import type { DecimalApiResponse } from '../models/DecimalApiResponse';
import type { OrderApiResponse } from '../models/OrderApiResponse';
import type { TransferBalanceDto } from '../models/TransferBalanceDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BalanceService {

    /**
     * @param requestBody
     * @returns OrderApiResponse Success
     * @throws ApiError
     */
    public static postApiV1BalanceIncrease(
        requestBody?: ChangeBalanceDto,
    ): CancelablePromise<OrderApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/balance/increase',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns OrderApiResponse Success
     * @throws ApiError
     */
    public static postApiV1BalanceTransfer(
        requestBody?: TransferBalanceDto,
    ): CancelablePromise<OrderApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/balance/transfer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param areaId
     * @returns DecimalApiResponse Success
     * @throws ApiError
     */
    public static getApiV1Balance(
        id: string,
        areaId?: string,
    ): CancelablePromise<DecimalApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/balance/{id}',
            path: {
                'id': id,
            },
            query: {
                'areaId': areaId,
            },
        });
    }

}
