/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalculateQuantitiesRequestDto } from '../models/CalculateQuantitiesRequestDto';
import type { CashierProductQuantityListApiResponse } from '../models/CashierProductQuantityListApiResponse';
import type { DecimalApiResponse } from '../models/DecimalApiResponse';
import type { GetTotalsDto } from '../models/GetTotalsDto';
import type { GetTotalsResponseDtoApiResponse } from '../models/GetTotalsResponseDtoApiResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FinancesService {

    /**
     * @param a
     * @param b
     * @returns DecimalApiResponse Success
     * @throws ApiError
     */
    public static getApiV1Divide(
        a?: number,
        b?: number,
    ): CancelablePromise<DecimalApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/divide',
            query: {
                'a': a,
                'b': b,
            },
        });
    }

    /**
     * @param a
     * @param b
     * @returns DecimalApiResponse Success
     * @throws ApiError
     */
    public static getApiV1Multiply(
        a?: number,
        b?: number,
    ): CancelablePromise<DecimalApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/multiply',
            query: {
                'a': a,
                'b': b,
            },
        });
    }

    /**
     * @param a
     * @param b
     * @returns DecimalApiResponse Success
     * @throws ApiError
     */
    public static getApiV1Subs(
        a?: number,
        b?: number,
    ): CancelablePromise<DecimalApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/subs',
            query: {
                'a': a,
                'b': b,
            },
        });
    }

    /**
     * @param a
     * @param b
     * @returns DecimalApiResponse Success
     * @throws ApiError
     */
    public static getApiV1Add(
        a?: number,
        b?: number,
    ): CancelablePromise<DecimalApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/add',
            query: {
                'a': a,
                'b': b,
            },
        });
    }

    /**
     * @param requestBody
     * @returns GetTotalsResponseDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1GetTotals(
        requestBody?: GetTotalsDto,
    ): CancelablePromise<GetTotalsResponseDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/get-totals',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns CashierProductQuantityListApiResponse Success
     * @throws ApiError
     */
    public static postApiV1GetQuantities(
        requestBody?: CalculateQuantitiesRequestDto,
    ): CancelablePromise<CashierProductQuantityListApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/get-quantities',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
