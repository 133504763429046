import * as React from 'react'
import { useEffect, useState } from 'react'
import { Button } from 'shared/components'
import { ProductsStore } from 'store/products.store'
import { observer } from 'mobx-react-lite'
import { ProductCategoriesStore } from 'store/product-categories.store'
import { SalePointsStore } from 'store/sale-points.store'
import ProductAddForm from './product-add.form'

interface ProductAddFeatureProps {
  onSuccess?: () => void
}

const ProductAddFeature: React.FC<ProductAddFeatureProps> = observer(({ onSuccess }) => {
  const [formData, setFormData] = useState()
  const createProduct = async (data: any) =>
    formData && ProductsStore.createProduct(data).then(() => onSuccess && onSuccess())

  useEffect(() => {
    SalePointsStore.load()
    ProductCategoriesStore.load()
  }, [])

  return (
    <div>
      <ProductAddForm
        setFormData={setFormData}
        productCategories={ProductCategoriesStore.productCategories}
        salePoints={SalePointsStore.salePoints}
      />
      <Button
        onClick={() => {
          createProduct(formData)
        }}
      >
        Create Product
      </Button>
    </div>
  )
})

export default ProductAddFeature
