import React from 'react'
import { observer } from 'mobx-react-lite'
import { Popconfirm, Table } from 'antd'
import { SalePointsStore } from 'store/sale-points.store'

export const SalePointListFeature: React.FC = observer(() => {
  const deleteItem = (item: any) => SalePointsStore.deleteSalePoint(item.id)

  const columns: any = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Delete',
      dataIndex: '',
      key: 'x',
      render: (item: any) => (
        <Popconfirm
          title="Delete this Sale Point?"
          onConfirm={() => deleteItem(item)}
          okText="Yes"
          cancelText="No"
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#">Delete</a>
        </Popconfirm>
      ),
    },
  ]
  return (
    <div>
      <h1>Sale points</h1>
      <Table columns={columns} dataSource={SalePointsStore?.data?.slice()} />
    </div>
  )
})
