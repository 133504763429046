import React from 'react'
import { observer } from 'mobx-react-lite'
import { MemberStore } from 'store/member.store'
import styled from 'styled-components'
import { Switch } from 'antd'

const Container = styled.div``

const Status = styled.div`
  color: #fff;
  font-size: 15px;
  margin-right: 12px;
  font-weight: 600;
  text-transform: uppercase;
`

const SwitchControl = styled.div`
  margin-left: auto;
`

interface MemberCheckInProps {
  id: string
}

export const MemberCheckIn = observer(({ id }: MemberCheckInProps) => {
  const { isCheckedIn, checkInMember } = MemberStore

  return (
    <Container
      style={{
        backgroundColor: isCheckedIn ? '#03bd82' : '#b91d1d',
        padding: '12px 12px 10px 12px',
        display: 'flex',
        width: '100%',
        lineHeight: 1,
        color: 'p22-underground-sc, sans-serif',
        alignItems: 'center',
        borderRadius: '4px',
      }}
    >
      <Status>
        {`Member is ${isCheckedIn ? 'Checked In' : 'Not Here'}`}
      </Status>

      <SwitchControl>
        <Switch
          checked={isCheckedIn}
          onChange={(checked) => {
            checkInMember(id, checked)
          }}
        />
      </SwitchControl>
    </Container>
  )
})
