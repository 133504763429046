import React from 'react'
import TermsAndConditionsFeature from 'features/terms/terms-and-conditions.feature'
import { CommonStepRegistrationProps } from './types'

interface StepTermsProps extends CommonStepRegistrationProps {
  termsApproved: boolean
  handleTerms: any
}

export function StepTerms({ termsApproved, handleTerms }: StepTermsProps) {
  return termsApproved ? (
    <h1>Approved</h1>
  ) : (
    <TermsAndConditionsFeature setApprove={handleTerms} setDecline={handleTerms} />
  )
}
