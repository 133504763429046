import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/components'
import { WarehouseRevisionsStore } from 'store/warehouse-revisions.store'

export const ReopenRevisionFeature = observer(({ id }: { id: string }) => {
  const { reopenRevision } = WarehouseRevisionsStore
  return (
    <Button
      onClick={() => {
        reopenRevision(id)
      }}
    >
      Reopen Revision
    </Button>
  )
})
