import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/components/modal'
import { WarehouseStore } from 'store/warehouse.store'
import { Button } from 'shared/components'
import { MergeBatchForm } from './merge-batch.form'
import { MergeBatchFormData, MergeBatchModalProps } from './types'

export const MergeBatchModal = observer(
  ({ onSubmit, onClose, parentBatchId, productId, productTitle }: MergeBatchModalProps) => {
    const { getBatchesByProduct, batchesByProduct } = WarehouseStore
    const [formData, setFormData] = useState<MergeBatchFormData>({ childBatchId: null })

    useEffect(() => {
      getBatchesByProduct(productId)
    }, [getBatchesByProduct, productId])

    return (
      <Modal>
        <ModalHeader onClose={onClose}>Merge Batch Weight ({productTitle})</ModalHeader>
        <ModalBody>
          <MergeBatchForm
            options={batchesByProduct.filter((b) => b.id !== parentBatchId)}
            setFormData={setFormData}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            primary
            onClick={() => {
              if (formData) onSubmit(formData)
              onClose()
            }}
          >
            Submit
          </Button>
          <Button danger bordered onClick={() => onClose()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
)
