import { computed, makeAutoObservable } from 'mobx'
import { Effect } from 'store/utils/Effect'
import { RequestHelper } from 'store/utils/RequestHelper'
import { AreasStore } from 'store/areas.store'
import ApiClient from 'api'
import { Order } from '../../../api-client'

export class OrderStoreClass {
  constructor() {
    makeAutoObservable(this)
  }

  /*
   * Effects
   */

  private loadAllEffect = new Effect(
    () =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().accounting.getApiV1AccountingOrders(AreasStore.selectedAreaId)
      ),
    '',
    true
  )

  /*
   * Actions
   */

  public loadAll = () => this.loadAllEffect.run()

  public get loading() {
    return this.loadAllEffect.isLoading
  }
  /*
   * Getters
   */

  @computed get orders() {
    return this.loadAllEffect.data ?? []
  }

  @computed get ordersSliced(): Array<Order> {
    return this.loadAllEffect.data?.slice() || []
  }

  /*
   * Setters
   */
}

export const OrderStore = new OrderStoreClass()
