import { action, runInAction } from 'mobx'
import { ReadProductCategoryDto, UpdateProductCategoryDto } from 'api-client'
import { BasicStore, BasicStoreApi } from './BasicStore'
import ApiClient from '../api'
import { AreasStore } from './areas.store'
import { Effect } from './utils/Effect'
import { RequestHelper } from './utils/RequestHelper'

export class ProductCategoriesStoreClass extends BasicStore<Array<ReadProductCategoryDto>, any> {
  // eslint-disable-next-line class-methods-use-this
  getCategoriesByArea = () =>
    ApiClient().productCategories.getApiV1ProductCategories(AreasStore.selectedAreaId)

  api: BasicStoreApi<Array<ReadProductCategoryDto>> = {
    load: this.getCategoriesByArea,
  }

  createCategoryEffect = new Effect((request) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().productCategories.postApiV1ProductCategories(request)
    )
  )

  @action createCategory = async (data: ReadProductCategoryDto) => {
    const response = await this.createCategoryEffect.run({
      ...data,
      areaId: AreasStore.selectedAreaId,
    })
    if (response) {
      runInAction(() => {
        if (this.data) {
          this.data = [...this.data, response]
        }
      })
    }
  }

  updateCategoryEffect = new Effect((id, request) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().productCategories.putApiV1ProductCategories(id, {
        ...request,
        id,
      })
    )
  )

  @action updateCategory = async (id: string, request: UpdateProductCategoryDto) => {
    const response = await this.updateCategoryEffect.run(id, request)
    return response
  }

  deleteCategoryEffect = new Effect((id) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().productCategories.deleteApiV1ProductCategories(id)
    )
  )

  loadCategoryByIdEffect = new Effect((id: string) =>
    RequestHelper.unwrapFromFetchResponse(ApiClient().productCategories.getProductCategoryById(id))
  )

  loadCategoryById = async (id: string) => {
    const response = await this.loadCategoryByIdEffect.run(id)
    return response
  }

  get category() {
    if (this.loadCategoryByIdEffect.data) {
      return {
        ...this.loadCategoryByIdEffect.data,
        measuredOrder: this.loadCategoryByIdEffect.data
          ? this.loadCategoryByIdEffect.data.measuredOrderCount! > 0
          : false,
      }
    }
    return {}
  }

  @action deleteCategory = async (id: string) => {
    await this.deleteCategoryEffect.run(id)
  }

  get productCategories() {
    return this.data?.slice() || []
  }
}

export const ProductCategoriesStore = new ProductCategoriesStoreClass()
