import React from 'react'
import styled from 'styled-components'
import { Select } from 'antd'
import { SalePointsStore } from 'store/sale-points.store'
import { observer } from 'mobx-react-lite'

const Container = styled.div`
  display: flex;

  .ant-select {
    font-size: 27px;
  }

  .ant-select-selection-item {
    color: #000;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    padding: 0;
  }
`

interface SalePointSelectProps {
  setSalePointId: (id: string) => void
}

export const SalePointSelectFeature = observer(
  ({ setSalePointId }: SalePointSelectProps) => (
      <Container>
        <Select
          defaultValue={SalePointsStore.defaultId}
          onSelect={(value) => {
              setSalePointId(value)
          }}
          style={{ width: '200px' }}
        >
          {SalePointsStore.salePoints.map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {item.title}
            </Select.Option>
          ))}
        </Select>
      </Container>
    )
)
