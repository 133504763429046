import React, { ReactNode, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import classnames from 'classnames'

import './Modal.scss'

export interface ModalProps {
  className?: string
  hidden?: boolean
  onClose?: (e?: Event) => void
  onEnter?: () => void
  appendToId?: string
  width?: number | string
  children?: ReactNode
}

export const Modal: React.FC<ModalProps> = (props) => {
  const { className, children, hidden = false, onClose, onEnter, appendToId, width } = props
  const modalRef = useRef<HTMLDivElement>(null)
  const [modalRootElement] = useState(document.createElement('div'))
  const onBackgroundClick = (event: any) =>
    modalRef.current && !modalRef.current.contains(event.target) && onClose?.()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    appendToId
      ? document.getElementById(appendToId)?.append(modalRootElement)
      : document.body.append(modalRootElement)
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      appendToId
        ? document.getElementById(appendToId)?.removeChild(modalRootElement)
        : document.body.removeChild(modalRootElement)
    }
  }, [modalRootElement, appendToId])

  const classNames = classnames(
    className,
    'modal-backdrop',
    hidden ? 'modal-backdrop_hide' : 'modal-backdrop_show'
  )
  return ReactDOM.createPortal(
    <div
      tabIndex={-1}
      role="button"
      onKeyDown={onBackgroundClick}
      className={classNames}
      onClick={onBackgroundClick}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          return onEnter && onEnter()
        }
        return false
      }}
    >
      <div ref={modalRef} className="modal-container" style={{ width }}>
        <div className="modal">{children}</div>
      </div>
    </div>,
    modalRootElement
  )
}
