import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { Col, Row } from 'antd'
import { Button } from 'shared/components'
import { Divider } from 'shared/components/divider/Divider'
import { getNumberDelimiterAgnostic, getStringDelimiterAgnostic } from 'shared/utils/number'
import { WarehouseStore } from 'store/warehouse.store'
import { getUiDateShortTime } from 'shared/dates/formatters'
import { CloseBatchTrigger } from './close-batch-trigger'
import { RegisterWasteTrigger } from './register-waste/register-waste.trigger'
import { RegisterWasteFormData } from './register-waste/types'
import { RechargeBatchTrigger } from './recharge-batch/recharge-batch.trigger'
import { MergeBatchTrigger } from './merge-batch/merge-batch.trigger'
import { columns } from '../warehouse-journal/warehouse-batch-journal-list-columns'
import { CloseBatchFormData } from '../store-batch/types'
import { WarehouseJournalListFeature } from '../warehouse-journal/warehouse-journal-list.feature'
import { Box, Card, DownTitle, FormActions, Price, Quantity, Title, UpTitle } from './styled'
import { MergeBatchFormData } from './merge-batch/types'

interface EditBatchFeatureProps {
  id: string
}

export const EditBatchFeature = observer(({ id }: EditBatchFeatureProps) => {
  const {
    warehouseBatch,
    loadWarehouseBatchJournal,
    closeWarehouseBatch,
    registerWaste,
    rechargeBatch,
    mergeBatchInto,
  } = WarehouseStore
  const { product } = warehouseBatch

  const loadJournal = useCallback(() => {
    const from = moment.utc().subtract(12, 'month').startOf('day').toISOString()
    const to = moment.utc().endOf('day').toISOString()
    loadWarehouseBatchJournal({
      batchId: id,
      from,
      to,
    })
  }, [id, loadWarehouseBatchJournal])

  const registerWasteSubmit = (formData: RegisterWasteFormData) =>
    registerWaste({
      id,
      quantity: parseFloat(getStringDelimiterAgnostic(formData.quantity)),
    }).then(() => loadJournal())

  const rechargeBatchSubmit = (formData: RegisterWasteFormData) =>
    rechargeBatch({
      batchId: id,
      quantity: parseFloat(getStringDelimiterAgnostic(formData.quantity)),
    }).then(() => loadJournal())

  const closeBatchSubmit = (formData: CloseBatchFormData) =>
    closeWarehouseBatch({
      batchId: id,
      quantity: getNumberDelimiterAgnostic(formData.quantity),
      comment: formData.comment,
    }).then(() => loadJournal())

  const mergeBatchSubmit = ({ childBatchId }: MergeBatchFormData) => {
    if (childBatchId)
      mergeBatchInto(id, childBatchId).then(() => {
        loadJournal()
      })
  }

  return (
    <div>
      <Card>
        <Row>
          <Col xs={24} md={6}>
            <Box>
              <Title>{product?.title}</Title>
              <Divider big />
              <Price>Price: {warehouseBatch.price}</Price>
            </Box>
          </Col>
          <Col xs={24} md={6}>
            <Box>
              <UpTitle>Original Quantity</UpTitle>
              <Quantity>{warehouseBatch.originalQuantity}</Quantity>
              <Divider big />
              <UpTitle>Quantity</UpTitle>
              <Quantity>{warehouseBatch.quantity}</Quantity>
            </Box>
          </Col>
          <Col xs={24} md={6}>
            <Box>
              <UpTitle>Sold</UpTitle>
              <Quantity>{warehouseBatch.sold} %</Quantity>
              <Divider big />
              <UpTitle>Lost</UpTitle>
              <Quantity>{warehouseBatch.lost} %</Quantity>
            </Box>
          </Col>
          <Col xs={24} md={6}>
            <Box>
              <UpTitle>Created:</UpTitle>
              <Divider gutter={2} />
              <DownTitle>{getUiDateShortTime(warehouseBatch.dateCreated)}</DownTitle>

              <Divider big />

              <UpTitle>Updated:</UpTitle>
              <Divider gutter={2} />
              <DownTitle>{getUiDateShortTime(warehouseBatch.dateUpdated)}</DownTitle>
            </Box>
          </Col>
        </Row>
      </Card>
      <Divider big />

      <Row>
        <Col xs={24}>
          <FormActions>
            {!warehouseBatch.isClosed && (
              <>
                <RegisterWasteTrigger
                  onSubmit={registerWasteSubmit}
                  productTitle={warehouseBatch.product?.title}
                >
                  <Button small bordered>
                    Register Waste
                  </Button>
                </RegisterWasteTrigger>

                <RechargeBatchTrigger
                  onSubmit={rechargeBatchSubmit}
                  productTitle={warehouseBatch.product?.title}
                >
                  <Button small primary>
                    Recharge Batch
                  </Button>
                </RechargeBatchTrigger>

                <CloseBatchTrigger
                  productTitle={warehouseBatch.product?.title}
                  onSubmit={closeBatchSubmit}
                >
                  <Button small danger>
                    Close Batch
                  </Button>
                </CloseBatchTrigger>

                <MergeBatchTrigger
                  onSubmit={mergeBatchSubmit}
                  productId={warehouseBatch.product?.id!}
                  productTitle={warehouseBatch.product?.title}
                  parentBatchId={id}
                >
                  <Button small primary>
                    Merge Batch Into This
                  </Button>
                </MergeBatchTrigger>
              </>
            )}

            {warehouseBatch.isClosed && <h3>Closed Batch</h3>}
          </FormActions>
        </Col>
      </Row>

      <Divider gutter={60} />

      <h1>All Operations with {product?.title}</h1>
      <WarehouseJournalListFeature columns={columns} data={WarehouseStore.journalForCurrentBatch} />
    </div>
  )
})
