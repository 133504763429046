import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { AreasStore } from 'store/areas.store'
import { ManagersStore } from '../store/managers.store'
import { AssignManagerToAreaModal } from './assign-manager-to-area.modal'
import { withAreaId } from './utils'
import { UserStore } from '../../../store/user.store'

type AssignManagerToAreaFeatureProps = {
  areaId?: string
  onClose: () => void
}
export const AssignManagerToAreaFeature = observer(
  ({ areaId, onClose }: AssignManagerToAreaFeatureProps) => {
    const [inited, setInited] = useState<boolean>(false)

    useEffect(() => {
      if (areaId) {
        Promise.all([
          ManagersStore.loadManagers(UserStore.userKeycloakId),
          AreasStore.loadAreaManagers(areaId),
          AreasStore.loadAreaById(areaId),
        ]).then(() => setInited(true))
      }
    }, [areaId])

    return inited ? (
      <AssignManagerToAreaModal
        onClose={onClose}
        managers={ManagersStore.managerForTransfer}
        selectedManagers={AreasStore.areaManagers}
        assignManagers={withAreaId(AreasStore.assignManagers, areaId)}
        detachManagers={withAreaId(AreasStore.detachManagers, areaId)}
      />
    ) : null
  }
)
