/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react'
import { Popconfirm, Table } from 'antd'
import { observer } from 'mobx-react-lite'
import { ReportsStore } from 'store/reports.store'
import { getUiDate } from 'shared/dates/formatters'
import { toTodayEnd, lastYear } from 'shared/utils/today'
import { MemberStore } from 'store/member.store'
import { Button } from 'shared/components'
import { PaymentStore } from 'store/payment.store'
import { MemberQuoteJournalType } from 'api-client'

export const MemberQuoteJournal = observer(() => {
  const { member } = MemberStore

  useEffect(() => {
    ReportsStore.loadMemberQuoteJournal(lastYear, toTodayEnd, member?.id!)
  }, [member?.id])

  return (
    <div>
      <h2>Membership Payments</h2>
      <Table
        loading={ReportsStore.memberQuoteJournalLoading}
        pagination={{
          pageSize: 5,
        }}
        rowKey="id"
        dataSource={ReportsStore.memberQuoteJournal}
        columns={[
          {
            title: 'Date',
            dataIndex: 'dateCreated',
            render: (date: string) => <span>{getUiDate(date)}</span>,
          },
          {
            title: 'Expiration',
            dataIndex: 'expirationDate',
            render: (date: string) => <span>{getUiDate(date)}</span>,
          },
          {
            title: 'Price',
            dataIndex: ['memberQuote', 'price'],
            render: (price: string) => <span>{price}</span>,
          },
          {
            title: 'Title',
            dataIndex: ['memberQuote', 'title'],
            render: (title: string) => <span>{title ?? 'Transfer'}</span>,
          },
          {
            title: 'Assigner',
            render: (item) => (
              <span>
                {item.assignerFirstName} {item.assignerLastName}
              </span>
            ),
          },
          {
            title: 'Revert',
            render: (item) => (
              <span>
                {item.revertable ? (
                  <Popconfirm
                    title="Are You Sure?"
                    onConfirm={() => {
                      PaymentStore.payMemberQuoteRevert(item.id)
                    }}
                  >
                    <Button danger small>
                      Revert
                    </Button>
                  </Popconfirm>
                ) : (
                  <span>{item.type === MemberQuoteJournalType._1 ? 'Reverted' : ''}</span>
                )}
              </span>
            ),
          },
        ]}
      />
    </div>
  )
})
