/* eslint-disable no-underscore-dangle */
import React from 'react'
import { ColumnsType } from 'antd/es/table'
import { MemberQuote, MemberQuoteJournalReadDto, MemberQuoteJournalType } from 'api-client'
import { getUiTime } from 'shared/dates/formatters'
import { isFalsy } from 'shared/utils'

export const ordersColumns: ColumnsType<MemberQuoteJournalReadDto> = [
  {
    title: 'Time',
    render: (value: MemberQuoteJournalReadDto) => <span>{getUiTime(value.dateCreated)}</span>,
  },

  {
    title: 'Member',
    render: (value: MemberQuoteJournalReadDto) => (
      <div>
        <span>{value.memberFirstName} </span>
        <span>{value.memberLastName}</span>
      </div>
    ),
  },

  {
    title: 'Quote',
    dataIndex: 'memberQuote',
    render: (value: MemberQuote) => (
      <div>
        <div>{value?.title ? value?.title : 'Transfer'}</div>
        <div>{!isFalsy(value?.price) ? `${value?.price} credits` : ''}</div>
      </div>
    ),
  },

  {
    title: 'Type',
    dataIndex: 'type',
    render: (value: MemberQuoteJournalType) => (
      <div>
       
       {value === MemberQuoteJournalType._1 && <span>Paid</span>}
       {value === MemberQuoteJournalType._2 && <span>Renew</span>}
       {value === MemberQuoteJournalType._3 && <span>Return</span>}
      </div>
    ),
  },
]
