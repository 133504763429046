import React from 'react'
import { NavigationMenu, RoutePageElement } from 'router/NavigationMenu'
import { getPagePath } from 'router/utils'

const basePath = 'owner'
const pages: RoutePageElement[] = [
  {
    path: getPagePath(`${basePath}/dashboard`),
    title: 'Dashboard',
    icon: 'chart',
    items: [
      {
        title: 'Monthly Stats',
        path: getPagePath(`${basePath}/dashboard/stats/month`),
      },
      {
        path: getPagePath(`${basePath}/dashboard/deviations`),
        title: 'Deviations',
        icon: 'cross',
      },      {
        path: getPagePath(`${basePath}/reports/batches-arrivals`),
        title: 'Batches Arrivals',
        icon: 'chart',
      },
    ],
  },
  {
    path: getPagePath(`${basePath}/warehouse`),
    title: 'Warehouse',
    icon: 'filter-money',
    items: [
      {
        path: getPagePath(`${basePath}/warehouse/closed`),
        title: 'Closed Stock',
        icon: 'file-zip',
      },
      {
        path: getPagePath(`${basePath}/warehouse-revisions`),
        title: 'Revisions',
        icon: 'reports',
      },

      {
        path: getPagePath(`${basePath}/products/list`),
        title: 'Products',
        icon: 'file-zip',
      },
      {
        path: getPagePath(`${basePath}/product-categories/list`),
        title: 'Categories',
        icon: 'file-zip',
      },
      {
        path: getPagePath(`${basePath}/sale-points/list`),
        title: 'Sale Points',
        icon: 'file-zip',
      },
      {
        path: getPagePath(`${basePath}/warehouse/journal`),
        title: 'Journal',
        icon: 'file-zip',
      },
    ],
  },

  {
    path: getPagePath(`${basePath}/search`),
    title: 'Members',
    icon: 'search-user',
    isCollapsed: true,
    items: [
      {
        path: getPagePath(`${basePath}/members/edit`),
        title: 'Add Member',
      },
      {
        path: getPagePath(`${basePath}/registrations/edit`),
        title: 'Add Appointment',
      },
      {
        path: getPagePath(`${basePath}/registrations`),
        title: 'Appointments',
      },
    ],
  },
  {
    path: getPagePath(`${basePath}/member-quote/list`),
    title: 'Quotes',
    icon: 'forward',
  },
  {
    path: getPagePath(`${basePath}/accounting`),
    title: 'Accounting',
    icon: 'account-balance',
    isCollapsed: true,
    items: [
      {
        title: 'General Accounts',
        path: getPagePath(`${basePath}/accounting/general-accounts`),
      },
      {
        title: 'Movements',
        path: getPagePath(`${basePath}/accounting/movements`),
      },
      {
        title: 'Product Orders',
        path: getPagePath(`${basePath}/accounting/product-orders`),
      },
    ],
  },
  {
    path: getPagePath(`${basePath}/areas`),
    title: 'Areas',
    icon: 'bubble-chart',
    isCollapsed: true,
    items: [
      {
        title: 'Add Area',
        path: getPagePath(`${basePath}/areas/add`),
      },
    ],
  },
  {
    path: getPagePath(`${basePath}/managers`),
    title: 'Managers',
    icon: 'group',
    isCollapsed: true,
    items: [
      {
        title: 'Add Manager',
        path: getPagePath(`${basePath}/managers/add`),
      },
    ],
  },
]

// eslint-disable-next-line react/function-component-definition
export const OwnerNavigationMenu: React.FC = () => (
  <NavigationMenu elements={pages} theme="area-admin" />
)
