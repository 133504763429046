import { action, makeObservable, observable } from 'mobx'
import { configureMobX } from './mobx.settings'

async function init() {
  await configureMobX()
}

export class AppStoreClass {

  @observable sidebarHidden: boolean = false

  @action setSidebarHidden = () => {
    this.sidebarHidden = true
  }

  @action setSidebarVisible = () => {
    this.sidebarHidden = false
  }

  @action toggleSidebarVisibility = () => {
    this.sidebarHidden = !this.sidebarHidden
  }

  @action
  init = init

  constructor() {
    makeObservable(this)
  }

}

export const AppStore = new AppStoreClass()
