import { useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useAuthentication } from 'auth/useAuthentication'
import { observer } from 'mobx-react-lite'
import { UserStore } from 'store/user.store'
import { AppStore } from './store/app.store'

type AppInitProps = {
  children: any
}
export const AppInit = observer(({ children }: AppInitProps) => {
  const { logIn, isInitialized, isAuthenticated, getRoles, logOut } = useAuthentication()


  useIdleTimer({
    onIdle: () => {
      logOut()
    },
    timeout: 240000,
    crossTab: true,
    eventsThrottle: 1000,
    syncTimers: 5000,
  })

  useEffect(() => {
    AppStore.init()
  }, [])

  useEffect(() => {
    if (isInitialized()) {
      if (!isAuthenticated()) {
        logIn()
      } else {
        UserStore.initUser(getRoles())
      }
    }
  }, [isInitialized, isAuthenticated, logIn, getRoles])

  return isInitialized() && isAuthenticated() && children
})
