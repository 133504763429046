import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SalePointsStore } from 'store/sale-points.store'
import { EntityAddModal } from 'shared/components/modal/entity-modal/EntityModal'
import { CommonPage } from 'shared/layout/CommonPage'
import { Button } from 'shared/components'
import { SalePointListFeature } from 'features/sale-point/sale-point-list/sale-point-list.feature'
import { SalePointAddFeature } from 'features/sale-point/sale-point-add/sale-point-add.feature'
import { AreasStore } from 'store/areas.store'
import { Preloader } from 'shared/components/preloader'

interface SalePointListPageProps {}

const SalePointListPage: React.FC<SalePointListPageProps> = observer(() => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false)

  useEffect(() => {
    SalePointsStore.load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AreasStore.selectedAreaId])

  return (
    <>
      {showAddModal && (
        <EntityAddModal
          title="Add Sale Point"
          entityForm={
            <SalePointAddFeature
              onSuccess={() => {
                setShowAddModal(false)
              }}
            />
          }
          onClose={() => {
            setShowAddModal(false)
          }}
        />
      )}
      {SalePointsStore.loadStatus.isLoading ? (
        <Preloader message="Loading Data." lower />
      ) : (
        <CommonPage
          actions={
            <div>
              <Button
                primary
                onClick={() => {
                  setShowAddModal(true)
                }}
              >
                Add Sale Point
              </Button>
            </div>
          }
          title="Sale Points"
        >
          <SalePointListFeature />
        </CommonPage>
      )}
    </>
  )
})
export default SalePointListPage
