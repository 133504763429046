import React from 'react'
import './member-photo.scss'

interface MemberPhotoProps {
  src?: string | null
  width?: string
}

export function MemberPhoto({ src, width }: MemberPhotoProps) {
  return (
    <div className="member-photo" style={{ width }}>
      <div className="member-photo__image">
        <img className="member-photo__image-source" src={src ?? undefined} alt="member" />
      </div>
    </div>
  )
}
