import { makeAutoObservable } from 'mobx'
import { AreasStore } from 'store/areas.store'
import { PaymentStore } from 'store/payment.store'
import { ReportsStore } from 'store/reports.store'

class DashboardViewModelClass {
  loading: boolean = true

  init = async (from: string, to: string) => {
      this.setLoading(true)
    if (AreasStore.selectedAreaId) {
      await Promise.all([
        PaymentStore.getAllCashSum(from, to),
        ReportsStore.loadVisits(from, to),
        ReportsStore.getTodayMemberQuoteJournal(from, to),
        ReportsStore.getTodayRegistrations(from, to),
      ])
      this.setLoading(false)
    }
  }

  setLoading = (loading: boolean) => {
    this.loading = loading
  }

  constructor() {
    makeAutoObservable(this)
  }
}

export const DashboardViewModel = new DashboardViewModelClass()
