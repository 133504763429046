import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment'
import ApiClient from 'api'
import { BasicStore, BasicStoreApi } from './BasicStore'
import { ReadMemberDto } from '../api-client'
import { AreasStore } from './areas.store'
import { Effect } from './utils/Effect'
import { RequestHelper } from './utils/RequestHelper'

function filterMembers(filter: string, data: any) {
  if (filter) {
    const query = filter.replace(/\s+/g, '').toLowerCase()
    return data?.filter((item: ReadMemberDto) => {
      const fullName = `${item.firstName}${item.lastName}`.toLowerCase().replace(/\s+/g, '').toLowerCase()
      const fullNameReversed = `${item.lastName}${item.firstName}`.toLowerCase().replace(/\s+/g, '').toLowerCase()
      return (
        fullName.includes(query) || fullNameReversed.includes(query) || item.nfcKey?.includes(query)
      )
    })
  }
  return data
}

export class MembersStoreClass extends BasicStore<Array<ReadMemberDto>, any> {
  private filterMembers = filterMembers

  @observable member?: ReadMemberDto

  @observable error: any = null

  @observable filter: string = ''

  api: BasicStoreApi<Array<ReadMemberDto>, any> = {
    load: () => ApiClient().members.getApiV1Members(AreasStore.selectedAreaId),
  }

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get filteredData() {
    return this.filterMembers(this.filter, this.data)
  }

  @computed get members() {
    return this.data || []
  }

  get todayMembers() {
    return this.data?.filter((item) => moment.utc(item.lastVisit).isSame(moment.utc(), 'day'))
  }

  @action setFilterValue = (value: string) => {
    this.filter = value
  }

  deleteMemberEffect = new Effect((id: string) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().members.deleteApiV1Members(id, AreasStore.selectedAreaId)
    )
  )

  @action deleteMember = async (id: string) => {
    const response = await this.deleteMemberEffect.run(id)
    if (response) {
      this.data = this.data?.filter((item) => item.id !== id)
    }
  }

  addReferrersToMemberEffect = new Effect((request) =>
    RequestHelper.unwrapFromFetchResponse(ApiClient().members.postApiV1MembersReferrers(request))
  )

  addReferrersToMember = async (memberId: string, referrersIds: Array<string>) => {
    await this.addReferrersToMemberEffect.run({
      memberId,
      referrersIds,
    })
    // Add reactive referer update
  }

  kickMembersOffEffect = new Effect(() =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().members.getApiV1MembersKickAll(AreasStore.selectedAreaId)
    )
  )

  kickMembersOff = () => this.kickMembersOffEffect.run()

  loadCheckedInMembersEffect = new Effect(() =>
      RequestHelper.unwrapFromFetchResponse(
          ApiClient().members.getApiV1MembersCheckedIn(AreasStore.selectedAreaId)
      )
  )

  loadCheckedInMembers = async () => {
    await this.loadCheckedInMembersEffect.run()
  }

  @computed get checkedInMembers() {
    return this.loadCheckedInMembersEffect.data || []
  }

  @computed get filteredCheckedInMembers() {
    return this.filterMembers(this.filter, this.checkedInMembers)
  }


  getMembersByFullNameEffect = new Effect((fullName) =>
      RequestHelper.unwrapFromFetchResponse(
          ApiClient().members.getApiV1MembersByFullName(fullName, AreasStore.selectedAreaId)
      )
  )

  getMembersByFullName = (fullName: string) => this.getMembersByFullNameEffect.run(fullName)

}

export const MembersStore = new MembersStoreClass()
