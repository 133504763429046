/* eslint-disable max-classes-per-file */
import { action, makeObservable, observable, runInAction } from 'mobx'
import { LoadingStatus } from '../shared/classes/LoadingStatus'
import { ApiResponse } from '../api/types'

export type BasicStoreApi<Data, DataForUpdate = Data> = {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  load: (params?: any) => Promise<ApiResponse<Data> | undefined | null>
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  update?: (data?: DataForUpdate) => Promise<DataForUpdate | void>
  delete?: (entityId: any) => Promise<any>
}

export abstract class BasicStore<Data, DataForUpdate = Data> {
  abstract api: BasicStoreApi<Data, DataForUpdate>

  @observable data?: Data | null

  @observable loadStatus = new LoadingStatus()

  updateStatus = new LoadingStatus()

  initStatus = new LoadingStatus()

  constructor() {
    makeObservable(this)
  }

  @action
  async load() {
    try {
      this.loadStatus.startLoading()
      const response = await this.api.load()
      runInAction((mapper?: any) => {
        this.data = response?.data
      })
      return this.data
    } catch (error: any) {
      this.loadStatus.setError(error)
      return error
    } finally {
      this.loadStatus.completeLoading()
    }
  }

  @action
  async delete(id: string) {
    try {
      this.loadStatus.startLoading()
      return await this.api.delete?.(id)
    } catch (error: any) {
      this.loadStatus.setError(error)
      return error
    } finally {
      this.loadStatus.completeLoading()
    }
  }

  @action
  async update(data: DataForUpdate) {
    if (!this.api.update) {
      return false
    }
    try {
      this.updateStatus.startLoading()
      return await this.api.update(data)
    } catch (error: any) {
      console.warn(error)
      this.updateStatus.setError(error)
      return false
    } finally {
      this.updateStatus.completeLoading()
    }
  }

  @action resetData() {
    this.data = undefined
  }

  // eslint-disable-next-line require-await
  @action
  async init() {
    this.initStatus.completeLoading()
  }
}
