import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import styled from 'styled-components'
import { DatePicker, Input } from 'antd'
import { WarehouseStore } from 'store/warehouse.store'
import { WarehouseJournalListFeature } from 'features/warehouse/warehouse-journal/warehouse-journal-list.feature'
import { Preloader } from 'shared/components/preloader'
import { fromTodayStart, toTodayEnd } from 'shared/utils/today'
import { CommonPage } from 'shared/layout/CommonPage'
import { columns } from 'features/warehouse/warehouse-journal/warehouse-journal-list-columns'
import { useTranslation } from "react-i18next";

const Actions = styled.div`
  display: flex;
  width: 600px;
  justify-content: flex-end;
`

const ActionsTextFilter = styled.div`
  width: 180px;
`



interface WarehouseJournalPageProps {}

export const WarehouseJournalPage: React.FC<WarehouseJournalPageProps> = observer(() => {
  const [date, setDate] = useState(moment())

  const { t } = useTranslation()

  useEffect(() => {
    WarehouseStore.loadJournal(fromTodayStart, toTodayEnd)
  }, [])

  useEffect(() => {
    if (date)
      WarehouseStore.loadJournal(date.startOf('day').toISOString(), date.endOf('day').toISOString())
  }, [date])

  return (
    <CommonPage
      title={t('warehouseJournal.pageTitle', 'Orders')}
      actions={
        <Actions>
          <DatePicker
            format="DD.MM.YYYY"
            defaultValue={date}
            onChange={(value) => {
              if (value) setDate(value)
            }}
          />
          <ActionsTextFilter>
            <Input
              onChange={(e) => WarehouseStore.setFilterProductTitle(e.target.value)}
              placeholder="Filter by Product Name"
            />
          </ActionsTextFilter>

          <ActionsTextFilter>
            <Input
              onChange={(e) => WarehouseStore.setFilterMember(e.target.value)}
              placeholder="Filter by Member Name"
            />
          </ActionsTextFilter>
        </Actions>
      }
    >
      {WarehouseStore.loading && <Preloader />}

      {WarehouseStore.hasJournal ? (
        <WarehouseJournalListFeature columns={columns} data={WarehouseStore.filteredJournal} />
      ) : (
        <div>Nothing was Found</div>
      )}
    </CommonPage>
  )
})
