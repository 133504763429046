export const ruRU = {
    translation: {
        intro: 'Hello my name is',
        checkInTime: 'Время входа',
        dashboard: 'Доска',
        warehouseJournal: {
            pageTitle: 'Заказы за дату',
            orderDetailsTitle: 'Детали заказа',
        },
    },
}