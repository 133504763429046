import React from 'react'
import { GeneralAccountEditModalTrigger } from 'features/accounting/general-account/edit/edit-modal-trigger'
import { Button } from 'shared/components'
import { Divider } from 'shared/components/divider/Divider'
import { GeneralAccountStore } from '../store'

export const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Code',
    dataIndex: 'code',
  },
  {
    title: 'Actions',
    render: (item: any) => (
      <div style={{ display: 'flex' }}>
        <GeneralAccountEditModalTrigger small text="Edit" id={item.id} data={item} />
        <Divider vertical />
        <Button small danger onClick={() => GeneralAccountStore.deleteGeneralAccount(item.id)}>
          Delete
        </Button>
      </div>
    ),
  },
]
