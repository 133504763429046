import React, { useEffect, useState } from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import { Preloader } from 'shared/components/preloader'
import { CommonPage } from 'shared/layout/CommonPage'
import { DeviationListFeature } from 'features/warehouse/deviation/deviation-list'
import { WarehouseDeviationsViewModel } from './warehouse-deviations.vm'

export const WarehouseDeviationsPage = observer(() => {
  const [date, setDate] = useState(moment())

  const from = date.startOf('month').toISOString()
  const to = date.endOf('month').toISOString()

  useEffect(() => {
    WarehouseDeviationsViewModel.init(from, to)
  }, [from, to])

  return WarehouseDeviationsViewModel.loading ? (
    <Preloader message="Loading Warehouse Deviations" lower />
  ) : (
    <CommonPage
      title={
        <DatePicker
          picker="month"
          defaultValue={date}
          onChange={(value) => {
            if (value) setDate(value)
          }}
        />
      }
      isMounted={!WarehouseDeviationsViewModel.loading}
    >
      <DeviationListFeature />
    </CommonPage>
  )
})
