export const esES = {
    translation: {
        intro: 'Hola que tal?',
        checkInTime: 'Entrada en',
        dashboard: 'Panel de control',
        warehouseJournal: {
            pageTitle: 'Pedidos por fecha',
            orderDetailsTitle: 'Detalles del pedido',
        },
    },
}