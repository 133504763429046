/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddReferrersDto } from '../models/AddReferrersDto';
import type { AppUserApiResponse } from '../models/AppUserApiResponse';
import type { CheckInOutMemberDto } from '../models/CheckInOutMemberDto';
import type { CreateMemberDto } from '../models/CreateMemberDto';
import type { MemberApiResponse } from '../models/MemberApiResponse';
import type { ReadMemberDtoApiResponse } from '../models/ReadMemberDtoApiResponse';
import type { ReadMemberDtoListApiResponse } from '../models/ReadMemberDtoListApiResponse';
import type { ReadMemberInListDtoIEnumerableApiResponse } from '../models/ReadMemberInListDtoIEnumerableApiResponse';
import type { ReadMemberVisitDtoApiResponse } from '../models/ReadMemberVisitDtoApiResponse';
import type { ReadMemberVisitsListDtoIEnumerableApiResponse } from '../models/ReadMemberVisitsListDtoIEnumerableApiResponse';
import type { UpdateInterviewDto } from '../models/UpdateInterviewDto';
import type { UpdateMemberDto } from '../models/UpdateMemberDto';
import type { UpdateMemberDtoJsonPatchDocument } from '../models/UpdateMemberDtoJsonPatchDocument';
import type { UpdatePhotoDto } from '../models/UpdatePhotoDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MembersService {

    /**
     * @param requestBody
     * @returns AppUserApiResponse Success
     * @throws ApiError
     */
    public static postApiV1Members(
        requestBody?: CreateMemberDto,
    ): CancelablePromise<AppUserApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/members',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param areaId
     * @returns ReadMemberInListDtoIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static getApiV1Members(
        areaId?: string,
    ): CancelablePromise<ReadMemberInListDtoIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/members',
            query: {
                'areaId': areaId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns ReadMemberDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1MembersPhoto(
        requestBody?: UpdatePhotoDto,
    ): CancelablePromise<ReadMemberDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/members/photo',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns ReadMemberDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1MembersInterview(
        requestBody?: UpdateInterviewDto,
    ): CancelablePromise<ReadMemberDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/members/interview',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns ReadMemberVisitDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1MembersCheckIn(
        requestBody?: CheckInOutMemberDto,
    ): CancelablePromise<ReadMemberVisitDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/members/check-in',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param areaId
     * @param requestBody
     * @returns ReadMemberDtoApiResponse Success
     * @throws ApiError
     */
    public static putApiV1Members(
        id: string,
        areaId?: string,
        requestBody?: UpdateMemberDto,
    ): CancelablePromise<ReadMemberDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/members/{id}',
            path: {
                'id': id,
            },
            query: {
                'areaId': areaId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param areaId
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchApiV1Members(
        id: string,
        areaId?: string,
        requestBody?: UpdateMemberDtoJsonPatchDocument,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/members/{id}',
            path: {
                'id': id,
            },
            query: {
                'areaId': areaId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param areaId
     * @returns ReadMemberDtoApiResponse Success
     * @throws ApiError
     */
    public static getMemberById(
        id: string,
        areaId?: string,
    ): CancelablePromise<ReadMemberDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/members/{id}',
            path: {
                'id': id,
            },
            query: {
                'areaId': areaId,
            },
        });
    }

    /**
     * @param id
     * @param areaId
     * @returns ReadMemberDtoApiResponse Success
     * @throws ApiError
     */
    public static deleteApiV1Members(
        id: string,
        areaId?: string,
    ): CancelablePromise<ReadMemberDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/members/{id}',
            path: {
                'id': id,
            },
            query: {
                'areaId': areaId,
            },
        });
    }

    /**
     * @param nfcNumber
     * @param areaId
     * @returns ReadMemberDtoApiResponse Success
     * @throws ApiError
     */
    public static getMemberByNfcNumber(
        nfcNumber: string,
        areaId?: string,
    ): CancelablePromise<ReadMemberDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/members/nfcNumber/{nfcNumber}',
            path: {
                'nfcNumber': nfcNumber,
            },
            query: {
                'areaId': areaId,
            },
        });
    }

    /**
     * @param areaId
     * @returns ReadMemberVisitsListDtoIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static getApiV1MembersCheckedIn(
        areaId?: string,
    ): CancelablePromise<ReadMemberVisitsListDtoIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/members/checked-in',
            query: {
                'areaId': areaId,
            },
        });
    }

    /**
     * @param id
     * @param areaId
     * @returns ReadMemberDtoApiResponse Success
     * @throws ApiError
     */
    public static getApiV1MembersReferrers(
        id: string,
        areaId?: string,
    ): CancelablePromise<ReadMemberDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/members/referrers/{id}',
            path: {
                'id': id,
            },
            query: {
                'areaId': areaId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns MemberApiResponse Success
     * @throws ApiError
     */
    public static postApiV1MembersReferrers(
        requestBody?: AddReferrersDto,
    ): CancelablePromise<MemberApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/members/referrers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param formData
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1MembersImport(
        formData?: {
            File?: Blob;
            AreaId?: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/members/import',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1MembersImportSmart(
        formData?: {
            File?: Blob;
            AreaId?: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/members/import-smart',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param areaId
     * @returns any Success
     * @throws ApiError
     */
    public static getApiV1MembersKickAll(
        areaId?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/members/kick-all',
            query: {
                'areaId': areaId,
            },
        });
    }

    /**
     * @param fullName
     * @param areaId
     * @returns ReadMemberDtoListApiResponse Success
     * @throws ApiError
     */
    public static getApiV1MembersByFullName(
        fullName?: string,
        areaId?: string,
    ): CancelablePromise<ReadMemberDtoListApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/members/by-full-name',
            query: {
                'fullName': fullName,
                'areaId': areaId,
            },
        });
    }

}
