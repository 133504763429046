import React, { ReactNode } from 'react'
import { Modal } from './Modal'
import { ModalHeader } from './ModalHeader'
import { ModalBody } from './ModalBody'
import { ModalFooter } from './ModalFooter'

interface ModalConfirmationProps {
  title?: string
  actions?: ReactNode
  onClose: () => void
  children?: ReactNode
}

export function ModalConfirmation(props: ModalConfirmationProps) {
  const { children, title, actions, onClose } = props

  return (
    <Modal>
      <ModalHeader onClose={onClose}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      {actions && <ModalFooter>{actions}</ModalFooter>}
    </Modal>
  )
}
