import React, { useEffect, useState } from 'react'
import { Alert, Col, Row } from 'antd'
import TextArea from "antd/es/input/TextArea";
import styled from 'styled-components'
import { CloseBatchFormData } from '../store-batch/types'
import { getNumberDelimiterAgnostic } from '../../../shared/utils/number'

interface CloseBatchFormProps {
  setFormData: (formData: CloseBatchFormData) => void
}

const FormRow = styled.div`
  width: 100%;
  margin-bottom: 15px;
`
const Input = styled.input`
  width: 100%;
  font-size: 16px;
  padding: 5px;
`

export function CloseBatchForm({ setFormData }: CloseBatchFormProps) {
  const [quantity, setQuantity] = useState<string>('0')
  const [comment, setComment] = useState<string>('')

  useEffect(() => {
    setFormData({
      quantity,
      comment,
    })
  }, [quantity, comment, setFormData])


  return (
    <form>
      <FormRow>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="quantity">
          <Row>
            <Col xs={8}>Quantity of product</Col>
            <Col xs={16}>
              <Input
                id="quantity"
                value={quantity}
                onChange={(e) => {
                  setQuantity(e.target.value)
                }}
              />

              {getNumberDelimiterAgnostic(quantity) < 0 && (
                <Alert
                  type="warning"
                  message="You are trying to close batch with Negative value. It's feasible but Please double check provided data "
                />
              )}
            </Col>
          </Row>
        </label>
      </FormRow>
      <FormRow>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="comment">
          <Row>
            <Col xs={8}>Comment</Col>
            <Col xs={16}>
              <TextArea
                  id="comment"
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value)
                  }}
              />

            </Col>
          </Row>
        </label>
      </FormRow>
    </form>
  )
}
