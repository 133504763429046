/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BooleanApiResponse } from '../models/BooleanApiResponse';
import type { CreateGeneralAccountDto } from '../models/CreateGeneralAccountDto';
import type { GeneralAccountIEnumerableApiResponse } from '../models/GeneralAccountIEnumerableApiResponse';
import type { JournalEntry } from '../models/JournalEntry';
import type { OrderIEnumerableApiResponse } from '../models/OrderIEnumerableApiResponse';
import type { ReadGeneralAccountDtoApiResponse } from '../models/ReadGeneralAccountDtoApiResponse';
import type { UpdateGeneralAccountDto } from '../models/UpdateGeneralAccountDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountingService {

    /**
     * @param requestBody
     * @returns ReadGeneralAccountDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1AccountingAccounts(
        requestBody?: CreateGeneralAccountDto,
    ): CancelablePromise<ReadGeneralAccountDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/accounting/accounts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GeneralAccountIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static getApiV1AccountingAccounts(): CancelablePromise<GeneralAccountIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/accounting/accounts',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ReadGeneralAccountDtoApiResponse Success
     * @throws ApiError
     */
    public static putApiV1AccountingAccounts(
        id: string,
        requestBody?: UpdateGeneralAccountDto,
    ): CancelablePromise<ReadGeneralAccountDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/accounting/accounts/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns BooleanApiResponse Success
     * @throws ApiError
     */
    public static deleteApiV1AccountingAccounts(
        id: string,
    ): CancelablePromise<BooleanApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/accounting/accounts/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param areaId
     * @returns JournalEntry Success
     * @throws ApiError
     */
    public static getApiV1AccountingEntries(
        areaId?: string,
    ): CancelablePromise<Array<JournalEntry>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/accounting/entries',
            query: {
                'areaId': areaId,
            },
        });
    }

    /**
     * @param areaId
     * @returns OrderIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static getApiV1AccountingOrders(
        areaId?: string,
    ): CancelablePromise<OrderIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/accounting/orders',
            query: {
                'areaId': areaId,
            },
        });
    }

}
