import React, { useState } from 'react'
import styled from 'styled-components'
import Icon from '../icon'

const SortingContainer = styled.div`
  display: flex;
`

const SortingLabel = styled.div`
  margin-right: 12px;
`

const SortingIcon = styled.div`
  cursor: pointer;
  margin-right: 8px;
`

export enum SortDirections {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type SortDirection = keyof typeof SortDirections

interface SortingProps {
  label: string
  defaultDirection?: SortDirection
  setCurrentDirection: (direction: SortDirection) => void
}

function Sorting({ label, defaultDirection = 'ASC', setCurrentDirection }: SortingProps) {
  const [direction, setDirection] = useState<SortDirection>(defaultDirection)

  // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/naming-convention
  function _setDirection(direction: SortDirection) {
    setCurrentDirection(direction)
    setDirection(direction)
  }

  return (
    <SortingContainer>
      <SortingLabel>{label}</SortingLabel>
      <SortingIcon onClick={() => _setDirection('ASC')}>
        <Icon name="arrow-up" color={direction === 'ASC' ? '#636363' : '#bababa'} />
      </SortingIcon>

      <SortingIcon onClick={() => _setDirection('DESC')}>
        <Icon name="arrow-down" color={direction === 'DESC' ? '#636363' : '#bababa'} />
      </SortingIcon>
    </SortingContainer>
  )
}

export default Sorting
