import React, { ReactNode } from 'react'
import classnames from 'classnames'

import './CommonPage.scss'
import { MountTransition } from 'shared/components/transitions/mount-transition'

export interface CommonPageProps {
  shadowed?: boolean
  centered?: boolean
  className?: string
  title?: string | ReactNode
  actions?: ReactNode
  isMounted?: boolean
}

export const CommonPage = React.forwardRef<
  HTMLDivElement,
  CommonPageProps & {
    children?: React.ReactNode
  }
>((props, ref) => {
  const { children, shadowed = false, centered = false, className, title, actions, isMounted } = props

  const classNames = classnames(className, {
    'app-page': true,
    'app-page_shadowed': shadowed,
    'app-page_centered': centered,
  })

  return (
    <MountTransition isMounted={isMounted ?? true}>
      <div ref={ref} className={classNames}>
        {(title || actions) && (
          <div className="common-page__head">
            {title && <div className="common-page__title">{title}</div>}

            {actions && <div className="common-page__actions">{actions}</div>}
          </div>
        )}

        <div className="common-page__body">{children}</div>
      </div>
    </MountTransition>
  )
})
