import { makeAutoObservable } from 'mobx'
import moment from 'moment'
import { BalanceStore } from './balance.store'
import { Effect } from './utils/Effect'
import { RequestHelper } from './utils/RequestHelper'
import { ReportsStore } from './reports.store'
import ApiClient from '../api'
import { AreasStore } from './areas.store'
import { MemberStore } from './member.store'
import { MemberQuoteStore } from './member-quote.store'
import { getRoundedDecimal } from '../shared/utils/get-rounded-decimal'

export class PaymentStoreClass {
  /**
   *  All Payment Operations should be processed by this store.
   */
  constructor() {
    makeAutoObservable(this)
  }

  /**
   * Effects
   */

  private payMemberQuoteEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().payments.postApiV1PaymentMemberQuote(request)
      ),
    'Paying Member Quote.'
  )

  private payMemberQuoteRevertEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().payments.postApiV1PaymentMemberQuoteRevert(request)
      ),
    'Paying Member Quote.'
  )

  private renewMemberQuoteEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().payments.postApiV1PaymentMemberQuoteRenew(request)
      ),
    'Paying Member Quote.'
  )

  private transferMemberQuoteEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().payments.postApiV1PaymentMemberQuoteTransfer(request)
      ),
    'Transferring Member Quote.'
  )

  private increaseBalanceEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().balance.postApiV1BalanceIncrease(request),
        `Balance was Increased Successfully`
      ),
    'Balance Increasing.'
  )

  private transferBalanceEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().balance.postApiV1BalanceTransfer(request),
        `Balance was Transferred Successfully`
      ),
    'Balance Transferring.'
  )

  private returnBalanceFundsEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().payments.postApiV1PaymentReturnMemberFunds(request)
      ),
    'Handling Funds Return.'
  )

  private getAllCashSumEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().payments.postApiV1PaymentGetAllCash(request)
      ),
    'Calculating today donations.',
    true
  )

  /**
   * Actions
   */

  public payMemberQuote = async (memberId: string, quoteId: string) => {
    const response = await this.payMemberQuoteEffect.run({
      memberId,
      quoteId,
      areaId: AreasStore.selectedAreaId,
    })
    if (response) {
      // Set Quote
      const quote = await MemberQuoteStore.loadById(quoteId)
      if (quote) MemberStore.setQuote(quote)
      // Set expiration date
      const expirationDate = moment
        .utc(response.dateCreated)
        .startOf('day')
        .add(quote!.period, 'days')
        .endOf('day')
        .toString()
      MemberStore.setQuotePaymentDate(expirationDate)
      // Set new balance
      BalanceStore.setCurrentBalance(BalanceStore.currentBalance - response.journalEntry!.count!)
    }
    return response
  }

  public payMemberQuoteRevert = async (journalId: string) => {
    const response = await this.payMemberQuoteRevertEffect.run({
      journalQuoteId: journalId,
      areaId: AreasStore.selectedAreaId,
    })
    if (response) {
      // Set Quote
      const quote = response.memberQuoteJournal!.memberQuote
      if (quote) MemberStore.setQuote(quote)
      // Set expiration date
      const expirationDate = moment
        .utc(response.dateCreated)
        .startOf('day')
        .subtract(quote!.period, 'days')
        .endOf('day')
        .toString()
      MemberStore.setQuotePaymentDate(expirationDate)
      // Set new balance
      BalanceStore.setCurrentBalance(BalanceStore.currentBalance + response.journalEntry!.count!)
    }
    return response
  }

  public renewMemberQuote = async (memberId: string, quoteId: string) => {
    const response = await this.renewMemberQuoteEffect.run({
      memberId,
      quoteId,
      areaId: AreasStore.selectedAreaId,
    })
    if (response) {
      // const expirationDateOld = moment.utc(MemberStore.quote.)
      // Set Quote
      const quote = response.memberQuoteJournal!.memberQuote
      if (quote) MemberStore.setQuote(quote)
      // Set expiration date
      const expirationDate = moment.utc(response.dateCreated).add(quote!.period, 'days').toString()
      MemberStore.setQuotePaymentDate(expirationDate)
      // Set new balance
      BalanceStore.setCurrentBalance(BalanceStore.currentBalance - response.journalEntry!.count!)
    }
    return response
  }

  public transferMemberQuote = async (memberId: string, period: number) => {
    const response = await this.transferMemberQuoteEffect.run({
      memberId,
      period,
      areaId: AreasStore.selectedAreaId,
    })
    if (response) {
      const expirationDate = moment.utc(response.dateCreated).add(period, 'days').toString()
      MemberStore.setQuotePaymentDate(expirationDate)
    }
    return response
  }

  public increaseBalance = async (memberId: string, amount: number) => {
    if (amount <= 0) {
      throw new Error('Negative amount is not allowed for increasing.')
    }
    const response = await this.increaseBalanceEffect.run({
      memberId,
      amount: getRoundedDecimal(amount),
      areaId: AreasStore.selectedAreaId,
    })
    if (response && response.journalEntry) {
      // await ReportsStore.getDepositTransactionsByUser(memberId)
      const { count } = response.journalEntry
      BalanceStore.setCurrentBalance(BalanceStore.currentBalance + getRoundedDecimal(count!))
      ReportsStore.setMemberBalanceMovements([response, ...ReportsStore.memberBalanceMovements])
    }
  }

  public transferBalance = async (memberId: string, amount: number) => {
    const response = await this.transferBalanceEffect.run({
      memberId,
      amount,
      areaId: AreasStore.selectedAreaId,
    })
    if (response && response.journalEntry) {
      await ReportsStore.getDepositTransactionsByUser(memberId)
      const { count } = response.journalEntry
      BalanceStore.setCurrentBalance(BalanceStore.currentBalance + count!)
    }
  }

  public returnFunds = async (memberId: string, amount: number) => {
    const response = await this.returnBalanceFundsEffect.run({
      memberId,
      amount,
      areaId: AreasStore.selectedAreaId,
    })
    if (response) {
      BalanceStore.setCurrentBalance(BalanceStore.currentBalance - amount)
      ReportsStore.setMemberBalanceMovements([response, ...ReportsStore.memberBalanceMovements])
    }
    return response
  }

  public getAllCashSum = async (from: string, to: string) => {
    const response = await this.getAllCashSumEffect.run({
      from,
      to,
      areaId: AreasStore.selectedAreaId,
    })
    return response
  }

  get cashForToday() {
    return this.getAllCashSumEffect.data || 0
  }
}

export const PaymentStore = new PaymentStoreClass()
