import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { AreaListFeature } from 'features/area/area-list'
import { CommonPage } from 'shared/layout/CommonPage'
import { EntityAddModal } from 'shared/components/modal/entity-modal/EntityModal'
import { Button } from 'shared/components'
import { AreaAddFeature } from 'features/area/area-edit'
import { Preloader } from 'shared/components/preloader'
import { UserStore } from 'store/user.store'
import { AreasStore } from 'store/areas.store'

export const AreaListPage = observer(() => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false)
  const userId = UserStore.userKeycloakId

  useEffect(() => {
    if (userId) {
      AreasStore.loadAreas(userId)
    }
  }, [userId])

  return (
    <>
      {showAddModal && (
        <EntityAddModal
          title="Add Area"
          entityForm={
            <AreaAddFeature
              onSuccess={() => {
                setShowAddModal(false)
              }}
            />
          }
          onClose={() => {
            setShowAddModal(false)
          }}
        />
      )}

      {AreasStore.areasEffect.isLoading ? (
        <Preloader message="Loading Areas." lower />
      ) : (
        <CommonPage
          actions={
            <div>
              <Button
                onClick={() => {
                  setShowAddModal(true)
                }}
              >
                Add Area
              </Button>
            </div>
          }
        >
          <AreaListFeature />
        </CommonPage>
      )}
    </>
  )
})
