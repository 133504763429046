import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { enUS } from './en-US'
import { ruRU } from './ru-RU'
import { esES } from './es-ES'

i18n.use(LanguageDetector).init({
  debug: false,
  lng: 'en-US',
  resources: {
    'es-ES': esES,
    'en-US': enUS,
    'ru-RU': ruRU,
  },
  react: {
    useSuspense: false,
    bindI18n: 'languageChanged loaded',
    bindI18nStore: 'added removed',
    nsMode: 'default',
  },
})

export default i18n
