import { Transfer } from 'antd'
import { TransferDirection } from 'antd/es/transfer'
import React, { useCallback, useEffect, useState } from 'react'
import { Button } from 'shared/components'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/components/modal'
import { ModalProps } from 'shared/components/modal/Modal'
import { Nullable } from 'shared/utils/types'
import { ManagerWithKey } from '../types'

const getMoved = (managers: Array<ManagerWithKey>, moveKeys: Array<number | string>) =>
  managers.reduce((acc: Array<string>, item) => {
    if (moveKeys.some((key: string | number) => key === item.key)) {
      if (item.id) {
        return [...acc, item.id]
      }
    }
    return acc
  }, [] as Array<string>)

type AssignManagerToAreaModalProps = {
  managers: Array<any>
  selectedManagers: Array<any>
  assignManagers: (managers: Array<string>) => void
  detachManagers: (managers: Array<string>) => void
} & ModalProps

export function AssignManagerToAreaModal({
  onClose,
  managers,
  selectedManagers,
  assignManagers,
  detachManagers,
}: AssignManagerToAreaModalProps) {
  const [targetKeys, setTargetKeys] = useState<Array<string>>([])
  const [managersToAssign, setManagersToAssign] = useState<Nullable<Array<string>>>(null)
  const [managersToDetach, setManagersToDetach] = useState<Nullable<Array<string>>>(null)

  useEffect(() => {
    const keys = managers.reduce((acc, item) => {
      if (selectedManagers.some((m) => m.id === item.id)) {
        return [...acc, item.key]
      }
      return acc
    }, [])
    if (keys) {
      setTargetKeys(keys)
    }
  }, [managers, selectedManagers])

  const handleChange = (
    newTargetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => {
    setTargetKeys(newTargetKeys)
    if (direction === 'right') {
      const managersToAssign = getMoved(managers, moveKeys)
      setManagersToAssign(managersToAssign)
    } else {
      const managersToDetach = getMoved(managers, moveKeys)
      setManagersToDetach(managersToDetach)
    }
  }

  const isSyncButtonDisabled = useCallback(
    () => !managersToAssign && !managersToDetach,
    [managersToAssign, managersToDetach]
  )

  return (
    <Modal onClose={onClose}>
      <ModalHeader onClose={onClose}>Please, choose Managers to assign</ModalHeader>
      <ModalBody>
        <Transfer
          listStyle={{ width: '100%' }}
          dataSource={managers}
          targetKeys={targetKeys}
          onChange={handleChange}
          render={(manager) => (
            <div>
              {manager.personalData?.firstName} {manager.personalData?.lastName}
            </div>
          )}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          primary
          disabled={isSyncButtonDisabled()}
          onClick={() => {
            if (managersToAssign) {
              assignManagers(managersToAssign)
            }
            if (managersToDetach) {
              detachManagers(managersToDetach)
            }
          }}
        >
          Sync Managers
        </Button>
      </ModalFooter>
    </Modal>
  )
}
