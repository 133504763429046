import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/components/modal'
import { Alert, Col, Input, Row } from 'antd'
import { ChangeBalanceModal } from 'features/member/increase-balance/increase-balance-modal'
import { Button } from 'shared/components'
import { MemberProfileShort } from 'shared/components/member/member-profile-short/member-profile-short'
import { observer } from 'mobx-react-lite'
import { ReadMemberDto } from 'api-client'
import { Preloader } from 'shared/components/preloader'
import { Divider } from 'shared/components/divider/Divider'
import { MembersStore } from 'store/members.store'
import { CheckoutStore } from 'store/checkout.store'
import { PaymentStore } from 'store/payment.store'
import { useAuthentication } from 'auth/useAuthentication'
import { Roles } from 'auth/Roles'
import './confirm-checkout-modal.scss'
import { WarehouseStore } from 'store/warehouse.store'
import { ApiStore } from 'store/api.store'
import { subs } from 'shared/utils/math'
import { CantChargeMessage } from './CantChargeMessage'
import { CheckedInMemberItem } from './checked-in-member-item'
import { ErrorMessage } from './error-message'

const MembersList = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

interface CheckoutModalProps {
  member?: ReadMemberDto | null
  balance: number
  total: number
  onApprove: () => any
  onChooseMember: (nfcNumber: string) => void
  onClose: () => void
  onReset: () => void
  loading?: boolean
  error?: any
}

export const ConfirmCheckoutModal: React.FC<CheckoutModalProps> = observer(
  ({ member, balance, total, onApprove, onChooseMember, onClose, onReset, loading, error }) => {
    const [memberId, setMemberId] = useState<string | null | undefined>(null)
    const inputRef = useRef<any>(null)
    const [showFilter, setShowFilter] = useState<boolean>(true)
    const { getRoles } = useAuthentication()

    const showChosen = CheckoutStore.chosenMember
    const showMembersList = !showChosen
    const choose = (member: ReadMemberDto) => {
      CheckoutStore.chooseMemberAndBalance(member)
      setShowFilter(false)
    }

    const canCharge = useCallback(() => {
      if (!memberId) return false
      if (!member) return false
      if (member.isNegativeBalanceAllowed) return true
      return balance >= total
    }, [balance, member, memberId, total])

    const isCharging = useCallback(
      () => WarehouseStore.releasingProducts,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [WarehouseStore.releasingProducts]
    )

    const insufficientCredits = Math.abs(subs(balance, total))

    useEffect(() => {
      if (!MembersStore.checkedInMembers.length && !CheckoutStore.chosenMember) {
        MembersStore.loadCheckedInMembers()
      }
      inputRef?.current?.focus()
    }, [])

    useEffect(() => {
      setMemberId(member?.id)
      if (member) {
        setShowFilter(false)
      }
    }, [member])

    const [showIncreaseBalanceModal, setShowIncreaseBalanceModal] = useState<boolean>(false)

    const onIncreaseBalance = () => {
      setShowIncreaseBalanceModal(true)
    }

    const changeMember = () => {
      setShowFilter(true)
      CheckoutStore.resetMember()
    }

    const onIncrease = (amount: number) => {
      if (memberId) {
        PaymentStore.increaseBalance(memberId, amount).then(() => {
          setShowIncreaseBalanceModal(false)
        })
      }
    }

    const canIncreaseBalance = getRoles().some((i) => i === Roles.MANAGER)

    const filter = () =>
      showFilter ? (
        <div>
          <Input.Group size="large">
            <Row>
              <Col span={24}>
                <Input
                  style={{ width: '100%' }}
                  placeholder="Please select member"
                  size="large"
                  ref={inputRef}
                  autoFocus={true}
                  onChange={(e) => {
                    MembersStore.setFilterValue(e.target.value)
                  }}
                />
              </Col>
            </Row>
          </Input.Group>
        </div>
      ) : (
        <Button
          primary
          small
          onClick={() => {
            changeMember()
          }}
        >
          Change Member
        </Button>
      )

    return (
      <>
        {showIncreaseBalanceModal && (
          <ChangeBalanceModal
            title="Increase Member's Balance"
            onSubmit={onIncrease}
            defaultAmount={insufficientCredits}
            buttonText="Increase"
            onClose={() => {
              setShowIncreaseBalanceModal(false)
            }}
          />
        )}

        <Modal width={900}>
          <ModalHeader onClose={onClose}>Approve your transaction</ModalHeader>
          <ModalBody minHeight="450px">
            <>
              {loading && <Preloader lower message="Loading." />}

              {filter()}
              <Divider />
              {showMembersList && (
                <MembersList>
                  {MembersStore?.filteredCheckedInMembers.map((i: ReadMemberDto) => (
                    <div
                      key={i.id}
                      onClick={() => choose(i)}
                      onKeyDown={() => choose(i)}
                      role="button"
                      tabIndex={0}
                    >
                      <CheckedInMemberItem member={i} />
                    </div>
                  ))}
                </MembersList>
              )}

              {!!error && (
                <Alert style={{ marginTop: 20 }} message={error.message} type="error" showIcon />
              )}
            </>

            {showChosen && !error && (
              <div className="confirm-checkout-profile">
                <MemberProfileShort member={member} balance={balance} total={total} />

                {ApiStore.message && <ErrorMessage message={ApiStore.message} />}

                {!canCharge() && (
                  <CantChargeMessage
                    insufficientCredits={insufficientCredits}
                    onIncreaseBalance={onIncreaseBalance}
                    canIncrease={canIncreaseBalance}
                  />
                )}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              primary
              disabled={isCharging()}
              onClick={() => {
                onApprove()
              }}
            >
              Charge
            </Button>
            <Button danger onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
)
