import React from 'react'
import * as R from 'ramda'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/components/modal'
import { Button } from 'shared/components'
import { Col, Row } from 'antd'
import { Divider } from 'shared/components/divider/Divider'
import { subs } from 'shared/utils/math'
import { getUiDateTime } from 'shared/dates/formatters'
import i18n from 'locales/i18n'
import { OrderDetailsModalProps } from './types'

const Measure = styled.span`
  font-size: 14px;
`

export const OrderDetailsModal = observer(
  ({ order, onRevert, onClose }: OrderDetailsModalProps) => {
    // @ts-ignore
    const sum: number = R.pipe<any, any>(
      R.head,
    // @ts-ignore
      R.pathOr(0, ['journalEntry', 'count'])
    )(order.orders!)

    // @ts-ignore
    const discount: number = R.pipe<any, any>(R.head, R.propOr(0, 'discount'))(order.orders!)

    const interTitle = i18n.t('dashboard')

    return (
      <Modal>
        <ModalHeader onClose={onClose}>
          <h3>{interTitle}</h3>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={24} sm={12}>
              <h3>Member</h3>
              <h2>
                {order.member?.lastName} {order.member?.firstName}
              </h2>
            </Col>
            <Col xs={24} sm={12}>
              <h3>Assigned by</h3>
              <h2>
                {order.assignedByUser?.lastName} {order.assignedByUser?.firstName}
              </h2>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}>
              <h3>Product:</h3>
              <h2>{order.warehouseItem?.product?.title}</h2>
            </Col>
            <Col xs={24} sm={12}>
              <h3>Purchase date:</h3>
              <h2>{getUiDateTime(order.dateCreated)}</h2>
            </Col>
          </Row>
          <Divider big />
          <Row>
            <Col xs={24} sm={12}>
              <Row>
                <Col xs={24} sm={12}>
                  <h3>Amount:</h3>
                  <h2>
                    {subs(order.quantity!, order.lastQuantity!)} <Measure>g</Measure>
                  </h2>
                </Col>

                <Col xs={24} sm={12}>
                  <h3>Sum:</h3>
                  <h2>
                    {sum} <Measure>cred</Measure>
                  </h2>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={12}>
              <Row>
                <Col xs={24} sm={12}>
                  <h3>Discount:</h3>
                  <h2>
                    {discount}
                    <Measure>cred</Measure>
                  </h2>
                </Col>

                <Col xs={24} sm={12}>
                  <h3>Gift:</h3>
                  <h2>
                    0 <Measure>cred</Measure>
                  </h2>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              if (onClose) onClose()
            }}
            bordered
          >
            Exit
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
)
