import { action, computed, makeAutoObservable, observable } from 'mobx'
import { AddMemberFormValues } from 'features/member/member-add/add-member-form-fields'
import moment from 'moment'
import { ApiStore } from './api.store'
import { ReportsStore } from './reports.store'
import { Effect } from './utils/Effect'
import { RequestHelper } from './utils/RequestHelper'
import { AreasStore } from './areas.store'
import ApiClient from '../api'
import { PersonalData, ReadMemberDto, ReadMemberQuoteDto, UpdateMemberDto } from '../api-client'
import { CheckoutStore } from './checkout.store'
import { CashierStore } from './cashier.store'
import { MembersStore } from './members.store'

export class MemberStoreClass {
  @observable error: any = null

  @observable resultSuccess: boolean = false

  @observable loading: boolean = false

  @observable id?: string | null = null

  @observable signature: string | null = null

  @observable photo: string | null = null

  @observable photoDocument: string | null = null

  @observable photoDocumentBack: string | null = null

  constructor() {
    makeAutoObservable(this)
  }

  // eslint-disable-next-line class-methods-use-this
  mapResponse = (response: ReadMemberDto) => {
    if (response) {
      return {
        ...response,
        birthDate: response.birthDate ? moment(response.birthDate).toISOString() : '',
      }
    }
    return {} as ReadMemberDto
  }

  getMemberReferrersEffect = new Effect((id) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().members.getApiV1MembersReferrers(id, AreasStore.selectedAreaId)
    )
  )

  getMemberReferrers = (userId: string) => this.getMemberReferrersEffect.run(userId)

  createMemberEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(ApiClient().members.postApiV1Members(request)),
    'Creating new Member, please wait.'
  )

  @action createMember = async (data: PersonalData) => {
    const request = {
      ...data,
      birthDate: moment(data.birthDate).endOf('day').toISOString(false),
      sign: this.signature,
      photo: this.photo,
      photoDocument: this.photoDocument,
      areaId: AreasStore.selectedAreaId,
    }
    // const response = await create(request)
    const response = await this.createMemberEffect.run(request)
    if (response) {
      this.showResultSuccess()
      this.id = response.id
      MembersStore.data = [{ id: response.id, ...request }, ...MembersStore.members]
    }
    return response
  }

  updateMemberEffect = new Effect(
    (id, request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().members.putApiV1Members(id, AreasStore.selectedAreaId, request),
        'Success.'
      ),
    'Updating User Data. Please wait.'
  )

  @action updateMember = async (id: string, data: UpdateMemberDto) => {
    const request = {
      ...data,
      birthDate: moment(data.birthDate).endOf('day').toISOString(true),
      sign: this.getSignature,
      photo: this.getPhoto,
      photoDocument: this.getPhotoDocument,
      areasIds: [AreasStore.selectedAreaId],
    }
    const response = await this.updateMemberEffect.run(id, request)
    if (response) {
      this.setFormData(this.mapResponse(response))
    }
  }

  loadItemEffect = new Effect(
    (id) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().members.getMemberById(id, AreasStore.selectedAreaId)
      ),
    'Loading Member Data',
    true
  )

  @action loadItem = async (id: string) => {
    const response = await this.loadItemEffect.run(id)
    if (response) {
      CashierStore.setDiscount(response.defaultDiscount || 0)
    }
    return response
  }

  get member() {
    return this.loadItemEffect.data ? this.mapResponse(this.loadItemEffect.data) : null
  }

  updateComment = (comment: string) => {
    if (comment !== this.member?.comment) {
      this.updateMember(this.member?.id!, {
        ...this.member,
        comment,
      })
    }
  }

  updateMemberPhotoEffect = new Effect((id, photo) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().members.postApiV1MembersPhoto({
        id,
        photo,
      })
    )
  )

  @action updateMemberPhoto = async (id: string, photo: string) => {
    const response = await this.updateMemberPhotoEffect.run(id, photo)
    if (response) {
      ApiStore.setNotification({
        title: 'Success',
        message: 'Photo was updated.',
      })

      this.setPhoto(response.photo!)
    }
  }

  checkInMemberEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(ApiClient().members.postApiV1MembersCheckIn(request)),
    'Checking in Member.'
  )

  checkInMember = async (memberId?: string, checkedIn?: boolean) => {
    if (!memberId) throw new Error('No Member ID provided.')
    const request = {
      memberId,
      checkedIn,
      areaId: AreasStore.selectedAreaId,
    }
    const response = await this.checkInMemberEffect.run(request)
    if (response) {
      this.setCheckedIn(response.isActive)
      if (!checkedIn) {
        ReportsStore.updateVisitItem(response.id, response)
      }
      CheckoutStore.setCheckInStatus(response.isActive!)
      ApiStore.setNotification({
        title: 'Success',
        message: `${response.isActive ? 'Member Checked In' : 'Member Checked Out'}`,
      })
    }
    return response
  }

  @computed get currentItem() {
    return this.member
  }

  @computed get isPaid() {
    return !!this.member?.quotePaymentDate
  }

  @computed get formData() {
    return this.member as AddMemberFormValues
  }

  @computed get getPhoto(): string | undefined | null {
    return this.member?.photo ?? this.photo
  }

  @computed get getPhotoDocument(): string | undefined | null {
    return this.member?.photoDocument ?? this.photoDocument
  }

  @computed get getPhotoDocumentBack(): string | undefined | null {
    return this.member?.photoDocumentBack ?? this.photoDocumentBack
  }

  @computed get getSignature(): string | undefined | null {
    return this.member?.sign ?? this.signature
  }

  @computed get hasPhoto(): boolean {
    return !!this.getPhoto
  }

  @computed get hasPhotoDocument(): boolean {
    return !!this.getPhotoDocument
  }

  @computed get hasPhotoDocumentBack(): boolean {
    return !!this.getPhotoDocumentBack
  }

  @computed get hasSignature(): boolean {
    return !!this.getSignature
  }

  @computed get hasQuoteId(): boolean {
    return !!this.member?.memberQuote
  }

  @computed get quoteId(): string | undefined {
    return this.member?.memberQuote?.id
  }

  @computed get quote(): ReadMemberQuoteDto | undefined {
    return this.member?.memberQuote
  }

  @action private startLoading = () => {
    this.loading = true
  }

  @action private stopLoading = () => {
    this.loading = false
  }

  @action showResultSuccess = () => {
    this.resultSuccess = true
  }

  @action hideResultSuccess = () => {
    this.resultSuccess = false
  }

  @action setFormData = (formData: ReadMemberDto) => {
    this.loadItemEffect.data = {
      ...this.member,
      ...formData,
    }
  }

  @action setQuotePaymentDate = (date: string) => {
    this.loadItemEffect.data = {
      ...this.member,
      quotePaymentDate: date,
      quoteExpirationDate: date,
    }
  }

  @action setQuote = (quote: ReadMemberQuoteDto) => {
    this.loadItemEffect.data = {
      ...this.member,
      memberQuote: quote,
    }
  }

  @action setSignature = (signature: string) => {
    this.signature = signature
    if (this.loadItemEffect.data) {
      this.loadItemEffect.data.sign = signature
    }
  }

  @action setPhoto = (photo: string) => {
    this.photo = photo
    if (this.loadItemEffect.data) {
      this.loadItemEffect.data.photo = photo
    }
  }

  @action setPhotoDocument = (photo: string | null) => {
    this.photoDocument = photo
    if (this.loadItemEffect.data) {
      this.loadItemEffect.data.photoDocument = photo
    }
  }

  @action setPhotoDocumentBack = (photo: string | null) => {
    this.photoDocumentBack = photo
    if (this.loadItemEffect.data) {
      this.loadItemEffect.data.photoDocumentBack = photo
    }
  }

  @action setCheckedIn = (checkedIn?: boolean) => {
    if (this.loadItemEffect.data) {
      this.loadItemEffect.data.isCheckedIn = checkedIn
    }
  }

  resetMember = () => {
    this.loadItemEffect.data = undefined
    this.signature = null
    this.photo = null
    this.photoDocument = null
    this.photoDocumentBack = null
    this.getMemberReferrersEffect.data = undefined
  }

  get isCheckedIn() {
    return this.currentItem?.isCheckedIn
  }
}

export const MemberStore = new MemberStoreClass()
