import * as React from 'react'
import { useCallback, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Button } from 'shared/components'
import './signature.scss'

interface SignaturePadFeatureProps {
  setSignature: (signature: string) => void
}

function SignaturePadFeature({ setSignature }: SignaturePadFeatureProps) {
  const [signaturePad, setSignaturePad] = useState() as any

  const saveBase64 = async () => {
    const canvas = signaturePad.getTrimmedCanvas().toDataURL('image/png')
    if (canvas) {
      setSignature(canvas)
    }
  }

  const resetSignature = async () => {
    signaturePad.clear()
  }

  const containerRef = useRef<HTMLDivElement>(null)
  const getHeight = useCallback(() => containerRef?.current?.offsetHeight, [containerRef])
  const getWidth = useCallback(() => containerRef?.current?.offsetWidth, [containerRef])
  const injectCanvas = useCallback(
    () => {

        const width = getWidth() ?? 0
        const height = getHeight() ?? 0

        return <SignatureCanvas
            canvasProps={{ width: width - 24, height: height - 24 }}
            ref={(ref) => {
                setSignaturePad(ref)
            }}
        />
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [containerRef, getWidth, getHeight]
  )

  return (
    <div className="app-signature-pad">
      <div className="app-signature-pad__canvas" ref={containerRef}>
        {injectCanvas()}
      </div>
      <div className="app-signature-pad__actions">
        <Button primary onClick={() => saveBase64()}>
          Save signature
        </Button>
        <Button danger onClick={() => resetSignature()}>
          Reset signature
        </Button>
      </div>
    </div>
  )
}

export default SignaturePadFeature
