import Decimal from 'decimal.js'

Decimal.set({
  precision: 4,
  rounding: 5,
})

export const subs = (a: number | string, b: number | string) => {
  const aDecimal = new Decimal(a)
  const bDecimal = new Decimal(b)
  return aDecimal.sub(bDecimal).toNumber()
}

export const add = (a: number | string, b: number | string) => {
  const aDecimal = new Decimal(a)
  const bDecimal = new Decimal(b)
  return aDecimal.add(bDecimal).toNumber()
}

export const multiply = (a: number | string, b: number | string) => {
  const aDecimal = new Decimal(a)
  const bDecimal = new Decimal(b)
  return aDecimal.mul(bDecimal).toNumber()
}

export const multiplyHighPrecision = (a: number | string, b: number | string) => {
  const result = parseFloat(a.toString()) * parseFloat(b.toString())
  const power = 10**3
  return Math.round(result * power) / power
}

export const divide = (a: number | string, b: number | string) => {
  const aDecimal = new Decimal(a)
  const bDecimal = new Decimal(b)
  return aDecimal.dividedBy(bDecimal).toNumber()
}


export const getPercentFromFull = (full: number | string, part: number | string) => {
  const fullDecimal = new Decimal(full)
  const partDecimal = new Decimal(part)
  return partDecimal.dividedBy(fullDecimal).mul(100).toNumber()
}