import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Table } from 'antd'
import styled from 'styled-components'
import { ProductsStore } from 'store/products.store'
import { WarehouseStore } from 'store/warehouse.store'
import { Button } from 'shared/components'
import { StoreBatchModal } from 'features/warehouse/store-batch/store-batch.modal'
import { StoreBatchFormData } from 'features/warehouse/store-batch/types'
import { ReadProductCategoryDto, ReadSalePointDto } from 'api-client'
import { ModalConfirmation } from 'shared/components/modal/ModalConfirmation'
import { Divider } from 'shared/components/divider/Divider'
import { ListSearch } from 'shared/components/listing/ListSearch'
import { observer } from 'mobx-react-lite'


const Emphatize = styled.span`
  color: #ff0000;
`

const ProductListFeature = observer(() => {
  const [showStoreBatchModal, setShowStoreBatchModal] = useState<boolean>(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)

  const [storeBatchData, setStoreBatchData] = useState<StoreBatchFormData | null>(null)

  const [productId, setProductId] = useState<string | null>(null)
  const [productTitle, setProductTitle] = useState<string | null>(null)
  const history = useHistory()

  const storeBatch = ({ id, title }: any) => {
    setProductId(id)
    setProductTitle(title)
    setShowStoreBatchModal(true)
  }

  const confirmAndSubmit = ({ price, quantity }: StoreBatchFormData) => {
    setStoreBatchData({ price, quantity })
    setShowConfirmationModal(true)
  }

  const submitBatch = () =>
    productId &&
    WarehouseStore.storeNewBatch(
      productId,
      parseFloat(storeBatchData?.quantity.replace(/,/g, '.') || '0'),
      parseFloat(storeBatchData?.price.replace(/,/g, '.') || '0')
    ).then(() => setShowConfirmationModal(false))

  const editProduct = (id: string) => {
    history.push(`edit/${id}`)
  }

  const columns: any = [
    {
      title: 'Title',
      dataIndex: 'title',
      width: '40%',
    },
    {
      title: 'Category',
      dataIndex: 'categories',
      render: (item: Array<ReadProductCategoryDto>) =>
        item?.map((i: ReadProductCategoryDto) => <div key={`i_cat_${i.id}`}>{i.title}</div>),
    },
    {
      title: 'Sale Point',
      dataIndex: 'salePoint',
      render: (item: ReadSalePointDto) => item?.title,
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'x',
      render: (item: any) => (
        <div style={{ display: 'flex' }}>
          <div style={{ width: '20px' }} />
          <Button
            small
            primary
            onClick={() =>
              storeBatch({
                id: item.id,
                title: item.title,
              })
            }
          >
            Add New Batch
          </Button>

          <div style={{ width: '20px' }} />

          <Button small primary onClick={() => editProduct(item.id)}>
            Edit
          </Button>
        </div>
      ),
    },
  ]

  return (
    <div>

      {showStoreBatchModal && (
        <StoreBatchModal
          productTitle={productTitle}
          onSubmit={confirmAndSubmit}
          onClose={() => {
            setShowStoreBatchModal(false)
          }}
        />
      )}

      {showConfirmationModal && (
        <ModalConfirmation
          onClose={() => {
            setShowConfirmationModal(false)
          }}
          title={`You are about to add ${productTitle}`}
          actions={
            <>
              <Button primary onClick={submitBatch}>
                Approve
              </Button>
              <Button
                danger
                onClick={() => {
                  setShowConfirmationModal(false)
                }}
              >
                Cancel
              </Button>
            </>
          }
        >
          <div>
            <h1>
              Product: <Emphatize>{productTitle}</Emphatize>
            </h1>
            <Divider big />

            <h1>
              Quantity: <Emphatize>{storeBatchData?.quantity}</Emphatize>
            </h1>
            <Divider big />

            <h1>
              Price: <Emphatize>{storeBatchData?.price}</Emphatize>
            </h1>
          </div>
        </ModalConfirmation>
      )}

      <ListSearch
        setValue={ProductsStore.setSearchQuery}
        value={ProductsStore.searchQuery}
        placeholder="Search by Product title"
      />

      <Table
        pagination={false}
        columns={columns}
        dataSource={ProductsStore?.productsFiltered}
        rowKey="id"
      />
    </div>
  )
})

export default ProductListFeature
