import React, { ReactNode } from 'react'
import { ServerError } from 'api/types'
import { Button } from 'shared/components'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/components/modal'

interface ErrorModalProps {
  error?: ServerError
  actions?: ReactNode
  header?: ReactNode
  onClose: () => void
}

export function ErrorModal({ error, actions, header, onClose }: ErrorModalProps) {
  return (
    <Modal>
      <ModalHeader>{header ?? 'Error!'}</ModalHeader>
      <ModalBody>
        <p>Kind an error was occurred.</p>
        <p>Code: {error?.code}</p>
        <p>Message: {error?.message}</p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Ok</Button>
        {actions && actions}
      </ModalFooter>
    </Modal>
  )
}
