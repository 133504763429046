import React, { useEffect } from 'react'
import { CashierStore } from 'store/cashier.store'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Divider } from 'shared/components/divider/Divider'
import Icon from 'shared/components/icon'
import { TabVirtual } from 'shared/components/tabs-virtual/tab-virtual'
import { MemberStore } from 'store/member.store'
import { CheckoutStore } from 'store/checkout.store'
import { SalePointsStore } from 'store/sale-points.store'
import { CashierViewModel } from 'view-models/cashier.view-model'
import { CashierProductList } from 'features/cashier/cashier-product-list'
import CheckoutFeature from './checkout/checkout.feature'
import './cashier.scss'
import { SalePointSelectFeature } from '../sale-point/sale-point-select/sale-point.select.feature'

const CategoryTabs = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
`

const HeaderActions = styled.div`
  display: flex;
  border-bottom: 1px solid #bababa;
`
const Sorting = styled.div`
  display: flex;
`

const SortingLabel = styled.div`
  margin-right: 12px;
`

const SortingIcon = styled.div`
  cursor: pointer;
  margin-right: 8px;
`

interface CashierFeatureProps {
  salePointId?: string | null
  categoryId?: string | null
  memberId?: string | null
}

export const CashierFeature: React.FC<CashierFeatureProps> = observer(
  ({ salePointId, categoryId, memberId }) => {
    useEffect(() => {
      CheckoutStore.setData(null)
      CheckoutStore.setData(MemberStore.member)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [MemberStore.member])

    const resetProducts = () => {
      CashierStore.resetCheckoutList()
    }

    if (CashierStore.products.length === 0) {
      return null
    }

    return (
      <>
        {CashierStore.error && <div>{CashierStore.error.status}</div>}

        <HeaderActions>
          {SalePointsStore.salePoints.length > 0 && (
            <>
              <SalePointSelectFeature setSalePointId={CashierViewModel.setSalePointId} />
              <CategoryTabs>
                {CashierStore.categories.map((c) => (
                  <TabVirtual
                    id={c?.id ?? ''}
                    onClick={(id) => {
                      CashierStore.setCategoryId(id)
                    }}
                    key={c?.id}
                    title={c?.title ?? ''}
                    isActive={c?.id === CashierStore.currentCategoryId}
                  />
                ))}
              </CategoryTabs>
            </>
          )}
        </HeaderActions>
        <Divider big />

        <Sorting>
          <SortingLabel>Sort By Price:</SortingLabel>
          <SortingIcon onClick={() => CashierStore.setPriceSort('ASC')}>
            <Icon
              name="arrow-up"
              color={CashierStore.isActiveSort('ASC') ? '#636363' : '#bababa'}
            />
          </SortingIcon>

          <SortingIcon onClick={() => CashierStore.setPriceSort('DESC')}>
            <Icon
              name="arrow-down"
              color={CashierStore.isActiveSort('DESC') ? '#636363' : '#bababa'}
            />
          </SortingIcon>
        </Sorting>

        <Divider big />

        <div className="app-cashier">
          <div className="app-cashier__product-list">
            <CashierProductList products={CashierStore.productsFiltered} />
          </div>
          <div className="app-cashier__checkout-list">
            <CheckoutFeature
              products={CashierStore.checkoutList}
              total={CashierStore.subTotals}
              resetProducts={resetProducts}
            />
          </div>
        </div>
      </>
    )
  }
)
