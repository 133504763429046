import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'
import Icon from '../icon'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Trigger = styled.div`
  margin-bottom: 12px;
  cursor: pointer;
  border-bottom: dashed 1px #bababa;
  padding: 6px;
  display: flex;
  justify-content: center;
`

const TriggerText = styled.span`
  font-size: 18px;
  align-self: center;
`

const TriggerIcon = styled.span`
  margin-left: 12px;
`
const Content = styled.div`
    border-bottom: dashed 1px #bababa;
`

interface CollapseProps {
  showText: string
  hideText: string
  children: ReactNode
}

export function Collapse({ showText, hideText, children }: CollapseProps) {
  const [open, setOpen] = useState<boolean>(false)
  const toggle = () => setOpen(!open)
  return (
    <Container>
      <Trigger onKeyDown={toggle} onClick={toggle}>
        <TriggerText>{open ? hideText : showText}</TriggerText>
        <TriggerIcon>
          <Icon name={open ? 'chevron-up' : 'chevron-down'} color="#bababa" />
        </TriggerIcon>
      </Trigger>
      {open && <Content>{children}</Content>}
    </Container>
  )
}
