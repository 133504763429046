/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BooleanApiResponse } from '../models/BooleanApiResponse';
import type { ReportByPeriodDto } from '../models/ReportByPeriodDto';
import type { WarehouseDeviationsGroupedDtoApiResponse } from '../models/WarehouseDeviationsGroupedDtoApiResponse';
import type { WarehouseRevisionBatchCreateDto } from '../models/WarehouseRevisionBatchCreateDto';
import type { WarehouseRevisionBatchReadDtoApiResponse } from '../models/WarehouseRevisionBatchReadDtoApiResponse';
import type { WarehouseRevisionBatchReadDtoIEnumerableApiResponse } from '../models/WarehouseRevisionBatchReadDtoIEnumerableApiResponse';
import type { WarehouseRevisionBatchUpdateDto } from '../models/WarehouseRevisionBatchUpdateDto';
import type { WarehouseRevisionCreateDto } from '../models/WarehouseRevisionCreateDto';
import type { WarehouseRevisionReadDtoApiResponse } from '../models/WarehouseRevisionReadDtoApiResponse';
import type { WarehouseRevisionReadDtoListApiResponse } from '../models/WarehouseRevisionReadDtoListApiResponse';
import type { WarehouseRevisionUpdateDto } from '../models/WarehouseRevisionUpdateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WarehouseRevisionBatchesService {

    /**
     * @param areaId
     * @returns WarehouseRevisionReadDtoListApiResponse Success
     * @throws ApiError
     */
    public static getApiV1WarehouseRevisionBatchesGetAll(
        areaId?: string,
    ): CancelablePromise<WarehouseRevisionReadDtoListApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/warehouse-revision-batches/get-all',
            query: {
                'areaId': areaId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns WarehouseRevisionReadDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1WarehouseRevisionBatchesStartRevision(
        requestBody?: WarehouseRevisionCreateDto,
    ): CancelablePromise<WarehouseRevisionReadDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/warehouse-revision-batches/start-revision',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param areaId
     * @returns WarehouseRevisionReadDtoApiResponse Success
     * @throws ApiError
     */
    public static getApiV1WarehouseRevisionBatchesGetRevision(
        id: string,
        areaId?: string,
    ): CancelablePromise<WarehouseRevisionReadDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/warehouse-revision-batches/get-revision/{id}',
            path: {
                'id': id,
            },
            query: {
                'areaId': areaId,
            },
        });
    }

    /**
     * @param id
     * @returns WarehouseRevisionBatchReadDtoApiResponse Success
     * @throws ApiError
     */
    public static getApiV1WarehouseRevisionBatchesToggleApproveBatch(
        id: string,
    ): CancelablePromise<WarehouseRevisionBatchReadDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/warehouse-revision-batches/toggle-approve-batch/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns BooleanApiResponse Success
     * @throws ApiError
     */
    public static getApiV1WarehouseRevisionBatchesApproveAllBatches(
        id: string,
    ): CancelablePromise<BooleanApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/warehouse-revision-batches/approve-all-batches/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns WarehouseRevisionReadDtoApiResponse Success
     * @throws ApiError
     */
    public static getApiV1WarehouseRevisionBatchesApplyRevision(
        id: string,
    ): CancelablePromise<WarehouseRevisionReadDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/warehouse-revision-batches/apply-revision/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns WarehouseRevisionReadDtoApiResponse Success
     * @throws ApiError
     */
    public static getApiV1WarehouseRevisionBatchesCancelRevision(
        id: string,
    ): CancelablePromise<WarehouseRevisionReadDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/warehouse-revision-batches/cancel-revision/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns WarehouseRevisionReadDtoApiResponse Success
     * @throws ApiError
     */
    public static putApiV1WarehouseRevisionBatchesUpdateRevision(
        id: string,
        requestBody?: WarehouseRevisionUpdateDto,
    ): CancelablePromise<WarehouseRevisionReadDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/warehouse-revision-batches/update-revision/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param areaId
     * @returns BooleanApiResponse Success
     * @throws ApiError
     */
    public static getApiV1WarehouseRevisionBatchesHasActiveRevision(
        areaId?: string,
    ): CancelablePromise<BooleanApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/warehouse-revision-batches/has-active-revision',
            query: {
                'areaId': areaId,
            },
        });
    }

    /**
     * @param id
     * @returns WarehouseRevisionReadDtoApiResponse Success
     * @throws ApiError
     */
    public static getApiV1WarehouseRevisionBatchesReopenRevision(
        id?: string,
    ): CancelablePromise<WarehouseRevisionReadDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/warehouse-revision-batches/reopen-revision',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody
     * @returns WarehouseDeviationsGroupedDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1WarehouseRevisionBatchesDeviations(
        requestBody?: ReportByPeriodDto,
    ): CancelablePromise<WarehouseDeviationsGroupedDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/warehouse-revision-batches/deviations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns WarehouseRevisionBatchReadDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1WarehouseRevisionBatches(
        requestBody?: WarehouseRevisionBatchCreateDto,
    ): CancelablePromise<WarehouseRevisionBatchReadDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/warehouse-revision-batches',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns WarehouseRevisionBatchReadDtoIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static getApiV1WarehouseRevisionBatches(): CancelablePromise<WarehouseRevisionBatchReadDtoIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/warehouse-revision-batches',
        });
    }

    /**
     * @param id
     * @returns WarehouseRevisionBatchReadDtoApiResponse Success
     * @throws ApiError
     */
    public static deleteApiV1WarehouseRevisionBatches(
        id?: string,
    ): CancelablePromise<WarehouseRevisionBatchReadDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/warehouse-revision-batches',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns WarehouseRevisionBatchReadDtoApiResponse Success
     * @throws ApiError
     */
    public static getApiV1WarehouseRevisionBatches1(
        id: string,
    ): CancelablePromise<WarehouseRevisionBatchReadDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/warehouse-revision-batches/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns WarehouseRevisionBatchReadDtoApiResponse Success
     * @throws ApiError
     */
    public static putApiV1WarehouseRevisionBatches(
        id: string,
        requestBody?: WarehouseRevisionBatchUpdateDto,
    ): CancelablePromise<WarehouseRevisionBatchReadDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/warehouse-revision-batches/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
