/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProductCategoryDto } from '../models/CreateProductCategoryDto';
import type { ReadProductCategoryDtoApiResponse } from '../models/ReadProductCategoryDtoApiResponse';
import type { ReadProductCategoryDtoIEnumerableApiResponse } from '../models/ReadProductCategoryDtoIEnumerableApiResponse';
import type { UpdateProductCategoryDto } from '../models/UpdateProductCategoryDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductCategoriesService {

    /**
     * @param requestBody
     * @returns ReadProductCategoryDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1ProductCategories(
        requestBody?: CreateProductCategoryDto,
    ): CancelablePromise<ReadProductCategoryDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/product-categories',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ReadProductCategoryDtoApiResponse Success
     * @throws ApiError
     */
    public static putApiV1ProductCategories(
        id: string,
        requestBody?: UpdateProductCategoryDto,
    ): CancelablePromise<ReadProductCategoryDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/product-categories/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiV1ProductCategories(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/product-categories/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns ReadProductCategoryDtoApiResponse Success
     * @throws ApiError
     */
    public static getProductCategoryById(
        id: string,
    ): CancelablePromise<ReadProductCategoryDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/product-category/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param areaId
     * @returns ReadProductCategoryDtoIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static getApiV1ProductCategories(
        areaId: string,
    ): CancelablePromise<ReadProductCategoryDtoIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/product-categories/{areaId}',
            path: {
                'areaId': areaId,
            },
        });
    }

}
