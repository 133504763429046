import { makeAutoObservable, runInAction } from 'mobx'
import { WarehouseStore } from 'store/warehouse.store'

class WarehouseViewModelClass {
  loading: boolean = true

  init = async () => {
    this.loading = true
    await WarehouseStore.loadAll()
    runInAction(() => {
      this.loading = false
    })
  }

  initClosed = async () => {
    this.loading = true
    WarehouseStore.setSearchProductTitle('')
    await WarehouseStore.loadClosed(
      undefined,
      WarehouseStore.closedStockCurrentPage,
      WarehouseStore.closedStockPageSize
    )

    runInAction(() => {
      this.loading = false
    })
  }

  get isLoading() {
    return this.loading
  }

  constructor() {
    makeAutoObservable(this)
  }
}

export const WarehouseViewModel = new WarehouseViewModelClass()
