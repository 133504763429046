import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx'
import { BalanceStore } from 'store/balance.store'
import { CashierStore } from './cashier.store'
import { WarehouseStore } from './warehouse.store'
import ApiClient from '../api'
import { AreasStore } from './areas.store'
import { ReadMemberDto, ProductsReleasedResponseDto } from '../api-client'

export class CheckoutStoreClass {
  constructor() {
    makeAutoObservable(this)
  }

  member?: ReadMemberDto | null = null

  loading: boolean = false

  error: any = null

  @observable lastOrder: ProductsReleasedResponseDto | null = null

  chooseMember = async (nfcNumber: string) => {
    this.startLoading()
    this.resetMember()
    ApiClient()
      .members.getMemberByNfcNumber(nfcNumber, AreasStore.selectedAreaId)
      .then((response) => {
        this.setData(response.data)
        if (response.data?.id) BalanceStore.getMemberBalance(response.data?.id)
      })
      .catch((error) => {
        this.error = error
        this.stopLoading()
      })
      .finally(() => {
        this.stopLoading()
      })
  }

  chooseMemberAndBalance = async (member: ReadMemberDto) => {
    this.startLoading()
    this.resetMember()

    this.setData(member)
    if (member.id) {
      BalanceStore.getMemberBalance(member?.id).finally(() => this.stopLoading())
    }
  }

  // eslint-disable-next-line class-methods-use-this
  checkout = async (memberId?: string) => {
    if (!memberId) throw Error('Member ID was not provided')
    const products = Object.values(CashierStore.checkoutList)
    const response = await WarehouseStore.releaseProducts(products, memberId, CashierStore.discount)
    if (response) {
      runInAction(() => {
        this.lastOrder = response
      })
    }
    return response
  }

  @computed get chosenMember() {
    return this.member
  }

  @action resetMember = () => {
    this.member = null
    this.error = null
  }

  @action setData = (data?: ReadMemberDto | null) => {
    this.member = data
  }

  @action setCheckInStatus = (isCheckedIn: boolean) => {
    this.setData({
      ...this.member,
      isCheckedIn,
    })
  }

  @action private startLoading = () => {
    this.loading = true
  }

  @action private stopLoading = () => {
    this.loading = false
  }
}

export const CheckoutStore = new CheckoutStoreClass()
