/* eslint-disable no-unused-vars */
import { action, computed, makeAutoObservable, observable } from 'mobx'
import { ApiStore } from 'store/api.store'

export type Unwrap<T> = T extends Promise<infer U>
  ? U
  : // eslint-disable-next-line @typescript-eslint/no-unused-vars
  T extends (...args: any) => Promise<infer U>
  ? U
  : // eslint-disable-next-line @typescript-eslint/no-unused-vars
  T extends (...args: any) => infer U
  ? U
  : T

export class Effect<
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  F extends (...args: any) => Promise<any>
> {
  @observable isLoading = false

  @observable error?: any

  @observable isLoaded = false

  @observable data?: Unwrap<F>

  @observable countLoads = 0

  loaderMessage?: string | null

  silent?: boolean = false

  @computed get hasData() {
    return Boolean(this.data)
  }

  @computed get hasError() {
    return Boolean(this.error)
  }

  constructor(private fn: F, loaderMessage?: string, silent?: boolean) {
    makeAutoObservable(this)
    this.loaderMessage = loaderMessage
    this.silent = silent
  }

  @action
  async run(...params: Parameters<F>) {
    try {
      this.startLoading()
      this.isLoaded = false
      if (!this.silent) ApiStore.startLoading()
      if (this.loaderMessage && !this.silent) {
        ApiStore.setLoadingMessage(this.loaderMessage)
      }
      this.error = undefined
      const response = await this.fn.apply(undefined, params)
      const data = response as Unwrap<F>
      this.setData(data)
      this.isLoaded = true
      return data
    } catch (error: any) {
      console.warn(error)
      this.error = error
      this.isLoaded = false

      if (!this.silent) ApiStore.setDefaultLoadingMessage()
      return false
    } finally {
      this.countLoads++
      this.stopLoading()
      if (!this.silent) ApiStore.setDefaultLoadingMessage()
      if (!this.silent) ApiStore.stopLoading()
    }
  }

  @action reset() {
    this.error = undefined
    this.data = undefined
  }

  startLoading = () => {
    this.isLoading = true
  }

  stopLoading = () => {
    this.isLoading = false
  }

  setData = (data: Unwrap<F>) => {
    this.data = data
  }

  setLoadingMessage = (message: string | null) => {
    this.loaderMessage = message
  }
}
