import React from 'react'
import classNames from 'classnames'
import { Spinner } from 'shared/components/preloader/Spinner'
import './Preloader.scss'

export interface IPreloaderProps {
  message?: string | null
  onClick?: () => void
  className?: string
  backgroundColor?: string
  over?: boolean
  lower?: boolean
  transparent?: boolean
  minHeight?: string
}

export function Preloader(props: IPreloaderProps) {
  const { message, onClick, className, backgroundColor, over, transparent, lower, minHeight } =
    props

  const classNameComputed = classNames('preloader-wrapper', className, {
    'preloader-wrapper_over': over,
    'preloader-wrapper_lower': lower,
    'preloader-wrapper_transparent': transparent,
  })

  return (
    <div
      tabIndex={-1}
      role="button"
      onKeyDown={onClick}
      className={classNameComputed}
      onClick={onClick}
      style={{ backgroundColor, minHeight }}
    >
      <div className="preloader-wrapper__body">
        <Spinner />
        {message && <div className="preloader-wrapper__message">{message}</div>}
      </div>
    </div>
  )
}
