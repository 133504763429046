import moment from 'moment'
import { parseApiDate } from './parser'
import { UI_DATE_FORMAT } from './constants'

const formatReplacementRegExp = /(d{1,2})|(m{1,2})|(y{1,4})/gi

/**
 * Simple implementation of converting dates into a string with the specified format.
 */
export function formatDate(date: Date | null, format: string) {
  if (date === null) {
    return ''
  }

  return format.toLowerCase().replace(formatReplacementRegExp, (substring) => {
    // Date
    if (substring[0] === 'd') {
      const day = date.getDate().toString()
      return day.toString().padStart(substring.length, '0')
    }
    // Month
    if (substring[0] === 'm') {
      const month = (date.getMonth() + 1).toString()
      return month.toString().padStart(substring.length, '0')
    }
    // Year
    if (substring[0] === 'y') {
      return date.getFullYear().toString()
    }

    // Otherwise, just return the original value
    return substring
  })
}

export function formatUiDate(date: Date | null): string {
  return formatDate(date, UI_DATE_FORMAT)
}

export function reformatApiToUiDate(apiDate: string | null): string {
  return formatUiDate(parseApiDate(apiDate))
}

export const getUiDateTime = (date?: string) => {
  if (!date) return ''
  return moment(date).format('DD MMMM YYYY, HH:mm')
}

export const getUiDateTimeShort = (date?: string) => {
  if (!date) return ''
  return moment(date).format('D MM YYYY, HH:mm')
}

export const getUiDateShortTime = (date?: string) => {
  if (!date) return ''
  return moment(date).format('DD MMM. YYYY, HH:mm')
}

export const getUiDate = (date?: string) => {
  if (!date) return ''
  return moment(date).format('DD MMMM YYYY')
}


export const getUiDateShort = (date?: string) => {
  if (!date) return ''
  return moment(date).format('DD MMM YYYY')
}

export const getUiTime = (date?: string) => {
  if (!date) return ''
  return moment(date).format('HH:mm')
}

export const getPeriodInMinutes = (start?: string, end?: string | null): number => {
  if (!start) throw new Error('Start time not provided.')
  if (!end) throw new Error('End time not provided.')

  return moment(end).diff(moment(start), 'minutes')
}

export const getDaysDiff = (start_date: string, end_date: string, date_format = 'YYYY-MM-DD') =>
  {
    if (!start_date || !end_date) return 0
    return moment(end_date).diff(moment(start_date), 'days')
  }

export function getFormattedDate(date: string, format?: string) {
  return moment.utc(date).format(format ?? UI_DATE_FORMAT)
}
