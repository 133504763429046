import React from 'react'
import { SalePointAddFeature } from 'features/sale-point/sale-point-add/sale-point-add.feature'
import { observer } from 'mobx-react-lite'
import { CommonPage } from 'shared/layout/CommonPage'

const SalePointAddPage = observer(() => (
  <CommonPage title="Create Sale Point">
    <SalePointAddFeature />
  </CommonPage>
))
export default SalePointAddPage
