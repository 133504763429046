import { Roles } from 'auth/Roles'
import { makeAutoObservable } from 'mobx'
import ApiClient from 'api'
import { Effect } from './utils/Effect'
import { RequestHelper } from './utils/RequestHelper'
import { authenticationService } from '../auth/authenticationService'

export class UserStoreClass {
  userId: string = ''

  userKeycloakId: string = ''

  userRoles: Array<Roles> | null = null

  currentRole: Roles | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get roles() {
    return this.userRoles?.filter((role) => role.toUpperCase() in Roles) ?? []
  }

  initUser = (roles: Array<Roles>) => {
    this.setUserKeycloakId(authenticationService.getUserId() ?? '')
    this.setUserRoles(roles)

    if (roles.length === 0) {
      return
    }
    if (roles.length === 0) {
      this.setUserRole(roles[0])
      return
    }
    const { pathname } = window.location
    const role = roles.find((r) => pathname.includes(r.toLocaleLowerCase()))
    this.setUserRole(role ?? roles[0])
  }

  setUserId = (id: string) => {
    this.userId = id
  }

  setUserKeycloakId = (id: string) => {
    this.userKeycloakId = id
  }

  setUserRoles = (roles: Array<Roles>) => {
    this.userRoles = roles
  }

  setUserRole = (role: Roles) => {
    this.currentRole = role
  }

  get isOwner() {
    return this.currentRole === Roles.OWNER
  }

  get hasOwnerPermissions() {
    return this.roles.includes(Roles.OWNER)
  }

  loadDataEffect = new Effect((id) =>
    RequestHelper.unwrapFromFetchResponse(ApiClient().users.getApiV1UserPersonalData(id))
  )

  loadData = (keycloakId: string) => {
    this.loadDataEffect.run(keycloakId).then((data) => {
      if (data && data.id) {
        this.setUserId(data?.id)
      }
    })
  }

  promotionEffect = new Effect(
    (id: string) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().users.postApiV1UserPromote(id),
        'Member was Created.'
      ),
    'Promoting Registration to Member.'
  )

  promoteToMember = async (id: string) => this.promotionEffect.run(id)
}

export const UserStore = new UserStoreClass()
