import { ColumnsType } from 'antd/es/table'
import { ReadWarehouseJournalInfoDto } from 'api-client'

export const columns: ColumnsType<ReadWarehouseJournalInfoDto> = [
  {
    title: 'Product',
    dataIndex: 'productTitle',
    width: '100px',
  },
  {
    title: 'Total Quantity',
    dataIndex: 'quantity',
    width: '80px',
  },
  {
    title: 'Total Sum',
    dataIndex: 'total',
    width: '80px',
  },
  {
    title: 'Price (average)',
    dataIndex: 'price',
    width: '80px',
  },
  
]
