import React, { useCallback, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/components/modal'
import { Form, Select } from 'antd'
import { MemberQuoteStore } from 'store/member-quote.store'
import { Button } from 'shared/components'

interface PayMemberQuoteModalProps {
  onClose: () => void
  memberId: string
  onSubmit: (memberId: string, quoteId: string) => Promise<any>
}
function PayMemberQuoteModal({ onClose, memberId, onSubmit }: PayMemberQuoteModalProps) {
  const memberQuotes = MemberQuoteStore.data
  const [quoteId, setQuoteId] = useState<string>('')

  const payMemberQuote = useCallback(() => {
    if (memberId) {
      onSubmit(memberId, quoteId).then(() => {
        onClose()
      })
    }
  }, [memberId, onSubmit, quoteId, onClose])

  return (
    <Modal>
      <ModalHeader onClose={onClose}>Pay member Quote</ModalHeader>
      <ModalBody>
        <Form.Item label="Member Quote" name="quoteId">
          <Select onSelect={(value: string) => setQuoteId(value)}>
            {memberQuotes &&
              memberQuotes.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.title} - {item.period} days - {item.price} credits
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </ModalBody>
      <ModalFooter>
        <Button primary onClick={payMemberQuote}>
          Pay
        </Button>
        <Button danger onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default PayMemberQuoteModal
