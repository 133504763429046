import React, { useEffect } from 'react'
import { CommonPage } from 'shared/layout/CommonPage'
import { OrderListFeature } from 'features/accounting/order/list'
import { OrderStore } from 'features/accounting/order/store'
import { Preloader } from 'shared/components/preloader'
import { observer } from 'mobx-react-lite'

export const AccountingListPage = observer(() => {
  useEffect(() => {
    OrderStore.loadAll()
  }, [])

  return OrderStore.loading ? (
    <Preloader message="Loading Accounting Operations" lower />
  ) : (
    <CommonPage isMounted={!OrderStore.loading} title="List of all operations">
      <OrderListFeature />
    </CommonPage>
  )
})
