import React, { useState } from 'react'
import { Button } from 'shared/components'
import ImportMembersModal from './import-members.modal'

type ImportMembersTriggerProps = {
  areaId: string
}

function ImportMembersTrigger({ areaId }: ImportMembersTriggerProps) {
  const [show, setShow] = useState<boolean>(false)

  return (
    <>
      <Button
        primary
        onClick={() => {
          setShow(true)
        }}
      >
        Import Members
      </Button>
      {show && <ImportMembersModal onClose={() => setShow(false)} areaId={areaId} />}
    </>
  )
}

export default ImportMembersTrigger
