import React, { ReactNode } from 'react'

interface ModalBodyProps {
  children?: ReactNode
  minHeight?: string
}

export function ModalBody({ children, minHeight }: ModalBodyProps) {
  return (
    <div className="modal-body-wrapper" style={{ minHeight }}>
      <div className="modal-body">{children}</div>
    </div>
  )
}
