import React from 'react'
import { CommonPage } from 'shared/layout/CommonPage'
import { GeneralAccountListFeature } from 'features/accounting/general-account/list'
import { GeneralAccountEditModalTrigger } from 'features/accounting/general-account/edit/edit-modal-trigger'

export function GeneralAccountListPage() {
  return (
    <CommonPage title="List of general accounts" actions={<GeneralAccountEditModalTrigger />}>
      <GeneralAccountListFeature />
    </CommonPage>
  )
}
