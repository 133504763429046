import * as React from 'react'
import { Col, DatePicker, Form, Input, Row, Switch } from 'antd'
import moment from 'moment'
import { useCallback, useMemo, useRef } from 'react'
import { legalAgeValidator } from 'shared/utils/validators'
import { addMemberFormTitles, AddMemberFormValues } from './add-member-form-fields'

interface AddMemberFormProps {
  formData?: AddMemberFormValues
  setFormData: (data: AddMemberFormValues) => void
  isValid?: (isValid: boolean) => void
}

function MemberAddForm({ formData, setFormData, isValid }: AddMemberFormProps) {
  const legalAge = 18
  const onFinish = (formData: AddMemberFormValues) => {
    setFormData(formData)
  }
  const [form] = Form.useForm()
  const formRef = useRef(null)

  const requiredFieldsList = useMemo(
    () => ['firstName', 'lastName', 'documentNumber', 'birthDate', 'nationality', 'nfcKey'],
    []
  )

  const validating = useCallback(() => {
    form
      .validateFields(requiredFieldsList)
      .then(() => isValid && isValid(true))
      .catch(() => isValid && isValid(false))
  }, [form, isValid, requiredFieldsList])

  return (
    <Form
      onClick={validating}
      layout="vertical"
      form={form}
      ref={formRef}
      onFinish={onFinish}
      initialValues={{
        ...formData,
        birthDate: formData?.birthDate ? moment.utc(formData?.birthDate) : null,
      }}
      onValuesChange={(changedValues: any, values: any) => {
        setFormData({
          ...values,
          ...changedValues,
        })
      }}
    >
      <Row gutter={50}>
        <Col xs={12}>
          <Form.Item
            label={addMemberFormTitles.documentNumber}
            name="documentNumber"
            rules={[
              {
                required: true,
                message: 'Enter DNI please',
              },
            ]}
          >
            <Input autoFocus />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            label={addMemberFormTitles.nfcKey}
            name="nfcKey"
            rules={[
              {
                required: true,
                message: 'Scan new NFC key please.',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={50}>
        <Col xs={12}>
          <Form.Item
            label={addMemberFormTitles.email}
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid Email!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col xs={12}>
          <Form.Item
            label={addMemberFormTitles.birthDate}
            name="birthDate"
            rules={[
              {
                required: true,
                message: 'Enter your birth date please.',
              },
              legalAgeValidator(legalAge),
            ]}
          >
            <DatePicker format="DD.MM.YYYY" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={50}>
        <Col xs={12}>
          <Form.Item
            label={addMemberFormTitles.firstName}
            name="firstName"
            rules={[
              {
                required: true,
                message: 'Please input your first Name!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={addMemberFormTitles.lastName}
            name="lastName"
            rules={[
              {
                required: true,
                message: 'Please input your last name!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            label={addMemberFormTitles.nationality}
            name="nationality"
            rules={[
              {
                required: true,
                message: 'Please enter Nationality.',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Default Discount" name="defaultDiscount">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={50}>
        <Col xs={12}>
          <Form.Item label={addMemberFormTitles.address} name="address">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label={addMemberFormTitles.refferedBy} name="refferedBy">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={50}>
        <Col xs={12}>
          <Form.Item name="isNegativeBalanceAllowed" valuePropName="checked">
            <Switch
              checkedChildren={addMemberFormTitles.isNegativeBalanceAllowed}
              unCheckedChildren="Negative Balance Not Allowed"
            />
          </Form.Item>

          {form.getFieldValue('isNegativeBalanceAllowed') === true && (
            <Form.Item label="Credit amount" name="creditAmount">
              <Input defaultValue={35} min={0} max={100} />
            </Form.Item>
          )}
        </Col>
        <Col xs={12}>
          <Form.Item label={addMemberFormTitles.isNoFee} name="isNoFee" valuePropName="checked">
            <Switch checkedChildren="No Membership Fee" unCheckedChildren="Should Pay" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default MemberAddForm
