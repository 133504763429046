import React, { useState } from 'react'
import { Steps } from 'antd'
import styled from 'styled-components'
import Icon from 'shared/components/icon'
import { Button } from 'shared/components'
import { StepTerms, StepSignature } from './steps'
import { STEPS } from './steps/enums'

const { Step } = Steps

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

const Head = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  min-height: 32px;
  padding: 18px;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 90px;

  .app-button {
    height: 100%;
  }
`

const CurrentStepTitle = styled.div`
  font-size: 24px;
`

const Trigger = styled.div`
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;

  background: #bababa;
  border-radius: 50%;

  @media (max-width: 768px) {
    margin-left: 0;
    position: absolute;
    display: flex;
    right: 17px;
    top: 7px;
  }
`

const Submitted = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const SubmittedIcon = styled.div`
  align-self: center;
  margin-bottom: 20px;
`
const SubmittedText = styled.div`
  font-size: 30px;
  align-self: center;
`

interface RegistrationFeatureProps {
  setSignature: (signature: string) => void
}

export function SignTermsAndConditionsFeature({ setSignature }: RegistrationFeatureProps) {
  const [termsApproved, setTermsApproved] = useState<boolean>(false)
  const [signature, setSignatureLocal] = useState<string>('')
  const [current, setCurrent] = useState<number>(1)
  const [stepsVisible, setStepsVisible] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(false)

  const toggleSteps = () => setStepsVisible(!stepsVisible)

  const onChange = (value: number) => {
    setCurrent(value)
  }

  const handleTerms = (isApproved: boolean) => {
    setTermsApproved(isApproved)
    if (isApproved) setCurrent(1)
  }

  const handleSignature = (signature: string) => {
    setSignatureLocal(signature)
  }

  const steps = [
    {
      key: STEPS.TERMS,
      title: 'Terms and Conditions',
      content: StepTerms({
        termsApproved,
        handleTerms,
      }),
    },
    {
      key: STEPS.SIGN,
      title: 'Place your sign',
      content: StepSignature({
        signature,
        handleSignature,
        resetSignature: () => setSignatureLocal(''),
      }),
    },
  ]

  return (
    <>
      {saved && (
        <Container>
          <Body>
            <Submitted>
              <SubmittedIcon>
                <Icon name="checkmark" size={60} fill="green" />
              </SubmittedIcon>
              <SubmittedText>Signed successfully</SubmittedText>
            </Submitted>
          </Body>
        </Container>
      )}
      {!saved && (
        <Container>
          <Head>
            {!stepsVisible && (
              <CurrentStepTitle>
                <span>{steps[current].title}</span>
              </CurrentStepTitle>
            )}
            {stepsVisible && (
              <Steps current={current} onChange={onChange}>
                {steps.map((item) => (
                  <Step key={item.key} title={item.title} />
                ))}
              </Steps>
            )}
            <Trigger onClick={toggleSteps}>
              {!stepsVisible ? (
                <Icon name="chevron-down" size={30} />
              ) : (
                <Icon name="chevron-up" size={30} />
              )}
            </Trigger>
          </Head>
          <Body>{steps[current].content}</Body>
          {steps[current].key === STEPS.SIGN && (
            <Footer>
              <Button
                large
                square
                primary
                disabled={!signature}
                onClick={() => {
                  setSignature(signature)
                  setSaved(true)
                }}
              >
                Submit
              </Button>
            </Footer>
          )}
        </Container>
      )}
    </>
  )
}
