import React from 'react';
import { OnbordingStep1Feature } from 'features/owner/onbording/onbording-step1.feature';
import { CommonPage } from 'shared/layout/CommonPage';

export function OnbordingPage() {
  return (
    <CommonPage>
      <OnbordingStep1Feature />
    </CommonPage>
  )
}