import * as React from 'react'
import { Result } from 'antd'
import { Button } from 'shared/components'

function MemberAddResult({ id, goToList, goToProfile, goToEdit }: any) {
  return (
    <Result
      status="success"
      title={`${!id ? 'Successfully Added Member' : 'Successfully Updated Member'}`}
      subTitle={`${!id ? 'Member Added.' : 'Member Updated.'}`}
      extra={[
        <div
          key="ad-member-result-container"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            key="add_member_result_1"
            primary
            onClick={() => {
              goToList()
            }}
          >
            Go Members List
          </Button>
          <Button
            key="add_member_result_2"
            primary
            onClick={() => {
              goToProfile()
            }}
          >
            Go Member's Profile
          </Button>
          <Button
            key="add_member_result_3"
            onClick={() => {
              goToEdit()
            }}
          >
            Continue Edit
          </Button>
        </div>,
      ]}
    />
  )
}

export default MemberAddResult
