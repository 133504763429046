import { makeAutoObservable } from 'mobx'
import { Effect } from './utils/Effect'
import { RequestHelper } from './utils/RequestHelper'
import ApiClient from '../api'

class WarehouseJournalStoreClass {
  constructor() {
    makeAutoObservable(this)
  }

  loadJournalRecordEffect = new Effect(
    (id) => RequestHelper.unwrapFromFetchResponse(ApiClient().reposts.getApiV1ReportsOrderInfo(id)),
    'Loading Warehouse Batches'
  )

  loadJournalRecord = async (journalId: string) => {
    const response = await this.loadJournalRecordEffect.run(journalId)
    if (response) {
      this.loadJournalRecordEffect.data = response
    }
  }

  get journalRecord() {
    return this.loadJournalRecordEffect.data || {}
  }
}

export const WarehouseJournalStore = new WarehouseJournalStoreClass()
