import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { AreasStore } from './store/areas.store'

interface AreaRelatedInitProps {
  children: any
}

export const AreaRelatedInit = observer(({ children }: AreaRelatedInitProps) => {
  const [ready, setReady] = useState<boolean>(false)
  const { selectedAreaId } = AreasStore

  useEffect(() => {
    setReady(false)
    const timer =  setTimeout(() => {
      setReady(true)
    }, 0)
    return () => clearTimeout(timer)
  }, [selectedAreaId])
  return ready && children
})
