import React, { useEffect, useState } from 'react'
import { Steps } from 'antd'
import styled from 'styled-components'
import Icon from 'shared/components/icon'
import { Button } from 'shared/components'
import { RegistrationData, useRegistration } from 'features/registration/useRegistration'
import { Preloader } from 'shared/components/preloader'
import {
  MemberPersonalDataFormValues,
  memberPersonalDataInitialData,
} from './member-personal-data.form-fields'
import { StepTerms, StepSignature, StepPhotoId, StepPhotoSelf, StepPersonalData } from './steps'
import { STEPS } from './steps/enums'

const { Step } = Steps

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

const Head = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  min-height: 42px;
  padding: 18px;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
`
const CurrentStepTitle = styled.div`
  font-size: 24px;
`

const Trigger = styled.div`
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;

  background: #bababa;
  border-radius: 50%;

  //display: none;
  @media (max-width: 768px) {
    margin-left: 0;
    position: absolute;
    display: flex;
    right: 17px;
    top: 7px;
  }
`

const Submitted = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const SubmittedIcon = styled.div`
  align-self: center;
  margin-bottom: 20px;
`
const SubmittedText = styled.div`
  font-size: 30px;
  align-self: center;
`

interface RegistrationFeatureProps {
  areaId: string
}

export function RegistrationFeature({ areaId }: RegistrationFeatureProps) {
  const [data, setData] = useState<RegistrationData>()

  const [termsApproved, setTermsApproved] = useState<boolean>(false)
  const [signature, setSignature] = useState<string>('')
  const [photoDocument, setPhotoDocument] = useState<string>('')
  const [photoSelf, setPhotoSelf] = useState<string>('')
  const [personalData, setPersonalData] = useState<MemberPersonalDataFormValues>(
    memberPersonalDataInitialData
  )
  const [canSubmit, setCanSubmit] = useState<boolean>(false)
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [stepsVisible, setStepsVisible] = useState<boolean>(false)
  const [current, setCurrent] = useState<number>(0)
  const { loading, request, submitted } = useRegistration(areaId)
  const toggleSteps = () => setStepsVisible(!stepsVisible)
  const submitData = () => data && request(data)

  const onChange = (value: number) => {
    setCurrent(value)
  }

  const handleTerms = (isApproved: boolean) => {
    setTermsApproved(isApproved)
    setCurrent(1)
  }

  const handleSignature = (signature: string) => {
    setSignature(signature)
  }

  const handlePhotoId = (photo: string) => {
    setPhotoDocument(photo)
  }

  const handlePhotoSelf = (photo: string) => {
    setPhotoSelf(photo)
  }

  const steps = [
    {
      key: STEPS.TERMS,
      title: 'Terms and Conditions',
      content: StepTerms({
        termsApproved,
        handleTerms,
      }),
    },
    {
      key: STEPS.SIGN,
      title: 'Place your sign',
      content: StepSignature({
        signature,
        handleSignature,
        resetSignature: () => setSignature(''),
        handleNext: () => setCurrent(2),
      }),
    },
    {
      key: STEPS.PHOTO_ID,
      title: 'Take a photo of your ID',
      content: StepPhotoId({
        photoDocument,
        handlePhotoId,
        resetPhoto: () => setPhotoDocument(''),
        handleNext: () => setCurrent(3),
      }),
    },
    {
      key: STEPS.PHOTO_SELF,
      title: 'Take photo of yourself',
      content: StepPhotoSelf({
        photoSelf,
        handlePhotoSelf,
        handleNext: () => setCurrent(4),
        resetPhoto: () => setPhotoSelf(''),
      }),
    },
    {
      key: STEPS.PERSONAL_DATA,
      title: 'Fill Personal Data',
      content: StepPersonalData({
        personalData,
        setPersonalData,
        setIsFormValid,
      }),
    },
  ]

  useEffect(() => {
    setData({
      termsApproved,
      signature,
      photoDocument,
      photoSelf,
      personalData,
    })
    if (termsApproved && !!signature && isFormValid) {
      setCanSubmit(true)
    } else {
      setCanSubmit(false)
    }
  }, [termsApproved, signature, photoDocument, photoSelf, personalData, isFormValid])

  return (
    <>
      {loading && <Preloader />}
      {submitted && (
        <Container>
          <Body>
            <Submitted>
              <SubmittedIcon>
                <Icon name="checkmark" size={60} fill="green" />
              </SubmittedIcon>
              <SubmittedText>Registration successful</SubmittedText>
            </Submitted>
          </Body>
        </Container>
      )}
      {!submitted && (
        <Container>
          <Head>
            {!stepsVisible && (
              <CurrentStepTitle>
                <span>{steps[current].title}</span>
              </CurrentStepTitle>
            )}
            {stepsVisible && (
              <Steps current={current} onChange={onChange}>
                {steps.map((item) => (
                  <Step key={item.key} title={item.title} />
                ))}
              </Steps>
            )}
            <Trigger onClick={toggleSteps}>
              {!stepsVisible ? (
                <Icon name="chevron-down" size={30} />
              ) : (
                <Icon name="chevron-up" size={30} />
              )}
            </Trigger>
          </Head>
          <Body>{steps[current].content}</Body>
          <Footer>
            {steps[current].key === STEPS.PERSONAL_DATA && (
              <Button large square primary disabled={!canSubmit} onClick={submitData}>
                Submit
              </Button>
            )}
          </Footer>
        </Container>
      )}
    </>
  )
}
