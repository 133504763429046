import { Roles } from 'auth/Roles'
import { LocalstorageService } from 'shared/classes/LocalstorageService'
import jwt_decode from 'jwt-decode'
import { AuthClientTokens } from '@react-keycloak/core/lib/types'
import keycloak from '../keycloak'

const localstorageAuthService = new LocalstorageService('authToken')

export const authenticationService = {
  getToken() {
    return localstorageAuthService.get()
  },
  setToken(token: string | null) {
    localstorageAuthService.set(token)
  },
  getRoles(): Roles[] {
    const token = localstorageAuthService.get()
    if (token) {
      const tokenPayload = jwt_decode(token) as any
      return tokenPayload.roles || []
    }
    return []
  },
  getUserId() {
    const token = localstorageAuthService.get() || keycloak.token
    if (token) {
      return jwt_decode<{ sub: string }>(token).sub
    }
    return null
  },
  isAuthenticated() {
    return !!authenticationService.getToken()
  },
}

export const tokensHandler = (tokens: AuthClientTokens) => {
  if (tokens.token) {
    authenticationService.setToken(tokens.token)
  }
}
