import React, { ReactNode } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  padding: 0;
  display: flex;
  justify-content: space-between;
  .app-button {
    flex: 1;
    border-radius: 0;
    padding: 15px;
  }
`

interface ModalFooterProps {
  children?: ReactNode
}

// eslint-disable-next-line react/function-component-definition
export const ModalFooter: React.FC<ModalFooterProps> = ({ children }) => (
  <Container>{children}</Container>
)
