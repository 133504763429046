import React from 'react'
import { Button } from 'shared/components'
import styled from 'styled-components'

interface CantChargeMessageProps {
  insufficientCredits: number
  onIncreaseBalance: () => void
  canIncrease: boolean
}

const Container = styled.div`
  margin-top: 20px;
  padding: 20px;
  text-align: center;
  background-color: #fcdddd;
`

export function CantChargeMessage({
  insufficientCredits,
  onIncreaseBalance,
  canIncrease,
}: CantChargeMessageProps) {
  return (
    <Container>
      <h1>Your balance is too low, increase balance for:</h1>
      <h1 style={{ textDecoration: 'underline' }}>{insufficientCredits.toFixed(2)} Credits</h1>
      {canIncrease && (
        <div style={{ marginTop: 20 }}>
          <Button
            primary
            centered
            onClick={() => {
              onIncreaseBalance()
            }}
          >
            Increase Balance
          </Button>
        </div>
      )}
    </Container>
  )
}
