import { action, computed, makeObservable, observable } from 'mobx'

interface ResponseError extends Error {
  status?: number
}
export class LoadingStatus {
  @observable countLoads = 0

  @observable isLoading = false

  @observable error?: Error = undefined

  constructor() {
    makeObservable(this)
  }

  @computed get hasError() {
    return Boolean(this.error)
  }

  @action reset() {
    this.isLoading = false
    this.error = undefined
    this.countLoads = 0
  }

  @action startLoading() {
    this.isLoading = true
    this.error = undefined
  }

  @action setError(error: ResponseError) {
    this.error = error
  }

  @action completeLoading() {
    this.isLoading = false
    this.countLoads++
  }
}
