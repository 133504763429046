import * as React from 'react'
import styled from 'styled-components'
import Icon from 'shared/components/icon'

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  border: solid 2px #05bd82;
  height: 100%;
  width: 100%;
`

const Image = styled.img`
  position: relative;
  max-height: 100%;
  width: auto;
  max-width: 100%;
`
const ImageDummy = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(227, 227, 227, 0.2);
`

const Action = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.2);
  top: 10px;
  right: 10px;
  cursor: pointer;
  padding: 5px;
  line-height: 1;
`

interface PhotoContainerProps {
  onReset?: () => void
  src?: string | null
  minHeight?: number
}

export function PhotoContainer({ onReset, src, minHeight }: PhotoContainerProps) {
  return (
    <Container style={{
      minHeight
    }}>
      {src && <Image src={src} />}
      {!src && <ImageDummy />}

      {onReset && (
        <Action>
          <Icon
            name="refresh"
            size={20}
            color="#000000"
            onClick={() => {
              onReset()
            }}
          />
        </Action>
      )}
    </Container>
  )
}
