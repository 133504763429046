import React, { useCallback } from 'react'
import { Col, Row } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { ReadMemberDto } from 'api-client'
import { Button } from 'shared/components'
import { Divider } from 'shared/components/divider/Divider'
import Icon from 'shared/components/icon'
import { MemberStore } from 'store/member.store'
import { UserStore } from 'store/user.store'
import './member-info.scss'

interface MemberInfoProps {
  member: ReadMemberDto
  shortMode?: boolean
  balance?: number
  onIncreaseBalance: () => void
  returnFunds: () => void
  transferBalance: () => void
  onEditMember: () => void
}

export const MemberInfo = observer(
  ({
    member,
    shortMode,
    balance,
    onIncreaseBalance,
    returnFunds,
    transferBalance,
    onEditMember,
  }: MemberInfoProps) => {
    const { hasOwnerPermissions } = UserStore

    const age = useCallback(() => {
      const now = moment.utc()
      const date = moment.utc(member.birthDate)
      return now.diff(date, 'years')
    }, [member.birthDate])
    return (
      <div className={`member-info ${shortMode ? 'member-info_short' : ''}`}>
        <Row gutter={30}>
          <Col xs={24} md={12}>
            <div className="member-info__item member-info__name">
              <div>{member.firstName}</div>
              <div>{member.lastName}</div>
              <div
                style={{
                  cursor: 'pointer',
                  color: '#03bd82',
                }}
              >
                <Icon name="edit" color="#03bd82" size={26} onClick={onEditMember} />
              </div>
            </div>

            <div className="member-info__item member-info__nfc">
              <div className="member-info__item-key">NFC Number:</div>
              <div className="member-info__item-value">{member.nfcKey}</div>
            </div>
            <div className="member-info__item member-info__birth-document-number">
              <div className="member-info__item-key">Document Number:</div>
              <div className="member-info__item-value">{member.documentNumber}</div>
            </div>
            <div className="member-info__item">
              <div className="member-info__item-key">Birth Date:</div>
              <div className="member-info__item-value">
                <span>{moment.utc(member.birthDate).format('DD. MM. YYYY')} </span>({age()})
              </div>
            </div>
            <div className="member-info__item">
              <div className="member-info__item-key">Member Interviewed:</div>
              <div className="member-info__item-value">{member.isInterviewed ? 'Yes' : 'No'}</div>
            </div>
            <div className="member-info__item">
              <div className="member-info__item-key">Nationality:</div>
              <div className="member-info__item-value">{member.nationality}</div>
            </div>
            <div className="member-info__item">
              <div className="member-info__item-key">Address:</div>
              <div className="member-info__item-value">{member.address}</div>
            </div>
            <div className="member-info__item">
              <div className="member-info__item-key">No Membership Fee:</div>
              <div className="member-info__item-value">{member.isNoFee ? 'Yes' : 'No'}</div>
            </div>
            <div className="member-info__item">
              <div className="member-info__item-key">Negative Balance Allowed:</div>
              <div className="member-info__item-value">
                {member.isNegativeBalanceAllowed ? 'Yes' : 'No'}
              </div>
            </div>
            <div className="member-info__item">
              <div className="member-info__item-key">Member discount:</div>
              <div className="member-info__item-value">
                {member.defaultDiscount ? `${member.defaultDiscount}%` : 0}
              </div>
            </div>
            <div className="member-info__item">
              <div className="member-info__item-key">Max credit amount</div>
              <div className="member-info__item-value">
                {member.creditAmount ? `${member.creditAmount} credits` : 0}
              </div>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="member-info__balance">
              <div className="member-info__balance-key">Current balance:</div>
              <div className="member-info__balance-value">{balance} Credits</div>
            </div>
            <div className="member-info__balance-actions">
              <Divider big />
              <Button primary onClick={onIncreaseBalance}>
                Make a Donation
              </Button>
              <Divider big />

              <Button danger onClick={returnFunds}>
                Return Credits
              </Button>

              {hasOwnerPermissions && (
                <>
                  <Divider big />
                  <Button danger bordered onClick={transferBalance}>
                    Transfer balance
                  </Button>
                </>
              )}
            </div>
            <Divider big />
            <h3>Notes</h3>
            <TextArea
              style={{ height: '100px', borderRadius: '4px', resize: 'none' }}
              onBlur={(e) => MemberStore.updateComment(e.target.value)}
              defaultValue={MemberStore.member?.comment!}
            />
          </Col>
        </Row>
      </div>
    )
  }
)
