import { notification } from 'antd'
import React, { ReactNode, useEffect } from 'react'
import { ApiStore } from 'store/api.store'
import { observer } from 'mobx-react-lite'
import { useAuthentication } from 'auth/useAuthentication'

interface ApiErrorHandlerProps {
  children?: ReactNode
}

export const ApiErrorHandler: React.FC<ApiErrorHandlerProps> = observer(({ children }) => {
  const clearStatus = () => {
    ApiStore?.setStatusCode(null)
    ApiStore?.setMessage(null)
  }
  const { logOut } = useAuthentication()

  const showError = () => {
    notification.error({
      message: ApiStore?.message,
      description: ApiStore?.statusCode && `Error code: ${ApiStore?.statusCode}`,
      duration: 2,
    })
    // clearStatus()
  }

  useEffect(() => {
    if (ApiStore?.statusCode === 401) {
      logOut()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ApiStore?.statusCode])

  useEffect(() => {
    if (ApiStore?.message) {
      showError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ApiStore.message])

  useEffect(() => {
    window.addEventListener('locationChange', clearStatus)
    return window.removeEventListener('locationChange', clearStatus)
  }, [])

  return null
})
