import React, { useEffect } from 'react'
import * as R from 'ramda'
import { Popconfirm, Table } from 'antd'
import { observer } from 'mobx-react-lite'
import { ReportsStore } from 'store/reports.store'
import { getUiDateTime } from 'shared/dates/formatters'
import { toTodayEnd, lastYear } from 'shared/utils/today'
import { MemberStore } from 'store/member.store'
import { Order, ReadWarehouseJournalDto, WarehouseJournalType } from 'api-client'
import { Button } from 'shared/components'
import { WarehouseStore } from 'store/warehouse.store'
import { add, subs } from 'shared/utils/math'
import { isFalsy } from 'shared/utils'
import styled from 'styled-components'

const RevertedLabel = styled.span`
  font-size: 11px;
  color: #bababa;
`

export const MemberOrdersFeature = observer(() => {
  const { member } = MemberStore

  useEffect(() => {
    ReportsStore.getTodayWarehouseJournalByMember(lastYear, toTodayEnd, member?.id!)
  }, [member?.id])

  return (
    <Table
      loading={ReportsStore.todayWarehouseJournalByMemberLoading}
      pagination={{
        pageSize: 5,
      }}
      rowKey="id"
      dataSource={ReportsStore.todayWarehouseJournalByMember}
      columns={[
        {
          title: 'Date',
          dataIndex: 'dateCreated',
          render: (date: string) => <span>{getUiDateTime(date)}</span>,
        },
        {
          title: 'products',
          render: (item: ReadWarehouseJournalDto) => (
            <div>
              {!isFalsy(item?.lastQuantity) && item?.quantity && (
                <div key={`warehouse_item_${item.id}`}>
                  <span>{item.warehouseItem?.product?.title}</span>
                  <span> x </span>
                  <span>{subs(item.lastQuantity!, item.quantity)} </span>
                </div>
              )}
            </div>
          ),
        },
        {
          title: 'Sum',
          dataIndex: 'orders',
          render: (orders: Array<Order>) => {
            const sum = R.pipe(
              R.map<Order, number>(R.pathOr(0, ['journalEntry', 'count'])),
              R.reduce(add, 0)
            )(orders)
            return <div>{sum}</div>
          },
        },
        {
          title: 'Discount',
          dataIndex: 'orders',
          render: (orders: Array<Order>) => {
            const sum = R.pipe(
              R.map<Order, number>(R.propOr(0, 'discount')),
              R.reduce(add, 0)
            )(orders)
            return <div>{sum}</div>
          },
        },
        {
          title: 'Revert',
          render: (item: ReadWarehouseJournalDto) => (
            <div>
              {/* eslint-disable-next-line no-underscore-dangle */}
              {item && !item.isReverted && item.type === WarehouseJournalType._1 && (
                <Popconfirm
                  title="Are You Sure?"
                  onConfirm={() => {
                    WarehouseStore.revertReleaseProducts(item.id!).then((data) => {
                      if (data) {
                        ReportsStore.setTodayWarehouseJournalByMember([
                          data,
                          ...ReportsStore.todayWarehouseJournalByMember,
                        ])
                      }
                    })
                  }}
                >
                  <Button small danger>
                    Revert
                  </Button>
                </Popconfirm>
              )}
              {item && item.isReverted && <RevertedLabel>Reverted</RevertedLabel>}
            </div>
          ),
          width: '80px',
        },
      ]}
    />
  )
})
