import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { ListSearch } from 'shared/components/listing/ListSearch'
import { ReadMemberDto } from 'api-client'
import { DashboardStore } from 'store/dashboard.store'
import { MemberStore } from 'store/member.store'
import { BalanceStore } from 'store/balance.store'
import { MembersStore } from 'store/members.store'
import { useDebounce } from 'shared/hooks/useDebounce'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
const DropDown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  border: solid 1px #8b8b8b;
  border-radius: 0 0 3px 3px;
  background: #eaeaea;
  width: 100%;
  top: 100%;
  z-index: 10;
  max-height: 400px;
  overflow-y: auto;
`

const DropDownItem = styled.div`
  display: flex;
  border-bottom: solid 1px black;
  font-size: 14px;
  padding: 5px 3px;
  cursor: pointer;

  &:hover {
    background: #c6c6c6;
  }

  &:last-child {
    border-bottom: none;
  }
`

interface CheckoutMemberSelectProps {
  setMember: (member: ReadMemberDto) => void
}

export const CheckoutMemberSelect = observer(({ setMember }: CheckoutMemberSelectProps) => {
  const [nfc, setNfc] = useState<string>('')
  const [fullName, setFullName] = useState<string>('false')
  const [members, setMembers] = useState<ReadMemberDto[]>([])
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const chooseMember = async (id: string) => {
    await BalanceStore.getMemberBalance(id)
    const member = await MemberStore.loadItem(id)
    if (member) {
      setMember(member as ReadMemberDto)
    }
  }

  const findMemberByNfc = async () => {
    const id = await DashboardStore.getMemberIdByNfc(nfc)
    if (id) {
      chooseMember(id)
    }
  }

  const findUserByFullName = async () => {
    const members = await MembersStore.getMembersByFullName(fullName)
    if (members) setMembers(members)
  }

  const debouncedRequest = useDebounce(findUserByFullName, 500)

  useEffect(() => {
    if (members.length > 0) {
      setShowDropdown(true)
    } else {
      setShowDropdown(false)
    }
  }, [members])

  return (
    <Container>
      <ListSearch
        autoFocus
        setValue={(value) => {
          setNfc(value)
          setFullName(value)
        }}
        value={nfc}
        placeholder="Enter NFC key"
        onSubmit={(event) => {
          if (event.key === 'Enter') {
            findMemberByNfc()
          } else {
            debouncedRequest()
          }
        }}
      />
      {showDropdown && (
        <DropDown>
          {members.map((member) => (
            <DropDownItem
              key={member.id}
              onClick={() => {
                chooseMember(member.id!)
                setMembers([])
                setFullName('')
              }}
            >
              {member.firstName} {member.lastName}
            </DropDownItem>
          ))}
        </DropDown>
      )}
    </Container>
  )
})
