import React from 'react'
import { ReadWarehouseJournalDto } from 'api-client'
import { WarehouseStore } from 'store/warehouse.store'
import Icon from 'shared/components/icon'
import { WarehouseJournalCommentPopover } from './warehouse-journal-comment-popover'

export const comments = (fullList: boolean) => ({
    title: 'Comments',
    width: '80px',
    render: (item: ReadWarehouseJournalDto) => {
        const comments = item.comments || []

        return (
            <div>
                <WarehouseJournalCommentPopover
                    comments={comments}
                    submitComment={(comment: string) => {
                        WarehouseStore.addCommentToJournal(
                            {
                                comment,
                                journalId: item.id,
                            },
                            fullList
                        )
                    }}
                >
                    {comments.length ? (
                        <div style={{ cursor: 'pointer' }}>
                            <Icon size={28} name="messages" color="#029530" />{' '}
                            <span style={{ fontSize: 12 }}>({comments.length})</span>
                        </div>
                    ) : (
                        <div style={{ cursor: 'pointer' }}>
                            <Icon size={28} name="messages" color="#029530" />
                        </div>
                    )}
                </WarehouseJournalCommentPopover>
            </div>
        )
    },
})
