import { css } from 'styled-components'

export const responsive = {
  mobile: {
    standard: (...styles: any) => css`
      @media (max-width: 600px) {
        // @ts-ignore
        ${css(styles)};
      }
    `,
  },
  desktop: {
    mobile: (...styles: any) => css`
      @media (max-width: 440px) {
        ${css(styles)};
      }
    `,

    small: (...styles: any) => css`
      @media (max-width: 1200px) {
        ${css(styles)};
      }
    `,
    standard: (...styles: any) => css`
      @media (min-width: 1200px) {
        ${css(styles)};
      }
    `,
    large: (...styles: any) => css`
      @media (min-width: 1500px) {
        ${css(styles)};
      }
    `,
  },
}

export const commonContainer = css`
  margin: 0 auto;

  ${responsive.mobile.standard`
    padding-left: 5px;
    padding-right: 5px;
  `}
  ${responsive.desktop.small`
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
  `}
  ${responsive.desktop.standard`
    max-width: 1200px;
  `}
  ${responsive.desktop.large`
    max-width: 1400px;
  `}
`
