import styled from "styled-components";
import React from "react";

const VirtualTabStyled = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 21px;
  padding: 10px;
  background: #03bd82;
  color: #fff;
  cursor: pointer;
  border-radius: 4px 4px 0 0;
`
const VirtualTabActiveStyled = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 21px;
  padding: 10px;
  background: #e7e7e7;
  color: #000;
  border-radius: 4px 4px 0 0;
`

interface TabVirtualProps {
    isActive: boolean
    id: string
    title: string
    onClick: (id: string) => void
}

export function TabVirtual({ id, title, isActive, onClick }: TabVirtualProps) {
    return isActive
        ? <VirtualTabActiveStyled>{title}</VirtualTabActiveStyled>
        : <VirtualTabStyled onClick={() => {onClick(id)}}>{title}</VirtualTabStyled>
}
