import React from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import Icon from 'shared/components/icon'
import { CheckoutStore } from 'store/checkout.store'
import { BalanceStore } from 'store/balance.store'
import { CheckoutMemberSelect } from './checkout-member-select'
import { CheckoutMemberProfile } from './checkout-member-profile'
import { CashierStore } from '../../../../store/cashier.store'

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`

const Content = styled.div`
  flex: 1;
`

const DeleteIcon = styled.div`
  margin-left: auto;
  width: 40px;
`

export const CheckoutMemberMainFeature = observer(() => (
  <div style={{ width: '100%' }}>
    {CheckoutStore.member ? (
      <Container>
        <Content>
          <CheckoutMemberProfile
            member={CheckoutStore.member}
            balance={BalanceStore.currentBalance}
            total={CashierStore.total}
            showAdditionalControls={CashierStore.hasProducts}
          />
        </Content>

        <DeleteIcon>
          <Icon name="cross" size={26} color="#f00" onClick={CheckoutStore.resetMember} />
        </DeleteIcon>
      </Container>
    ) : (
      <CheckoutMemberSelect setMember={CheckoutStore.setData} />
    )}
  </div>
))
