import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Preloader } from 'shared/components/preloader'
import { MemberProfileFeature } from 'features/member/member-profile/member-profile.feature'
import { CashierFeature } from 'features/cashier/cashier.feature'
import { MemberStore } from 'store/member.store'
import { MemberProfileViewModel } from 'view-models/member-profile.view-model'
import { MountTransition } from 'shared/components/transitions/mount-transition'

const MemberProfilePage = observer(() => {
  const { id } = useParams<{
    id: string
  }>()

  useEffect(() => {
    MemberProfileViewModel.init(id)
    return () => {
      MemberStore.resetMember()
    }
  }, [id])

  if (!id) return null

  return MemberProfileViewModel.loading ? (
    <Preloader lower message="Loading Member Data" />
  ) : (
    <MountTransition isMounted={!MemberProfileViewModel.loading}>
      <MemberProfileFeature key={`memprof_${id}`} id={id} />
      <CashierFeature key={`cashierf_${id}`} />
    </MountTransition>
  )
})
export default MemberProfilePage
