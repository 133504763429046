import * as React from 'react'
import { Form, Input, Switch } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useRef } from 'react'

function ProductCategoryAddForm(props: any) {
  const { formData, setFormData } = props
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const showMeasuredOrder = () =>
    form.getFieldValue('measuredOrder') === true || formData?.measuredOrder
  return (
    <div>
      <Form
        ref={formRef}
        initialValues={formData}
        form={form}
        labelCol={{
          xs: 24,
          sm: 6,
        }}
        onValuesChange={(changed: any, values: any) => {
          setFormData({ ...values, ...changed })
        }}
      >
        <Form.Item
          label="Category name"
          name="title"
          rules={[
            {
              required: true,
              message: 'Enter category name',
            },
          ]}
        >
          <Input  />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: 'Enter parent category ID',
            },
          ]}
        >
          <TextArea />
        </Form.Item>

        <Form.Item
          label="Sort Order"
          name="sortOrder"
        >
          <Input />
        </Form.Item>

        <Form.Item label="Measured Order Count" name="measuredOrder" valuePropName="checked">
          <Switch
            checked={form.getFieldValue('measuredOrderCount') > 0}
            checkedChildren="Set"
            unCheckedChildren="Not Set"
          />
        </Form.Item>

        {showMeasuredOrder() && (
          <Form.Item label="Count" name="measuredOrderCount">
            <Input />
          </Form.Item>
        )}
      </Form>
    </div>
  )
}

export default ProductCategoryAddForm
