import React from 'react'
import { CheckoutProductType } from 'shared/models/product.types'
import { ProductCheckout } from './product-checkout'

interface CheckoutProductsListProps {
  products: CheckoutProductType
}

export function CheckoutProductsList({ products }: CheckoutProductsListProps) {
  return (
    <div>
      {Object.values(products).length > 0 &&
        Object.values(products).map(({ productTitle, ...rest }: any) => (
          <ProductCheckout
            {...rest}
            title={productTitle}
            key={`checkout-product_${rest.id}`}
          />
        ))}
    </div>
  )
}
