import { makeAutoObservable } from 'mobx'
import * as R from 'ramda'
import { Effect } from './utils/Effect'
import { RequestHelper } from './utils/RequestHelper'
import ApiClient from '../api'
import { CheckoutProductType } from '../shared/models/product.types'
import { CashierStore } from './cashier.store'
import { CalculateQuantitiesRequestDto } from '../api-client'

class FinancesStoreClass {
  constructor() {
    makeAutoObservable(this)
  }

  divideEffect = new Effect(
    (sum, price) =>
      RequestHelper.unwrapFromFetchResponse(ApiClient().finances.getApiV1Divide(sum, price)),
    undefined,
    true
  )

  divide = async (sum: number, price: number): Promise<number> => {
    const result = await this.divideEffect.run(sum, price)
    return result || 0
  }

  multiplyEffect = new Effect(
    (a, b) => RequestHelper.unwrapFromFetchResponse(ApiClient().finances.getApiV1Multiply(a, b)),
    undefined,
    true
  )

  multiply = async (a: number, b: number): Promise<number> => {
    const result = await this.multiplyEffect.run(a, b)
    return result || 0
  }

  addEffect = new Effect(
    (a, b) => RequestHelper.unwrapFromFetchResponse(ApiClient().finances.getApiV1Add(a, b)),
    undefined,
    true
  )

  add = async (a: number, b: number): Promise<number> => {
    const result = await this.addEffect.run(a, b)
    return result || 0
  }

  subsEffect = new Effect(
    (a, b) => RequestHelper.unwrapFromFetchResponse(ApiClient().finances.getApiV1Subs(a, b)),
    undefined,
    true
  )

  subs = async (a: number, b: number): Promise<number> => {
    const result = await this.subsEffect.run(a, b)
    return result || 0
  }

  getTotalsEffect = new Effect((request) =>
    RequestHelper.unwrapFromFetchResponse(ApiClient().finances.postApiV1GetTotals(request))
  )

  getTotals = async (checkoutList: CheckoutProductType) => {
    const products = R.pipe(
      R.values,
      R.map((p: any) => ({
        price: p.price,
        quantity: p.quantity,
        giftQuantity: p.giftQuantity,
      }))
    )(checkoutList)

    const result = await this.getTotalsEffect.run({
      products,
      discount: CashierStore.discount,
    })
    if (result) {
      CashierStore.setTotal(result.totals!)
    }

    return result || 0
  }

  get isLoading() {
    return this.multiplyEffect.isLoading || this.divideEffect.isLoading
  }

  calculateQuantitiesEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(ApiClient().finances.postApiV1GetQuantities(request)),
    undefined,
    true
  )

  calculateQuantities = async (request: CalculateQuantitiesRequestDto) => {
    const result = await this.calculateQuantitiesEffect.run(request)
    if (result) {
      return result
    }
    return []
  }
}

export const FinancesStore = new FinancesStoreClass()
