/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CloseBatchDto } from '../models/CloseBatchDto';
import type { CreateWarehouseJournalThreadDto } from '../models/CreateWarehouseJournalThreadDto';
import type { GetBatchesByProductDto } from '../models/GetBatchesByProductDto';
import type { GetWarehouseJournalRecordsDto } from '../models/GetWarehouseJournalRecordsDto';
import type { MergeBatchIntoDto } from '../models/MergeBatchIntoDto';
import type { ProductsReleasedResponseDtoApiResponse } from '../models/ProductsReleasedResponseDtoApiResponse';
import type { ReadWarehouseItemDtoApiResponse } from '../models/ReadWarehouseItemDtoApiResponse';
import type { ReadWarehouseItemDtoIEnumerableApiResponse } from '../models/ReadWarehouseItemDtoIEnumerableApiResponse';
import type { ReadWarehouseItemDtoListApiResponse } from '../models/ReadWarehouseItemDtoListApiResponse';
import type { ReadWarehouseJournalDtoApiResponse } from '../models/ReadWarehouseJournalDtoApiResponse';
import type { ReadWarehouseJournalDtoIEnumerableApiResponse } from '../models/ReadWarehouseJournalDtoIEnumerableApiResponse';
import type { ReadWarehouseJournalDtoListApiResponse } from '../models/ReadWarehouseJournalDtoListApiResponse';
import type { RechargeBatchDto } from '../models/RechargeBatchDto';
import type { RegisterWasteDto } from '../models/RegisterWasteDto';
import type { ReleaseProductsDto } from '../models/ReleaseProductsDto';
import type { ReportByPeriodDto } from '../models/ReportByPeriodDto';
import type { RevertReleaseProductsDto } from '../models/RevertReleaseProductsDto';
import type { SetProductIsVisibleDto } from '../models/SetProductIsVisibleDto';
import type { StoreProductDto } from '../models/StoreProductDto';
import type { WarehouseItemReadShortDtoIEnumerableApiResponse } from '../models/WarehouseItemReadShortDtoIEnumerableApiResponse';
import type { WarehouseJournalRecordsByMemberDto } from '../models/WarehouseJournalRecordsByMemberDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WarehouseService {

    /**
     * @param requestBody
     * @returns ReadWarehouseItemDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1WarehouseStore(
        requestBody?: StoreProductDto,
    ): CancelablePromise<ReadWarehouseItemDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/warehouse/store',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns ReadWarehouseItemDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1WarehouseRegisterWaste(
        requestBody?: RegisterWasteDto,
    ): CancelablePromise<ReadWarehouseItemDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/warehouse/register-waste',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns ReadWarehouseItemDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1WarehouseRechargeBatch(
        requestBody?: RechargeBatchDto,
    ): CancelablePromise<ReadWarehouseItemDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/warehouse/recharge-batch',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns ReadWarehouseItemDtoListApiResponse Success
     * @throws ApiError
     */
    public static postApiV1WarehouseGetByProduct(
        requestBody?: GetBatchesByProductDto,
    ): CancelablePromise<ReadWarehouseItemDtoListApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/warehouse/get-by-product',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns ReadWarehouseItemDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1WarehouseMergeBatchInto(
        requestBody?: MergeBatchIntoDto,
    ): CancelablePromise<ReadWarehouseItemDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/warehouse/merge-batch-into',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns ReadWarehouseItemDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1WarehouseCloseBatch(
        requestBody?: CloseBatchDto,
    ): CancelablePromise<ReadWarehouseItemDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/warehouse/close-batch',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns ProductsReleasedResponseDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1WarehouseReleaseProducts(
        requestBody?: ReleaseProductsDto,
    ): CancelablePromise<ProductsReleasedResponseDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/warehouse/release-products',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns ReadWarehouseJournalDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1WarehouseRevertReleaseProducts(
        requestBody?: RevertReleaseProductsDto,
    ): CancelablePromise<ReadWarehouseJournalDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/warehouse/revert-release-products',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns ReadWarehouseItemDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1WarehouseSetIsListed(
        requestBody?: SetProductIsVisibleDto,
    ): CancelablePromise<ReadWarehouseItemDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/warehouse/set-is-listed',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns ReadWarehouseJournalDtoIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static postApiV1WarehouseJournal(
        requestBody?: ReportByPeriodDto,
    ): CancelablePromise<ReadWarehouseJournalDtoIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/warehouse/journal',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns ReadWarehouseJournalDtoIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static postApiV1WarehouseJournalFiltered(
        requestBody?: GetWarehouseJournalRecordsDto,
    ): CancelablePromise<ReadWarehouseJournalDtoIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/warehouse/journal-filtered',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns ReadWarehouseJournalDtoListApiResponse Success
     * @throws ApiError
     */
    public static postApiV1WarehouseJournalByMember(
        requestBody?: WarehouseJournalRecordsByMemberDto,
    ): CancelablePromise<ReadWarehouseJournalDtoListApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/warehouse/journal-by-member',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns ReadWarehouseItemDtoApiResponse Success
     * @throws ApiError
     */
    public static getWarehouseItem(
        id: string,
    ): CancelablePromise<ReadWarehouseItemDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/warehouse/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns ReadWarehouseItemDtoApiResponse Success
     * @throws ApiError
     */
    public static deleteApiV1Warehouse(
        id: string,
    ): CancelablePromise<ReadWarehouseItemDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/warehouse/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param areaId
     * @param listedOnly
     * @returns ReadWarehouseItemDtoIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static getApiV1WarehouseByArea(
        areaId: string,
        listedOnly?: boolean,
    ): CancelablePromise<ReadWarehouseItemDtoIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/warehouse/by-area/{areaId}',
            path: {
                'areaId': areaId,
            },
            query: {
                'listedOnly': listedOnly,
            },
        });
    }

    /**
     * @param areaId
     * @param searchTerm
     * @param pageNumber
     * @param pageSize
     * @returns ReadWarehouseItemDtoIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static getApiV1WarehouseByAreaClosed(
        areaId: string,
        searchTerm?: string,
        pageNumber: number = 1,
        pageSize: number = 100,
    ): CancelablePromise<ReadWarehouseItemDtoIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/warehouse/by-area/{areaId}/closed',
            path: {
                'areaId': areaId,
            },
            query: {
                'searchTerm': searchTerm,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
            },
        });
    }

    /**
     * @param areaId
     * @returns WarehouseItemReadShortDtoIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static getApiV1WarehouseCashierByArea(
        areaId: string,
    ): CancelablePromise<WarehouseItemReadShortDtoIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/warehouse/cashier/by-area/{areaId}',
            path: {
                'areaId': areaId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns ReadWarehouseJournalDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1WarehouseAddJournalComment(
        requestBody?: CreateWarehouseJournalThreadDto,
    ): CancelablePromise<ReadWarehouseJournalDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/warehouse/add-journal-comment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
