import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { CommonMessage } from 'shared/components/messages/common-message'
import { getDaysDiff, getUiDate } from 'shared/dates/formatters'
import { Divider } from 'shared/components/divider/Divider'
import { MemberStore } from 'store/member.store'
import PayMemberQuoteTrigger from './pay-member-quote/pay-member-quote.trigger'
import RenewMemberQuoteTrigger from './pay-member-quote/renew-member-quote.trigger'

type PayMemberQuoteFeatureProps = {
  id: string
}

export const PayMemberQuoteFeature = observer(({ id }: PayMemberQuoteFeatureProps) => {
  const getDaysLeft = useCallback((): number => {
    const start = moment().toISOString()
    const end = moment(MemberStore.currentItem?.quoteExpirationDate).toISOString()

    return getDaysDiff(start, end)
  }, [])

  const showPayQuoteButton = () => getDaysLeft() <= 0

  const showWarning = useCallback(() => getDaysLeft() < 30, [getDaysLeft])

  return (
    <>
      {!showPayQuoteButton() && (
        <>
          <CommonMessage
            block
            success={!showWarning()}
            warning={showWarning()}
            title="Active Member."
            text={`Quote paid for ${getDaysLeft()} days. Till: ${getUiDate(
              moment.utc(MemberStore.currentItem?.quoteExpirationDate).toISOString()
            )}`}
            additionalText={showWarning() ? 'Please do not forget to pay the Quote' : undefined}
          />
          <Divider big />
          <RenewMemberQuoteTrigger memberId={id} />
        </>
      )}
      {showPayQuoteButton() && (
        <>
          <PayMemberQuoteTrigger memberId={id} />
          <Divider big />
        </>
      )}
    </>
  )
})
