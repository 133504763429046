import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { ReadMemberDto, ReadMemberVisitDto } from 'api-client'
import { getPeriodInMinutes, getUiDateTime } from 'shared/dates/formatters'
import { MemberStore } from 'store/member.store'
import { StayTime } from './StayTime'
import { CheckoutButton } from './CheckoutButton'

export const membersColumns = [
  {
    title: 'Full Name',
    dataIndex: 'member',
    render: (item: ReadMemberDto) => (
      <Link to={`members/${item.id}`}>
        <span style={{ fontSize: 18 }}>
          <span style={{ marginRight: 10 }}>{item?.firstName}</span>
          <span>{item?.lastName}</span>
        </span>
      </Link>
    ),
  },
  {
    title: 'Check In Time',
    render: (item: ReadMemberVisitDto) => (
      <div>{item?.checkIn && getUiDateTime(item?.checkIn)}</div>
    ),
  },
  {
    title: 'Check Out Time',
    render: (item: ReadMemberVisitDto) => (
      <div>{item?.checkOut && getUiDateTime(item?.checkOut)}</div>
    ),
  },
  {
    title: 'Stay Time',
    render: (item: ReadMemberVisitDto) => {
      const time: number | null = item?.isActive
        ? getPeriodInMinutes(item?.checkIn, moment.utc().toISOString())
        : getPeriodInMinutes(item?.checkIn, item?.checkOut)

      return <StayTime time={time} noInterval={!item?.isActive} />
    },
  },
  {
    title: 'Actions',
    render: (item: ReadMemberVisitDto) => {
      const checkOut = () => {
        MemberStore.checkInMember(item.member?.id, false)
      }
      return <CheckoutButton showButton={item.isActive} checkOut={checkOut} />
    },
  },
]
