
type FormFields<T> = Array<[T, string]>

export interface AddMemberFormValues {
  documentNumber?: string
  nfcKey?: string
  firstName?: string
  lastName?: string
  email?: string
  birthDate?: string
  nationality?: string
  address?: string
  age?: number
  isInterviewed?: boolean
  isNoFee?: boolean
  isNegativeBalanceAllowed?: boolean
  refferedBy?: string
}

export type AddMemberFormField = keyof AddMemberFormValues

export const addMemberFormTitles: Record<AddMemberFormField, string> = {
  documentNumber: 'Identification Document Number',
  nfcKey: 'NFC Key number',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email address',
  birthDate: 'Date of birth',
  nationality: 'Nationality',
  address: 'Address',
  age: 'Age',
  isInterviewed: 'Is member interviewed',
  isNoFee: 'No Membership Fee',
  isNegativeBalanceAllowed: 'Negative Balance Allowed',
  refferedBy: 'Reffered By',
}

export const addMemberFormFields: FormFields<AddMemberFormField> = Object.entries(
  addMemberFormTitles
) as FormFields<AddMemberFormField>

export const addMemberInitialData: AddMemberFormValues = {
  documentNumber: '',
  nfcKey: '',
  firstName: '',
  lastName: '',
  email: '',
  birthDate: '',
  nationality: '',
  address: '',
  age: 0,
  isInterviewed: false,
  isNoFee: false,
  isNegativeBalanceAllowed: false,
  refferedBy: '',
}
