import * as React from 'react'
import { useEffect, useState } from 'react'
import { Button } from 'shared/components'
import { ProductsStore } from 'store/products.store'
import { observer } from 'mobx-react-lite'
import { ProductCategoriesStore } from 'store/product-categories.store'
import { SalePointsStore } from 'store/sale-points.store'
import ProductAddForm from './product-add.form'
import { ReadProductCategoryDto } from '../../../api-client'

interface ProductEditFeatureProps {
  id: string
  onSuccess?: () => void
}

const ProductEditFeature: React.FC<ProductEditFeatureProps> = observer(({ id, onSuccess }) => {
  const [formData, setFormData] = useState<ReadProductCategoryDto>(ProductsStore.product)
  const [initialized, setInitialized] = useState<boolean>(false)
  const updateProduct = async (data: any) =>
    formData &&
    ProductsStore.updateProduct({
      ...data,
      id,
    }).then(() => onSuccess && onSuccess())

  useEffect(() => {
    SalePointsStore.load()
    ProductCategoriesStore.load()
    ProductsStore.loadProduct(id).then(() => {
      setFormData(ProductsStore.product)
      setInitialized(true)
    })
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {formData && initialized && (
        <ProductAddForm
          formData={ProductsStore.product}
          setFormData={setFormData}
          productCategories={ProductCategoriesStore.productCategories}
          salePoints={SalePointsStore.salePoints}
        />
      )}

      <Button
        onClick={() => {
          updateProduct(formData)
        }}
      >
        Update Product
      </Button>
    </div>
  )
})

export default ProductEditFeature
