import { CashierViewModel } from 'view-models/cashier.view-model'
import { action, makeAutoObservable } from 'mobx'
import { BalanceStore } from 'store/balance.store'
import { MemberStore } from 'store/member.store'

class MemberProfileViewModelClass {
  loading: boolean = true

  errors: string[] = []

  init = async (id: string) => {
    MemberStore.resetMember()
    this.setLoading(true)
    BalanceStore.setCurrentBalance(0)
    Promise.all([
      CashierViewModel.loadCashierData(),
      MemberStore.loadItem(id),
      BalanceStore.getMemberBalance(id),
    ])
      .catch((e) => {
        this.errors.push(e)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @action setLoading = (loading: boolean) => {
    this.loading = loading
  }

  constructor() {
    makeAutoObservable(this)
  }
}

export const MemberProfileViewModel = new MemberProfileViewModelClass()
