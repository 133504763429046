import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { ListSearch } from 'shared/components/listing/ListSearch'
import { DashboardStore } from 'store/dashboard.store'
import { getPagePath } from 'router/utils'

export const GoToMemberProfileFeature = observer(() => {
  const [search, setSearch] = useState<string>('')
  const goToUserProfile = async () => {
    const id = await DashboardStore.getMemberIdByNfc(search)
    if (id) {
        window.open(getPagePath(`manager/members/${id}`), "_blank");
    }
  }

  return (
    <ListSearch
      autoFocus
      setValue={setSearch}
      value={search}
      placeholder="Go to member profile"
      onSubmit={(event) => {
        if (event.key === 'Enter') {
          goToUserProfile()
        }
      }}
    />
  )
})

