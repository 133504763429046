import React from 'react'
import { Form, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { CommonFormProps } from 'shared/components/form/types'

interface GeneralAccountFormProps extends CommonFormProps<any> {}

export function GeneralAccountForm({ formData, setFormData }: GeneralAccountFormProps) {
  return (
    <Form
      initialValues={formData}
      labelCol={{ xs: 24, sm: 6 }}
      onValuesChange={(changed: any, values: any) => {
        setFormData({
          ...values,
          ...changed,
        })
      }}
    >
      <Form.Item
        label="Code"
        name="code"
        rules={[
          {
            required: true,
            message: 'Enter General account Code',
          },
        ]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item
        label="Title"
        name="title"
        rules={[
          {
            required: true,
            message: 'Enter General account Title',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Description" name="description">
        <TextArea />
      </Form.Item>
    </Form>
  )
}
