import React, { useState } from 'react'
import { useInterval } from 'shared/hooks/useInterval'
import { getHoursAndMinutesFromMinutes } from 'shared/helpers'

interface StayTimeProps {
  time: number
  noInterval?: boolean
}

export function StayTime({ time, noInterval }: StayTimeProps) {
  const [count, setCount] = useState<number>(time)

  useInterval(() => !noInterval && setCount(count + 1), 60000)

  return <div>{getHoursAndMinutesFromMinutes(count)}</div>
}
