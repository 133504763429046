import * as React from 'react'
import { useState, useEffect } from 'react'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { HubConnection } from '@microsoft/signalr/dist/esm/HubConnection'
import { PhotoContainer } from 'shared/components/photo-container/PhotoContainer'
import { Preloader } from 'shared/components/preloader'
import styled from 'styled-components'
import { MemberSignatureData } from './types'
import { Modal, ModalBody, ModalHeader } from '../../shared/components/modal'
import SignaturePadFeature from './signature-pad.feature'

interface SignatureFeatureProps {
  wsUrl: string
  onFinish: (signature: string) => void
  areaId: string
  withPreview?: boolean
}

const Container = styled.div`
  position: relative;
  min-height: 200px;
  min-width: 200px;
  border: solid 2px rgb(5, 189, 130);
  border-radius: 4px;
  margin-bottom: 20px;
`

const ContainerSignaturePad = styled.div`
  height: 380px;
  width: 100%;
`

function SignatureFeature({ wsUrl, onFinish, areaId, withPreview }: SignatureFeatureProps) {
  const [connection, setConnection] = useState<HubConnection | null>(null)
  const [signature, setSignature] = useState<MemberSignatureData | null>(null)
  const [showSignatureModal, setShowSignatureModal] = useState<boolean>(false)

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(wsUrl)
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Trace)
      .build()

    setConnection(newConnection)
  }, [wsUrl])

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on('SendSignature', (signatureData: MemberSignatureData) => {
            if (signatureData.areaId === areaId) {
              setSignature(signatureData)
              onFinish(signatureData.signature)
            }
          })
        })
        .catch((e: any) => console.error('Connection failed: ', e))
    }
  }, [connection, wsUrl, areaId, onFinish])

  const showSignaturePad = (e: React.MouseEvent<HTMLDivElement>) => {
    e?.stopPropagation()
    setShowSignatureModal(true)
  }

  const hideSignaturePad = (e?: Event) => {
    e?.stopPropagation()
    setShowSignatureModal(false)
  }

  return (
    <Container onClick={showSignaturePad}>
      {showSignatureModal && (
        <Modal onClose={hideSignaturePad}>
          <ModalHeader onClose={hideSignaturePad}>Signature Pad</ModalHeader>
          <ModalBody>
            <ContainerSignaturePad>
              <SignaturePadFeature setSignature={onFinish} />
            </ContainerSignaturePad>
          </ModalBody>
        </Modal>
      )}

      {signature?.signature ? (
        withPreview && <PhotoContainer src={signature?.signature} />
      ) : (
        <Preloader over lower message="Get Signature From QR Code or Click here to insert directly." />
      )}
    </Container>
  )
}

export default SignatureFeature
