import React, { useCallback, useEffect, useState } from 'react'
import { Button } from 'shared/components'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'shared/components/modal'
import { GeneralAccountFormModel } from '../models'
import { GeneralAccountStore } from '../store'
import { GeneralAccountForm } from './form'

export const INITIAL_STATE: GeneralAccountFormModel = {
  code: 0,
  title: '',
  description: '',
}

export function GeneralAccountEditModal({
  id,
  data,
  onSave,
  onClose,
}: GeneralAccountEditModalProps) {
  const initialState = !data
    ? (GeneralAccountStore.generalAccount as GeneralAccountFormModel)
    : data ?? INITIAL_STATE
  const [formData, setFormData] = useState<GeneralAccountFormModel>(initialState)

  const save = useCallback(() => {
    if (id) {
      GeneralAccountStore.updateGeneralAccount(id, formData).then(() => onClose && onClose())
    } else {
      GeneralAccountStore.createGeneralAccount(formData).then(() => onClose && onClose())
    }
    return onSave && onSave()
  }, [formData, onSave, onClose, id])

  useEffect(() => {
    if (id && !data) {
      GeneralAccountStore.loadById(id)
    }
  }, [id, data])

  return (
    <Modal onEnter={save}>
      <ModalHeader onClose={onClose}>General Account</ModalHeader>
      <ModalBody>
        <GeneralAccountForm setFormData={setFormData} formData={formData} />
      </ModalBody>
      <ModalFooter>
        <Button primary onClick={save}>
          Save
        </Button>
        <Button danger onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export type GeneralAccountEditModalProps = {
  id?: string
  data?: any
} & EditModalProps

export interface EditModalProps {
  onSave?: () => void
  onClose?: () => void
}
