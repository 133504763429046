import React, { useEffect } from 'react'
import ProductAddFeature from 'features/product/product-add/product-add.feature'
import { ProductCategoriesStore } from 'store/product-categories.store'
import { observer } from 'mobx-react-lite'
import { SalePointsStore } from 'store/sale-points.store'
import { CommonPage } from 'shared/layout/CommonPage'

interface ProductAddPageProps {}
const ProductAddPage: React.FC<ProductAddPageProps> = observer(() => {
  useEffect(() => {
    SalePointsStore.load()
    ProductCategoriesStore.load()
  }, [])

  return (
    <CommonPage title="Product Add Page ">
      <ProductAddFeature />
    </CommonPage>
  )
})
export default ProductAddPage
