import React, { useState } from 'react'
import { Button } from 'shared/components'
import { GeneralAccountEditModal, GeneralAccountEditModalProps } from './modal'

export function GeneralAccountEditModalTrigger({
  onClose,
  small,
  text,
  ...props
}: GeneralAccountEditModalProps & ModalTriggerProps) {
  const [show, setShow] = useState<boolean>(false)
  return (
    <>
      {show && (
        <GeneralAccountEditModal
          onClose={() => {
            setShow(false)
            return onClose && onClose()
          }}
          {...props}
        />
      )}
      <Button small={small} primary onClick={() => setShow(!show)}>
        {text ?? `${props.id ? 'Edit' : 'Add'} General Account`}
      </Button>
    </>
  )
}

interface ModalTriggerProps {
  small?: boolean
  text?: string
}
