import React from 'react'
import {
  Image,
  ImageBody,
  ImageButton,
  ImageContainer,
  ImageWrapper,
} from 'shared/components/image-container'
import SignaturePadFeature from 'features/signature/signature-pad.feature'
import { Button } from 'shared/components'
import { CommonStepRegistrationProps } from './types'

interface StepSignatureProps extends CommonStepRegistrationProps {
  signature: string
  handleSignature: (signature: string) => void
  resetSignature: () => void
}

export function StepSignature({
  signature,
  handleSignature,
  resetSignature,
  handleNext,
}: StepSignatureProps) {
  return (
    <>
      {!signature && <SignaturePadFeature setSignature={handleSignature} />}
      {signature && (
        <ImageContainer>
          <ImageBody>
            <ImageWrapper>
              <Image src={signature} alt="" />
            </ImageWrapper>
          </ImageBody>
          <ImageButton>
            <Button
              large
              square
              onClick={() => {
                resetSignature()
              }}
            >
              Reset Signature
            </Button>
            {handleNext && (
              <Button large primary square onClick={() => handleNext && handleNext()}>
                Next
              </Button>
            )}
          </ImageButton>
        </ImageContainer>
      )}
    </>
  )
}
