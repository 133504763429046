import { makeAutoObservable } from 'mobx'
import { Effect } from './utils/Effect'
import { RequestHelper } from './utils/RequestHelper'
import ApiClient from '../api'
import { OwnerCreateDto } from '../api-client'

export type ImportMembersRequest = {
  File: File
  AreaId: string
}

export class OwnerStoreClass {
  constructor() {
    makeAutoObservable(this)
  }

  private createOwnerEffect = new Effect(
    (dto: OwnerCreateDto) =>
      RequestHelper.unwrapFromFetchResponse(ApiClient().owners.postApiV1Owners(dto), 'Created.'),
    'Creating new Owner.'
  )

  public createOwner = async (dto: OwnerCreateDto) => {
    const response = await this.createOwnerEffect.run(dto)
    return response
  }

  private importMembersEffect = new Effect(
    (request: ImportMembersRequest) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().members.postApiV1MembersImportSmart(request),
        'Upload successfully done.'
      ),
    'Importing Members, please wait.'
  )

  public importMembers = async (request: ImportMembersRequest) => {
    const response = await this.importMembersEffect.run(request)
    return response
  }
}

export const OwnerStore = new OwnerStoreClass()
