import { action, makeObservable, observable } from 'mobx'

export abstract class ViewModelClass {
  @observable loading = true

  @action startLoading = () => {
    this.loading = true
  }

  @action stopLoading = () => {
    this.loading = false
  }

  constructor() {
    makeObservable(this)
  }
}
