import { ViewModelClass } from "store/utils/ViewModel";
import { WarehouseRevisionsStore } from "store/warehouse-revisions.store";

class WarehouseDeviationsViewModelClass extends ViewModelClass {

    init = async (from: string, to: string) => {
        this.startLoading()
        await WarehouseRevisionsStore.getDeviations(from, to)
        this.stopLoading()
    }
}

export const WarehouseDeviationsViewModel = new WarehouseDeviationsViewModelClass()