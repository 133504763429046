import React, { useEffect, useState } from 'react'
import { CheckoutProductType } from 'shared/models/product.types'
import { observer } from 'mobx-react-lite'
import { CashierStore } from 'store/cashier.store'
import { CheckoutStore } from 'store/checkout.store'
import { MemberStore } from 'store/member.store'
import { MembersStore } from 'store/members.store'
import { BalanceStore } from 'store/balance.store'
import { FinancesStore } from 'store/finances.store'
import { ApiStore } from 'store/api.store'
import { Button } from 'shared/components'
import ModalCommon from 'shared/components/modal/ModalCommon'
import { CommonMessage } from 'shared/components/messages/common-message'
import { Divider } from 'shared/components/divider/Divider'
import { CheckoutProductsList } from 'features/cashier/checkout/checkout-products-list.component'
import { ConfirmCheckoutModal } from 'features/cashier/checkout/checkout-modal/confirm-checkout-modal'
import { Discount } from 'features/cashier/checkout/discount/discount'
import { CheckoutMemberMainFeature } from './checkout-member/checkout-member-main.feature'
import './checkout.feature.scss'
import { CheckoutCurrentOrderModal } from './checkout-current-order/checkout-current-order.modal'

interface CheckoutFeatureProps {
  products: CheckoutProductType
  total: number
  resetProducts?: () => void
}

const CheckoutFeature: React.FC<CheckoutFeatureProps> = observer(
  ({ products, total, resetProducts }) => {
    const [showCheckoutModal, setShowCheckoutModal] = useState<boolean>(false)
    const [showCheckoutResultModal, setShowCheckoutResultModal] = useState<boolean>(false)
    const [showCheckInModal, setShowCheckInModal] = useState<boolean>(false)
    const [totals, setTotals] = useState<number>(0)
    const [subTotals, setSubTotals] = useState<number>(0)

    const negativeTotal = totals < 0

    const isCheckoutDissalowed = negativeTotal || !CheckoutStore.chosenMember

    const checkInAndProceed = async () => {
      const checkedIn = await MemberStore.checkInMember(CheckoutStore.chosenMember?.id, true)
      setShowCheckInModal(false)
      if (checkedIn) {
        CheckoutStore.checkout(CheckoutStore.chosenMember?.id).then(() => {
          setShowCheckoutModal(false)
          resetProducts?.()
        })
      }
    }

    const { discount, setDiscount } = CashierStore

    useEffect(() => {
      if (Object.values(products).length > 0) {
        FinancesStore.getTotals(products).then((data) => {
          if (data) {
            setTotals(data.totals!)
            setSubTotals(data.subTotals!)
          }
        })
      }
    }, [products, discount])

    return (
      <>
        {showCheckInModal && (
          <ModalCommon
            onClose={() => setShowCheckInModal(false)}
            title="Not Checked In Member"
            body={<h2>This Member is not Checked In. Please Check In first. </h2>}
            actions={
              <>
                <Button primary onClick={checkInAndProceed}>
                  Check In and Charge
                </Button>
                <Button danger onClick={() => setShowCheckInModal(false)}>
                  Cancel
                </Button>
              </>
            }
          />
        )}

        {showCheckoutModal && (
          <ConfirmCheckoutModal
            total={CashierStore.total}
            member={CheckoutStore.chosenMember}
            balance={BalanceStore.currentBalance}
            onChooseMember={CheckoutStore.chooseMember}
            onApprove={() => {
              if (CheckoutStore.chosenMember?.isCheckedIn) {
                return CheckoutStore.checkout(CheckoutStore.chosenMember?.id)
                  .then((data) => {
                    if (!data) return
                    setShowCheckoutModal(false)
                    resetProducts?.()
                    setShowCheckoutResultModal(true)
                  })
                  .catch(() => {
                    setShowCheckoutResultModal(false)
                    return false
                  })
              }
              setShowCheckInModal(true)

              return false
            }}
            onClose={() => {
              setShowCheckoutModal(false)
              ApiStore.setMessage('')
            }}
            onReset={CheckoutStore.resetMember}
            loading={
              CheckoutStore.loading || BalanceStore.loading || MembersStore.loadStatus.isLoading
            }
            error={CheckoutStore.error}
          />
        )}

        {showCheckoutResultModal && (
          <CheckoutCurrentOrderModal
            onClose={() => {
              setShowCheckoutResultModal(false)
            }}
          />
        )}

        <div className="checkout">
          <div className="checkout__header">
            <CheckoutMemberMainFeature />
          </div>
          <div className="checkout__body">
            <div className="checkout__body-holder">
              {products && <CheckoutProductsList products={products} />}
            </div>
          </div>

          {Object.keys(products)?.length > 0 && (
            <div className="checkout__footer">
              <div className="checkout__discount">
                <Discount defaultDiscount={discount} total={subTotals} setDiscount={setDiscount} />
              </div>
              <div className="checkout__total">
                <h3>Total price:</h3>
                <h2>{totals} credits</h2>
              </div>

              <Button
                disabled={isCheckoutDissalowed}
                block
                primary
                onClick={() => {
                  setShowCheckoutModal(true)
                }}
              >
                Checkout
              </Button>
              {isCheckoutDissalowed && (
                <div>
                  <Divider />
                  {negativeTotal && <CommonMessage title="Warning: Negative total" warning />}
                </div>
              )}
            </div>
          )}
        </div>
      </>
    )
  }
)

export default CheckoutFeature
