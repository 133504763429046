import React from 'react'
import { observer } from 'mobx-react-lite'
import { Table } from 'antd'
import { AreasStore } from 'store/areas.store'
import { UserStore } from 'store/user.store'
import { getAreaListColumns } from './area-list-columns'

export const AreaListFeature = observer(() => {
  const userId = UserStore.userKeycloakId

  return (
    <div>
      <Table
        columns={getAreaListColumns(userId)}
        dataSource={AreasStore.areasSpliced}
        rowKey="id"
      />
    </div>
  )
})
