import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { WarehouseItemReadShortDto } from '../../api-client'
import CashierProductItem from './cashier-product-item'

const CashierListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
interface CashierProductListProps {
  products?: Array<WarehouseItemReadShortDto> | null
}

export const CashierProductList: React.FC<CashierProductListProps> = observer(({ products }) => {
  const list =
    products &&
    products.map((item) => <CashierProductItem key={`cashier-list_${item.id}`} product={item} />)

  return <CashierListContainer>{list}</CashierListContainer>
})
