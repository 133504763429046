import { Rule } from 'rc-field-form/es/interface'

import { DATE_FORMAT } from './constants'
import { parseDate } from './parser'

const dateFormatRegExp = /^((0?[1-9]|1[012])[-](0?[1-9]|[12][0-9]|3[01])[-](19|20)?[0-9]{2})*$/

export function createDateFormatValidator(): Rule {
  return {
    validator(rule, value) {
      if (value) {
        if (!dateFormatRegExp.test(value)) {
          return Promise.reject(
            new Error(`Date format is incorrect, required format: ${DATE_FORMAT}.`)
          )
        }

        const date = parseDate(value)
        if (date === null) {
          return Promise.reject(new Error(`Date is incorrect.`))
        }
      }
      return Promise.resolve(value)
    },
  }
}

export function createNoFutureDateValidator(): Rule {
  return {
    validator: (rule, value) => {
      const now = Date.now()
      const inputDate = parseDate(value)
      if (inputDate !== null && inputDate.getTime() > now) {
        return Promise.reject(new Error('Please do not use the date from the future.'))
      }

      return Promise.resolve(value)
    },
  }
}

export function createValidEmailRule(message = ''): Rule {
  return {
    message,
    pattern:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  }
}

export const createRequiredRule = (message?: string) => ({
  message,
  required: true,
})

export function createFacilityCodeRule(): Rule {
  return {
    pattern: /^\d{4}-\d{4}-\d{4}$/,
    message: 'Facility authorization code does not match pattern xxxx-xxxx-xxxx',
  }
}
