import React from 'react'
import { Form, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { CommonFormProps } from 'shared/components/form/types'
import { CreateAreaDto } from 'api-client'

interface AreaAddFormProps extends CommonFormProps<CreateAreaDto> {}

export function AreaAddForm({ formData, setFormData }: AreaAddFormProps) {
  return (
    <Form
      initialValues={formData}
      labelCol={{
        xs: 24,
        sm: 6,
      }}
      onValuesChange={(changed: any, values: any) => {
        setFormData({
          ...values,
          ...changed,
        })
      }}
    >
      <Form.Item
        label="Area Title"
        name="title"
        rules={[
          {
            required: true,
            message: 'Enter your social area title',
          },
        ]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item label="Address" name="address">
        <TextArea />
      </Form.Item>
    </Form>
  )
}
