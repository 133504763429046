import React from 'react'
import { NavigationMenu, RoutePageElement } from 'router/NavigationMenu'
import { getPagePath } from 'router/utils'

const basePath = 'dispensary'
const pages: RoutePageElement[] = [
  {
    path: getPagePath(`${basePath}/search`),
    title: 'Members',
    icon: 'search-user',
  },
  {
    path: getPagePath(`${basePath}/cashier`),
    title: 'Cashier',
    icon: 'shopping-cart',
    items: [
      {
        path: getPagePath(`${basePath}/cashier/orders`),
        title: 'Orders',
      },
    ],
  },
  {
    path: getPagePath(`${basePath}/warehouse`),
    title: 'Warehouse',
    icon: 'filter-money',
    items: [
      {
        path: getPagePath(`${basePath}/warehouse/closed`),
        title: 'Closed Stock',
        icon: 'file-zip',
      },
      {
        path: getPagePath(`${basePath}/products/list`),
        title: 'Products',
        icon: 'file-zip',
      },
      {
        path: getPagePath(`${basePath}/product-categories/list`),
        title: 'Categories',
        icon: 'file-zip',
      },
      {
        path: getPagePath(`${basePath}/sale-points/list`),
        title: 'Sale Points',
        icon: 'file-zip',
      },
      {
        path: getPagePath(`${basePath}/warehouse-revisions`),
        title: 'Revisions',
        icon: 'reports',
      },
    ],
  },
]

// eslint-disable-next-line react/function-component-definition
export const DispensaryNavigationMenu: React.FC = () => (
  <NavigationMenu elements={pages} theme="area-admin" />
)
