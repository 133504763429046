import React, { useCallback, useEffect, useState } from 'react'
import './discount.scss'
import { divide, multiply } from 'shared/utils/math'
import { getNumberDelimiterAgnostic, getStringDelimiterAgnostic } from 'shared/utils/number'

interface DiscountProps {
  total: number
  setDiscount: (discount: number) => void
  defaultDiscount?: number | null
}

export function Discount({ total, setDiscount, defaultDiscount }: DiscountProps) {
  const [discountPercent, setDiscountPercent] = useState<string>(defaultDiscount?.toString() || '0')
  const [discountNumber, setDiscountNumber] = useState<string>('0')

  const getDiscountPercent = (d: number) => divide(multiply(100, d), total)

  const setPercent = useCallback(
    (percent: string) => {
        const getDiscountNumber = (d: number) => divide(multiply(total, d), 100)
      const percentNumber = parseFloat(percent)
      const discount = getDiscountNumber(percentNumber)
      if (discount <= total) {
        setDiscountNumber(discount.toString())
        setDiscountPercent(percent.toString())
      }
    },
    [total]
  )

  const setNumber = (n: string) => {
    const dp = getDiscountPercent(parseFloat(n))
    if (dp <= 100) {
      setDiscountPercent(dp.toString())
      setDiscountNumber(n)
    }
  }

  useEffect(() => {
    setPercent(defaultDiscount?.toString() || '0')
  }, [defaultDiscount, setPercent, total])

  return (
    <div className="app-discount">
      Discount:
      <div className="app-discount-item">
        <input
          value={discountNumber}
          onChange={(e) => setNumber(getStringDelimiterAgnostic(e.target.value))}
          onBlur={(e) => {
            const dp = getDiscountPercent(getNumberDelimiterAgnostic(e.target.value))
            setDiscount(dp)
          }}
        />
        <span className="app-discount-item__label">Cred.</span>
      </div>
      <div className="app-discount-item">
        <input
          value={discountPercent.toString()}
          onChange={(e) => setPercent(getStringDelimiterAgnostic(e.target.value))}
          onBlur={(e) => {
            setDiscount(getNumberDelimiterAgnostic(e.target.value))
          }}
        />
        <span className="app-discount-item__label">%</span>
      </div>
    </div>
  )
}
