import React, { useState } from 'react'
import { Button } from 'shared/components'
import { AreasStore } from 'store/areas.store'
import { CreateAreaDto } from 'api-client'
import { AreaAddForm } from '../shared/area-add.form'

interface AreaEditFeatureProps {
  onSuccess?: () => void
}

export function AreaAddFeature({ onSuccess }: AreaEditFeatureProps) {
  const [formData, setFormData] = useState<CreateAreaDto>({
    title: '',
    address: '',
  })

  const submit = () => {
    AreasStore.createArea(formData).then((res) => {
      if (onSuccess) onSuccess()
    })
  }

  return (
    <>
      <AreaAddForm formData={formData} setFormData={setFormData} />
      <Button
        onClick={() => {
          submit()
        }}
      >
        Add
      </Button>
    </>
  )
}
