import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Table } from 'antd'
import { GeneralAccountStore } from '../store'
import { columns } from './columns'

export const GeneralAccountListFeature = observer(() => {
  useEffect(() => {
    GeneralAccountStore.loadGeneralAccounts()
  }, [])

  return (
    <div>
      <Table columns={columns} dataSource={GeneralAccountStore.generalAccountsSliced} rowKey="id" />
    </div>
  )
})
