import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Button } from 'shared/components'
import { ProductCategoriesStore } from 'store/product-categories.store'
import ProductCategoryAddForm from './product-category-add.form'
import { ReadProductCategoryDto } from '../../api-client'

const ProductCategoryEditFeature = observer(({ id, onSuccess }: any) => {
  const [formData, setFormData] = useState<ReadProductCategoryDto>(ProductCategoriesStore.category)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    ProductCategoriesStore.loadCategoryById(id).then(() => {
      setFormData(ProductCategoriesStore.category)
      setIsLoading(false)
    })
  }, [id])

  useEffect(() => {
    if (!id) {
      setFormData({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const update = () =>
    formData &&
    ProductCategoriesStore.updateCategory(id, formData).then(() => onSuccess && onSuccess())

  return (
    <div>
      {!isLoading && formData && (
        <>
          <ProductCategoryAddForm setFormData={setFormData} formData={ProductCategoriesStore.category} />
          <Button primary onClick={update}>
            Update
          </Button>
        </>
      )}
    </div>
  )
})

export default ProductCategoryEditFeature
