import React from 'react'
import { Col, Popconfirm, Row, Table } from 'antd'
import { observer } from 'mobx-react-lite'
import { PaymentStore } from 'store/payment.store'
import { ReportsStore } from 'store/reports.store'
import { MembersStore } from 'store/members.store'
import { Divider } from 'shared/components/divider/Divider'
import { DashboardContainer } from 'shared/components/dashboard/dashboard-container'
import { DashboardBlock } from 'shared/components/dashboard/dashboard-block'
import { DashboardList } from 'shared/components/dashboard/dashboard-list'
import { Button } from 'shared/components'
import { ordersColumns } from './orders-columns'
import { membersColumns } from './members-columns'

import {
  DashboardActions,
  DashboardHeader,
  DashboardHeaderAnnotation,
  DashboardHeaderPrimary,
  DashboardHeaderTitle,
  DashboardHeaderValue,
} from './styled'

interface DailyReportProps {
  from: string
  to: string
}

export const DailyReportFeature = observer(({ from, to }: DailyReportProps) => {
  const endTheDay = () => {
    MembersStore.kickMembersOff().then(() => {
      ReportsStore.loadVisits(from, to)
    })
  }

  return (
    <div>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={6}>
          <DashboardHeaderPrimary>
            <DashboardHeaderTitle>Total Donations</DashboardHeaderTitle>
            <DashboardHeaderValue>{PaymentStore.cashForToday}</DashboardHeaderValue>
            <DashboardHeaderAnnotation>Points</DashboardHeaderAnnotation>
          </DashboardHeaderPrimary>
        </Col>
        <Col xs={24} md={12} lg={6}>
          <DashboardHeader>
            <DashboardHeaderTitle>Total checked in</DashboardHeaderTitle>
            <DashboardHeaderValue>{ReportsStore.activeVisits}</DashboardHeaderValue>
            <DashboardHeaderAnnotation>People</DashboardHeaderAnnotation>
          </DashboardHeader>
        </Col>

        <Col xs={24} md={12} lg={6}>
          <DashboardHeader>
            <DashboardHeaderTitle>New members</DashboardHeaderTitle>
            <DashboardHeaderValue>{ReportsStore.todayRegistrationsCount}</DashboardHeaderValue>
            <DashboardHeaderAnnotation>for today</DashboardHeaderAnnotation>
          </DashboardHeader>
        </Col>

        <Col xs={24} md={12} lg={6}>
          <DashboardHeader>
            <DashboardHeaderTitle>Today Quotes</DashboardHeaderTitle>
            {Object.entries(ReportsStore.todayMemberQuotesByGroup).map((i) => (
              <DashboardHeaderAnnotation key={i[0]}>{`${i[0]} - ${i[1].length} x ${i[1][0]
                ?.price!}`}</DashboardHeaderAnnotation>
            ))}
            <DashboardHeaderTitle>
              <hr />
              Total: {ReportsStore.todayMemberQuoteSum}
            </DashboardHeaderTitle>
          </DashboardHeader>
        </Col>
      </Row>

      <Divider big />

      <Row gutter={24}>
        <Col xs={24}>
          <DashboardContainer>
            <DashboardBlock
              title={`Today Visits (${ReportsStore.totalUniqueVisitsCount})`}
              actions={
                <DashboardActions>
                  <Popconfirm
                    title="Are you sure you want to close the Day??"
                    onConfirm={() => endTheDay()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button danger>End the day</Button>
                  </Popconfirm>
                </DashboardActions>
              }
              primary
            >
              <Table
                pagination={{
                  pageSize: 15,
                  hideOnSinglePage: true,
                  position: ['bottomCenter'],
                }}
                rowKey="id"
                columns={membersColumns}
                dataSource={ReportsStore.visits}
              />
            </DashboardBlock>
          </DashboardContainer>
        </Col>
      </Row>
      <Divider big />
      <Row gutter={24}>
        <Col xs={24}>
          <DashboardContainer>
            <DashboardBlock title="Today Quotes" primary>
              <DashboardList
                loading={false}
                rowKey="id"
                columns={ordersColumns}
                data={ReportsStore.todayMemberQuoteJournal}
              />
            </DashboardBlock>
          </DashboardContainer>
        </Col>
      </Row>
    </div>
  )
})
