import React from 'react'
import { observer } from 'mobx-react-lite'
import { ManagerRoutes } from 'modules/manager/router/ManagerRoutes'
import { AreaOwnerRoutes } from 'modules/owner/router/OwnerRoutes'
import { DispensaryRoutes } from 'modules/dispensary/router/DispensaryRoutes'
import { Roles } from 'auth/Roles'
import { RoleProtectedRoute } from './RoleProtectedRoute'
import { privateWebAppPath } from './constants'

interface RoleBasedRoutesProps {
  redirectUrl?: string
}

/* eslint-disable jsx-a11y/aria-role */
export const RoleBasedRoutes: React.FC<RoleBasedRoutesProps> = observer(({ redirectUrl }) => (
  <>
    <RoleProtectedRoute
      redirectUrl={redirectUrl}
      role={[Roles.MANAGER]}
      path={`${privateWebAppPath}/manager`}
    >
      <ManagerRoutes />
    </RoleProtectedRoute>

    <RoleProtectedRoute
      redirectUrl={redirectUrl}
      role={[Roles.OWNER]}
      path={`${privateWebAppPath}/owner`}
    >
      <AreaOwnerRoutes />
    </RoleProtectedRoute>

    <RoleProtectedRoute
      redirectUrl={redirectUrl}
      role={[Roles.DISPENSARY]}
      path={`${privateWebAppPath}/dispensary`}
    >
      <DispensaryRoutes />
    </RoleProtectedRoute>
  </>
))

export default RoleBasedRoutes
