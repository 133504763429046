import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from 'shared/components'
import { MemberQuoteStore } from 'store/member-quote.store'
import { PaymentStore } from 'store/payment.store'
import PayMemberQuoteModal from './pay-member-quote.modal'

const Container = styled.div`
    width: 100%;
`

type RenewMemberQuoteTriggerProps = {
  memberId: string
}

function RenewMemberQuoteTrigger({ memberId }: RenewMemberQuoteTriggerProps) {
  const [show, setShow] = useState<boolean>(false)
  useEffect(() => {
    MemberQuoteStore.load()
  }, [])
  return (
    <Container>
      <Button
        block
        primary
        onClick={() => {
          setShow(true)
        }}
      >
        Renew Quote
      </Button>
      {show && (
        <PayMemberQuoteModal
          onSubmit={PaymentStore.renewMemberQuote}
          memberId={memberId}
          onClose={() => setShow(false)}
        />
      )}
    </Container>
  )
}

export default RenewMemberQuoteTrigger
