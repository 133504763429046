import React from 'react'
import { CommonPage } from 'shared/layout/CommonPage'
import { ManagerAddFeature } from 'features/manager'
import { useHistory } from 'react-router'
import { privateWebAppPath } from '../../../../router/constants'

export function ManagerAddPage() {
  const history = useHistory()
  return (
    <CommonPage title="Add a new Manager">
      <ManagerAddFeature
        onSuccess={() => {
          history.push(`${privateWebAppPath}/owner/managers`)
        }}
      />
    </CommonPage>
  )
}
