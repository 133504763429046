import React from 'react'
import { MemberPhoto } from 'shared/components/member/member-photo/member-photo'
import './member-profile-short.scss'
import { Col, Row } from 'antd'
import { MemberInfoShort } from 'features/member/member-info/member-info-short'
import { ReadMemberDto } from 'api-client'

interface MemberProfileShortProps {
  member?: ReadMemberDto | null
  balance?: number
  total?: number
}

export function MemberProfileShort({ member, balance, total }: MemberProfileShortProps) {
  return (
    <div className="member-profile-short">
      <Row gutter={30}>
        <Col xs={24} md={12}>
          <div className="member-profile-short__photo">
            <MemberPhoto width="100%" src={member?.photo} />
          </div>
        </Col>
        <Col xs={24} md={12}>
          {member && <MemberInfoShort member={member} balance={balance} total={total} />}
        </Col>
      </Row>
    </div>
  )
}
