import { AreaAddFeature } from 'features/area/area-edit';
import React from 'react';
import { useHistory } from 'react-router';
import { privateWebAppPath } from 'router/constants';
import { Divider } from 'shared/components/divider/Divider';

export function OnbordingStep1Feature() {
    const history = useHistory();
  return (
    <div>
      <h1>Please add your first Area</h1>
      <Divider big />
      <AreaAddFeature
        onSuccess={() => {
          history.push(`${privateWebAppPath}/owner/areas`)
        }}
      />
    </div>
  )
}