import React, { ReactNode } from 'react'
import styled from 'styled-components'

const Container = styled.div<DashboardBlockProps>`
  background: #fff;
  border-radius: 4px;

  ${({ primary }) =>
    primary &&
    `
    flex: 1;
  `};
`
const Body = styled.div`
  border: solid 1px #dcdcdc;
  border-radius: 4px;
`
const Head = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`
const Title = styled.div`
  background: #fff;
  font-size: 24px;
  font-weight: 300;
`
const Actions = styled.div`
  margin-left: auto;
`

interface DashboardBlockProps {
  title?: string
  primary?: boolean
  children?: any
  actions?: ReactNode
}

export function DashboardBlock({ children, title, primary, actions }: DashboardBlockProps) {
  return (
    <Container primary={primary}>
      {title && (
        <Head>
          <Title>{title}</Title>
          {actions && <Actions>{actions}</Actions>}
        </Head>
      )}
      <Body>{children}</Body>
    </Container>
  )
}
