import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { RevisionsListFeature } from 'features/warehouse/revision/revisions-list.feature'
import { CommonPage } from 'shared/layout/CommonPage'
import { Preloader } from 'shared/components/preloader'
import { StartRevisionFeature } from 'features/warehouse/revision/start-revision.feature'
import { WarehouseRevisionsViewModel } from './warehouse-revisions.view-model'

export const WarehouseRevisionPage = observer(() => {
  useEffect(() => {
    WarehouseRevisionsViewModel.init()
  }, [])
  return WarehouseRevisionsViewModel.loading ? (
    <Preloader message="Loading Revisions Data." lower />
  ) : (
    <CommonPage
      title="Revisions"
      actions={<StartRevisionFeature />}
      isMounted={!WarehouseRevisionsViewModel.loading}
    >
      <RevisionsListFeature />
    </CommonPage>
  )
})
