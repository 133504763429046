import React from 'react'
import { Table } from 'antd'
import { MembersStore } from 'store/members.store'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router'
import './member-list.scss'
import { getPagePath } from 'router/utils'
import BadgeStatus from 'shared/components/badge-status/BadgeStatus'
import { Link } from 'react-router-dom'

const MembersListFeature = observer(() => {
  const history = useHistory()
  const editItem = (id: string) => history.push(getPagePath(`manager/members/edit/${id}`))

  const columns: any = [
    {
      title: 'Full Name',
      render: (item: any) => (
        <Link replace to={`members/${item.id}`} className="table-link">
          {`${item.firstName} ${item.lastName}`}
        </Link>
      ),
    },
    {
      title: 'NFC ID',
      dataIndex: 'nfcKey',
      sorter: (a: any, b: any) => a.nfcKey.length - b.nfcKey.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Checked In',
      dataIndex: 'isCheckedIn',
      render: (item: boolean) => (
        <div>
          {item ? (
            <BadgeStatus status="success">Yes</BadgeStatus>
          ) : (
            <BadgeStatus status="failure">No</BadgeStatus>
          )}
        </div>
      ),
    },
    {
      title: 'Edit',
      dataIndex: 'id',
      key: 'x',
      render: (item: string) => (
        <span
          tabIndex={-1}
          role="button"
          onKeyDown={() => editItem(item)}
          onClick={() => editItem(item)}
          className="table-link"
        >
          Edit
        </span>
      ),
    },
  ]

  return (
    <div>
      <Table
        pagination={{
          pageSize: 50,
        }}
        columns={columns}
        dataSource={MembersStore.filteredData}
        rowKey="id"
      />
    </div>
  )
})
export default MembersListFeature
