import React from 'react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { ReadWarehouseJournalDto } from 'api-client'

interface Props {
  columns: ColumnsType<ReadWarehouseJournalDto>
  data?: Array<any> | null
}

export function WarehouseJournalListFeature({ data, columns }: Props) {
  return data ? (
    <Table
      rowClassName={(record, index, indent) =>
        record?.isClosed ? 'app-table-row_closed' : 'app-table-row_opened'
      }
      columns={columns}
      dataSource={data}
      pagination={false}
    />
  ) : null
}
