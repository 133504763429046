import React, { useEffect, useState } from 'react'
import * as R from 'ramda'
import { observer } from 'mobx-react-lite'
import { SalePointsStore } from 'store/sale-points.store'
import { type ReadSalePointDto } from 'api-client'
import { LabeledCheckbox } from 'shared/components/form/checkbox/LabeledCheckbox'
import styled from 'styled-components'
import { WarehouseStore } from "../../../store/warehouse.store";

const Container = styled.div`
  display: flex;
`

const CheckBox = styled.div`
  margin-left: 12px;
`

export const SalePointCheckerFeature = observer(() => {
  const { salePoints, setSelectedIds } = SalePointsStore

  const [selected, setSelected] = useState<Record<string, boolean>>({})

  const onChange = (id: string) => {
    const newSelected = { ...selected }
    newSelected[id] = !newSelected[id]
    setSelected(newSelected)

    const s = R.pipe(
      R.filter((i) => i !== false),
      R.keys
    )(newSelected)
    setSelectedIds(s as Array<string>)
    WarehouseStore.setFilterSalePoint(s as Array<string>)
  }

  useEffect(() => {
    SalePointsStore.load()
  }, [])

  useEffect(() => {
    const newSelected = R.pipe(
      R.map((i: ReadSalePointDto) => ({
        [i.id as string]: false,
      })),
      R.mergeAll<Record<string, boolean>>
    )(salePoints)

    setSelected(newSelected)
    // WarehouseStore.setFilterSalePoint(salePoints.map((i) => i.id!) as Array<string>)
  }, [salePoints])

  return (
    <Container>
      {salePoints.map((i, index) => (
        <CheckBox key={i.id}>
          <LabeledCheckbox
            label={i.title!}
            checked={selected[i.id!]}
            onChange={() => onChange(i.id!)}
            id={i.id}
          />
        </CheckBox>
      ))}
    </Container>
  )
})
