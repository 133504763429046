import React, { ReactNode } from 'react'
import './LayoutSidebar.scss'

interface LayoutSidebarProps {
  children?: ReactNode
}

export function LayoutSidebar(props: LayoutSidebarProps) {
  const { children } = props

  return <div className="app-sidebar">{children}</div>
}
