import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { ReportsStore } from 'store/reports.store'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { getUiDate } from 'shared/dates/formatters'

export const MonthMoneyIncomeFeature = observer(() => {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

  useEffect(() => {
    ReportsStore.loadIncomeForMonth(
      moment.utc().startOf('month').toISOString(),
      moment.utc().endOf('day').toISOString()
    )
  }, [])
  return (
    <div>
      <div>
        <Bar
          data={{
            labels: ReportsStore.incomeForMonth.map((i) => getUiDate(i.day)),
            datasets: [
              {
                label: 'Donations',
                backgroundColor: '#039965',
                data: ReportsStore.incomeForMonthDataSet,
              },
            ],
          }}
          options={{
            responsive: true,
          }}
        />
      </div>
    </div>
  )
})
