export const getNumberDelimiterAgnostic = (value: string) =>
    parseFloat(value.replace(',', '.'))

    export const getStringDelimiterAgnostic = (value: string) =>
    value.replace(',', '.')


export const isOnlyNumbers = (value: string) => {
    const regex = /^\d+$/
    return regex.test(value)
}