import { action, computed, makeAutoObservable, observable } from 'mobx'
import * as R from 'ramda'
import { ReadProductCategoryDto, ReadProductDto, UpdateProductDto } from 'api-client'
import ApiClient from '../api'
import { AreasStore } from './areas.store'
import { Effect } from './utils/Effect'
import { RequestHelper } from './utils/RequestHelper'

export class ProductsStoreClass {
  constructor() {
    makeAutoObservable(this)
  }

  @observable error: any = null

  @observable searchQuery: string = ''

  @action setSearchQuery = (query: string) => {
    this.searchQuery = query?.replace(/\s+/g, '').toLowerCase()
  }

  @observable productCategories?: Array<ReadProductCategoryDto>

  loadEffect = new Effect(() =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().products.getApiV1ProductsByArea(AreasStore.selectedAreaId)
    ),
    '',
    true
  )

  load = () => this.loadEffect.run()

  createProductEffect = new Effect((request) =>
    RequestHelper.unwrapFromFetchResponse(ApiClient().products.postApiV1Products(request))
  )

  @action createProduct = async (data: any) => {
    const response = await this.createProductEffect.run({
      ...data,
      categoriesIds: [data.categoryId],
      areaId: AreasStore.selectedAreaId,
    })
    if (response) {
      this.loadEffect.data = [...(this.loadEffect.data ? this.loadEffect.data : []), response]
    }
  }

  updateProductEffect = new Effect((id, request: UpdateProductDto) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().products.putApiV1Products(id, request),
      'Product updated'
    )
  )

  @action updateProduct = async (data: UpdateProductDto) => {
    const response = await this.updateProductEffect.run(data.id, data)
    if (response) {
      this.loadProductEffect.data = response
    }
  }

  loadProductEffect = new Effect((id) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().products.getProductById(id),
      'Product updated'
    )
  )

  @action loadProduct = async (id: string) => {
    await this.loadProductEffect.run(id)
  }

  get product() {
    return this.loadProductEffect.data || {}
  }

  get products() {
    return this.loadEffect.data?.slice() || []
  }

  @computed get productsFiltered() {
    const filterByTitle = (item: ReadProductDto) => {
      if (!this.searchQuery) {
        return true
      }
      const query = this.searchQuery.replace(/\s+/g, '').toLowerCase()
      return item!.title!.toLowerCase().includes(query)
    }

    return R.pipe<any, any>(R.filter(filterByTitle))(this.products)
  }

  deleteProductEffect = new Effect((id) =>
    RequestHelper.unwrapFromFetchResponse(ApiClient().products.deleteApiV1Products(id))
  )

  @action deleteProduct = async (id: string) => {
    const response = await this.deleteProductEffect.run(id)
    if (response) {
      if (this.loadEffect.data) {
        this.loadEffect.data = this.loadEffect.data.filter((item) => item.id !== id)
      }
    }
  }

  @computed get hasProducts() {
    return !!this.loadEffect.data?.length
  }
}

export const ProductsStore = new ProductsStoreClass()
