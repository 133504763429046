import React from 'react'
import { Form, Input } from 'antd'
import { CommonFormProps } from 'shared/components/form/types'
import { createValidEmailRule } from 'shared/dates/validators'
import { CreateManagerDto } from 'api-client'

interface ManagerAddFormProps extends CommonFormProps<CreateManagerDto> {}

export function ManagerAddForm({ formData, setFormData }: ManagerAddFormProps) {
  return (
    <Form
      initialValues={formData}
      labelCol={{
        xs: 24,
        sm: 6,
      }}
      onValuesChange={(changed: any, values: any) => {
        setFormData({
          ...values,
          ...changed,
        })
      }}
    >
      <Form.Item
        label="First Name"
        name="firstName"
        rules={[
          {
            required: true,
            message: 'Enter first name',
          },
        ]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item
        label="Last Name"
        name="lastName"
        rules={[
          {
            required: true,
            message: 'Enter last name',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Username"
        name="username"
        rules={[
          {
            required: true,
            message: 'Enter last name',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'Enter email',
          },
          createValidEmailRule(),
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  )
}
