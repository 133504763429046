import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { ListSearch } from 'shared/components/listing/ListSearch'
import { CommonPage } from 'shared/layout/CommonPage'
import { Preloader } from 'shared/components/preloader'
import { MembersStore } from 'store/members.store'
import MembersListFeature from 'features/member/members-list/members-list.feature'
import './members-list.scss'
import { MemberListViewModel } from 'view-models/member-list.view-model'

const MembersListPage = observer(() => {
  useEffect(() => {
    MemberListViewModel.init()
  }, [])

  return (
    <div>
      {MemberListViewModel.loading ? (
        <Preloader lower message="Loading Members" />
      ) : (
        <CommonPage title="Members" isMounted={!MemberListViewModel.loading}>
          <ListSearch
            setValue={MembersStore.setFilterValue}
            value={MembersStore.filter}
            placeholder="Search by NFC Tag number, First or Last Name"
          />
          <div className="listing-hr" />
          <MembersListFeature />
        </CommonPage>
      )}
    </div>
  )
})

export default MembersListPage
