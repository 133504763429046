import React, { useState } from 'react'
import { AreasStore } from 'store/areas.store'
import { Button } from 'shared/components'
import { AreaResolverModal } from './area-resolver.modal'

function ChangeAreaTrigger({ title }: { title: string }) {
  const [show, setShow] = useState<boolean>(false)
  const options = AreasStore.commonAreas
  const canShow = !!options?.length && options.length > 1

  return (
    <>
      <Button
        primary
        small
        onClick={() => setShow(canShow)}
      >
        {title}
      </Button>
      {show && <AreaResolverModal onClose={() => setShow(false)} />}
    </>
  )
}

export default ChangeAreaTrigger
