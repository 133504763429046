import React from 'react'
import {
  ImageBody,
  ImageContainer,
  ImageWrapper,
  Image,
  ImageButton,
} from 'shared/components/image-container'
import GetPhotoFeature from 'features/camera/get-photo/get-photo.feature'
import { Button } from 'shared/components'
import { CommonStepRegistrationProps } from './types'

interface StepPhotoIdProps extends CommonStepRegistrationProps {
  photoDocument: string
  handlePhotoId: (photo: string) => void
  resetPhoto: () => void
}

export function StepPhotoId({
  photoDocument,
  handlePhotoId,
  resetPhoto,
  handleNext,
}: StepPhotoIdProps) {
  return (
    <>
      {!photoDocument && (
        <ImageContainer>
          <ImageBody>
            <GetPhotoFeature setPhoto={handlePhotoId} />
          </ImageBody>
        </ImageContainer>
      )}
      {photoDocument && (
        <ImageContainer>
          <ImageBody>
            <ImageWrapper>
              <Image src={photoDocument} alt="" />
            </ImageWrapper>
          </ImageBody>
          <ImageButton>
            <Button
              square
              onClick={() => {
                resetPhoto()
              }}
            >
              Reset Photo
            </Button>

            <Button large primary square onClick={() => handleNext && handleNext()}>
              Next
            </Button>
          </ImageButton>
        </ImageContainer>
      )}
    </>
  )
}
