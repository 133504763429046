import React from 'react'
import { ColumnsType } from 'antd/es/table'
import { AssignManagerTrigger } from 'features/manager/assign/assign-manager-trigger'
import { Link } from 'react-router-dom'
import { getPagePath } from 'router/utils'
import { ReadAreaDto } from 'api-client'

export const getAreaListColumns = (managerId: string): ColumnsType<ReadAreaDto> => [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    render: (item) => <Link to={`${getPagePath('owner/areas')}/${item}`}>{item}</Link>,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Attach',
    render: (item: ReadAreaDto) => <AssignManagerTrigger areaId={item.id} />,
  },
]
