import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { CashierFeature } from 'features/cashier/cashier.feature'
import { CommonPage } from 'shared/layout/CommonPage'
import { Divider } from 'shared/components/divider/Divider'
import './cashier.page.scss'
import { CashierViewModel } from 'view-models/cashier.view-model'
import { Preloader } from 'shared/components/preloader'

const Overlay = styled.div`
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 24px;
  text-align: center;
  background-color: rgb(255, 255, 255, 0.8);
`

const CashierPage = observer(() => {
  useEffect(() => {
    CashierViewModel.initCashier()
  }, [])

  return CashierViewModel.loading ? (
    <Preloader message="Initializing Cashier." lower />
  ) : (
    <CommonPage isMounted={!CashierViewModel.loading}>
      {CashierViewModel.hasActiveRevision && (
        <Overlay>
          <div>
            <div>You Have Revision In Progress.</div>
            <Divider />
            <div>Please either Apply or Cancel this revision to start using Cashier.</div>
          </div>
        </Overlay>
      )}
      <CashierFeature />
    </CommonPage>
  )
})
export default CashierPage
