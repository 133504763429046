import React from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { CommonPage } from 'shared/layout/CommonPage'
import ProductCategoryEditFeature from 'features/product-category/product-category-edit.feature'

interface ProductCategoryListPageProps {}

const ProductCategoryEditPage: React.FC<ProductCategoryListPageProps> = observer(() => {
  const { id } = useParams<{
    id: string
  }>()

  return (
    <CommonPage title="Product Category Edit">
      <span>{id}</span>
      <ProductCategoryEditFeature id={id} />
    </CommonPage>
  )
})
export default ProductCategoryEditPage
