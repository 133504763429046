import * as React from 'react'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { ReadRegistrationDto } from 'api-client'
import { RegistrationsStore } from 'store/registrations.store'
import { Listing } from 'shared/components/listing/Listing'
import { columns } from './registration-list.columns'

export const RegistrationList = observer(() => {
  useEffect(() => {
    RegistrationsStore.loadRegistrations()
  }, [])

  return (
    <div>
      <Listing<ReadRegistrationDto>
        data={RegistrationsStore!.registrations!}
        loading={RegistrationsStore.registrationsEffect.isLoading}
        columns={columns}
      />
    </div>
  )
})
