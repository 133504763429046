import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/components'

interface CheckoutButtonProps {
  showButton?: boolean
  checkOut: () => void
}

export const CheckoutButton = observer(({ showButton, checkOut }: CheckoutButtonProps) => (
  <div>
    {showButton && (
      <Button
        small
        danger
        onClick={() => {
          checkOut()
        }}
      >
        Check Out
      </Button>
    )}
  </div>
))
