import React from 'react'
import { useHistory } from 'react-router'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { getPagePathRoleBased } from 'router/utils'
import { WarehouseRevisionsStore } from 'store/warehouse-revisions.store'
import { getUiDate } from 'shared/dates/formatters'
import { Roles } from 'auth/Roles'
import { subs } from 'shared/utils/math'

const Item = styled.div`
  display: flex;
  border-bottom: solid 1px black;
  padding: 12px;
  transition: background-color 0.2s ease-in-out;
  background-color: #f4f4f4;
  cursor: pointer;
  &:hover {
    background-color: #e5e5e5;
  }
`

const Day = styled.div`
  border-top: solid 3px black;
  border-bottom: solid 3px black;
`

const DeviationCount = styled.div`
  margin: 0 24px;
  font-size: 18px;
  font-weight: 600;
`

export const DeviationListFeature = observer(() => {
  const history = useHistory()

  const goToRevision = (id: string) => {
    history.push(`${getPagePathRoleBased(`warehouse-revisions`, Roles.OWNER)}/${id}`)
  }

  return <div>
    {WarehouseRevisionsStore.deviationsTuple.map(([key, values]) => (
    <div key={key}>
      <h3>Date: <strong>{getUiDate(key)}</strong></h3>
      <Day>
        {values.map((item) => (
          <Item key={item.id} onClick={() => goToRevision(item.parentId!)}>
          <div>{item.productTitle}</div>
          <DeviationCount>Deviation: {subs(item.quantity!, item.expectedQuantity!)}</DeviationCount>
          <div>Assigner: {item.approvedByFullName}</div>
        </Item>
        ))}
      </Day>
    </div>
  ))}
  </div>
})

