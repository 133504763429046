import { PersonalData } from 'api-client'

type FormFields<T> = Array<[T, string]>

export interface MemberPersonalDataFormValues extends PersonalData {}

export type MemberPersonalDataFormField = keyof Omit<
  MemberPersonalDataFormValues,
  | 'sign'
  | 'photo'
  | 'photoDocument'
  | 'photoDocumentBack'
  | 'id'
  | 'dateCreated'
  | 'dateModified'
  | 'isDeleted'
  | 'username'
  | 'gender'
>

export const memberPersonalDataFormTitles: Record<MemberPersonalDataFormField, string> = {
  firstName: 'First Name',
  lastName: 'Last Name',
  documentNumber: 'Identification Document Number',
  email: 'Email address',
  birthDate: 'Date of birth',
  nationality: 'Nationality',
  address: 'Address',
}

export const memberPersonalDataFormFields: FormFields<MemberPersonalDataFormField> = Object.entries(
  memberPersonalDataFormTitles
) as FormFields<MemberPersonalDataFormField>

export const memberPersonalDataInitialData: MemberPersonalDataFormValues = {
  documentNumber: '',
  firstName: '',
  lastName: '',
  email: '',
  birthDate: '',
  nationality: '',
  address: '',
}

export const documentNumber = {
  type: 'string',
  title: 'Identification Document Number',
  initialState: '',
  name: 'documentNumber',
}
