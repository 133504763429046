import { AreasStore } from 'store/areas.store'
import { PaymentStore } from 'store/payment.store'
import { ReportsStore } from 'store/reports.store'
import { SalePointsStore } from 'store/sale-points.store'
import { ViewModelClass } from 'store/utils/ViewModel'
import { WarehouseStore } from 'store/warehouse.store'

class OwnerDashboardViewModelClass extends ViewModelClass {
  init = async (from: string, to: string) => {
    this.startLoading()
    await WarehouseStore.loadAll()
    await Promise.all([
      PaymentStore.getAllCashSum(from, to),
      ReportsStore.loadVisits(from, to),
      ReportsStore.getTodayMemberQuoteJournal(from, to),
      ReportsStore.getTodayRegistrations(from, to),
    ])

    WarehouseStore.setFilterStatus('OPENED')
    SalePointsStore.setSelectedIds([AreasStore.settings?.dispensaryDefaultSalePointId!])
    WarehouseStore.setFilterSalePoint([AreasStore.settings?.dispensaryDefaultSalePointId!])
    this.stopLoading()
  }
}

export const OwnerDashboardViewModel = new OwnerDashboardViewModelClass()
