import React from 'react'
import Icon from 'shared/components/icon'
import { ListingColumns } from 'shared/components/listing/Listing'
import { Link } from 'react-router-dom'
import Moment from 'moment'
import { ReadRegistrationDto } from '../../../api-client'

export const columns: ListingColumns<ReadRegistrationDto> = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Nationality',
    dataIndex: 'nationality',
  },
  {
    title: 'Address',
    dataIndex: 'address',
  },
  {
    title: 'Appointment Time',
    dataIndex: 'appointmentTime',
    render: (item) => <div>{Moment(item).format('DD MMM YYYY hh:mm')}</div>,
  },
  {
    title: 'Signed',
    dataIndex: 'sign',
    render: (item) =>
      !item ? <Icon name="cross" fill="#f00" /> : <Icon name="check" color="green" fill="green" />,
  },
  {
    title: 'Document Photo',
    dataIndex: 'photoDocument',
    render: (item) =>
      !item ? <Icon name="cross" fill="#f00" /> : <Icon name="check" color="green" fill="green" />,
  },
  {
    title: 'Member Photo',
    dataIndex: 'photo',
    render: (item) =>
      !item ? <Icon name="cross" fill="#f00" /> : <Icon name="check" color="green" fill="green" />,
  },
  {
    title: 'Actions',
    render: (item) => (
      <div>
        <Link to={`/app/manager/registrations/edit/${item.id}`}>Process</Link>
      </div>
    ),
  },
]
