import { makeAutoObservable } from 'mobx'
import { MembersStore } from 'store/members.store'

class MemberListViewModelClass {
  loading: boolean = false

  init = async () => {
    this.loading = true
    await MembersStore.load()
    this.loading = false
  }

  constructor() {
    makeAutoObservable(this)
  }
}

export const MemberListViewModel = new MemberListViewModelClass()
