import moment from 'moment'

export function getShortTime(date: string | null, zone = false) {
  if (date === null) {
    return null
  }

  let dateMoment
  if (zone) {
    dateMoment = moment.parseZone(date)
  } else {
    dateMoment = moment(date)
  }

  const hours = dateMoment.get('hours')
  const minutes: string | number = dateMoment.format('mm')
  const ending = hours > 12 ? 'pm' : 'am'
  const shortHours: number | string = hours > 12 ? hours - 12 : hours
  return `${shortHours}:${minutes} ${ending}`
}

export function addressToString(address?: any) {
  if (!address) {
    return ''
  }
  return `${address.city}, ${address.state} ${address.zip}`
}

export const colors = [
  'DARK_BLUE',
  'BLACK',
  'MUDDY',
  'BLUE',
  'ORANGE',
  'DARK_GRAY',
  'GRAY',
  'GREEN',
  'DARK_ORANGE',
]

export const avatarSubstitutionBackgroundColorMap: { [key in string]: string } = {
  DARK_BLUE: '#2C3377',
  BLACK: '#121B25',
  MUDDY: '#2E4457',
  BLUE: '#2699FB',
  ORANGE: '#F8BB7B',
  DARK_GRAY: '#D1D9DA',
  GRAY: '#D4EFFC',
  GREEN: '#00BE82',
  DARK_ORANGE: '#E6AF4A',
}

export const avatarSubstitutionColorMap: { [key in string]: string } = {
  DARK_BLUE: '#FFFFFF',
  BLACK: '#FFFFFF',
  MUDDY: '#FFFFFF',
  BLUE: '#FFFFFF',
  ORANGE: '#121B25',
  DARK_GRAY: '#121B25',
  GRAY: '#121B25',
  GREEN: '#121B25',
  DARK_ORANGE: '#121B25',
}

export const getHoursAndMinutesFromMinutes = (minutesCount: number): string => {
  if (!minutesCount) {
    return '0'
  }
  const hours = Math.round(minutesCount / 60)
  const minutes = minutesCount % 60
  return hours ? `${hours} h. ${minutes} m.` : `${minutes} m.`
}
