import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/components/modal'
import { Button } from 'shared/components'
import { OwnerStore } from 'store/owner.store'
import FileUploadFeature from '../../file-upload/file-upload.feature'

function ImportMembersModal({ onClose, areaId }: any) {
  const [file, setFile] = useState<any>()

  const upload = () => {
    OwnerStore.importMembers({
      File: file,
      AreaId: areaId,
    })
  }

  return (
    <Modal onClose={onClose}>
      <ModalHeader>Choose file to import</ModalHeader>
      <ModalBody>
        <FileUploadFeature setFile={setFile} />
      </ModalBody>
      <ModalFooter>
        <Button primary onClick={upload}>
          Upload
        </Button>
        <Button danger>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ImportMembersModal
