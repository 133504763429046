import React from 'react'
import { Col, Input, Row } from 'antd'
import { ReadMemberDto } from 'api-client'
import styled from 'styled-components'
import { PhotoContainer } from 'shared/components/photo-container/PhotoContainer'
import { Divider } from 'shared/components/divider/Divider'
import { subs } from 'shared/utils/math'
import { CashierStore } from 'store/cashier.store'
import { getPagePath } from 'router/utils'
import { calculateQuantitiesFromTotal } from '../calculate-quantities'

const NameBalance = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 21px;
`

const Photo = styled.div`
  min-width: 100px;
  width: 100%;
  min-height: 80px;
  padding-right: 12px;
`

const Name = styled.div`
  font-size: 21px;
  text-decoration: underline;
  cursor: pointer;
  color: #00b0ff;
`

const Balance = styled.div`
  font-size: 18px;
`

const BalanceInput = styled.div`
  display: flex;
  font-size: 18px;
`

const BalanceInputAll = styled.div``

const BalanceInputControl = styled.div`
  margin-left: 10px;
  width: 50px;

  input {
    padding: 0;
  }
`

const Discount = styled.div`
  font-size: 12px;
`
const FillBalanceLink = styled.span`
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`

const DividerHor = styled.div`
  width: 16px;
  text-align: center;
`

interface CheckoutMemberProfileProps {
  member: ReadMemberDto
  balance: number
  total?: number
  showAdditionalControls?: boolean
}

export function CheckoutMemberProfile({
  member,
  balance,
  total,
  showAdditionalControls,
}: CheckoutMemberProfileProps) {
  const fillTotal = async (t?: string) => {
    if (t === undefined) {
      return
    }
    if (parseFloat(t) <= 0) {
      return
    }
    const newCheckoutList = await calculateQuantitiesFromTotal(
      parseFloat(t),
      CashierStore.checkoutList,
      CashierStore.discount
    )
    if (newCheckoutList) {
      CashierStore.setCheckoutList(newCheckoutList)
    }
  }

  const goToMemberProfile = () => {
    window.open(getPagePath(`manager/members/${member.id}`), '_blank')
  }

  return (
    <div>
      <Row>
        <Col xs={10}>
          <Photo>
            <PhotoContainer minHeight={80} src={member.photo} />
          </Photo>
        </Col>
        <Col xs={14}>
          <NameBalance>
            <Name onClick={goToMemberProfile}>
              {member.firstName} {member.lastName}
            </Name>

            <Divider />

            {!!member?.defaultDiscount && (
              <Discount>Default Discount: {member.defaultDiscount}%</Discount>
            )}

            <Divider small />
            <Balance>Balance: {balance}</Balance>
          </NameBalance>

          {showAdditionalControls && (
            <>
              <Divider small />
              <BalanceInput>
                <BalanceInputAll>
                  <FillBalanceLink
                    onClick={() => {
                      fillTotal(balance.toString())
                    }}
                  >
                    Spend All
                  </FillBalanceLink>
                </BalanceInputAll>

                <DividerHor>|</DividerHor>

                <BalanceInputAll>
                  <FillBalanceLink
                    onClick={() => {
                      fillTotal(`${balance / 2}`)
                    }}
                  >
                    Half
                  </FillBalanceLink>
                </BalanceInputAll>

                <DividerHor>|</DividerHor>

                <BalanceInputControl>
                  <Input
                    type="text"
                    onBlur={(e) => {
                      fillTotal(e.target.value)
                    }}
                  />
                </BalanceInputControl>
              </BalanceInput>

              <Divider small />
              <Balance>Next Balance: {subs(balance, total || 0)}</Balance>
            </>
          )}
        </Col>
      </Row>
    </div>
  )
}
