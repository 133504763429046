import * as React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { Switch, Table } from 'antd'
import { observer } from 'mobx-react-lite'
import { ColumnsType } from 'antd/es/table'
import { ReadWarehouseItemDto } from 'api-client'
import { WarehouseStore } from 'store/warehouse.store'
import { getPagePathRoleBased } from 'router/utils'
import { Button } from 'shared/components'
import { UserStore } from 'store/user.store'
import { ListSearch } from 'shared/components/listing/ListSearch'
import { TabVirtual } from 'shared/components/tabs-virtual/tab-virtual'
import { getUiDate } from 'shared/dates/formatters'
import { sortByDate } from 'shared/utils/dates'
import { sortAlphabetically } from 'shared/utils/sortings'

const TabsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`

const CategoryTabs = styled.div`
  display: flex;
  flex: 1;
  align-content: center;
  align-items: center;
`

export const WarehouseListFeature = observer(() => {
  const history = useHistory()
  const changeIsListed = (item: ReadWarehouseItemDto, isListed: boolean) => {
    if (!item.id) return
    WarehouseStore.setIsListed(item!.id, isListed)
  }

  const columns: ColumnsType<ReadWarehouseItemDto> = [
    {
      title: 'Title',
      dataIndex: 'product',
      render: (item) => <div>{item?.title}</div>,
    },
    {
      title: 'Price',
      dataIndex: '',
      sorter: (a, b) => a.price! - b.price!,
      render: (item) => <strong>{item?.price}</strong>,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: (a: ReadWarehouseItemDto, b: ReadWarehouseItemDto) => a.quantity! - b.quantity!,
      render: (item) => (
        <div>
          <strong>{item}</strong>
        </div>
      ),
    },
    {
      title: 'Original Quantity',
      dataIndex: 'originalQuantity',
      render: (item) => (
        <div>
          <strong>{item}</strong>
        </div>
      ),
    },
    {
      title: 'Date Added',
      sorter: (a, b) => sortByDate(a.dateCreated!, b.dateCreated!),
      render: (item) => <strong>{getUiDate(item?.dateCreated)}</strong>,
    },
    {
      title: 'Category',
      dataIndex: '',
      sorter: (a, b) =>
        sortAlphabetically(a?.product!.categories![0].title!, b.product?.categories![0].title!),
      render: (item: ReadWarehouseItemDto) => (
        <strong>
          {item.product?.categories?.length &&
            item.product?.categories.map((c) => <div key={c.id}>{c.title}</div>)}
        </strong>
      ),
    },
    {
      title: 'Sale Point',
      dataIndex: '',
      render: (item: ReadWarehouseItemDto) => (
        <strong>{item.product?.salePoint && item.product?.salePoint.title}</strong>
      ),
    },
    {
      title: 'Show in Cashier',
      dataIndex: '',
      key: 'x',
      render: (item: ReadWarehouseItemDto) => (
        <div>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="cbx1">
            <Switch
              id="cbx1"
              checked={item?.isListed}
              onChange={(checked) => {
                changeIsListed(item, checked)
              }}
            />
          </label>
        </div>
      ),
    },
    {
      title: 'Change',
      render: (item: ReadWarehouseItemDto) => (
        <Button
          primary
          onClick={() =>
            history.push(getPagePathRoleBased(`warehouse/${item.id}`, UserStore.currentRole!))
          }
        >
          Edit
        </Button>
      ),
    },
  ]

  return (
    <div>
      <TabsContainer>
        <CategoryTabs>
          <TabVirtual
            id=""
            onClick={(id) => {
              WarehouseStore.setCategoryId('')
            }}
            title="All"
            isActive={WarehouseStore.currentCategoryId === ''}
          />
          {WarehouseStore.categories.map((c) => (
            <TabVirtual
              id={c?.id ?? ''}
              onClick={(id) => {
                WarehouseStore.setCategoryId(id)
              }}
              key={c?.id}
              title={c?.title ?? ''}
              isActive={c?.id === WarehouseStore.currentCategoryId}
            />
          ))}
        </CategoryTabs>
      </TabsContainer>

      <ListSearch
        setValue={WarehouseStore.setFilterProductTitle}
        value={WarehouseStore.filter}
        placeholder="Filter by Product title"
      />
      <Table
        columns={columns}
        dataSource={WarehouseStore.filteredProducts.slice()}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 50
        }}
      />
    </div>
  )
})
