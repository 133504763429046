import React, { ReactNode, useMemo, useState } from 'react'
import * as R from 'ramda'
import { TableColumnType } from 'antd'
import { isMobile } from 'react-device-detect'
import { Table as TableLib, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import styled from 'styled-components'
import Sorting, { SortDirection, SortDirections } from '../sorting/Sorting'

const Container = styled.div`
  width: 100%;
  table {
    th {
      padding: 5px 0;
      text-align: left;
      font-size: 14px;
      border-bottom: solid 1px #bababa;
    }
    tr {
      td {
        padding: 5px 0;
        font-size: 14px;
      }
      &:hover {
        td {
          background-color: #d0d0d0;
        }
      }
      &:nth-child(2n+1) {
        td {
          background-color: #ececec;
        }
        &:hover {
        td {
          background-color: #d0d0d0;
        }
      }
      }
    }
  }
`

type TableColumn<T> = Omit<TableColumnType<T>, 'sorter'> & {
  sortable?: Boolean
}

interface HasId {
  id: string
}

type DataHasId<T> = T extends T & HasId ? T : T & { id: string }

interface TableProps<T> {
  columns: Array<TableColumn<DataHasId<T>>>
  data: Array<T>
  sortColumnDefault?: string
}

function Table<T>({ columns, data, sortColumnDefault }: TableProps<T>) {
  const renderTableCell = (value: string | ReactNode, key: string) => <Td key={key}>{value}</Td>

  const [sortColumnName, setSortColumnName] = useState<Array<string>>([sortColumnDefault || ''])
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirections.ASC)

  const sortedData = useMemo(() => {
    if (data.length === 0) return data

    return R.sortWith([
      sortDirection === SortDirections.ASC
      // @ts-ignore
      ? R.ascend<any>(R.path(sortColumnName))
      // @ts-ignore
        : R.descend<any>(R.path(sortColumnName)),
    ])(data)
    
  }, [data, sortDirection, sortColumnName])

  return (
    <Container>
      {columns.length > 0 ? (
        <TableLib>
          <>
            <Thead>
              <Tr>
                {columns.map((column) => (
                  <Th key={column.dataIndex as string}>
                    {(!isMobile && column.sortable) ? (
                      <Sorting
                        label={column.title as string}
                        setCurrentDirection={(direction) => {
                          setSortColumnName(column.dataIndex as Array<string>)
                          setSortDirection(direction)
                        }}
                      />
                    ) : (
                      <div>{column.title as string}</div>
                    )}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {sortedData.map((dataRow: any, indexRow: any) => (
                <Tr key={dataRow.id}>
                  {columns.map((column) => {
                    const path = R.cond<any, Array<string>>([
                      [R.is(String), R.split('.')],
                      [R.T, R.identity],
                    ])(column.dataIndex as string)

                    const value = R.pathOr('', path, dataRow)
                    if (column.render) {
                      return renderTableCell(
                        column.render(value, dataRow, indexRow) as ReactNode,
                        dataRow.id
                      )
                    }
                    return renderTableCell(value, dataRow.id)
                  })}
                </Tr>
              ))}
            </Tbody>
          </>
        </TableLib>
      ) : (
        <div>No Data to Display</div>
      )}
    </Container>
  )
}

export default Table
