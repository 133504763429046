import React, { useEffect, useState } from 'react'
import { Button } from 'shared/components'
import { MemberQuoteStore } from 'store/member-quote.store'
import { PaymentStore } from 'store/payment.store'
import PayMemberQuoteModal from './pay-member-quote.modal'

type ImportMembersTriggerProps = {
  memberId: string
}

function PayMemberQuoteTrigger({ memberId }: ImportMembersTriggerProps) {
  const [show, setShow] = useState<boolean>(false)
  useEffect(() => {
    MemberQuoteStore.load()
  }, [])
  return (
    <>
      <Button
        block
        primary
        onClick={() => {
          setShow(true)
        }}
      >
        Pay Quote
      </Button>
      {show && (
        <PayMemberQuoteModal
          onSubmit={PaymentStore.payMemberQuote}
          memberId={memberId}
          onClose={() => setShow(false)}
        />
      )}
    </>
  )
}

export default PayMemberQuoteTrigger
