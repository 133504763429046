import { privateWebAppPath } from './constants'
import { Roles } from '../auth/Roles'

export const getPagePath = (pagePath: string) => `${privateWebAppPath}/${pagePath}`

export const getPagePathRoleBased = (pagePath: string, role: Roles) => {
    const rolePath = {
        [Roles.OWNER]: 'owner',
        [Roles.DISPENSARY]: 'dispensary',
        [Roles.MANAGER]: 'manager',
    }

    return `${privateWebAppPath}/${rolePath[role]}/${pagePath}`

}
