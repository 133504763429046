import React from 'react'
import MemberAddFeature from 'features/member/member-add/member-add.feature'
import { CommonPage } from 'shared/layout/CommonPage'

export default function AddMemberPage() {
  return (
    <CommonPage>
      <MemberAddFeature />
    </CommonPage>
  )
}
