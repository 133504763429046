import { makeAutoObservable } from 'mobx'
import { AddMemberFormValues } from 'features/member/member-add/add-member-form-fields'
import Moment from 'moment'
import { Effect } from './utils/Effect'
import { RequestHelper } from './utils/RequestHelper'
import { AreasStore } from './areas.store'
import ApiClient from '../api'
import { CreateRegistrationDto } from '../api-client'

export class RegistrationsStoreClass {
  constructor() {
    makeAutoObservable(this)
  }

  registrationsEffect = new Effect(() =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().appointments.getApiV1Appointments(AreasStore.selectedAreaId)
    )
  )

  byIdEffect = new Effect((id) =>
    RequestHelper.unwrapFromFetchResponse(ApiClient().appointments.getApiV1Registration1(id))
  )

  updateRegistrationEffect = new Effect(
    (data, id) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().appointments.putApiV1Registration(id, data),
        'Registration data updated.'
      ),
    'Updating registration Data.'
  )

  addAppointmentEffect = new Effect((request: CreateRegistrationDto) =>
    RequestHelper.unwrapFromFetchResponse(ApiClient().appointments.postApiV1Appointments(request))
  )

  initAppointmentPage = () => {
    this.byIdEffect.data = {}
  }

  addAppointment = async () => {
    if (this.registration) {
      await this.addAppointmentEffect.run({
        ...this.registration,
        areaId: AreasStore.selectedAreaId,
      })
    }
  }

  loadRegistrations = async () => {
    await this.registrationsEffect.run()
  }

  get registrations() {
    return this.registrationsEffect.data
  }

  get registration() {
    return this.byIdEffect.data
  }

  get appointmentTime() {
    return Moment(this.registration?.appointmentTime)
  }

  get sign() {
    return this.registration?.sign
  }

  loadById = async (id: string) => {
    await this.byIdEffect.run(id)
  }

  updateRegistration = async (id: string) => {
    await this.updateRegistrationEffect.run(
      {
        ...this.registration,
        areaId: AreasStore.selectedAreaId,
      },
      id
    )
    this.byIdEffect.data = this.updateRegistrationEffect.data
  }

  get viewData() {
    const data: any = {
      sign: this.registration?.sign,
      photo: this.registration?.photo,
      photoDocument: this.registration?.photoDocument,
      personalData: {
        ...this.registration,
        birthDate: this.registration?.birthDate ?? null,
      },
    }
    return data
  }

  setAppointmentTime = (dateTime?: string) => {
    this.byIdEffect.data = {
      ...this.registration,
      appointmentTime: dateTime,
    }
  }

  setSignature = (signature: string) => {
    this.byIdEffect.data = {
      ...this.registration,
      sign: signature,
    }
  }

  setPhoto = (photo: string) => {
    this.byIdEffect.data = {
      ...this.registration,
      photo,
    }
  }

  setPhotoDocument = (photo: string) => {
    this.byIdEffect.data = {
      ...this.registration,
      photoDocument: photo,
    }
  }

  setPersonalData = (data: AddMemberFormValues) => {
    this.byIdEffect.data = {
      ...this.registration,
      ...data,
    }
  }
}

export const RegistrationsStore = new RegistrationsStoreClass()
