import React, { useState } from 'react'
import { Button } from 'shared/components'
import { MemberQuoteStore } from 'store/member-quote.store'
import { observer } from 'mobx-react-lite'
import { MemberQuoteAddForm } from './member-quote-add.form'

interface MemberQuoteAddFeatureProps {
  id?: string
  onSuccess?: () => void
}

export const MemberQuoteAddFeature: React.FC<MemberQuoteAddFeatureProps> = observer(
  ({ onSuccess }) => {
    const [formData, setFormData] = useState<any>(null)

    const onSubmit = () => {
      MemberQuoteStore.create(formData.title, formData.period, formData.price).then(
        () => onSuccess && onSuccess()
      )
    }
    return (
      <div>
        <MemberQuoteAddForm setFormData={setFormData} formData={formData} />
        <Button onClick={onSubmit}>Create</Button>
      </div>
    )
  }
)
