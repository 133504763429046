import React, { ReactNode } from 'react'
import classnames from 'classnames'
import './PageSection.scss'

export interface IPageSectionProps {
  children?: ReactNode
  title?: string
  icon?: ReactNode
  footer?: ReactNode
  actions?: ReactNode
  flex?: boolean
  className?: string
}

export function PageSection(props: IPageSectionProps) {
  const { children, title, icon, flex = false, footer, actions, className } = props

  const classNames = classnames(
    className,
    'common-page-section',
    flex && 'common-page-section_flex'
  )

  return (
    <div className={classNames}>
      <div className="common-page-section__head">
        {title && <div className="common-page-section__title">{title}</div>}
        {actions && <div className="common-page-section__actions">{actions}</div>}
      </div>
      {icon && <div className="common-page-section__icon">{icon}</div>}
      {children && <div className="common-page-section__body">{children}</div>}
      {footer && <div className="common-page-section__footer">{footer}</div>}
    </div>
  )
}
