import React from 'react'
import { JournalEntry } from 'shared/models/journal-entry'
import { getFormattedDate } from 'shared/dates/formatters'
import { AppUser } from 'api-client'

export const columns = [
  {
    title: 'Created Date',
    dataIndex: 'dateCreated',
    render: (item: string) => <div>{getFormattedDate(item)}</div>,
  },
  {
    title: 'Debit',
    dataIndex: 'journalEntry',
    render: (item: JournalEntry) => (
      <div>
        <div>Code: {item.debit.code}</div>
        <div>Title: {item.debit.title}</div>
      </div>
    ),
  },
  {
    title: 'Credit',
    dataIndex: 'journalEntry',
    render: (item: JournalEntry) => (
      <div>
        <div>Code: {item.credit.code}</div>
        <div>Title: {item.credit.title}</div>
      </div>
    ),
  },
  {
    title: 'Count',
    dataIndex: 'journalEntry',
    render: (item: JournalEntry) => (
      <div>
        <strong>{item.count}</strong>
      </div>
    ),
  },
  {
    title: 'Discount',
    dataIndex: 'discount',
    render: (item: number) => <div>{item ?? 0}</div>,
  },
  {
    title: 'Assigner',
    dataIndex: 'assigner',
    render: (item: AppUser) => (
      <div>
        <div>{item?.personalData?.username}</div>
        <div>{item?.personalData?.firstName}</div>
        <div>{item?.personalData?.lastName}</div>
      </div>
    ),
  },
  {
    title: 'member',
    dataIndex: 'member',
    render: (item: AppUser) => (
      <div>
        <div>{item?.personalData?.username}</div>
        <div>{item?.personalData?.firstName}</div>
        <div>{item?.personalData?.lastName}</div>
      </div>
    ),
  },
]
