import React from 'react'
import { observer } from 'mobx-react-lite'
import i18n from 'locales/i18n'
import { NavigationMenu, RoutePageElement } from 'router/NavigationMenu'
import { getPagePath } from 'router/utils'

const basePath = 'manager'
const managerPages: RoutePageElement[] = [
  {
    path: getPagePath(`${basePath}/dashboard`),
    title: `${i18n.t('dashboard')}`,
    icon: 'direction',
  },
  {
    path: getPagePath(`${basePath}/search`),
    title: 'Members',
    icon: 'search-user',
    items: [
      {
        path: getPagePath(`${basePath}/members/edit`),
        title: 'Add Member',
      },
      {
        path: getPagePath(`${basePath}/registrations/edit`),
        title: 'Add Appointment',
      },
      {
        path: getPagePath(`${basePath}/registrations`),
        title: 'Appointments',
      },
    ],
  },
  {
    path: getPagePath(`${basePath}/cashier/list`),
    title: 'Bar',
    icon: 'shopping-cart',
  },
  {
    path: getPagePath(`${basePath}/area/qr/registration`),
    title: 'QR Code Registration',
    icon: 'qrcode',
  },
  {
    path: getPagePath(`${basePath}/area/qr/signature`),
    title: 'QR Code Signature',
    icon: 'qrcode',
  },
]

export const ManagerNavigationMenu: React.FC = observer((props) => (
  <NavigationMenu elements={managerPages} theme="manager" />
))
