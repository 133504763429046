import React from 'react'
import styled from 'styled-components'

type BadgeStatuses = 'failure' | 'success'
type BadgeStatusProps = {
  children: string
  status: BadgeStatuses
}

const colors: Record<BadgeStatuses, string> = {
  failure: '#c90e0e',
  success: '#05a920',
}

const Container = styled.div<{
  backgroundColor: string
}>`
  padding: 2px 8px;
  border-radius: 2px;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#bababa')};
  width: auto;
`

const Text = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #fff;
`

function BadgeStatus({ children, status }: BadgeStatusProps) {
  return (
    <Container backgroundColor={colors[status]}>
      <Text>{children}</Text>
    </Container>
  )
}

export default BadgeStatus
