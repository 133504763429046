import { MemberQuoteListFeature } from 'features/member/member-quote/member-quote-list.feature'
import { CommonPage } from 'shared/layout/CommonPage'
import { Button } from 'shared/components'
import React, { useEffect, useState } from 'react'
import { EntityAddModal } from 'shared/components/modal/entity-modal/EntityModal'
import { MemberQuoteAddFeature } from 'features/member/member-quote/member-quote-add/member-quote-add.feature'
import { AreasStore } from 'store/areas.store'
import { MemberQuoteStore } from 'store/member-quote.store'
import { Preloader } from 'shared/components/preloader'
import { observer } from 'mobx-react-lite'

export const MemberQuoteListPage = observer(() => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false)

  useEffect(() => {
    MemberQuoteStore.load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AreasStore.selectedAreaId])

  return (
    <>
      {showAddModal && (
        <EntityAddModal
          title="Add Quote"
          entityForm={
            <MemberQuoteAddFeature
              onSuccess={() => {
                setShowAddModal(false)
              }}
            />
          }
          onClose={() => {
            setShowAddModal(false)
          }}
        />
      )}
      {MemberQuoteStore.loadStatus.isLoading ? (
        <Preloader message="Loading Quotes." lower />
      ) : (
        <CommonPage
          isMounted={!MemberQuoteStore.loadStatus.isLoading}
          title="Member Quotes"
          actions={
            <Button
              primary
              onClick={() => {
                setShowAddModal(true)
              }}
            >
              Add Quote
            </Button>
          }
        >
          <MemberQuoteListFeature />
        </CommonPage>
      )}
    </>
  )
})
