import React from 'react'
import { observer } from 'mobx-react-lite'
import { Select } from 'antd'
import { AreasStore } from 'store/areas.store'

interface AreaSelectorProps {
  afterSelect?: () => void
}

export const AreaSelectFeature = observer(({ afterSelect }: AreaSelectorProps) => {
  const { commonAreas, setSelectedAreaId, selectedAreaId } = AreasStore
  return (
    <Select
      defaultValue={selectedAreaId}
      onSelect={(value) => {
        setSelectedAreaId(value)
        if (afterSelect) afterSelect()
      }}
      style={{ width: '100%' }}
    >
      {commonAreas.map((item) => (
        <Select.Option key={item.id} value={item.id}>
          {item.title}
        </Select.Option>
      ))}
    </Select>
  )
})
