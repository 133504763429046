import React, { ReactNode } from 'react'
import Icon from 'shared/components/icon'

export interface ModalHeaderProps {
  closeable?: boolean
  onClose?: () => void
  children?: ReactNode
}

export function ModalHeader({ children, closeable = true, onClose }: ModalHeaderProps) {
  return (
    <div className="modal-header">
      <h1 className="modal-title">{children}</h1>
      {closeable && (
        <span
          role="button"
          tabIndex={0}
          onKeyDown={onClose}
          className="modal-close"
          onClick={onClose}
        >
          <Icon name="cross" color="#6d6d6d" size={18} />
        </span>
      )}
    </div>
  )
}
