import { action } from 'mobx'
import { ViewModelClass } from 'store/utils/ViewModel'
import { WarehouseRevisionsStore } from 'store/warehouse-revisions.store'

class WarehouseRevisionsViewModelClass extends ViewModelClass {
  @action init = async () => {
    this.startLoading()
    await WarehouseRevisionsStore.getAllRevisions()
    this.stopLoading()
  }

  @action initRevision = async (id: string) => {
    this.startLoading()
    await WarehouseRevisionsStore.getRevisionById(id)
    this.stopLoading()
  }
}

export const WarehouseRevisionsViewModel = new WarehouseRevisionsViewModelClass()
