import { action, makeAutoObservable, observable } from 'mobx'
import { Effect } from './utils/Effect'
import { RequestHelper } from './utils/RequestHelper'
import ApiClient from '../api'
import { AreasStore } from './areas.store'
import { getRoundedDecimal } from "../shared/utils/get-rounded-decimal";
import { subs } from "../shared/utils/math";

class BalanceStoreClass {
  @observable result: boolean = false

  @observable loading: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  getMemberBalanceEffect = new Effect(
    (id) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().balance.getApiV1Balance(id, AreasStore.selectedAreaId)
      ),
    '',
    true
  )

  @action getMemberBalance = async (memberId: string) => {
    this.startLoading()
    this.getMemberBalanceEffect.run(memberId).then(() => {
      this.stopLoading()
    })
  }

  @action setCurrentBalance = (balance: number) => {
    this.getMemberBalanceEffect.data = balance
  }

  @action addToBalance = (balance: number) => {
    this.getMemberBalanceEffect.data = balance + this.currentBalance
  }



  @action reduceCurrentBalance = (diversion: number) => {
    const balance = subs(this.currentBalance, diversion)
    this.getMemberBalanceEffect.data = balance
  }

  @action startLoading = () => {
    this.loading = true
  }

  @action stopLoading = () => {
    this.loading = false
  }

  @observable get currentBalance() {
    return this.getMemberBalanceEffect.data ? getRoundedDecimal(this.getMemberBalanceEffect.data) : 0
  }
}

export const BalanceStore = new BalanceStoreClass()
