import React from 'react'
import { useParams } from 'react-router-dom'
import { CommonPage } from 'shared/layout/CommonPage'
import ProductEditFeature from 'features/product/product-add/product-edit.feature'

function ProductEditPage() {
  const { id } = useParams<{
    id: string
  }>()

  return (
    <CommonPage title="Product EDIT Page ">
      <ProductEditFeature id={id} />
    </CommonPage>
  )
}

export default ProductEditPage
