import React from 'react'
import classNames from 'classnames'
import './product.scss'
import { WarehouseItemReadShortDto } from 'api-client'
import styled from 'styled-components'
import Icon from '../icon'

const OutOfStock = styled.div`
  font-size: 18px;
`
const Footer = styled.div`
  display: flex;
  background-color: #039965;
  color: #fff;
  font-weight: 600;
  padding: 5px;
  margin-left: -8px;
  margin-right: -8px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom: solid 1px #0adc93;
  font-size: 16px;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  * {
    cursor: pointer;
    &:hover {
      color: #0adc93;
    }
  }
`

export interface ProductComponentProps {
  productWithQuantity: WarehouseItemReadShortDto
  quantityInWarehouse: number
  checkoutView?: boolean
  onClick?: () => void
  goToBatch?: (id: string) => void
  outOfStock?: boolean
}

export function ProductComponent(props: ProductComponentProps) {
  const { productWithQuantity, checkoutView, onClick, goToBatch, outOfStock, quantityInWarehouse } =
    props

  const { price, productTitle } = productWithQuantity

  const productClassNames = classNames({
    product: true,
    'product_checkout-view': checkoutView,
  })

  return (
    <div className="product-wrapper">
      <div
        tabIndex={-1}
        role="button"
        onKeyDown={onClick}
        className={productClassNames}
        onClick={onClick}
      >
        <div className="product__header">
          <div className="product__header-title">{productTitle}</div>
        </div>

        <div className="product__body">
          <div>
            Products Left: <b>{quantityInWarehouse}</b>
          </div>
        </div>

        <Footer>
          <div className="product__price">
            {price} credits
            {outOfStock && (
              <OutOfStock>
                <span>Out Of Stock</span>
              </OutOfStock>
            )}
          </div>
          <Actions>
            <Icon
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                if (goToBatch) goToBatch(productWithQuantity.id!)
              }}
              name="settings"
            />
          </Actions>
        </Footer>
      </div>
    </div>
  )
}
