import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'shared/components/modal'
import { AreaSelectFeature } from '../area-select'

type AreaResolverModalProps = {
  onClose?: () => void
}

export function AreaResolverModal({
  onClose,
}: AreaResolverModalProps) {
  return (
    <Modal>
      <ModalHeader onClose={onClose}>Please choose current Area</ModalHeader>
      <ModalBody>
        <AreaSelectFeature afterSelect={onClose} />
      </ModalBody>
    </Modal>
  )
}
