import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { CashierFeature } from 'features/cashier/cashier.feature'
import { AreasStore } from 'store/areas.store'
import { CommonPage } from 'shared/layout/CommonPage'
import './cashier.page.scss'
import { Preloader } from 'shared/components/preloader'
import { CashierViewModel } from 'view-models/cashier.view-model'

const CashierPage = observer(() => {
  useEffect(() => {
    CashierViewModel.initCashier()
  }, [])

  return CashierViewModel.loading ? (
    <Preloader message="Initializing Cashier." lower />
  ) : (
    <CommonPage isMounted={!CashierViewModel.loading}>
      <CashierFeature salePointId={AreasStore.getAreaData?.settings?.managerDefaultSalePointId} />
    </CommonPage>
  )
})
export default CashierPage
