import React from 'react'
import { useParams } from 'react-router-dom'
import { CommonPage } from 'shared/layout/CommonPage'
import MemberAddFeature from 'features/member/member-add/member-add.feature'

export default function EditMemberPage() {
  const { id } = useParams<{ id: string }>()
  return (
    <CommonPage title="Edit member">
      <MemberAddFeature id={id} />
    </CommonPage>
  )
}
