import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { ManagerNavigationMenu } from 'modules/manager/router/ManagerNavigationMenu'
import { ContentLayout } from 'shared/layout'
import { LayoutSidebar } from 'shared/layout/sidebar/LayoutSidebar'
import { CurrentUser } from 'auth/components/CurrentUser'
import { LayoutSidebarSection } from 'shared/layout/sidebar/LayoutSidebarSection'
import MemberQuoteListPage from 'modules/manager/pages/member-quote-list.page'
import AddMemberPage from 'modules/shared/add-member-page/add-member.page'
import EditMemberPage from 'modules/shared/add-member-page/edit-member.page'
import MembersListPage from 'modules/shared/members-list/members-list.page'
import MemberProfilePage from 'modules/shared/member-profile/member-profile.page'
import CashierPage from 'modules/shared/cashier-page/cashier.page'
import ProductAddPage from 'modules/manager/pages/product-add.page'
import ProductsPage from 'modules/shared/products/products.page'
import ProductCategoryListPage from 'modules/shared/products/product-category-list.page'
import ProductCategoryAddPage from 'modules/admin/components/product-category-add-page/product-category-add.page'
import SalePointListPage from 'modules/shared/products/sale-point-list.page'
import SalePointAddPage from 'modules/admin/components/sale-point-add-page/sale-point-add.page'
import MemberQuoteAddPage from 'modules/manager/pages/member-quote-add.page'
import AreaQrSignaturePage from 'modules/manager/pages/club-qr.page'
import DashboardPage from 'modules/manager/pages/dashboard/dashboard.page'
import RegistrationListPage from 'modules/manager/pages/registration-list.page'
import MemberRegistrationEditPage from 'modules/manager/pages/member-registration-edit.page'
import AreaQrRegistrationPage from 'modules/manager/pages/area-qr-registration.page'
import { AreaDataResolver } from 'features/area/area-resolver'
import { GoToMemberProfileFeature } from 'features/go-to-member-profile/go-to-member-profile.feature'
import { AreasStore } from 'store/areas.store'
import { getPagePath } from 'router/utils'
import { AreaRelatedInit } from 'AreaRelatedInit'
import ProductCategoryEditPage from "../../owner/pages/product-category-edit.page";

export const ManagerRoutes: React.FC = observer(() => {
  const path = getPagePath('manager')

  return (
    <AreaDataResolver>
      <AreaRelatedInit>
        <ContentLayout
          sidebar={
            <LayoutSidebar>
              <LayoutSidebarSection profile>
                <CurrentUser />
              </LayoutSidebarSection>
              <LayoutSidebarSection>
                <GoToMemberProfileFeature />
              </LayoutSidebarSection>
              <LayoutSidebarSection fullHeight>
                <ManagerNavigationMenu />
              </LayoutSidebarSection>
            </LayoutSidebar>
          }
        >
          <Switch>
            <Route path={`${path}`} exact>
              <Redirect to={`${path}/dashboard`} />
            </Route>

            <Route path={`${path}/dashboard`}>
              <DashboardPage />
            </Route>

            <Route path={`${path}/search`}>
              <MembersListPage />
            </Route>

            <Route path={`${path}/members/edit`} exact>
              <AddMemberPage />
            </Route>

            <Route path={`${path}/members/edit/:id`}>
              <EditMemberPage />
            </Route>

            <Route path={`${path}/members/:id`}>
              <MemberProfilePage />
            </Route>

            <Route path={`${path}/registrations`} exact>
              <RegistrationListPage />
            </Route>

            <Route path={`${path}/registrations/edit`} exact>
              <MemberRegistrationEditPage />
            </Route>

            <Route path={`${path}/registrations/edit/:id`} exact>
              <MemberRegistrationEditPage />
            </Route>

            <Route path={`${path}/cashier/list`}>
              <CashierPage />
            </Route>

            <Route path={`${path}/products/list`}>
              <ProductsPage />
            </Route>

            <Route path={`${path}/products/edit`}>
              <ProductAddPage />
            </Route>

            <Route path={`${path}/area/qr/registration`}>
              <AreaQrRegistrationPage areaId={AreasStore.selectedAreaId} />
            </Route>

            <Route path={`${path}/area/qr/signature`}>
              <AreaQrSignaturePage areaId={AreasStore.selectedAreaId} />
            </Route>

            <Route path={`${path}/member-quote/list`}>
              <MemberQuoteListPage />
            </Route>
            <Route path={`${path}/member-quote/edit`}>
              <MemberQuoteAddPage />
            </Route>

            <Route path={`${path}/product-categories/edit`} exact>
              <ProductCategoryAddPage />
            </Route>

            <Route path={`${path}/product-categories/edit/:id`}>
              <ProductCategoryEditPage />
            </Route>

            <Route path={`${path}/product-categories/list`}>
              <ProductCategoryListPage />
            </Route>

            <Route path={`${path}/sale-points/edit`}>
              <SalePointAddPage />
            </Route>
            <Route path={`${path}/sale-points/list`}>
              <SalePointListPage />
            </Route>

            <Route path={`${path}/settings`}>
              <h2>Settings</h2>
            </Route>

            <Route path="*">Page not found</Route>
          </Switch>
        </ContentLayout>
      </AreaRelatedInit>
    </AreaDataResolver>
  )
})
