import {
  OpenAPI,
  AreaService,
  ManagersService,
  UserService,
  ProductsService,
  ProductCategoriesService,
  RegistrationService,
  WarehouseService,
  MemberQuoteService,
  AccountingService,
  SalePointsService,
  MembersService,
  ReportsService,
  BalanceService,
  OwnerService,
  PaymentService,
  QrCodeDataService,
  WebSocketService,
  WarehouseRevisionBatchesService,
  FinancesService,
} from '../api-client'
import { authenticationService } from '../auth/authenticationService'

function ApiClient() {
  // eslint-disable-next-line @typescript-eslint/dot-notation
  OpenAPI.BASE = process.env['REACT_APP_API_BASE_URL'] as string
  OpenAPI.TOKEN = authenticationService.getToken() as string
  return {
    managers: ManagersService,
    areas: AreaService,
    users: UserService,
    products: ProductsService,
    productCategories: ProductCategoriesService,
    appointments: RegistrationService,
    warehouse: WarehouseService,
    warehouseRevisions: WarehouseRevisionBatchesService,
    quotes: MemberQuoteService,
    accounting: AccountingService,
    salePoints: SalePointsService,
    members: MembersService,
    memberQuotes: MemberQuoteService,
    reposts: ReportsService,
    balance: BalanceService,
    owners: OwnerService,
    payments: PaymentService,
    qr: QrCodeDataService,
    webSocket: WebSocketService,
    finances: FinancesService,
  }
}

export default ApiClient

export type { SalePoint } from './models/sale-point'
