import TextArea from 'antd/es/input/TextArea'
import React from 'react'

interface RevisionCommentProps {
  comment: string
  setComment: (comment: string) => void
}

export function RevisionComment({ comment, setComment }: RevisionCommentProps) {
  return (
    <TextArea
      style={{ height: '80px' }}
      onBlur={(e) => setComment(e.target.value)}
      defaultValue={comment}
    />
  )
}
