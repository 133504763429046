import React, { ReactNode } from 'react'
import { CSSTransition } from 'react-transition-group'
import './mount-animation.scss'

interface MountAnimationProps {
  timeout?: number
  isVisible: boolean
  unmountOnExit?: boolean
  classNames?: string
  children?: ReactNode
}

export function MountAnimation({
  children,
  timeout = 200,
  isVisible = false,
  unmountOnExit = true,
  classNames = 'transition-translate-y',
  ...restProps
}: MountAnimationProps) {
  const nodeRef = React.useRef(null)
  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={isVisible}
      timeout={timeout}
      classNames={classNames}
      unmountOnExit={unmountOnExit}
      {...restProps}
    >
      <div>{children}</div>
    </CSSTransition>
  )
}
